import React, { Component } from 'react';
import arrow from '../../../assets/images/arrow-blue.svg';
import Modal from 'react-awesome-modal';
import close from '../../../assets/logo/close.svg';
import i18next from 'i18next';

var noScroll = require('no-scroll');

export default class AsientoBeneftis extends Component {
	constructor(props) {
		super(props);
		this.state = {
			visible: false
		};
		this.escFunction = this.escFunction.bind(this);
		this.setWrapperRef = this.setWrapperRef.bind(this);
	}

	componentDidMount() {
		setTimeout(() => this.setState({ loading: false }), 1500);
		document.addEventListener('keydown', this.escFunction, false);
	}

	UNSAFE_componentWillMount() {
		document.addEventListener('mousedown', this.handleClick, false);
	}
	escFunction(event) {
		if (event.keyCode === 27) {
			this.setState({
				modalIsShow: false,
				modalIsShowIdiomas: false,
				visible: false
			});
			noScroll.off();
		}
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.escFunction, false);
		document.removeEventListener('mousedown', this.handleClick, false);
	}
	handleClick = e => {
		if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
			this.setState({
				visible: false
			});
			noScroll.off();
		}
	};
	setWrapperRef(node) {
		this.wrapperRef = node;
	}

	openModal() {
		this.setState({
			visible: true
		});
		noScroll.on();
	}

	closeModal() {
		this.setState({
			visible: false
		});
		noScroll.off();
	}

	render() {
		return (
			<div>
				<Modal
					visible={this.state.visible}
					width="auto"
					height="auto"
					effect="fadeInUp"
					onClickAway={() => this.closeModal()}
				>
					<div className="modalWindowAncillaries">
						<div
							className="closeModal"
							onClick={() => this.closeModal()}
						>
							<img src={close} alt='cerrado'></img>
						</div>

						<div className="topBox">
							<div className="header">
								<p className="title">
									{i18next.t(
										'MiExperAM.travel.ancillares.asiento.title'
									)}
								</p>
							</div>

							<div className="contentType">
								<p>
									{i18next.t(
										'MiExperAM.travel.ancillares.asiento.subModal'
									)}
								</p>
							</div>
						</div>
					</div>
				</Modal>

				<a
					className="totalBenefisTravel__card seatsfree"
					onClick={() => this.openModal()}
				>
					<div className="totalBenefitsTravel__card__image" />
					<h5 className="totalBenefitsTravel__card__title">
						{i18next.t('MiExperAM.travel.ancillares.asiento.title')}
					</h5>
					<p className="totalBenefitsTravel__card__text">
						{i18next.t('MiExperAM.travel.ancillares.asiento.sub')}
					</p>
					<a
						className="travel-link flex items-center justify-start"
						to="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/eabautista_aeromexico_com/EeYuMCfE4xJCotcla-TwkXYB9FWIIjWNY9It6l5h4oMkOQ?e=Z7ZRmC"
					>
						<h6 className="totalBenefitsTravel__card__more">
							{i18next.t('MiExperAM.travel.ancillares.conoce')}{' '}
						</h6>
						<img
							className="blue-arrow mr-2"
							src={arrow}
							alt="blue-arrow"
						/>{' '}
					</a>
				</a>
			</div>
		);
	}
}
