import React, { Component, Fragment } from "react";
import arrow from "../../../assets/images/arrow-blue.svg";
import "../css/moreTravelBenefits.css";
import { Link } from "react-router-dom";
import "./css/modal.css";

import close from "../../../assets/logo/close.svg"
import Modal from 'react-awesome-modal';
import i18next from "i18next";


var noScroll = require('no-scroll');

export default class TotalBenefits extends Component {
  constructor() {
    super();
    this.state = {
      modalIsShow: false,
      visible:false,
    };
    this.escFunction = this.escFunction.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
  }
  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
    document.addEventListener("mousedown", this.handleClick, false);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
    document.removeEventListener("mousedown", this.handleClick, false);
  }
  escFunction(event) {
    if (event.keyCode === 27) {
      this.setState({
        visible: false
      });
      noScroll.off();
    }
  }
  handleClick = e => {
    if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
      this.setState({
        modalIsShow: false
      });
      noScroll.off();
    }
  };

  openModal() {
    this.setState({
        visible : true
    });
    noScroll.on();
  }

  closeModal() {
      this.setState({
          visible : false
      });
      noScroll.off();
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }
  showAlertModal = () => {
    this.setState({
      modalIsShow: true
    });
    
  };
  render() {
    return (

      <div className={
        "total-benefits-big01" + " " +
        this.props.data.fields.paymentGroupEmployee
          .toString()
          .replace(/,/g, ' ')}>

      <div className={
        "total-benefits-big cards-container" +
        " " +
        this.props.data.fields.myBenefitCategoryClass +
        " " +
        this.props.data.fields.paymentGroupEmployee
          .toString()
          .replace(/,/g, ' ')
      }>
      <div
      onClick={() => this.openModal()}
      >

        <div className="benefits-simple-card">
          <h5 className="moreTravelBenefits__card__title">
            {this.props.data.fields.myBenefitTitle}
          </h5>
          <p className="moreTravelBenefits__card__text">
            {this.props.data.fields.myBenefitIntro}
          </p>
          <a className="link-benefitss" onClick={() => this.openModal()}>
            <div className="moreTravel">
              <h6 className="moreTravelBenefits__card__more">{i18next.t('MiExperAM.benef.conoce')}</h6>
              <img className="blue-arrow" src={arrow} alt="blue-arrow" />{" "}
            </div>
            <img
              className="icon-benefits"
              src={this.props.data.fields.myBenefitIcon.fields.file.url}
            />
          </a>
        </div>
        </div>
        </div>

        <Modal 
            visible={this.state.visible}
            width="auto"
            height="auto"
            effect="fadeInDown"
            onClickAway={() => this.closeModal()} className="container-modal-notification modal-minicard-benefits"
        >
          <div className="">
            <div className="modal-notification" ref={this.setWrapperRef}>
            <div class="closeModal" onClick={() => this.closeModal()}><img src={close}/></div>
              <div
                className="background-benefits"
                style={{
                  backgroundImage: `url(${
                    this.props.data.fields.myBenefitBackground.fields.file.url
                  })`
                }}
              >
                {/* <span>{this.props.data.fields.myBenefitCategoryTitleEs}</span> */}
                <span
                  className={
                    this.props.data.fields.myBenefitCategoryClass +
                    " " +
                    this.props.data.fields.paymentGroupEmployee
                      .toString()
                      .replace(",", " ")
                  }
                >
                  {this.props.data.fields.myBenefitCategoryTitleEs}
                </span>
              </div>
              <div className="content-modal">

                <div className="head-modal">
                  <div>
                    <img
                      className="icon-benefits"
                      src={this.props.data.fields.myBenefitIcon.fields.file.url}
                    />
                  </div>
                  <div>
                    <p>{this.props.data.fields.myBenefitTitle}</p>
                    <p>{this.props.data.fields.myBenefitIntro}</p>
                  </div>
                </div>

                <div className="BenefitContent">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.props.data.fields.myBenefitContentLong
                    }}
                  />
                  {/* <p>{this.props.data.fields.myBenefitContentLong}</p> */}
                  <a
                    className={
                      this.props.data.fields.myBenefitMoreInformationClass
                    }
                    href={this.props.data.fields.myBenefitInformation}
                    target="_blank"
                  >
                    {i18next.t('MiExperAM.benef.conoce')}
                  </a>
                </div>

                
              </div>

              <div className="termsConditions">
                  <p>{i18next.t('MiExperAM.benef.terminos')}</p>
                  <p>{this.props.data.fields.myBenefitTermsLong}</p>
                </div>
                
            </div>
          </div>
          </Modal>
          </div>



      

    );
  }
}
