import React, { Component } from "react";
import Buttons from "../News/Buttons";
import "./css/article.css";
import * as contentful from "../../lib/contentful";
import LoadingScreen from "react-loading-screen";
import ArticleCards from "../../components/Home/Cards/articleNewsMore";
import { Parallax, Background } from "react-parallax";
import getLocale from "../../utils/getLocale";
import i18next from "i18next";
import initLang from "../../utils/initLang";
import Arriba from "../Header/Arriba";
import Index from "../Footer";

export var locale;

export default class SingleNews extends Component {
	constructor() {
		super();
		this.state = {
			loading: false,
			error: null,
			entry: null,
			loading: true,
			lang: ""
		};
	}

	componentDidMount = () =>{
		initLang(this); 
		locale = getLocale(); 
		const currentUrl = window.location.pathname.split('/').pop();
    	this.fetchEntry(currentUrl);
		//this.fetchEntry(this.props.match.params.articleId);
		setTimeout(() => this.setState({ loading: false }), 1500);
		window.scrollTo(0,0)
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.props.match.params.articleId !== nextProps.match.params.articleId) {
			this.fetchEntry(nextProps.match.params.articleId);
			setTimeout(() => this.setState({ loading: false }), 1500);
		}
	}

	fetchEntry = (articleId) => {
			contentful.client
			.getEntry(articleId, { locale: locale })
			.then((entry) => {
				this.setState({
					loading: false,
					entry: entry
				});
			})
			.catch((error) => {
				this.setState({
					loading: false,
					error: error
				});
			})
			.catch((err) => console.error(err));
	};

	getEntryTag(entry) {
		if (this.state.lang === "es") {
			return entry.fields.articleTagES;
		} else {
			return entry.fields.articleTagEN;
		}
	}

	renderList = (entry) => {
		return (
			<>
			{/* <Arriba/> */}
				<div className="article-container">
					<div className="barNavButtons">
						<Buttons />
					</div>

					{/* HEADER IMAGE */}

					<div className="imageHeaderContainer">
						<div class="filterImageHeader" />

						{/* <img
                className="article-big-image"
                src={item.fields.articleCover.fields.file.url}               
              /> */}
						<div
							className="imgDivArticle"
							style={{
								backgroundImage: `url(${entry.fields.articleCover.fields.file.url})`
							}}></div>
					</div>

					{/* END HEADER IMAGE */}

					<div className="left-container">
						<div className="paddingarticle">
							<div className="container-scroll-article">
								<div className="paddingTextArticle">
									{/* ARTICLE TYPE */}
									<div className="subtitle-articles">
										<h1 className="Subtitle-art !text-[#035cf7]">{this.getEntryTag(entry)}</h1>
									</div>
									{/* END ARTICLE TYPE */}

									{/* ARTICLE TITLE */}
									<h2 className="desktop-title !text-[#020C41]">{entry.fields.articleTittle} </h2>
									{/* END ARTICLE TITLE */}

									{/* DATE */}
									<div className="Container_date">
										<h1 className="Date_articles">
											{/* Pubicado el {item.sys.createdAt} */}
											{i18next.t("News.publicado")} {entry.fields.fechaDeLaPublicacion}
										</h1>
									</div>
									{/* END DATE */}
									{/* Intro for articles */}
									<p className="introTextArticle">{entry.fields.articleIntro}</p>
									{/* END Intro for articles */}

									<div className="container-text-article">
										<div className="mobile-buttons">
											<button className="small-font">A</button>
											<button className="big-font">A</button>
										</div>

										<div className="text-article">
											<div
												dangerouslySetInnerHTML={{
													__html: entry.fields.articleContent
												}}
											/>
										</div>
									</div>
								</div>
							</div>
							<ArticleCards />
						</div>
					</div>
				</div>
				{/* <Index/> */}
			</>
		);
	};

	render() {
		const { loading, entry } = this.state;

		return <React.Fragment>{!loading && entry && this.renderList(entry)}</React.Fragment>;
	}
}
