import React, { Component, Fragment } from 'react';
import * as contentful from '../../lib/contentful';
import getLocale from '../../utils/getLocale';
import i18next from 'i18next';
import Loader from '../../components/Loader/Loader';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
//import{configureAnchors} from 'react-scrollable-anchor';
import Header from '../../components/Header';
import Index from '../../components/Footer/index';

import './css/desde.css';
import { ReactComponent as Featured } from '../../assets/images/profile/icon-mybenefits.svg';
import { ReactComponent as Viaje } from '../../assets/travel/avion-sujeto.svg';
import { ReactComponent as Bienestar } from '../../assets/images/profile/categoria-01.svg';
import { ReactComponent as Manualidades } from '../../assets/images/profile/comps/icon-comps-05.svg';
import { ReactComponent as Educacion } from '../../assets/images/profile/categoria-05.svg';
import { ReactComponent as Juegos } from '../../assets/images/profile/categoria-02.svg';
import { ReactComponent as Libros } from '../../assets/politica.svg';
import { ReactComponent as Arrow } from '../../assets/images/arrow-blue.svg';
import { animateScroll as scroll} from "react-scroll";
// import Featured from '../../assets/images/profile/icon-mybenefits.svg';

//configureAnchors({ offset: 0, scrollDuration: 200 });
export default class DesdeCasa extends Component {
	constructor(props) {
		super(props);
		this.state = {
			entries: null,
			error: null,
			featured: null,
			language: i18next.language,
			loading: true,
			scrolled: false,
		};
	}
	
	componentDidMount() {
		this.fetchEntries();
		scroll.scrollToTop()
		// window.addEventListener('scroll', (e) => this.handleScroll(e));
	}

	// componentWillUnmount() {
	// 	window.removeEventListener('scroll', (e) => this.handleScroll(e));
	// }

	// handleClick = (e, element) => {
	// 	e.preventDefault();
	// 	goToAnchor(element, false);
	// };

	handleScroll = (event) => {
		let scrollTop =
			document.body.scrollTop || document.documentElement.scrollTop;
		if (scrollTop > 170) {
			this.setState({
				...this.state,
				scrolled: true,
			});
		} else {
			this.setState({
				...this.state,
				scrolled: false,
			});
		}
		// const height =
		// 	document.documentElement.scrollHeight -
		// 	document.documentElement.clientHeight;
		
	};

	fetchEntries() {
		contentful.client
			.getEntries({
				content_type: 'desdeCasa',
				include: 2,
				locale: getLocale(),
				// order: '-sys.updatedAt',
				// order: '-fields.fechaDeLaPublicacion',
				// 'fields.articleClass': query,
				// limit: 8,
			})
			.then((response) => response.items)
			.then((data) => {
				let featured = [];
				let entries = {
					atracciones: [],
					bienestar: [],
					educacion: [],
					juegos: [],
					libros: [],
					manualidades: [],
				};
				data.map((item) => {
					if (item.fields.featured) {
						featured.push(item.fields);
					} else {
						if (item.fields.category.includes('Atracciones y cultura')) {
							entries.atracciones.push(item.fields);
						} else if (item.fields.category.includes('Bienestar')) {
							entries.bienestar.push(item.fields);
						} else if (item.fields.category.includes('Educación en línea')) {
							entries.educacion.push(item.fields);
						} else if (item.fields.category.includes('Juegos')) {
							entries.juegos.push(item.fields);
						} else if (item.fields.category.includes('Libros y revistas')) {
							entries.libros.push(item.fields);
						} else if (item.fields.category.includes('Cocina y manualidades')) {
							entries.manualidades.push(item.fields);
						}
						// entries.push(item.fields);
					}
					return null;
				});
			
				this.setState({
					entries,
					featured,
					loading: false,
				});
			})
			.catch((error) => {
				this.setState({
					loading: false,
					error: error,
				});
			})
			.catch((err) => console.error(err));
	}

	render() {
		return (
			<Fragment>
				{/* <div
					className={`nav-container ${this.state.scrolled ? 'scrolled' : ''}`}
				>
					<ul
						className={`section-nav ${this.state.scrolled ? 'scrolled' : ''}`}
					>
						<li>
							<a
								href="#atracciones"
								onClick={(e) => this.handleClick(e, 'atracciones')}
							>
								Atracciones, viajes y cultura
							</a>
						</li>
						<li>
							<a
								href="#bienestar"
								onClick={(e) => this.handleClick(e, 'bienestar')}
							>
								Bienestar
							</a>
						</li>
						<li>
							<a href="#manualidades">Cocina y manualidades</a>
						</li>
						<li>
							<a href="#educacion">Educación en línea</a>
						</li>
						<li>
							<a href="#juegos">Juegos</a>
						</li>
						<li>
							<a href="#libros">Libros y revistas</a>
						</li>
					</ul>
				</div> */}

				{/* <Header/> */}
				<section className="container-fluid header-main-holder">
					<article className="col-12 desde-header">
						<h1>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
							>
								<path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
							</svg>
							{this.state.language === 'es'
								? 'Juntos Desde Casa'
								: 'Together From Home'}
							<p className="small-title">
								{this.state.language === 'es'
									? 'Comparte en tus redes cómo estás viviendo esta temporada desde casa con el hastag #AMJuntosdesdecasa'
									: 'Share on your social networks how are you living this season from home with the hashtag #AMTogetherfromhome'}
							</p>
						</h1>
					</article>
				</section>
				<section className=" main-content-container" style={{marginLeft:'6%',marginRight:'6%'}}>
					{this.state.loading && (
						<Fragment>
							<div className="row">
								<div className="col-12 mb-4">
									<Loader />
								</div>
								<div className="col-12 mb-4">
									<Loader />
								</div>
								<div className="col-12 mb-4">
									<Loader />
								</div>
								<div className="col-12 mb-4">
									<Loader />
								</div>
								<div className="col-12 mb-4">
									<Loader />
								</div>
								<div className="col-12 mb-4">
									<Loader />
								</div>
								<div className="col-12 mb-4">
									<Loader />
								</div>
							</div>
						</Fragment>
					)}
					{!this.state.loading && (
						<Fragment>
							{this.state.featured && (
								<article className="row this-desde-section featured mb-5">
									<header className="col-12 desde-title">
										<Featured />
										<h2>
											{this.state.language === 'es' ? 'Destacados' : 'Featured'}
										</h2>
									</header>
									{this.state.featured.map((feature) => {
										return (
											<div className="col-12 col-md-6">
												<a
													className="featured-item"
													href={feature.url}
													target="_blank"
													rel="noopener noreferrer"
												>
													<div
														className="bg-overlay"
														style={{
															background: `url(${feature.background.fields.file.url})`,
														}}
													></div>
													<p className="category">
														{this.state.language === 'es'
															? feature.category
															: feature.categoryEN}
													</p>
													<h3 className="title">{feature.title}</h3>
													<p className="description">
														{documentToReactComponents(feature.description)}
													</p>
													<p className="go2">
														{this.state.language === 'es'
															? 'Ir al contenido'
															: 'Go to content'}
														<Arrow />
													</p>
												</a>
											</div>
										);
									})}
								</article>
							)}
							{this.state.entries.atracciones.length > 0 && (
								<Fragment>
									{/* <ScrollableAnchor id={'atracciones'}></ScrollableAnchor> */}
									<article className="row this-desde-section mb-5 regular-section-holder">
										<header className="col-12 desde-title">
											<Viaje />
											<h2>
												{this.state.language === 'es'
													? this.state.entries.atracciones[0].category
													: this.state.entries.atracciones[0].categoryEN}
											</h2>
										</header>
										<div className="col-12 items-regular-holder">
											{this.state.entries.atracciones.map((item) => {
												return (
													<a
														href={item.url}
														target="_blank"
														rel="noopener noreferrer"
														className="post-item-holder-parent"
													>
														<div
															className="post-item"
															style={{
																background: `url(${item.background.fields.file.url})`,
															}}
														>
															<div className="bg-overlay"></div>
															<div className="intel-holder">
																<p className="description">
																	{documentToReactComponents(item.description)}
																</p>
																<p className="go2">
																	{this.state.language === 'es'
																		? 'Ir al contenido'
																		: 'Go to content'}
																	<Arrow />
																</p>
															</div>
														</div>
														<h4 className="title">{item.title}</h4>
													</a>
												);
											})}
										</div>
									</article>
								</Fragment>
							)}
							{this.state.entries.bienestar.length > 0 && (
								<Fragment>
									{/* <ScrollableAnchor id={'bienestar'}></ScrollableAnchor> */}
									<article className="row this-desde-section mb-5 regular-section-holder">
										<header className="col-12 desde-title">
											<Bienestar />
											<h2>
												{this.state.language === 'es'
													? this.state.entries.bienestar[0].category
													: this.state.entries.bienestar[0].categoryEN}
											</h2>
										</header>
										<div className="col-12 items-regular-holder">
											{this.state.entries.bienestar.map((item) => {
												return (
													<a
														href={item.url}
														target="_blank"
														rel="noopener noreferrer"
														className="post-item-holder-parent"
													>
														<div
															className="post-item"
															style={{
																background: `url(${item.background.fields.file.url})`,
															}}
														>
															<div className="bg-overlay"></div>
															<div className="intel-holder">
																<p className="description">
																	{documentToReactComponents(item.description)}
																</p>
																<p className="go2">
																	{this.state.language === 'es'
																		? 'Ir al contenido'
																		: 'Go to content'}
																	<Arrow />
																</p>
															</div>
														</div>
														<h4 className="title">{item.title}</h4>
													</a>
												);
											})}
										</div>
									</article>
								</Fragment>
							)}
							{this.state.entries.manualidades.length > 0 && (
								<Fragment>
									{/* <ScrollableAnchor id={'manualidades'}></ScrollableAnchor> */}
									<article className="row this-desde-section mb-5 regular-section-holder">
										<header className="col-12 desde-title">
											<Manualidades />
											<h2>
												{this.state.language === 'es'
													? this.state.entries.manualidades[0].category
													: this.state.entries.manualidades[0].categoryEN}
											</h2>
										</header>
										<div className="col-12 items-regular-holder">
											{this.state.entries.manualidades.map((item) => {
												return (
													<a
														href={item.url}
														target="_blank"
														rel="noopener noreferrer"
														className="post-item-holder-parent"
													>
														<div
															className="post-item"
															style={{
																background: `url(${item.background.fields.file.url})`,
															}}
														>
															<div className="bg-overlay"></div>
															<div className="intel-holder">
																<p className="description">
																	{documentToReactComponents(item.description)}
																</p>
																<p className="go2">
																	{this.state.language === 'es'
																		? 'Ir al contenido'
																		: 'Go to content'}
																	<Arrow />
																</p>
															</div>
														</div>
														<h4 className="title">{item.title}</h4>
													</a>
												);
											})}
										</div>
									</article>
								</Fragment>
							)}
							{this.state.entries.educacion.length > 0 && (
								<Fragment>
									{/* <ScrollableAnchor id={'educacion'}></ScrollableAnchor> */}
									<article className="row this-desde-section mb-5 regular-section-holder">
										<header className="col-12 desde-title">
											<Educacion />
											<h2>
												{this.state.language === 'es'
													? this.state.entries.educacion[0].category
													: this.state.entries.educacion[0].categoryEN}
											</h2>
										</header>
										<div className="col-12 items-regular-holder">
											{this.state.entries.educacion.map((item) => {
												return (
													<a
														href={item.url}
														target="_blank"
														rel="noopener noreferrer"
														className="post-item-holder-parent"
													>
														<div
															className="post-item"
															style={{
																background: `url(${item.background.fields.file.url})`,
															}}
														>
															<div className="bg-overlay"></div>
															<div className="intel-holder">
																<p className="description">
																	{documentToReactComponents(item.description)}
																</p>
																<p className="go2">
																	{this.state.language === 'es'
																		? 'Ir al contenido'
																		: 'Go to content'}
																	<Arrow />
																</p>
															</div>
														</div>
														<h4 className="title">{item.title}</h4>
													</a>
												);
											})}
										</div>
									</article>
								</Fragment>
							)}
							{this.state.entries.juegos.length > 0 && (
								<Fragment>
									{/* <ScrollableAnchor id={'juegos'}></ScrollableAnchor> */}
									<article className="row this-desde-section mb-5 regular-section-holder">
										<header className="col-12 desde-title">
											<Juegos />
											<h2>
												{this.state.language === 'es'
													? this.state.entries.juegos[0].category
													: this.state.entries.juegos[0].categoryEN}
											</h2>
										</header>
										<div className="col-12 items-regular-holder">
											{this.state.entries.juegos.map((item) => {
												return (
													<a
														href={item.url}
														target="_blank"
														rel="noopener noreferrer"
														className="post-item-holder-parent"
													>
														<div
															className="post-item"
															style={{
																background: `url(${item.background.fields.file.url})`,
															}}
														>
															<div className="bg-overlay"></div>
															<div className="intel-holder">
																<p className="description">
																	{documentToReactComponents(item.description)}
																</p>
																<p className="go2">
																	{this.state.language === 'es'
																		? 'Ir al contenido'
																		: 'Go to content'}
																	<Arrow />
																</p>
															</div>
														</div>
														<h4 className="title">{item.title}</h4>
													</a>
												);
											})}
										</div>
									</article>
								</Fragment>
							)}
							{this.state.entries.libros.length > 0 && (
								<Fragment>
									{/* <ScrollableAnchor id={'libros'}></ScrollableAnchor> */}
									<article className="row this-desde-section mb-5 regular-section-holder">
										<header className="col-12 desde-title">
											<Libros />
											<h2>
												{this.state.language === 'es'
													? this.state.entries.libros[0].category
													: this.state.entries.libros[0].categoryEN}
											</h2>
										</header>
										<div className="col-12 items-regular-holder">
											{this.state.entries.libros.map((item) => {
												return (
													<a
														href={item.url}
														target="_blank"
														rel="noopener noreferrer"
														className="post-item-holder-parent"
													>
														<div
															className="post-item"
															style={{
																background: `url(${item.background.fields.file.url})`,
															}}
														>
															<div className="bg-overlay"></div>
															<div className="intel-holder">
																<p className="description">
																	{documentToReactComponents(item.description)}
																</p>
																<p className="go2">
																	{this.state.language === 'es'
																		? 'Ir al contenido'
																		: 'Go to content'}
																	<Arrow />
																</p>
															</div>
														</div>
														<h4 className="title">{item.title}</h4>
													</a>
												);
											})}
										</div>
									</article>
								</Fragment>
								
							)}
						</Fragment>
					)}
				</section>
				{/* <Index/> */}
			</Fragment>
		
		);
	}
}
