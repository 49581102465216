import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import "./css/denuncia.css";
import { Trans } from "react-i18next";


export default class ReportaDenuncia extends Component {

  constructor() {
    super();
    this.state = {
      loading: true,
      error: null,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  handleScroll = event => {
    const scrollTop = window.pageYOffset;
    if (scrollTop > 320) {
      this.setState({ hasScrolled: true });
    } else {
      this.setState({ hasScrolled: false });
    }
  };

  render() {
    return (

      <Link className={
              this.state.hasScrolled
                ? "DenunciaReporta opacityChange"
                : "DenunciaReporta"
            } to="/seguridad#gamreport"> 

        <div className="imagePlane"><div className="Plane"></div></div>
        <div className="padding">
            <p><Trans i18nKey="Footer.denuncia"></Trans></p>
        </div>
      </Link>
    );
  }
}
