import React, { useState } from 'react';
import i18next from 'i18next';
import Modal from 'react-awesome-modal';
import './modales.css';
import UpgradeIMG from '../../../assets/images/profile/travel-benefits-01.jpg';
import close from '../../../assets/logo/close.svg';
import arrow from '../../../assets/images/arrow-blue.svg';
import { ReactComponent as Warning } from '../../../assets/travel/important.svg';

const noScroll = require('no-scroll');

const UpgradePremiere = () => {
	const [data, setData] = useState({});
	const [visible, setVisible] = useState(false);

	const closeModal = () => {
		setVisible(false);
		noScroll.off();
	};

	const openModal = () => {
		setVisible(true);
		noScroll.on();
	};

	// const levelSelector = () => {
	// 	switch (level) {
	// 		case level < 7:
	// 			setData({
	// 				title: i18next.t(''),
	// 				subtitle: i18next.t('')
	// 			});
	// 			break;

	// 		default:
	// 			break;
	// 	}
	// };

	return (
		<div>
			<ModalImplement visible={visible} closeModal={closeModal} />
			<button
				type="button"
				onClick={openModal}
				className="ancillar-card my-[10px] flex h-full w-full flex-col items-center justify-start rounded-lg border-none outline-none focus:outline-none"
			>
				<img
					src={UpgradeIMG}
					alt="Premiere Upgrade"
					className="card-image-holder rounded-lg object-cover shadow-2xl group-hover:shadow-2xl"
					loading="lazy"
				/>
				<h5 className="totalBenefitsTravel__card__title mt-4">
					{i18next.t('MiExperAM.travel.ancillares.upgrade.title')}
				</h5>
				<p className="totalBenefitsTravel__card__text mt-1 px-2 text-left">
					{i18next.t('MiExperAM.travel.ancillares.upgrade.preview')}
				</p>
				<span className="travel-link mt-3 flex w-full items-center justify-start px-2">
					<p className="totalBenefitsTravel__card__more">
						{i18next.t('MiExperAM.travel.ancillares.conoce')}
					</p>
					<img
						className="blue-arrow ml-2"
						src={arrow}
						alt="blue-arrow"
					/>
				</span>
			</button>
		</div>
	);
};

export default UpgradePremiere;

const ModalImplement = ({ visible, closeModal }) => {
	return (
		<Modal
			visible={visible}
			width="auto"
			height="auto"
			effect="fadeInUp"
			onClickAway={closeModal}
		>
			<div className="modalWindowAncillaries upgradeModalHeight">
				<div className="closeModal" onClick={closeModal}>
					<img src={close} alt="close modal" />
				</div>

				<div className="topBox">
					<div className="header flex items-center justify-start">
						<div className="imageCircle asientoupgrade"></div>
						<div className="textLeftCircle m-0">
							<p className="m-0 text-2xl font-medium text-amBlack">
								{i18next.t(
									'MiExperAM.travel.ancillares.upgrade.title'
								)}
							</p>
						</div>
					</div>

					<div className="contentType upgrade">
						<div className="flex flex-col items-center justify-center">
							<div className="alert mb-3 flex items-center justify-start rounded-lg bg-amRegularBlue p-3">
								<p className="small-text-alert-modal small-text-alert-modal m-0 ml-2 leading-normal">
									{i18next.t(
										'MiExperAM.travel.ancillares.upgrade.small_preview'
									)}
								</p>
							</div>
							<Warning className="mb-4 h-8 w-8 fill-current text-amRegularBlue" />
						</div>
						<div
							className="regular-text-content prose pt-4 text-amBlack"
							style={{ marginBottom: '20px' }}
						>
							<h3>
								1. Ascenso sin costo al cierre del vuelo de
								acuerdo a disponibilidad
							</h3>
							<ul>
								<li>
									Tu familia y amigos también podrán disfrutar
									este beneficio aunque no viajen contigo,
									sujeto a la disponibilidad al cierre de
									vuelo.
								</li>
								<li>
									A partir del 1 de noviembre, todas las
									reservaciones que realices de Travel
									Benefits aparecerán en lista de espera de
									Upgrade de manera automática al hacer
									check-in.
								</li>
								<li>
									¿Cómo funciona?
									<ul>
										<li>
											Realiza tu check-in en la app
											Aeroméxico para ingresar
											automáticamente a la lista de espera
											para ascenso.
										</li>
										<li>
											Identifica el icono “Lista de espera
											Upgrade” y verifica que tus
											iniciales aparezcan enlistadas.
										</li>
										<li>
											Al final de esta pantalla, verás la
											disponibilidad de asientos y las
											posibilidades de ascender.
										</li>
									</ul>
								</li>
								<li>
									En caso de quedar lugares libres en el
									avión, se asignará de acuerdo al orden de la
									lista de espera, después de nuestros
									clientes Premier.
								</li>
								<li>
									Aprox. 30 min antes del abordaje podrás
									visualizar en la app si te fue asignado el
									ascenso
								</li>
							</ul>
						</div>
						<div className="flex flex-col items-center justify-center">
							<Warning className="mb-4 h-8 w-8 fill-current text-amYellow" />
							<div className="alert mb-3 flex items-center justify-start rounded-lg bg-amYellow p-3">
								<p className="small-text-alert-modal-2 small-text-alert-modal m-0 ml-2 leading-normal">
									IMPORTANTE. AEROPUERTOS QUE NO OPERAN CON
									SABRE: recuerda que para inscribirte en la
									lista de espera para Upgrade saliendo desde
									AMS, ATL, DTW, MIA, JFK, CDG, SLC, SAT, SEA,
									NRT deberás marcar a Mi línea de atención
									(55 9132 4488) ext. 4488 opción 2 para que
									te anoten en la lista de espera.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};
