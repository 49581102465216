// ACTION CREATOR

// import libraries
import InfoEnvironment from '../../utils/info.environment';
const hex = require('string-hex');
const sha1 = require('sha1');

// ACTION TYPE CONSTANT
// TO PREVENT TYPO ERRORS
// export const LOADED_PRIVATE_USER = 'LOADED_PRIVATE_USER';
export const GET_PRIVATE_USER = 'GET_PRIVATE_USER';

// BETTER WAY TO DISPATCH DATA
// export function loadedPrivateUser() {
// 	return {
// 		type: 'LOADED_PRIVATE_USER'
// 	};
// }

const formatRewardNumber = reward => {
	if (typeof reward === 'object') {
		return 0;
	} else {
		const hex = reward.toString();
		let str = '';
		for (let i = 0; i < hex.length; i += 2) {
			str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
		}
		const number = parseFloat(str.replace(/,/g, ''));
		return number;
	}
};

const getAutomovilMensual = theData => {
	if (formatRewardNumber(theData) === 0) return 0;
	return formatRewardNumber(theData) / 12;
};

export function getPrivateUser() {
	return async function(dispatch) {
		const infoEnvironment = new InfoEnvironment();
		const email = localStorage.getItem('email');
		const HOST = 'NOMBREHOST';
		const token = sha1(`${HOST}|${email}`).toUpperCase();
		const theEmail = hex(email).toUpperCase();
		const URL = infoEnvironment.api_url({
			host: window.location.hostname
		});
		const formData = new FormData();
		formData.append('token', token);
		formData.append('email', theEmail);
		formData.append('tipo', '0');
		formData.append('tipo_salida', 'JSON');
		const response = await fetch(URL, {
			body: formData,
			method: 'POST'
		});
		const res = await response.json();
		console.log("problemas de info privada", res);
		const compensacionMensual =
			formatRewardNumber(res.compensacion) === 0
				? 0
				: formatRewardNumber(res.compensacion) / 12;
		const bonoUtilesMensual =
			formatRewardNumber(res.bonoUtiles) === 0
				? 0
				: formatRewardNumber(res.bonoUtiles) / 12;
		const bonoAutomovilMensual =
			parseInt(localStorage.getItem('nivel')) < 10
				? getAutomovilMensual(res.bonoAutomovil)
				: 0;
		const valesMensual =
			formatRewardNumber(res.valesDespensa) === 0
				? 0
				: formatRewardNumber(res.valesDespensa) / 12;
		const seguroVida= formatRewardNumber(res.sv);
		const sgmm= formatRewardNumber(res.sgmm);
		const sgmmBenef= formatRewardNumber(res.numBenefSGMM);
		const checkup= formatRewardNumber(res.checkup);
		const travelBenefits= formatRewardNumber(res.saldo_boleto);
		const userData = {
			aguinaldo: formatRewardNumber(res.aguinaldo),
			bonoAutomovil: formatRewardNumber(res.bonoAutomovil),
			bonoAutomovilMensual,
			bonoDesempeno: formatRewardNumber(res.bonoDesempeno),
			bonoUtiles: formatRewardNumber(res.bonoUtiles),
			bonoUtilesMensual,
			compensacion: formatRewardNumber(res.compensacion),
			compensacionMensual,
			fondoAhorro: formatRewardNumber(res.fondoAhorro),
			lti: res.lti !== null ? formatRewardNumber(res.lti) : 0,
			primaVacacional: formatRewardNumber(res.primaVacacional),
			ratio: res.ratio,
			salario: formatRewardNumber(res.salario),
			salarioAnual: formatRewardNumber(res.salarioAnual),
			sumaAnual:
				formatRewardNumber(res.salarioAnual) +
				formatRewardNumber(res.aguinaldo) +
				formatRewardNumber(res.primaVacacional) +
				formatRewardNumber(res.fondoAhorro) +
				formatRewardNumber(res.compensacion) +
				formatRewardNumber(res.valesDespensa) +
				formatRewardNumber(res.bonoUtiles) +
				formatRewardNumber(res.bonoAutomovil),
			sumaAnualTotal:
				formatRewardNumber(res.salarioAnual) +
				formatRewardNumber(res.aguinaldo) +
				formatRewardNumber(res.primaVacacional) +
				formatRewardNumber(res.fondoAhorro) +
				formatRewardNumber(res.compensacion) +
				formatRewardNumber(res.valesDespensa) +
				formatRewardNumber(res.bonoUtiles) +
				formatRewardNumber(res.bonoAutomovil) +
				formatRewardNumber(res.bonoDesempeno) +
				(res.lti !== null ? formatRewardNumber(res.lti) : 0),
			sumaMensual:
				compensacionMensual +
				bonoUtilesMensual +
				valesMensual +
				bonoAutomovilMensual +
				formatRewardNumber(res.salario),
			valesDespensa: formatRewardNumber(res.valesDespensa),
			valesMensual,
			seguroVida,
			sgmm,
			sgmmBenef,
			checkup,
			travelBenefits,
			totalBeneficios: seguroVida + sgmm + checkup + travelBenefits
		};
		return dispatch({
			type: 'GET_PRIVATE_USER',
			data: userData
		});
	};
}
