export default function isMockUser(){
    var email = localStorage.getItem("email");
    return (email === "amaccesodelta@aeromexico.com"||
    email === "amaccesoskyteam@aeromexico.com"||
    email === "amaccesoinvitado@aeromexico.com" ||
    email === "amskyteamaccess@aeromexico.com"||
    email === "amacessdelta@aeromexico.com"||
    email === "amaccessguest@aeromexico.com" || 
    email === "amusercan@aeromexico.com"||
    email === "amuserusa@aeromexico.com"||
    email === "amuserl1@aeromexico.com"||
    email === "amuserl2@aeromexico.com"||
    email === "amuserl3@aeromexico.com"||
    email === "amuserl4@aeromexico.com"||
    email === "amuserl5@aeromexico.com"||
    email === "amuserl6@aeromexico.com"||
    email === "amuserl7@aeromexico.com"||
    email === "amuserl8@aeromexico.com"||
    email === "amuserl9@aeromexico.com"||
    email === "amuserl10@aeromexico.com"||
    email === "amuserl11@aeromexico.com"||
    email === "amuserl12@aeromexico.com"    
    )
};
