import React, { Component } from 'react';
import './css/comunicados.css';

export const ComunicadosPage = () => {
	return (
		<section className="comunicadosPage">
			<h1>Medidas preventivas ante coronavirus</h1>
			<div className="iframe-holder-container">
				<div className="video-container">
					<iframe
						title="YT video"
						width="100%"
						height="100%"
						src="https://www.youtube.com/embed/2tPrtIWDW3k"
						frameborder="0"
						allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
						allowfullscreen
					></iframe>
				</div>
			</div>
		</section>
	);
};
