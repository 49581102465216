import React, { useState, useEffect } from 'react';
import i18next from 'i18next';
import Modal from 'react-awesome-modal';
import './modales.css';
import close from '../../../assets/logo/close.svg';
import arrow from '../../../assets/images/arrow-blue.svg';
import { ReactComponent as Warning } from '../../../assets/travel/important.svg';
import estrellanew from '../../../assets/travel/estrella_new.svg';
import { color } from 'framer-motion';

const noScroll = require('no-scroll');

const AncillarBtn = ({ image, title, description, data, icon, nameModal, levelUser }) => {
	const [visible, setVisible] = useState(false);

	const closeModal = () => {
		setVisible(false);
		noScroll.off();
	};

	const openModal = () => {
		setVisible(true);
		noScroll.on();
	};

	return (
		<div>
			{!!data ? (
				<ModalImplement
					nameModal={nameModal}
					levelUser={levelUser}
					data={data}
					visible={visible}
					closeModal={closeModal}
					icon={icon}
				/>
			) : null}
			<button
				type="button"
				onClick={openModal}
				className="ancillar-card relative z-10 my-[10px] flex h-full w-full flex-col items-center justify-start rounded-lg border-none outline-none hover:z-20 focus:outline-none"
			>
				<img
					src={image}
					alt="Premiere Upgrade"
					className="card-image-holder w-full rounded-lg object-cover shadow-2xl group-hover:shadow-2xl"
					loading="lazy"
				/>
				<h5 className="totalBenefitsTravel__card__title mt-4 w-full px-1 text-left">
					{title}
				</h5>
				<p className="totalBenefitsTravel__card__text mt-1 mb-3 px-1 text-left">
					{description}
				</p>
				<span className="travel-link absolute left-0 bottom-0 mt-3 flex w-full items-center justify-start px-2">
					<p className="totalBenefitsTravel__card__more">
						{i18next.t('MiExperAM.travel.ancillares.conoce')}
					</p>
					<img
						className="blue-arrow ml-2"
						src={arrow}
						alt="blue-arrow"
					/>
				</span>
			</button>
		</div>
	);
};

export default AncillarBtn;

const ModalImplement = ({ nameModal,levelUser,visible, closeModal, data, icon }) => {
	const theTable = !!data.table ? JSON.parse(data.table) : null;
	useEffect(() => {
		const paragraphs = document.querySelectorAll('.myp');
		paragraphs.forEach(paragraph => {
			paragraph.style.setProperty('color', 'blue', 'important'); // Añadir !important dinámicamente
		});
	  }, []);

	return (
		<Modal
			visible={visible}
			width="auto"
			height="auto"
			effect="fadeInUp"
			onClickAway={closeModal}
		>
			<div className="modalWindowAncillaries" style={{maxWidth:'700px'}}>
				<div className="closeModal" onClick={closeModal}>
					<img src={close} alt="close modal" />
				</div>

				<div className="topBox">
					<div className="header flex items-center justify-start">
						{!!icon ? (
							<img
								className="imageCircle"
								src={icon}
								alt={data.title}
							/>
						) : null}
						<div className="textLeftCircle m-0">
							<p className="m-0 text-2xl font-medium text-amBlack">
								{data.title}
							</p>
							{!!data.subtitle ? (
								<p className="subtitle">{data.subtitle}</p>
							) : null}
						</div>
					</div>

					<div className="contentType upgrade">
						{(() => {
							switch (nameModal) {
								case 'AsientoSinCosto':
									return (
										<>
											<div class="regular-text-content prose pt-4 font-normal text-amBlack" style={{ marginBottom: '20px' }}>
												<p>La asignación de asientos en boletos con derecho a reservación está excenta de cualquier cargo adicional, respetando los asientos para venta a nuestros clientes, de acuerdo con lo siguiente:</p>
												<p>• En todo momento, el sistema te mostrará los asientos “regulares” (sin costo).</p>
												{(() => {
												const level = parseInt(levelUser);
													if (level >= 1 && level <= 4) {
														return(
															<>
																<p style={{ display: 'flex', alignItems: 'center' }}><img src={estrellanew} style={{ maxWidth: '40px', marginRight: '15px' }} /> 24 horas previas a la salida del vuelo, podrás seleccionar sin costo los asientos Preferentes disponibles.</p>

															</>
														)
													} else {
														return(
															<>
																<p style={{ display: 'flex', alignItems: 'center' }}><img src={estrellanew} style={{ maxWidth: '40px', marginRight: '15px' }} /> 24 horas previas a la salida del vuelo, podrás seleccionar sin costo los Asientos AM Plus y Preferentes disponibles.</p>
															</>
														)
													}
												})()}
												<p>La asignación podrá realizarse a través de Core o llamando a Mi Línea de Atención en la opción 1 (Linc).</p>
												<p>Aplica para el empleado activo y/o beneficiarios</p>
												<p>Nota: En caso de que quieras mejorar tu asiento en cabina turista antes de las 24h, lo puedes comprar en cualquier momento desde la app y/o aeromexico.com</p>
												{(() => {
												const level = parseInt(levelUser);
													if (level >= 10 && level <= 12) {
														return(
															<>
																<p style={{ display: 'flex', alignItems: 'center' }}><img src={estrellanew} style={{ maxWidth: '40px', marginRight: '15px' }} />
																<span>
																	<strong>Viajes en Comisión de Servicio (Duty Travel)</strong> <br />
																	Acceso a Asientos AM Plus y Preferentes disponibles, llamando a Mi línea de Atención en la opción 2 (Cobus).</span></p>

															</>
														)
													} else if(level >= 1 && level <= 4){
														return(
															<>
																<p style={{ display: 'flex', alignItems: 'center' }}><img src={estrellanew} style={{ maxWidth: '40px', marginRight: '15px' }} /> <span><strong>Viajes en Comisión de Servicio (Duty Travel)</strong> <br />Acceso a asientos Preferentes disponibles, llamando a Mi línea de Atención en la opción 2 (Cobus).</span></p>
															</>
														)
													}else {
														return(
															<>
																<p style={{ display: 'flex', alignItems: 'center' }}><img src={estrellanew} style={{ maxWidth: '40px', marginRight: '15px' }} /> <span><strong>Viajes en Comisión de Servicio (Duty Travel)</strong> <br />Acceso a Asientos AM Plus y Preferentes disponibles, llamando a Mi línea de Atención en la opción 2 (Cobus).</span></p>
															</>
														)
													}
												})()}

											</div>
										</>
									);
								case 'UpgradeUsers':
									return (
										<>
											
											{(() => {
												const luser = ((levelUser == '12C')? '13' : levelUser);
												const level = parseInt(luser);
												if(level >= 13 && level <= 13){
													return (
														<>
															<div className="regular-text-content prose pt-4 font-normal text-amBlack" style={{ marginBottom: '20px' }}>
																<p style={{ display: 'flex', alignItems: 'center' }}>
																	<img src={estrellanew} style={{ maxWidth: '40px', marginRight: '15px' }} />
																	<span className='myp'>PODRÁS PAGAR TU ASCENSO 30 DÍAS ANTES DEL VUELO CON UN COSTO COSTO PREFERENCIAL Y CON PUNTOS AEROMÉXICO REWARDS</span>
																</p>
																<ul>
																	<li>Aplicable para colaborador y/o cónyuge</li>
																</ul>
																<p style={{ display: 'flex', alignItems: 'center' }}>
																	<img src={estrellanew} style={{ maxWidth: '40px', marginRight: '15px' }} />
																	<span className='myp'>O DENTRO DE LAS 48 H PREVIAS AL VUELO CON UN COSTO PREFERENCIAL MÁS BAJO UTILIZANDO PUNTOS REWARDS</span>
																</p>
																<ul>
																	<li>Máximo 4 personas por colaborador</li>
																	<li>No es necesario que el colaborador viaje</li>
																</ul>
																<p style={{ display: 'flex', alignItems: 'center' }}>
																	<span className='myp'>CÓMO OBTENERLO</span>
																</p>
																<p>Una vez que se cumpla el periodo establecido, llamando a Mi Línea de Atención, opción #1 (LINC) en un horario de 8 am a 12 pm de Lunes a Domingo.</p>
																<p>Entra a Mi Aeroméxico y consulta las tarifas aplicables en dinero o con puntos Aeroméxico Rewards. por región, ya sea pagando</p>
																<p>En caso de no haber disponibilidad de clase F, realiza tu solicitud de cambio escribiendo a: amcsci@aeromexico.com, quienes te darán respuesta de acuerdo con el pronóstico del vuelo. </p>
																<table>
																<thead>
																	<tr>
																		<th>Región</th>
																		<th>Costo USD<sup>*</sup></th>
																		<th>Región</th>
																		<th>Costo USD<sup>*</sup></th>
																	</tr>
																</thead>
																<tbody>
																	<tr>
																		<td>ASI</td>
																		<td>$300</td>
																		<td>CAM/CAR</td>
																		<td>$50</td>
																	</tr>
																	<tr>
																		<td>DOM</td>
																		<td>$25</td>
																		<td>EUR</td>
																		<td>$400</td>
																	</tr>
																	<tr>
																		<td>SUD_SH/SCL</td>
																		<td>$100</td>
																		<td>USA 787</td>
																		<td>$150</td>
																	</tr>
																	<tr>
																		<td>GRU/EZE</td>
																		<td>$350</td>
																		<td>USA/CAN</td>
																		<td>$75</td>
																	</tr>
																</tbody>
															</table>
															<p style={{ display: 'flex', alignItems: 'center' }}>
																<strong>Pago con puntos Aeroméxico Rewards</strong>
															</p>
															<table>
																<thead>
																	<tr>
																		<th>Región</th>
																		<th>Puntos<sup>*</sup></th>
																		<th>Región</th>
																		<th>Puntos<sup>*</sup></th>
																	</tr>
																</thead>
																<tbody>
																	<tr>
																		<td>ASI</td>
																		<td>43,400</td>
																		<td>CAM/CAR</td>
																		<td>7,300</td>
																	</tr>
																	<tr>
																		<td>DOM</td>
																		<td>3,000</td>
																		<td>EUR</td>
																		<td>52,500</td>
																	</tr>
																	<tr>
																		<td>SUD_SH/SCL</td>
																		<td>10,000</td>
																		<td>USA 787</td>
																		<td>17,600</td>
																	</tr>
																	<tr>
																		<td>GRU/EZE</td>
																		<td>48,600</td>
																		<td>USA/CAN</td>
																		<td>8,800</td>
																	</tr>
																</tbody>
															</table>
															<hr />
															<p style={{ display: 'flex', alignItems: 'center' }}>
																<img src={estrellanew} style={{ maxWidth: '40px', marginRight: '15px' }} />
																<span className='myp'>MAYOR DISPONIBILIDAD PARA ASIGNACIÓN AUTOMÁTICA SIN COSTO EN “CLASE O” DURANTE LAS 120 H PREVIAS AL VUELO</span>
															</p>
															<ul>
																<li>Clase "O"</li>
																<li>Empleado y cónyuge o compañero de viaje</li>
																<li>Requiere viajar el empleado</li>
																<li>Aplica por vuelo en boletos Clase Turista</li>
															</ul>
															<p className='myp'>CÓMO OBTENERLO</p>
															<p>El proceso para la asignación es automático, a partir de que se cumpla el tiempo establecido, recibirás una notificación al momento que el sistema detecte la disponibilidad de clase.</p>
															<hr />
															<p style={{ display: 'flex', alignItems: 'center' }}>
																<img src={estrellanew} style={{ maxWidth: '40px', marginRight: '15px' }} />
																<span className='myp'>72 H ANTES DEL VUELO, CONFIRMADO CON COSTO PREFERENCIAL</span>
															</p>
															<ul>
																<li>Clase "F"</li>
																<li>Máximo 4 personas</li>
																<li>No requiere viajar el empleado</li>
																<li>No reembolsable</li>
																<li>Aplica por vuelo en boletos Clase Turista</li>
																<li>Se puede pagar con puntos AM Rewards</li>
															</ul>
															<p className='myp'>CÓMO OBTENERLO</p>
															<p>Una vez que se cumpla el periodo establecido, llamando a Mi Linea de Atención opción 1 (LINC) en un horario de 8 am a 12 pm de Lunes a Domingo.</p>
															<p>Consulta las tarifas en el portal de mi aeromexico aplicables por región ya sea pagando en dinero o con puntos AM Rewards.</p>
															<p>En caso de no haber disponibilidad de clase F, realiza tu solicitud de cambio escribiendo a:amcsci@aeromexico.com quienes te darán respuesta de acuerdo con el pronóstico del vuelo.</p>
															<hr />
															<p style={{ display: 'flex', alignItems: 'center' }}>
																<span className='myp'>AL CIERRE DEL VUELO* SUJETO A DISPONIBILIDAD, SIN COSTO</span>
															</p>
															<ul>
																<li>Sujeto a espacio</li>
																<li>Empleado y beneficiarios o beneficiarios viajando solos</li>
																<li>No requiere viajar el empleado</li>
															</ul>
															<p className='myp'>CÓMO OBTENERLO</p>
															<p>Al realizar check-in, en web o en app de Aeroméxico, automáticamente estarás en lista de espera. Podrás consultar la lista en la App y/o la Web de Aeroméxico.</p>
															<p>En caso de haber disponibilidad, se te asignará a ti y a tus acompañantes al término del abordaje y el ASC te hará entrega de tu nuevo pase de abordar. Debes cumplir con todos los criterios del Código de viajero.</p>
															</div>
															<p><sub>*Si el (los) beneficiarios viaja(n) con el empleado, el orden de prioridad es de acuerdo con su nivel y después de clientes. En caso contrario, aplica la prioridad después de cualquier empleado NSD.</sub></p>
														</>
													)
												}else if (level >= 10 && level <= 12) {
													return (
														<>
														<div className="regular-text-content prose pt-4 font-normal text-amBlack" style={{ marginBottom: '20px' }}>
															<p style={{ display: 'flex', alignItems: 'center' }}>
																<img src={estrellanew} style={{ maxWidth: '40px', marginRight: '15px' }} />
																<span className='myp'>MAYOR DISPONIBILIDAD PARA ASIGNACIÓN AUTOMÁTICA SIN COSTO EN “CLASE O” DURANTE LAS 120 H PREVIAS AL VUELO</span>
															</p>
															<ul>
																<li>Aplicable para cualquier acompañante viajando en la misma reserva</li>
																<li>Requiere viajar el empleado</li>
																<li>Aplica por vuelo en boletos Clase Turista</li>
															</ul>
															<p className='myp'>CÓMO OBTENERLO</p>
															<p>El proceso para la asignación es automático, a partir de que se cumpla el tiempo establecido, recibirás una notificación al momento que el sistema detecte la disponibilidad de clase.</p>
															<hr />
															<p style={{ display: 'flex', alignItems: 'center' }}>
																<img src={estrellanew} style={{ maxWidth: '40px', marginRight: '15px' }} />
																<span className='myp'>ADQUIRIR ASCENSO CON COSTOS PREFERENCIALES O UTILIZANDO PUNTOS AEROMÉXICO REWARDS, DURANTE LAS 48 H PREVIAS AL VUELO</span>
															</p>
															<ul>
																<li>Clase "F"</li>
																<li>Máximo 4 personas</li>
																<li>No requiere viajar el empleado</li>
																<li>No reembolsable</li>
																<li>Aplica por vuelo en boletos Clase Turista</li>
															</ul>
															<p className='myp'>CÓMO OBTENERLO</p>
															<p>El proceso para la asignación es automático, a partir de que se cumpla el tiempo establecido, recibirás una notificación al momento que el sistema detecte la disponibilidad de clase.</p>
															<p>Consulta las tarifas aplicables por región:</p>
															<table>
																<thead>
																	<tr>
																		<th>Región</th>
																		<th>Costo USD<sup>*</sup></th>
																		<th>Región</th>
																		<th>Costo USD<sup>*</sup></th>
																	</tr>
																</thead>
																<tbody>
																	<tr>
																		<td>ASI</td>
																		<td>$300</td>
																		<td>CAM/CAR</td>
																		<td>$50</td>
																	</tr>
																	<tr>
																		<td>DOM</td>
																		<td>$25</td>
																		<td>EUR</td>
																		<td>$400</td>
																	</tr>
																	<tr>
																		<td>SUD_SH/SCL</td>
																		<td>$100</td>
																		<td>USA 787</td>
																		<td>$150</td>
																	</tr>
																	<tr>
																		<td>GRU/EZE</td>
																		<td>$350</td>
																		<td>USA/CAN</td>
																		<td>$75</td>
																	</tr>
																</tbody>
															</table>
															<p style={{ display: 'flex', alignItems: 'center' }}>
																<strong>Pago con puntos Aeroméxico Rewards</strong>
																<img src={estrellanew} style={{ maxWidth: '40px', marginLeft: '15px', marginTop: '0',marginBottom:'0' }} />
															</p>
															<table>
																<thead>
																	<tr>
																		<th>Región</th>
																		<th>Puntos<sup>*</sup></th>
																		<th>Región</th>
																		<th>Puntos<sup>*</sup></th>
																	</tr>
																</thead>
																<tbody>
																	<tr>
																		<td>ASI</td>
																		<td>43,400</td>
																		<td>CAM/CAR</td>
																		<td>7,300</td>
																	</tr>
																	<tr>
																		<td>DOM</td>
																		<td>3,000</td>
																		<td>EUR</td>
																		<td>52,500</td>
																	</tr>
																	<tr>
																		<td>SUD_SH/SCL</td>
																		<td>10,000</td>
																		<td>USA 787</td>
																		<td>17,600</td>
																	</tr>
																	<tr>
																		<td>GRU/EZE</td>
																		<td>48,600</td>
																		<td>USA/CAN</td>
																		<td>8,800</td>
																	</tr>
																</tbody>
															</table>
															<hr />
															<p style={{ display: 'flex', alignItems: 'center' }}>
																<strong className='myp'>AL CIERRE DEL VUELO* SUJETO A DISPONIBILIDAD SIN COSTO </strong>
															</p>
															<ul>
																<li>Sujeto a espacio</li>
																<li>Empleado y beneficiarios o beneficiarios viajando solos</li>
																<li>No requiere viajar el empleado</li>
															</ul>
															<p style={{ display: 'flex', alignItems: 'center' }}>
																<strong className='myp'>CÓMO OBTENERLO</strong>
															</p>
															<p>Al realizar check-in, en web o en app de Aeroméxico, automáticamente estarás en lista de espera. Podrás consultar la lista en la App y/o la Web de Aeroméxico.</p>
															<p>En caso de haber disponibilidad, se te asignará a ti y a tus acompañantes al término del abordaje y el ASC te hará entrega de tu nuevo pase de abordar. Debes cumplir con todos los criterios del Código de viajero.</p>
														</div>
														<p><sub>*Si el (los) beneficiarios viaja(n) con el empleado, el orden de prioridad es de acuerdo con su nivel y después de clientes. En caso contrario, aplica la prioridad después de cualquier empleado NSD.</sub></p>
														</>
													);
												} else if (level >= 1 && level <= 9) {
													return (
														<>
															<div className="regular-text-content prose pt-4 font-normal text-amBlack" style={{ marginBottom: '20px' }}>
																<p><strong class='myp'>1. Upgrade al cierre del vuelo</strong></p>
																<p>Con la iniciativa Upgrade, al realizar check-in en web o en app de Aeroméxico automáticamente estarás en lista de espera para ascensos a Clase Premier (podrás consultar la lista en la App y/o la Web de AM)</p>
																<p className='myp'>¿Cómo hacerlo?</p>
																<p>En caso de haber disponibilidad, se te asignará a ti y a tus acompañantes el Upgrade al término del abordaje y el ASC te hará entrega de tu nuevo pase de abordar. Debes cumplir con todos los criterios del Código de viajero.</p>
																{(() => {
																const level = parseInt(levelUser);
																	if (level >= 9) {
																		return(
																			<>
																				<p class='myp'><strong class='myp'>2. Compra de Upgrade a precio preferencial<sup>*</sup></strong><br />(Sujeto a disponiblidad de clase F por viaje sencillo)</p>
																			</>
																		)
																	} else {
																		return(
																			<>
																				<p><strong class='myp'>2. Compra de Upgrade a precio preferencial<sup>*</sup></strong></p>
																			</>
																		)
																	}
																})()}
																<ul>
																	<li>El trámite se debe realizar 48 h antes del vuelo (hora local), a través del LINC o CAP, reservando en clase "F" sujeto a disponibilidad.</li>
																	<li>Aplica para un máximo de 4 personas en el vuelo.</li>
																	<li>No requiere viajar el empleado.</li>
																	<li>Aplica solo para boletos con espacio positivo.</li>
																	<li>No aplica en vuelos de código compartido</li>
																	<li>Aplica en vuelos de AM y AM Connect con cabina Premier.</li>
																	<li>El pago de ascenso no es reembolsable.</li>
																	<li>Aplica para personal activo</li>
																	<li>En caso de no haber disponibilidad de clase F, realiza tu solicitud de cambio escribiendo a: amcsci@aeromexico.com, quienes te darán respuesta de acuerdo con el pronóstico del vuelo</li>
																</ul>
																<table>
																		<thead>
																			<tr>
																				<th>Región</th>
																				<th>Costo USD<sup>*</sup></th>
																				<th>Región</th>
																				<th>Costo USD<sup>*</sup></th>
																			</tr>
																		</thead>
																		<tbody>
																			<tr>
																				<td>ASI</td>
																				<td>$300</td>
																				<td>CAM/CAR</td>
																				<td>$50</td>
																			</tr>
																			<tr>
																				<td>DOM</td>
																				<td>$25</td>
																				<td>EUR</td>
																				<td>$400</td>
																			</tr>
																			<tr>
																				<td>SUD_SH/SCL</td>
																				<td>$100</td>
																				<td>USA 787</td>
																				<td>$150</td>
																			</tr>
																			<tr>
																				<td>GRU/EZE</td>
																				<td>$350</td>
																				<td>USA/CAN</td>
																				<td>$75</td>
																			</tr>
																		</tbody>
																	</table>
															</div>
															<p>*Los montos están en dólares americanos (aplican al tipo de cambio de la fecha de pago), incluyen IVA y los costos pueden cambiar sin previo aviso. </p>
															{(() => {
																const level = parseInt(levelUser);
																if (level >= 7 && level <= 8) {
																	return (
																		<>
																			<div className="regular-text-content prose pt-4 font-normal text-amBlack" style={{ marginBottom: '20px' }}>
																			<p style={{ display: 'flex', alignItems: 'center' }}><span class='myp'><strong className="myp">3. Compra de Upgrade con Puntos Aeroméxico Rewards</strong><br />(Sujeto a disponibilidad de clase F)</span> <img src={estrellanew} style={{ maxWidth: '40px', marginLeft: '15px' }} /> </p>
																				<p>Si cuentas con <strong>Tarjetas Santander Aeroméxico o Tarjetas American Express® Aeroméxico</strong>, podrás usar tus puntos Aeroméxico Rewards para adquirir el Upgrade.</p>
																				<p>Lo puedes solicitar 48 h antes del vuelo (hora local) a través de Mi Línea de Atención, opción #1 (LINC).</p>
																				<table>
																					<thead>
																						<tr>
																							<th>Región</th>
																							<th>Puntos<sup>*</sup></th>
																							<th>Región</th>
																							<th>Puntos<sup>*</sup></th>
																						</tr>
																					</thead>
																					<tbody>
																						<tr>
																							<td>ASI</td>
																							<td>43,400</td>
																							<td>CAM/CAR</td>
																							<td>7,300</td>
																						</tr>
																						<tr>
																							<td>DOM</td>
																							<td>3,000</td>
																							<td>EUR</td>
																							<td>52,500</td>
																						</tr>
																						<tr>
																							<td>SUD_SH/SCL</td>
																							<td>10,000</td>
																							<td>USA 787</td>
																							<td>17,600</td>
																						</tr>
																						<tr>
																							<td>GRU/EZE</td>
																							<td>48,600</td>
																							<td>USA/CAN</td>
																							<td>8,800</td>
																						</tr>
																					</tbody>
																				</table>
																			</div>
																			<p>*El valor en puntos se actualiza a principio de cada año.</p><br />
																			<p><strong>Términos y condiciones</strong></p><br />
																			<ul>
																				<li><p><sub>• Aplica para el empleado y sus acompañantes (máximo 4 personas por vuelo)</sub></p></li>
																				<li><p><sub>• No es necesario que el colaborador viaje</sub></p></li>
																				<li><p><sub>• El ascenso no incluye el acceso a Salones Premier o cualquier otro beneficio.</sub></p></li>
																				<li><p><sub>• La prioridad de lista de espera es después de clientes elegibles para ascenso y de acuerdo con el nivel de puesto del empleado considerando a los beneficiarios que viajan en la misma reservación. En caso contrario, aplica la prioridad para “beneficiarios viajando solos”.</sub></p></li>
																			</ul>
																		</>
																	);
																}else if (level >= 9 && level <= 9){
																	return (
																		<>
																			<div className="regular-text-content prose pt-4 font-normal text-amBlack" style={{ marginBottom: '20px' }}>
																				<p style={{ display: 'flex', alignItems: 'center' }}><span class='myp'><strong className="myp">3. Compra de Upgrade con Puntos Aeroméxico Rewards</strong><br />(Sujeto a disponibilidad de clase F)</span> <img src={estrellanew} style={{ maxWidth: '40px', marginLeft: '15px' }} /> </p>
																				<p>Si cuentas con <strong>Tarjetas Santander Aeroméxico o Tarjetas American Express® Aeroméxico</strong>, podrás usar tus puntos Aeroméxico Rewards para adquirir el Upgrade.</p>
																				<p>Lo puedes solicitar 48 h antes del vuelo (hora local) a través de Mi Línea de Atención, opción #1 (LINC).</p>
																				<table>
																					<thead>
																						<tr>
																							<th>Región</th>
																							<th>Puntos<sup>*</sup></th>
																							<th>Región</th>
																							<th>Puntos<sup>*</sup></th>
																						</tr>
																					</thead>
																					<tbody>
																						<tr>
																							<td>ASI</td>
																							<td>43,400</td>
																							<td>CAM/CAR</td>
																							<td>7,300</td>
																						</tr>
																						<tr>
																							<td>DOM</td>
																							<td>3,000</td>
																							<td>EUR</td>
																							<td>52,500</td>
																						</tr>
																						<tr>
																							<td>SUD_SH/SCL</td>
																							<td>10,000</td>
																							<td>USA 787</td>
																							<td>17,600</td>
																						</tr>
																						<tr>
																							<td>GRU/EZE</td>
																							<td>48,600</td>
																							<td>USA/CAN</td>
																							<td>8,800</td>
																						</tr>
																					</tbody>
																				</table>
																			</div>
																			<p>*El valor en puntos se actualiza a principio de cada año.</p><br />
																			<p><strong>Términos y condiciones</strong></p><br />
																			<ul>
																				<li><p><sub>• Aplica para el empleado y sus acompañantes (máximo 4 personas por vuelo)</sub></p></li>
																				<li><p><sub>• No es necesario que el colaborador viaje</sub></p></li>
																				<li><p><sub>• El ascenso no incluye el acceso a Salones Premier o cualquier otro beneficio.</sub></p></li>
																				<li><p><sub>• La prioridad de lista de espera es después de clientes elegibles para ascenso y de acuerdo con el nivel de puesto del empleado considerando a los beneficiarios que viajan en la misma reservación. En caso contrario, aplica la prioridad para “beneficiarios viajando solos”.</sub></p></li>
																			</ul>
																		</>
																	);
																}else {
																	return null;
																}
															})()}
														</>
														);
												} else {
													return null;  // Si el nivel no está en el rango esperado
												}
												})()}
										</>
									);
								case 'RewardsUsers':
									return (
										<>
											<div class="regular-text-content prose pt-4 font-normal text-amBlack" style={{ marginBottom: '20px' }}>
												<p>Si cuentas con <strong>Tarjetas Santander Aeroméxico o Tarjetas American Express® Aeroméxico</strong>, podrás usar tus puntos Aeroméxico Rewards* para adquirir:</p>
												<ul>
													<li>Vuela verde</li>
													{(() => {
														const level = parseInt(levelUser);
														if (level >= 7 && level <= 8) {
															return (
																<>
																<li>Upgrades <sup>**</sup></li>
																</>
															)
														}else if (level >= 9 && level <= 9){
															return (
																<>
																<li>Upgrades <sup>**</sup></li>
																</>
															)
														}else if (level >= 10 && level <= 12){
															return (
																<>
																<li>Upgrades <sup>**</sup></li>
																</>
															)
														}else {
															return null;
														}
													})()}
													<li>Equipaje adicional</li>
													{(() => {
														const level = parseInt(levelUser);
														if(level >= 1 && level <= 4){
															return (
																<>
																	<li>Asientos Preferentes y en salidas de emergencia en cualquier momento</li>
																</>
															)
														}else if(level >= 5 && level <= 6){
															return (
																<>
																	<li>Asientos AM Plus, preferentes y en salidas de emergencia en cualquier momento</li>
																</>
															)
														}else if (level >= 7 && level <= 8) {
															return (
																<>
																	<li>Asientos AM Plus, preferentes y en salidas de emergencia en cualquier momento</li>
																</>
															)
														}else if (level >= 9 && level <= 9){
															return (
																<>
																	<li>Asientos AM Plus, preferentes y en salidas de emergencia en cualquier momento</li>
																</>
															)
														}else if (level >= 10 && level <= 12){
															return (
																<>
																	<li>Asientos AM Plus, preferentes y en salidas de emergencia en cualquier momento</li>
																</>
															)
														}else {
															return null;
														}
													})()}
													
												</ul>
												<p>Podrás comprar tus ancillaries a través de la app Aeroméxico, sitio aeromexico.com o Call Center de Aeroméxico Rewards.</p>
											</div>
											<p>*No aplica para compra de boletos de Travel Benefits ni acumulación de Puntos Aeroméxico Rewards</p>
											{(() => {
												const level = parseInt(levelUser);
												if (level >= 7 && level <= 8) {
													return (
														<>
															<p>** Consultar la sección <strong>Upgrade</strong></p>
														</>
													)
												}else if (level >= 9 && level <= 9){
													return (
														<>
															<p>** Consultar la sección <strong>Upgrade</strong></p>
														</>
													)
												}else if (level >= 10 && level <= 12){
													return (
														<>
															<p>** Consultar la sección <strong>Upgrade</strong></p>
														</>
													)
												}else {
													return null;
												}
											})()}
										</>
									)
								default:
									return <p>Este es el contenido por defecto para otros modales.</p>;
							}
						})()}


						{/* {!!data.firstAlert ? (
							<div className="flex flex-col items-center justify-center">
								<div className="alert mb-3 flex items-center justify-start rounded-lg bg-amRegularBlue p-3">
									<p
										dangerouslySetInnerHTML={{
											__html: i18next.t(
												'MiExperAM.travel.ancillares.upgrade.small_preview'
											)
										}}
										className="small-text-alert-modal small-text-alert-modal m-0 ml-2 leading-normal"
									/>
								</div>
								<Warning className="mb-4 h-8 w-8 fill-current text-amRegularBlue" />
							</div>
						) : null}
						<div
							className="regular-text-content prose pt-4 font-normal text-amBlack"
							style={{ marginBottom: '20px' }}
							dangerouslySetInnerHTML={{ __html: data.content }}
						/> */}

						{/* {!!data.endAlert ? (
							<div className="flex flex-col items-center justify-center">
								<Warning className="mb-4 h-8 w-8 fill-current text-amYellow" />
								<div className="alert mb-3 flex items-center justify-start rounded-lg bg-amYellow p-3">
									<p
										className="small-text-alert-modal-2 small-text-alert-modal m-0 ml-2 leading-normal"
										dangerouslySetInnerHTML={{
											__html: data.endAlert
										}}
									/>
								</div>
							</div>
						) : null}

						{!!data.secondContent ? (
							<div
								className="regular-text-content prose pt-4 font-normal text-amBlack"
								style={{ marginBottom: '20px' }}
								dangerouslySetInnerHTML={{
									__html: data.secondContent
								}}
							/>
						) : null} */}
						{/* {!!data.table ? (
							<div className="overflow-hidden rounded-lg">
								{Array.isArray(theTable)
									? theTable.map((item, index) => (
											<div
												key={item.name}
												className={`flex w-full items-start justify-start py-2 text-white bg-${
													(index + 1) % 2 === 0
														? 'amBlack'
														: 'amBlue'
												}`}
											>
												<div className="w-8/12 px-4">
													{item.name}
												</div>
												<div className="w-4/12 px-4 text-right">
													{item.value}
												</div>
											</div>
									  ))
									: null}
							</div>
						) : null} */}
						{/* {!!data.endContent ? (
							<div
								className="regular-text-content prose pt-4 font-normal text-amBlack"
								style={{ marginBottom: '20px' }}
								dangerouslySetInnerHTML={{
									__html: data.endContent
								}}
							/>
						) : null} */}
					</div>
				</div>
			</div>
		</Modal>
	);
};
