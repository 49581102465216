import React from "react";
import { motion } from "framer-motion";
import { HeaderStyled } from "../styles/Styled";
import Integridad from "../../../assets/compliance/benefits/integridad.png";
import Seguridad from "../assets/seguridad-borderless.svg";
import QR from "../assets/nom35QR2024.png";
import i18next from 'i18next';

function Header() {
	const isSpanish = i18next.language === 'es';
	return (
		<HeaderStyled>
			<div className="container first">
				<img src={Seguridad} alt="Pon la seguridad primero" />
				<h1>{isSpanish?"¡Tu bienestar es lo más importante!":"Your well-being is the most important thing!"}</h1>
				<p>
					{isSpanish 
					? <>En Grupo Aeroméxico, estamos comprometidos con tu salud y bienestar emocional. Como parte de este compromiso, durante los meses de <strong>octubre a diciembre</strong> aplicaremos un cuestionario donde podrás brindarnos tu retroalimentación para seguir construyendo un mejor espacio de trabajo para todos.</> 
					: <>At Grupo Aeromexico, we are committed to your health and emotional well-being. As part of this commitment, during the months of  <strong>October through December</strong>, we will apply a questionnaire where you can give us your feedback to continue building a better workplace for everyone.</> }
				</p>
			</div>
			<div className="container video-container d-flex justify-content-center">
				<div className="row">
					{/* <motion.a
						whileHover={{
							scale: 1.05
						}}
						href="https://web.microsoftstream.com/video/1e876607-84a3-4d00-a1c7-713ccbc8139f"
						target="_blank"
						rel="noopener noreferrer"
						className="card-video col-12 col-md-12 col-lg-6"
					>
						<div className="card-item col-lg-12">
							<div className="img-holder">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
								>
									<path d="M7 6L7 18 17 12z" />
								</svg>
							</div>
							<div className="card-intel-holder">
								<h3>
									{isSpanish?"Sesión sobre la":"Session on the"}
									<br />
									<span>{isSpanish?"NOM 035":"NOM 035"}</span>
								</h3>
								<p>
									{isSpanish?"Vuelve a ver la sesión para conocer más sobre la NOM 035 y cómo la adoptaremos en nuestra cultura.":"Watch the session again to learn more about NOM 035 and how we will adopt it in Our culture."}
								</p>
								<p>
									<span className="fake-link">
										{isSpanish?"Ver sesión":"View session"}
									</span>
								</p>
							</div>
						</div>
					</motion.a> */}
					<motion.a
						whileHover={{
							scale: 1.05
						}}
						target="_blank"
						rel="noopener noreferrer"
						className="card-video the-qr-card-video-item col-12 col-md-12 col-lg-12"
						href="https://forms.office.com/Pages/ResponsePage.aspx?id=u29ZJTxgskaa4aln4WKi_zkM43thNHdJqH7cKhou05hUODNYS0NNVTBCSU5IUjZFUFpDVktCQUdLVS4u&origin=QRCode"
					>
						<div className="card-item col-lg-12">
							<div className="img-holder">
								<img src={QR} alt="Contesta el cuestionario" />
							</div>
							<div className="card-intel-holder with-button">
								<h3>{isSpanish?"¡Contesta el cuestionario aquí!":"Take the quiz here!"}</h3>
								<div className="bottom">
									<p>{isSpanish?"Escanea el código QR o":"Scan the QR code or"}</p>
									<button>{isSpanish?"Accede dando clic aquí":"Access by clicking here"}</button>
								</div>
							</div>
						</div>
					</motion.a>
				</div>
			</div>
		</HeaderStyled>
	);
}

export default Header;
