import React, { Component } from 'react';
import arrow from '../../../assets/images/arrow-blue.svg';
import Modal from 'react-awesome-modal';
import close from '../../../assets/logo/close.svg';
import periodos from '../../../assets/calendar.svg';
import accesTravel01 from '../../../assets/images/arrow-blue.svg';
import i18next from 'i18next';
import { Trans } from 'react-i18next';
import { isLocal, isFromDev, isFromQA } from './../../../core/Ambientes';

import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemPanel,
	AccordionItemButton
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';

var noScroll = require('no-scroll');

// # Variables que switchean las ligas que se consumen segun sea elcaso
// const isLocal = process.env.REACT_APP_ISLOCAL; // Tomara entorno local
// const isFromDev = process.env.REACT_APP_ISFROMDEV; //Se pondra en true para ambiente DEV
// const isFromQA = process.env.REACT_APP_ISFROMQA; //Se pondra en true para ambiente QA

// #URl de ambiente
const urlLocal = `${process.env.REACT_APP_LOCAL}`;
const urlDev = `${process.env.REACT_APP_CADMIN_DEV}`;
const urlQA = `${process.env.REACT_APP_CADMIN_QA}`
const urlProd = `${process.env.REACT_APP_CADMIN_PROD}`

const tokenLocal = `${process.env.REACT_APP_TOKEN_LOCAL}`;
const tokenDev   = `${process.env.REACT_APP_CADMIN_DEV_TOKEN}`;
const tokenQA 	 = `${process.env.REACT_APP_CADMIN_QA_TOKEN}`;
const tokenProd  = `${process.env.REACT_APP_API_TOKEN}`

// # Liga validada
const urlValid = isLocal === true? urlLocal 
	: isFromDev === true ? urlDev
	: isFromQA === true? urlQA
	: urlProd

// Se genera el token segun el ambiente
const tokenValid = isLocal === true ? tokenLocal
	: isFromDev === true ? tokenDev
	: isFromQA === true ? tokenQA
	: tokenProd;

	
	const cabecera = new Headers({
		'Content-type': 'application/json',
		'Authorization': `Bearer ${tokenValid}`
	});
export default class ModalEmbargo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			visible: false,
			embargo: []
		};
		this.escFunction = this.escFunction.bind(this);
		this.setWrapperRef = this.setWrapperRef.bind(this);
	}

	async componentDidMount() {
		await fetch(`${urlValid}traduccion-es`, {headers:cabecera} )
			.then(response => {
				return response.json();
			})
			.then(async json => {
				console.log("modalembargo",json)
				await this.setState({
					...this.state,
					embargo: json.data.attributes.data.MiExperAM.travel.embargo
				});
			});
		setTimeout(() => this.setState({ loading: false }), 1500);
		document.addEventListener('keydown', this.escFunction, false);
	}

	UNSAFE_componentWillMount() {
		document.addEventListener('mousedown', this.handleClick, false);
	}
	escFunction(event) {
		if (event.keyCode === 27) {
			this.setState({
				modalIsShow: false,
				modalIsShowIdiomas: false,
				visible: false
			});
			noScroll.off();
		}
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.escFunction, false);
		document.removeEventListener('mousedown', this.handleClick, false);
	}
	handleClick = e => {
		if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
			this.setState({
				visible: false
			});
			noScroll.off();
		}
	};
	setWrapperRef(node) {
		this.wrapperRef = node;
	}

	openModal() {
		this.setState({
			visible: true
		});
		noScroll.on();
	}

	closeModal() {
		this.setState({
			visible: false
		});
		noScroll.off();
	}

	render() {
		return (
			<div>
				<Modal
					visible={this.state.visible}
					width="auto"
					height="auto"
					effect="fadeInUp"
					onClickAway={() => this.closeModal()}
				>
					<div className="modalWindowAncillaries upgradeModalHeightEmbargo">
						<div
							className="closeModal"
							onClick={() => this.closeModal()}
						>
							<img src={close} alt='cerrado'></img>
						</div>

						<div className="topBox">
							<div className="header">
								<div className="imageCircle calendarioembargo"></div>
								<div className="textLeftCircle">
									<p className="title">
										{i18next.t(
											'MiExperAM.travel.leftMenu.embargo'
										)}
									</p>
									<p className="subtitle">
										{i18next.t(
											'MiExperAM.travel.embargo.sub'
										)}
									</p>
								</div>
							</div>

							<div className="contentType">
								<Accordion
									allowZeroExpanded="true"
									id="wrapperAccordionThis"
								>
									{/* <AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<div className="tableBoletosHeader">
													<p className="title">
														<Trans i18nKey="MiExperAM.travel.embargo.nov19.m"></Trans>
													</p>
												</div>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<ul>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.nov19.t1"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.nov19.t2"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.nov19.t3"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.nov19.t4"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.nov19.t5"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.nov19.t6"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.nov19.t7"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.nov19.t8"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.nov19.t9"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.nov19.t10"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.nov19.t11"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.nov19.t12"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.nov19.t13"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.nov19.t14"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.nov19.t15"></Trans>
												</li>
											</ul>
										</AccordionItemPanel>
									</AccordionItem>

									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<div className="tableBoletosHeader">
													<p className="title">
														<Trans i18nKey="MiExperAM.travel.embargo.dic19.m"></Trans>
													</p>
												</div>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<ul>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.dic19.t1"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.dic19.t2"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.dic19.t3"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.dic19.t4"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.dic19.t5"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.dic19.t6"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.dic19.t7"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.dic19.t8"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.dic19.t9"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.dic19.t10"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.dic19.t11"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.dic19.t12"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.dic19.t13"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.dic19.t14"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.dic19.t15"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.dic19.t16"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.dic19.t17"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.dic19.t18"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.dic19.t19"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.dic19.t20"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.dic19.t21"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.dic19.t22"></Trans>
												</li>
											</ul>
										</AccordionItemPanel>
									</AccordionItem>

									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<div className="tableBoletosHeader">
													<p className="title">
														<Trans i18nKey="MiExperAM.travel.embargo.ene20.m"></Trans>
													</p>
												</div>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<ul>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.ene20.t1"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.ene20.t2"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.ene20.t3"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.ene20.t4"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.ene20.t5"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.ene20.t6"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.ene20.t7"></Trans>
												</li>
											</ul>
										</AccordionItemPanel>
									</AccordionItem>

									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<div className="tableBoletosHeader">
													<p className="title">
														<Trans i18nKey="MiExperAM.travel.embargo.feb20.m"></Trans>
													</p>
												</div>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<ul>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.feb20.t1"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.feb20.t2"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.feb20.t3"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.feb20.t4"></Trans>
												</li>
											</ul>
										</AccordionItemPanel>
									</AccordionItem>

									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<div className="tableBoletosHeader">
													<p className="title">
														<Trans i18nKey="MiExperAM.travel.embargo.mar20.m"></Trans>
													</p>
												</div>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<ul>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.mar20.t1"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.mar20.t2"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.mar20.t3"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.mar20.t4"></Trans>
												</li>
											</ul>
										</AccordionItemPanel>
									</AccordionItem>

									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<div className="tableBoletosHeader">
													<p className="title">
														<Trans i18nKey="MiExperAM.travel.embargo.abr20.m"></Trans>
													</p>
												</div>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<ul>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.abr20.t1"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.abr20.t2"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.abr20.t3"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.abr20.t4"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.abr20.t5"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.abr20.t6"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.abr20.t7"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.abr20.t8"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.abr20.t9"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.abr20.t10"></Trans>
												</li>
											</ul>
										</AccordionItemPanel>
									</AccordionItem>

									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<div className="tableBoletosHeader">
													<p className="title">
														<Trans i18nKey="MiExperAM.travel.embargo.may20.m"></Trans>
													</p>
												</div>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<ul>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.may20.t1"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.may20.t2"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.may20.t3"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.may20.t4"></Trans>
												</li>
											</ul>
										</AccordionItemPanel>
									</AccordionItem>

									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<div className="tableBoletosHeader">
													<p className="title">
														<Trans i18nKey="MiExperAM.travel.embargo.jun20.m"></Trans>
													</p>
												</div>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<ul>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.jun20.t1"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.jun20.t2"></Trans>
												</li>
											</ul>
										</AccordionItemPanel>
									</AccordionItem>

									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<div className="tableBoletosHeader">
													<p className="title">
														<Trans i18nKey="MiExperAM.travel.embargo.jul20.m"></Trans>
													</p>
												</div>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<ul>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.jul20.t1"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.jul20.t2"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.jul20.t3"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.jul20.t4"></Trans>
												</li>
											</ul>
										</AccordionItemPanel>
									</AccordionItem>

									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<div className="tableBoletosHeader">
													<p className="title">
														<Trans i18nKey="MiExperAM.travel.embargo.ago20.m"></Trans>
													</p>
												</div>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<ul>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.ago20.t1"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.ago20.t2"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.ago20.t3"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.ago20.t4"></Trans>
												</li>
											</ul>
										</AccordionItemPanel>
									</AccordionItem>

									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<div className="tableBoletosHeader">
													<p className="title">
														<Trans i18nKey="MiExperAM.travel.embargo.sep20.m"></Trans>
													</p>
												</div>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<ul>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.sep20.t1"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.sep20.t2"></Trans>
												</li>
											</ul>
										</AccordionItemPanel>
									</AccordionItem>

									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<div className="tableBoletosHeader">
													<p className="title">
														<Trans i18nKey="MiExperAM.travel.embargo.oct20.m"></Trans>
													</p>
												</div>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<ul>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.oct20.t1"></Trans>
												</li>
											</ul>
										</AccordionItemPanel>
									</AccordionItem>

									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<div className="tableBoletosHeader">
													<p className="title">
														<Trans i18nKey="MiExperAM.travel.embargo.nov20.m"></Trans>
													</p>
												</div>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<ul>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.nov20.t1"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.nov20.t2"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.nov20.t3"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.nov20.t4"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.nov20.t5"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.nov20.t6"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.nov20.t7"></Trans>
												</li>
											</ul>
										</AccordionItemPanel>
									</AccordionItem>

									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<div className="tableBoletosHeader">
													<p className="title">
														<Trans i18nKey="MiExperAM.travel.embargo.dic20.m"></Trans>
													</p>
												</div>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<ul>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.dic20.t1"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.dic20.t2"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.dic20.t3"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.dic20.t4"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.dic20.t5"></Trans>
												</li>
											</ul>
										</AccordionItemPanel>
									</AccordionItem>

									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<div className="tableBoletosHeader">
													<p className="title">
														<Trans i18nKey="MiExperAM.travel.embargo.ene21.m"></Trans>
													</p>
												</div>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<ul>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.ene21.t1"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.ene21.t2"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.ene21.t3"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.ene21.t4"></Trans>
												</li>
												<li>
													<Trans i18nKey="MiExperAM.travel.embargo.ene21.t5"></Trans>
												</li>
											</ul>
										</AccordionItemPanel>
									</AccordionItem> */}
									{/* INICIO MiExperAM.travel.embargo.dates */}
									{/* {this.state.embargo.map(item => (
										<AccordionItem key={item.m}>
											<AccordionItemHeading>
												<AccordionItemButton>
													<div className="tableBoletosHeader">
														<p className="title">
															{item.m}
														</p>
													</div>
												</AccordionItemButton>
											</AccordionItemHeading>
											<AccordionItemPanel>
												<ul>
													{item.data.map(it => (
														<li
															key={it}
															dangerouslySetInnerHTML={{
																__html: it
															}}
														></li>
													))}
												</ul>
											</AccordionItemPanel>
										</AccordionItem>
									))} */}
									{/* FIN MiExperAM.travel.embargo.dates */}
								</Accordion>
								<p className="small">
									<Trans i18nKey="MiExperAM.travel.embargo.refs.t1"></Trans>
								</p>
								<p className="small">
									<Trans i18nKey="MiExperAM.travel.embargo.refs.t2"></Trans>
								</p>
								<p className="small">
									<Trans i18nKey="MiExperAM.travel.embargo.refs.t3"></Trans>
								</p>
								<p className="small">
									<Trans i18nKey="MiExperAM.travel.embargo.refs.t4"></Trans>
								</p>
								<p className="small">
									<Trans i18nKey="MiExperAM.travel.embargo.refs.t5"></Trans>
								</p>
								<p className="small">
									<Trans i18nKey="MiExperAM.travel.embargo.refs.t6"></Trans>
								</p>
								<p className="small">
									<Trans i18nKey="MiExperAM.travel.embargo.refs.t7"></Trans>
								</p>
								<p className="small">
									<Trans i18nKey="MiExperAM.travel.embargo.refs.t8"></Trans>
								</p>
								<p className="small">
									<Trans i18nKey="MiExperAM.travel.embargo.refs.t9"></Trans>
								</p>
								<p className="small">
									<Trans i18nKey="MiExperAM.travel.embargo.refs.t10"></Trans>
								</p>
							</div>
						</div>
					</div>
				</Modal>

				{/* <a onClick={() => this.openModal()}> */}
				<a
					// href="https://rodrigogarcia.com.mx"
					href={i18next.t('MiExperAM.travel.leftMenu.embargo_url')}
					target="_blank"
					rel="noopener noreferrer"
				>
					<div className="cardWrapperBoletos">
						<img
							className="icon"
							src={periodos}
							alt="embargo"
						></img>
						<div className="ticketsText">
							<p>
								{i18next.t('MiExperAM.travel.leftMenu.embargo')}
							</p>
						</div>
						<img
							className="arrow"
							src={accesTravel01}
							alt="arrow"
						></img>
					</div>
				</a>
			</div>
		);
	}
}
