import React, { Fragment, useState } from "react";
import Modal from "react-awesome-modal";
import { Nitem } from "../styles/Styled";

import close from "../../../assets/logo/close.svg";

var noScroll = require("no-scroll");

function NomItem({ text, backgroundImage, content, p2, children }) {
	const [modalActive, setModalActive] = useState(false);

	const openModal = () => {
		setModalActive(true);
		noScroll.on();
	};

	const closeModal = () => {
		setModalActive(false);
		noScroll.off();
	};

	return (
		<Fragment>
			<Nitem onClick={openModal}>
				<div className="left-nitem">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
						<path fill="none" d="M0 0h24v24H0z" />
						<path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-7v2h2v-2h-2zm0-8v6h2V7h-2z" />
					</svg>
				</div>
				<div className="right-nitem">
					<p>{text}</p>
					<span className="background-filter"></span>
				</div>
			</Nitem>
			<Modal
				visible={modalActive}
				width="auto"
				height="auto"
				effect="fadeInDown"
				onClickAway={() => closeModal()}
			>
				<div className="container-footer-card-modal compliance-modal-2">
					<div class="closeModal" onClick={() => closeModal()}>
						<img src={close} alt="Cerrar modal" />
					</div>

					<div
						className="modal-footer-card-top"
						style={{
							backgroundColor: "#11B1A2",
							backgroundImage: `url(${backgroundImage})`,
							height: "100px"
						}}
					></div>

					<div className="modal-footer-card-content">
						<h3 className="type-title-modal">{text}</h3>
						<div className="actual-modal-content">
							<p>{content}</p>
							{p2 && <p>{p2}</p>}
							{children}
						</div>
					</div>
				</div>
			</Modal>
		</Fragment>
	);
}

export default NomItem;
