import React, { Fragment } from 'react';
import { ColaboradorOne, ColaboradorTwo } from '../styles/Styled';
import CCard from './ColaborarCard';
import i18next from 'i18next';

function Colaborar() {
	const isSpanish = i18next.language === 'es';
	return (
		<Fragment>
			<ColaboradorOne>
				<article className="container">
					<div className='row w-100'>
						<div className='col-lg text-center'>
							<h2 style={{fontSize:"1.9rem"}}>
								{isSpanish?"Como colaboradores de Grupo Aeroméxico, es compromiso de todos:":"As collaborators of Grupo Aeroméxico, it is our commitment to all:"}
							</h2>
						</div>
					</div>
				</article>
			</ColaboradorOne>
			<ColaboradorTwo>
				<article className="the-gridder container">
					<CCard
						text= {isSpanish?"Participar en el cuestionario de la NOM-035 para identificar factores de riesgo psicosocial":"Participate in the NOM-035 questionnaire to identify psychosocial risk factors"}
						icon="Alarm"
					/>
					<CCard
						text={isSpanish?"Conocer nuestra política de prevención de riesgos psicosociales":"Know our psychosocial risk prevention policy"}
						icon="Shield"
						url={`${process.env.REACT_APP_SRC_URL}nom035/Politica%20de%20prevencio%CC%81n%20de%20riegos.pdf`}
						link={ i18next.language==='es'? "Descargar": "Dowland"}
					/>
					<CCard
						text={isSpanish?"Reportar cualquier acción que perjudique nuestro bienestar integral o el de nuestros compañeros.":"Report any action that harms our overall well-being or that of our colleagues."}
						icon="Warning"
					/>
					<CCard
						text={isSpanish?"Promover un entorno de trabajo seguro y saludable":"Promote a safe and healthy work environment"}
						icon="Mind"
					/>
				</article>
			</ColaboradorTwo>
		</Fragment>
	);
}

export default Colaborar;
