export const learn_14 = [
	{
		title: 'Leadership',
		one: {
			text: null,
			duration: null,
			url: null
		},
		two: {
			text: null,
			duration: null,
			url: null
		},
		three: {
			text: 'Leading as a CEO',
			duration: '3h 9min',
			url:
				'https://www.linkedin.com/learning/jeff-weiner-on-leading-like-a-ceo/welcome-to-leading-like-a-ceo'
		},
		four: {
			text: null,
			duration: null,
			url: null
		}
	},
	{
		title: 'DE&I',
		one: {
			text: 'Inclusive Leadership',
			duration: '1h',
			url: 'https://www.linkedin.com/learning/leading-inclusive-teams'
		},
		two: {
			text: null,
			duration: null,
			url: null
		},

		three: {
			text: null,
			duration: null,
			url: null
		},
		four: {
			text: 'Confronting Bias: Thriving Across Differences',
			duration: '40min',
			url:
				'https://www.linkedin.com/learning/leveraging-virtual-and-hybrid-teams-for-improved-effectiveness'
		}
	},
	{
		title: null,
		one: {
			text: '1h',
			is_duration: true,
			url: null
		},
		two: {
			text: '',
			is_duration: false,
			url: null
		},
		three: {
			text: '3h 9min',
			is_duration: true,
			url: null
		},
		four: {
			text: '40min',
			is_duration: true,
			url: null
		}
	}
];

export const learn_10_12 = [
	{
		title: 'Business Capabilities',
		one: {
			text: 'Critical Thinking for Better Judgement and Decision Making',
			duration: '56min',
			url:
				'https://www.linkedin.com/learning/paths/grupo-aeromexico-directors-vicepresidents'
		},
		two: {
			text: 'Strategic Agillity',
			duration: '36min',
			url:
				'https://www.linkedin.com/learning/paths/grupo-aeromexico-directors-vicepresidents'
		},
		three: {
			text: null,
			duration: null,
			url: null
		},
		four: {
			text: null,
			duration: null,
			url: null
		}
	},
	{
		title: 'Customer Centricity',
		one: {
			text: 'Customer Experience Leadership',
			duration: '48min',
			url:
				'https://www.linkedin.com/learning/paths/grupo-aeromexico-directors-vicepresidents'
		},
		two: {
			text: null,
			duration: null,
			url: null
		},

		three: {
			text: 'VIDEO Align EX with CX',
			duration: '3min',
			url:
				'https://www.linkedin.com/learning/paths/grupo-aeromexico-directors-vicepresidents'
		},
		four: {
			text: 'Service Innovation',
			duration: '58min',
			url:
				'https://www.linkedin.com/learning/paths/grupo-aeromexico-directors-vicepresidents'
		}
	},
	{
		title: 'Leadership',
		one: {
			text: 'Transitioning from Manager to Leader',
			duration: '59min',
			url:
				'https://www.linkedin.com/learning/paths/grupo-aeromexico-directors-vicepresidents'
		},
		two: {
			text: 'Counterintuitive Leadership Strategies VUCA Environments',
			duration: '57min',
			url:
				'https://www.linkedin.com/learning/paths/grupo-aeromexico-directors-vicepresidents'
		},

		three: {
			text: 'Executive Influence',
			duration: '22min',
			url:
				'https://www.linkedin.com/learning/paths/grupo-aeromexico-directors-vicepresidents'
		},
		four: {
			text: null,
			duration: null,
			url: null
		}
	},
	{
		title: 'DE&I',
		one: {
			text: null,
			duration: null,
			url: null
		},
		two: {
			text: null,
			duration: null,
			url: null
		},
		three: {
			text: 'Inclusive Leadership',
			duration: '1h',
			url:
				'https://www.linkedin.com/learning/paths/grupo-aeromexico-directors-vicepresidents'
		},
		four: {
			text: 'Confronting Bias: Thriving Across Differences',
			duration: '40min',
			url:
				'https://www.linkedin.com/learning/paths/grupo-aeromexico-directors-vicepresidents'
		}
	},
	{
		title: 'Collaboration',
		one: {
			text: null,
			duration: null,
			url: null
		},
		two: {
			text: null,
			duration: null,
			url: null
		},
		three: {
			text: null,
			duration: null,
			url: null
		},
		four: {
			text: 'Working in Harmony as a Senior Team',
			duration: '52min',
			url:
				'https://www.linkedin.com/learning/paths/grupo-aeromexico-directors-vicepresidents'
		}
	},
	{
		title: null,
		one: {
			text: '2h 43min',
			is_duration: true,
			url: null
		},
		two: {
			text: '1h 33min',
			is_duration: true,
			url: null
		},
		three: {
			text: '1h 50min',
			is_duration: true,
			url: null
		},
		four: {
			text: '2h 30min',
			is_duration: true,
			url: null
		}
	}
];

export const learn_7_9 = [
	{
		title: 'Business Capabilities',
		one: {
			text: 'Critical Thinking',
			duration: '59min',
			url:
				'https://www.linkedin.com/learning/paths/grupo-aeromexico-middle-management?u=118941042'
		},
		two: {
			text: 'Strategic Thinking',
			duration: '36min',
			url:
				'https://www.linkedin.com/learning/paths/grupo-aeromexico-middle-management?u=118941042'
		},
		three: {
			text: 'Strategic Planning Foundations',
			duration: '1h 22min',
			url:
				'https://www.linkedin.com/learning/paths/grupo-aeromexico-middle-management?u=118941042'
		},
		four: {
			text: 'Agile at Work: Build your Agile Team',
			duration: '55min',
			url:
				'https://www.linkedin.com/learning/paths/grupo-aeromexico-middle-management?u=118941042'
		}
	},
	{
		title: 'Customer Centricity',
		one: {
			text: 'Leading a Customer Centric Culture',
			duration: '36min',
			url:
				'https://www.linkedin.com/learning/paths/grupo-aeromexico-middle-management?u=118941042'
		},
		two: {
			text: null,
			duration: null,
			url: null
		},

		three: {
			text: 'Developing a Service Mindset',
			duration: '41min',
			url:
				'https://www.linkedin.com/learning/paths/grupo-aeromexico-middle-management?u=118941042'
		},
		four: {
			text: null,
			duration: null,
			url: null
		}
	},
	{
		title: 'Leadership',
		one: {
			text: 'Essential Lessons for First-Time Managers',
			duration: '1h 5min',
			url:
				'https://www.linkedin.com/learning/paths/grupo-aeromexico-middle-management?u=118941042'
		},
		two: {
			text: 'Leading with Emotional Intelligence',
			duration: '1h 2min',
			url:
				'https://www.linkedin.com/learning/paths/grupo-aeromexico-middle-management?u=118941042'
		},

		three: {
			text: null,
			duration: null,
			url: null
		},
		four: {
			text: 'Transformational Leadership',
			duration: '1h 7min',
			url:
				'https://www.linkedin.com/learning/paths/grupo-aeromexico-middle-management?u=118941042'
		}
	},
	{
		title: 'DE&I',
		one: {
			text: null,
			duration: null,
			url: null
		},
		two: {
			text: null,
			duration: null,
			url: null
		},
		three: {
			text: 'Leading Inclusive Teams',
			duration: '1h',
			url:
				'https://www.linkedin.com/learning/paths/grupo-aeromexico-middle-management?u=118941042'
		},
		four: {
			text: 'Confronting Bias: Thriving Across Differences',
			duration: '40min',
			url:
				'https://www.linkedin.com/learning/paths/grupo-aeromexico-middle-management?u=118941042'
		}
	},
	{
		title: 'Hybrid Collaboration',
		one: {
			text:
				'Leveraging Virtual and Hybrid Teams for Improved Effectiveness',
			duration: '29min',
			url:
				'https://www.linkedin.com/learning/paths/grupo-aeromexico-middle-management?u=118941042'
		},
		two: {
			text: null,
			duration: null,
			url: null
		},
		three: {
			text: null,
			duration: null,
			url: null
		},
		four: {
			text: null,
			duration: null,
			url: null
		}
	},
	{
		title: null,
		one: {
			text: '3h 9min',
			is_duration: true,
			url: null
		},
		two: {
			text: '1h 38min',
			is_duration: true,
			url: null
		},
		three: {
			text: '3h 3min',
			is_duration: true,
			url: null
		},
		four: {
			text: '2h 42min',
			is_duration: true,
			url: null
		}
	}
];

export const learn_5_6 = [
	{
		title: 'Business Capabilities',
		one: {
			text: 'Pensamiento Crítico',
			duration: '1h 6min',
			url:
				'https://www.linkedin.com/learning/paths/grupo-aeromexico-specialists?u=118941042'
		},
		two: {
			text: 'Fundamentos de la gestión de proyectos ágiles',
			duration: '1h 17min',
			url:
				'https://www.linkedin.com/learning/paths/grupo-aeromexico-specialists?u=118941042'
		},
		three: {
			text: null,
			duration: null,
			url: null
		},
		four: {
			text: 'Análisis de Información: Detectar Patrones y Tendencias',
			duration: '1h 11min',
			url:
				'https://www.linkedin.com/learning/paths/grupo-aeromexico-specialists?u=118941042'
		}
	},
	{
		title: 'Customer Centricity',
		one: {
			text: null,
			duration: null,
			url: null
		},
		two: {
			text: 'Cómo gestionar el enfado de la clientela',
			duration: '40min',
			url:
				'https://www.linkedin.com/learning/paths/grupo-aeromexico-specialists?u=118941042'
		},

		three: {
			text: 'Técnicas innovadoras de atención al cliente',
			duration: '45min',
			url:
				'https://www.linkedin.com/learning/paths/grupo-aeromexico-specialists?u=118941042'
		},
		four: {
			text: null,
			duration: null,
			url: null
		}
	},
	{
		title: 'Leadership',
		one: {
			text: null,
			duration: null,
			url: null
		},
		two: {
			text: 'Cómo gestionar a tu superior',
			duration: '1h 3min',
			url:
				'https://www.linkedin.com/learning/paths/grupo-aeromexico-specialists?u=118941042'
		},

		three: {
			text: 'Fundamentos y consejos para Gerentes Principiantes',
			duration: '1h 8min',
			url:
				'https://www.linkedin.com/learning/paths/grupo-aeromexico-specialists?u=118941042'
		},
		four: {
			text: null,
			duration: null,
			url: null
		}
	},
	{
		title: 'DE&I',
		one: {
			text: null,
			duration: null,
			url: null
		},
		two: {
			text: null,
			duration: null,
			url: null
		},
		three: {
			text: 'Diversidad, Inclusión y Pertenencia',
			duration: '47min',
			url:
				'https://www.linkedin.com/learning/paths/grupo-aeromexico-specialists?u=118941042'
		},
		four: {
			text:
				'Cómo enfrentar los prejuicios, superando nuestras diferencias',
			duration: '39min',
			url:
				'https://www.linkedin.com/learning/paths/grupo-aeromexico-specialists?u=118941042'
		}
	},
	{
		title: 'Hybrid Collaboration',
		one: {
			text: 'Gestión de equipos remotos',
			duration: '2h 21min',
			url:
				'https://www.linkedin.com/learning/paths/grupo-aeromexico-specialists?u=118941042'
		},
		two: {
			text: null,
			duration: null,
			url: null
		},
		three: {
			text: null,
			duration: null,
			url: null
		},
		four: {
			text: null,
			duration: null,
			url: null
		}
	},
	{
		title: null,
		one: {
			text: '3h 27min',
			is_duration: true,
			url: null
		},
		two: {
			text: '3h',
			is_duration: true,
			url: null
		},
		three: {
			text: '2h 40min',
			is_duration: true,
			url: null
		},
		four: {
			text: '2h 56min',
			is_duration: true,
			url: null
		}
	}
];

export const learn_1_4 = [
	{
		title: 'Business Capabilities',
		one: {
			text: 'Pensamiento Crítico',
			duration: '1h 6min',
			url:
				'https://www.linkedin.com/learning/paths/grupo-aeromexico-analysts-9464075?u=118941042'
		},
		two: {
			text: 'Qué es Agilidad',
			duration: '4min',
			url:
				'https://www.linkedin.com/learning/paths/grupo-aeromexico-analysts-9464075?u=118941042'
		},
		three: {
			text: 'Análisis de Información: Detectar Patrones y Tendencias',
			duration: '1h 11min',
			url:
				'https://www.linkedin.com/learning/paths/grupo-aeromexico-analysts-9464075?u=118941042'
		},
		four: {
			text: null,
			duration: null,
			url: null
		}
	},
	{
		title: 'Customer Centricity',
		one: {
			text: 'Fundamentos del servicio de atención al cliente',
			duration: '1h 22min',
			url:
				'https://www.linkedin.com/learning/paths/grupo-aeromexico-analysts-9464075?u=118941042'
		},
		two: {
			text: 'Cómo gestionar el enfado de la clientela',
			duration: '40min',
			url:
				'https://www.linkedin.com/learning/paths/grupo-aeromexico-analysts-9464075?u=118941042'
		},

		three: {
			text: 'Normas de calidad en el servicio de atención al cliente',
			duration: '1h 4min',
			url:
				'https://www.linkedin.com/learning/paths/grupo-aeromexico-analysts-9464075?u=118941042'
		},
		four: {
			text: null,
			duration: null,
			url: null
		}
	},
	{
		title: 'Leadership',
		one: {
			text: 'Autoconocimiento, autenticidad y liderazgo',
			duration: '30min',
			url:
				'https://www.linkedin.com/learning/paths/grupo-aeromexico-analysts-9464075?u=118941042'
		},
		two: {
			text: 'Fundamentos y consejos para Gerentes Principiantes',
			duration: '1h 8min',
			url:
				'https://www.linkedin.com/learning/paths/grupo-aeromexico-analysts-9464075?u=118941042'
		},

		three: {
			text: null,
			duration: null,
			url: null
		},
		four: {
			text: null,
			duration: null,
			url: null
		}
	},
	{
		title: 'DE&I',
		one: {
			text: null,
			duration: null,
			url: null
		},
		two: {
			text: null,
			duration: null,
			url: null
		},
		three: {
			text: 'Diversidad, Inclusión y Pertenencia',
			duration: '47min',
			url:
				'https://www.linkedin.com/learning/paths/grupo-aeromexico-analysts-9464075?u=118941042'
		},
		four: {
			text:
				'Cómo enfrentar los prejuicios, superando nuestras diferencias',
			duration: '39min',
			url:
				'https://www.linkedin.com/learning/paths/grupo-aeromexico-analysts-9464075?u=118941042'
		}
	},
	{
		title: 'Collaboration',
		one: {
			text: null,
			duration: null,
			url: null
		},
		two: {
			text: 'La Escucha Activa',
			duration: '1h 9min',
			url:
				'https://www.linkedin.com/learning/paths/grupo-aeromexico-analysts-9464075?u=118941042'
		},
		three: {
			text: null,
			duration: null,
			url: null
		},
		four: {
			text: 'Cómo generar confianza',
			duration: '1h',
			url:
				'https://www.linkedin.com/learning/paths/grupo-aeromexico-analysts-9464075?u=118941042'
		}
	},
	{
		title: null,
		one: {
			text: '2h 58min',
			is_duration: true,
			url: null
		},
		two: {
			text: '3h 1min',
			is_duration: true,
			url: null
		},
		three: {
			text: '3h 2min',
			is_duration: true,
			url: null
		},
		four: {
			text: '1h 39min',
			is_duration: true,
			url: null
		}
	}
];
