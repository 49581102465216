import React from "react";
import { AyudaStyle } from "../styles/Styled";
import i18next from 'i18next';

function Ayuda() {
	const isSpanish = i18next.language === 'es';
	return (
		<AyudaStyle>
			<article className="container">
				<p className="text-center">
					{isSpanish?"Si necesitas apoyo emocional puedes contactar":"If you need emotional support you can contact"}
					<br />
					{isSpanish?"al Servicio Médico de Grupo Aeroméxico":"to the Aeroméxico Group Medical Service"}
				</p>
			</article>
		</AyudaStyle>
	);
}

export default Ayuda;
