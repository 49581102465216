import React, { Component } from "react";
import * as contentful from "../../../lib/contentful";
import Modal from "react-awesome-modal";

import "./css/style.css";
import "./css/modal.css";

import close from "../../../assets/logo/close.svg";

import ReactMarkdown from "react-markdown";
import validateSindicate from "../../../utils/validateSindicate";

var noScroll = require("no-scroll");

const ModalNotification = ({ data }) => (
	<div className="container-modal-notification">
		<div className="modal-notification">
			<h1>{data.titulo}</h1>
			<p>{data.notify}</p>
			<p>{data.mensaje}</p>
		</div>
	</div>
);

export default class AlertHome extends Component {
	constructor() {
		super();
		this.state = {
			loading: true,
			error: null,
			entries: null,
			hasScrolledAlert: false,
			visible: false
		};
		this.escFunction = this.escFunction.bind(this);
		this.setWrapperRef = this.setWrapperRef.bind(this);
	}

	openModal() {
		this.setState({
			visible: true
		});
		noScroll.on();
	}

	closeModal() {
		this.setState({
			visible: false
		});
		noScroll.off();
	}

	componentDidMount() {
		this.fetchEntries();
		document.addEventListener("keydown", this.escFunction, false);
		window.addEventListener("scroll", this.handleScroll);
	}
	handleScroll = event => {
		const scrollTop = window.pageYOffset;
		if (scrollTop > 100) {
			this.setState({ hasScrolledAlert: true });
		} else {
			this.setState({ hasScrolledAlert: false });
		}
	};

	UNSAFE_componentWillMount() {
		document.addEventListener("mousedown", this.handleClick, false);
	}
	escFunction(event) {
		if (event.keyCode === 27) {
			this.setState({
				visible: false
			});
			noScroll.off();
		}
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.escFunction, false);
		document.removeEventListener("mousedown", this.handleClick, false);
	}

	handleClick = e => {
		if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
			this.setState({
				visible: false
			});
			noScroll.off();
		}
	};

	setWrapperRef(node) {
		this.wrapperRef = node;
	}
	fetchEntries = () => {
		contentful.client
			.getEntries({
				content_type: "homeNotification",
				include: 2
			})
			.then(response => response.items)
			.then(data => {
				
				const array = this.getAlerts(
					data,
					localStorage.getItem("grupo")
				);
				
				this.setState({
					loading: false,
					entries: array
				});
			})
			.catch(error => {
				this.setState({
					loading: false,
					error: error
				});
			})
			.catch(err => console.error(err));
	};

	getAlerts = (data, sindico) => {
		let sindicato = validateSindicate(sindico);
		// let sindicato = false;
		let array = [];
		data.map(item => {
			if (item.fields.sindicato === sindicato) {
				array.push(item);
			}
		});
		return array;
	};

	render() {
		const { loading, entries } = this.state;
		return (
			<>
				{!loading &&
					entries &&
					entries.map(HomeAlert => (
						<div
							className={
								"nivelClaseAlert" +
								localStorage.getItem("nivel") +
								" " +
								"alertwidth"
							}
						>
							<div
								className={
									"ModalClaseBlock" +
									" " +
									HomeAlert.fields.nivelesParaAlerta
										.toString()
										.replace(/,/g, " ")
								}
							>
								{/* <div className={
              this.state.hasScrolledAlert ? "alertScrolled" : ""
            }> */}

								<div>
									<div
										key={HomeAlert.sys.id}
										className={
											HomeAlert.fields.notifyVisible +
											" " +
											"HomeAlertWrap" +
											" " +
											HomeAlert.fields.nivelesParaAlerta
												.toString()
												.replace(/,/g, " ")
										}
										onClick={() => this.openModal()}
									>
										<div className="HomeAlertMid">
											<p className="ImportantNote">
												{HomeAlert.fields.notifyType}
											</p>
											<p className="TextImportant">
												{HomeAlert.fields.notifyText}
											</p>
										</div>
									</div>
								</div>

								<Modal
									visible={this.state.visible}
									width="100%"
									height="auto"
									effect="fadeInDown"
									onClickAway={() => this.closeModal()}
								>
									<div
										ref={this.setWrapperRef}
										className="modal-notification homealertModalModification"
									>
										<div className="scrollModalAlert">
											<div class="imageNotification">
												{/* <p>{HomeAlert.fields.notifyType}</p> */}
												<img
													src={
														HomeAlert.fields
															.imageNotification
															.fields.file.url
													}
												/>
											</div>

											<div className="modalPadding">
												<div
													class="closeModal"
													href="javascript:void(0);"
													onClick={() =>
														this.closeModal()
													}
												>
													<img src={close} alt="cerrar"></img>
												</div>
												<h1>
													{
														HomeAlert.fields
															.notifyType
													}
												</h1>
												<p>
													{
														HomeAlert.fields
															.notifyText
													}
												</p>
												<ReactMarkdown className="content">
													{
														HomeAlert.fields
															.contentNotification
													}
												</ReactMarkdown>
												{/* <div className="content" dangerouslySetInnerHTML={{ __html: HomeAlert.fields.contentNotification}}/> */}
											</div>
										</div>
									</div>
								</Modal>
							</div>
						</div>
					))}
			</>
		);
	}
}
