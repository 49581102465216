export const travelBenefitsSpecialCases = [
	{
		name: 'AM Comunicación Interna',
		email: 'amcomunicacioninterna@aeromexico.com'
	},
	{
		name: 'GARCIA TAPIA ERNESTO',
		email: 'ergarcia@aeromexico.com'
	},
	{
		name: 'HERNANDEZ MORENO JORGE EDUARDO',
		email: 'jhernandez@aeromexico.com'
	},
	{
		name: 'MENDEZ GONZALEZ ALEJANDRO NICOLAS',
		email: 'amendez@aeromexico.com'
	},
	{
		name: 'MEDINA VALDEZ MIGUEL ANGEL',
		email: 'ifabianl@aeromexico.com'
	},
	{
		name: 'PUENTE CASTRO JORGE LUIS',
		email: 'mmedinav@aeromexico.com'
	},
	{
		name: 'ZALDIVAR CONTRERAS CONSUELO ESTEFANI',
		email: 'czaldivar@aeromexico.com'
	},
	{
		name: 'MEJIA MORALES MARIO ALBERTO',
		email: 'mmejiam@aeromexico.com'
	},
	{
		name: 'DE LA ROSA GOMEZ MARIA LETICIA',
		email: 'mdelarosa@aeromexico.com'
	},
	{
		name: 'VALENCIA RODRIGUEZ GERARDO',
		email: 'gvalencia@aeromexico.com'
	},
	{
		name: 'MARQUEZ LIRA DIANA MELINA',
		email: 'dmarquez@aeromexico.com'
	},
	{
		name: 'SERRANO GAVINO MARIA ANGELINA',
		email: 'maserrano@aeromexico.com'
	}
];
