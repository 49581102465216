import React, { Component } from 'react';
import ScrollmenuJCA from '../Header/scrollmenuJCA';
import {
	Element
} from 'react-scroll';
import './css/jca.css';
import ArticleCards from '../Home/Cards/jcaarticles';
import i18next from 'i18next';
import { Trans } from 'react-i18next';
import Header from '../../components/Header';
import Index from '../Footer/index';
import { animateScroll as scroll} from "react-scroll";


class Jcaaeromexicodelta extends Component {
	componentDidMount(){
		scroll.scrollToTop();
	}
	render() {
		return (
			<div className="jca">
				{/* <Header/> */}
				<Element name="test1" className="element">
					<ScrollmenuJCA />

					<div className="headerJCA">
						<div className="midWrapJCA">
							<div className="imageDelta"></div>
							<p className="title">{i18next.t('JCA.main')}</p>
							<p className="subtitle">{i18next.t('JCA.sub')}</p>
						</div>
					</div>

					<div className="bannerIntroJCA">
						<div className="midWrapJCAdetla">
							<p>{i18next.t('JCA.alianza')}</p>
							<p>{i18next.t('JCA.alianzaSub')}</p>
						</div>
					</div>
				</Element>

				<Element name="test2" class="element">
					<div className="deltainNumbers">
						<div className="midWrapNumbers">
							<p className="titleNumbers">
								{i18next.t('JCA.cifras.title')}
							</p>

							<div className="gridnumbers">
								<div className="boxWrappergrid">
									<div className="imageGrid"></div>
									<div className="textWrapper">
										<p className="percentage">
											{i18next.t(
												'JCA.cifras.opera_title'
											)}
										</p>
										<p className="description">
											{i18next.t('JCA.cifras.opera')}
										</p>
									</div>
								</div>

								<div className="boxWrappergrid">
									<div className="imageGrid"></div>
									<div className="textWrapper">
										<p className="percentage">
											{i18next.t(
												'JCA.cifras.manejo_title'
											)}
											<span></span>
										</p>
										<p className="description">
											{i18next.t('JCA.cifras.manejo')}
										</p>
									</div>
								</div>

								<div className="boxWrappergrid">
									<div className="imageGrid"></div>
									<div className="textWrapper">
										<p className="percentage">
											<span></span>
											{i18next.t(
												'JCA.cifras.vuelos_title'
											)}
											<span></span>
										</p>
										<p className="description">
											{i18next.t('JCA.cifras.vuelos')}
										</p>
									</div>
								</div>

								<div className="boxWrappergrid">
									<div className="imageGrid"></div>
									<div className="textWrapper">
										<p className="percentage">
											{i18next.t(
												'JCA.cifras.pasajeros_title'
											)}
											<span></span>
										</p>
										<p className="description">
											{i18next.t('JCA.cifras.pasajeros')}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="weflytoimage">
						<div className="midWrapFly">
							<div className="textWrapper">
								<p className="title">
									{i18next.t('JCA.operamos.title')}
								</p>
								<p className="subtitle">
									{i18next.t('JCA.operamos.sub')}
								</p>
							</div>
						</div>
					</div>
				</Element>

				<Element name="test3" class="element">
					<div className="patrociniosWrapper">
						<div className="midWrapperPatrocinios">
							<p className="title">
								{i18next.t('JCA.patrocinios.title')}
							</p>
							<p className="sibtittle">
								{i18next.t('JCA.patrocinios.sub')}
							</p>
							<div className="wrapperBoxGridPatro">
								<div className="oneBoxPrato">
									<div className="img"></div>
								</div>

								<div className="oneBoxPrato">
									<div className="img"></div>
								</div>

								<div className="oneBoxPrato">
									<div className="img"></div>
								</div>

								<div className="oneBoxPrato">
									<div className="img"></div>
								</div>

								<div className="oneBoxPrato">
									<div className="img"></div>
								</div>

								{/* <div className="oneBoxPrato">
                 <div className="img"></div>
               </div> */}
							</div>
						</div>
					</div>
				</Element>

				<Element name="test5" class="element">
					<div className="experienceDelta">
						<div className="midWrapper">
							<p className="title">
								{i18next.t('JCA.experiencia.title')}
							</p>
							<p className="subtitle">
								{i18next.t('JCA.experiencia.sub')}
							</p>

							<div className="boxExperienceGrid">
								<div className="gridEx">
									<div className="padding">
										<p className="GridText">
											{i18next.t(
												'JCA.experiencia.cards.alimentos.title'
											)}
										</p>
										<p className="subtitleGrid">
											{i18next.t(
												'JCA.experiencia.cards.alimentos.sub'
											)}
										</p>
									</div>
								</div>

								<div className="gridEx">
									<div className="padding">
										<p className="GridText">
											<Trans i18nKey="JCA.experiencia.cards.mensajes.title"></Trans>
										</p>
										<p className="subtitleGrid">
											{i18next.t(
												'JCA.experiencia.cards.mensajes.sub'
											)}
										</p>
									</div>
								</div>

								<div className="gridEx">
									<div className="padding">
										<p className="GridText">
											{i18next.t(
												'JCA.experiencia.cards.wifi.title'
											)}
										</p>
										<p className="subtitleGrid">
											{i18next.t(
												'JCA.experiencia.cards.wifi.sub'
											)}
										</p>
									</div>
								</div>

								<div className="gridEx gridExSmall gridExWidthBigger">
									<div className="padding">
										<div className="logo"></div>
										<p className="GridText">
											{i18next.t(
												'JCA.experiencia.cards.sky.title'
											)}
										</p>
										<p className="subtitleGrid">
											{i18next.t(
												'JCA.experiencia.cards.sky.sub'
											)}
										</p>
									</div>
								</div>

								<div className="gridEx gridExSmall">
									<div className="padding">
										<div className="logo"></div>
										<p className="GridText">
											{i18next.t(
												'JCA.experiencia.cards.familias.title'
											)}
										</p>
										<p className="subtitleGrid">
											{i18next.t(
												'JCA.experiencia.cards.familias.sub'
											)}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Element>

				<Element name="test4" class="element">
					<section class="newsJCA" id="newsPageJCA">
						<ArticleCards />
					</section>
				</Element>
				{/* <Index/> */}
			</div>
		);
	}
}

export default Jcaaeromexicodelta;
