import React, { Component } from 'react';
import Tab from './Tab';
import PropTypes from 'prop-types';

export default class Tabs extends Component {
	static propTypes = {
		children: PropTypes.instanceOf(Array).isRequired
	};

	constructor(props) {
		super(props);
		this.state = {
			// activeTab: this.props.children[0].props.label,
			activeTab: this.selectActive(this.props.children),
			// activeTab: this.props.currentTab
			isActive: this.props.isActive
		};
	}

	selectActive(childrens) {
		for (let i = 0; i < childrens.length; i++) {
			if (childrens[i].props.isActive === true) {
				return childrens[i].props.label;
			}
		}
	}

	onClickTabItem = tab => {
		this.setState({ activeTab: tab });
	};

	render() {
		const {
			onClickTabItem,
			props: { children },
			state: { activeTab }
		} = this;

		return (
			<section className="tabber">
				{this.state.isActive && (
					<React.Fragment>
						<ul className="tabber-selector">
							{children.map(child => {
								const { label, exists } = child.props;
								if (exists) {
									return (
										<Tab
											activeTab={activeTab}
											key={label}
											label={label}
											onClick={onClickTabItem}
										/>
									);
								} else {
									return null;
								}
							})}
						</ul>
						{children.map(child => {
							if (child.props.label !== activeTab) return undefined;
							return child.props.children;
						})}
					</React.Fragment>
				)}
			</section>
		);
	}
}
