import React, { Component } from 'react';
import Modal from 'simple-react-modal';

import * as contentful from '../../lib/contentful';
import profileIcon02 from '../../assets/images/profile/icon-mybenefits04.svg';
import NewsCard from './FooterProfile/NewsCard';

import './css/footerProfile.css';
import './css/modalCard.css';
import i18next from 'i18next';
import getLocale from '../../utils/getLocale';

export default class FooterProfile extends Component {
	constructor() {
		super();
		this.state = {
			loading: true,
			error: null,
			entries: null
		};
	}
	componentDidMount() {
		this.fetchEntries();
	}
	fetchEntries = () => {
		contentful.client
			.getEntries({
				content_type: 'myDiscountsFeatured',
				include: 2,
				order: '-sys.updatedAt',
				locale: getLocale()
			})
			.then((response) => response.items)
			.then((data) => {
				
				this.setState({
					loading: false,
					entries: data
				});
			})
			.catch((error) => {
				this.setState({
					loading: false,
					error: error
				});
			})
			.catch((err) => console.error(err));
	};
	render() {
		const { loading, entries } = this.state;
		return (
			<>
				<div className="footerProfile">
					<div className="footerProfile__topContainer">
						<div className="totalBenefits__title__container">
							<div className="iconTitleProfile">
								<img src={profileIcon02} />
							</div>
							<h1>{i18next.t('MiExperAM.benef.anadido')}</h1>
							<p></p>
							{/*<a className='viewAll' herf='#'>
              Ver todos
            </a>*/}
						</div>
					</div>
					<div
						className={`footerProfile__middleContainer ${this.props.marginBottom}`}
					>
						<div className="footerProfile__newsContainer">
							{!loading &&
								entries &&
								entries
									.slice(0, 3)
									.map((newsCard) => (
										<NewsCard
											data={newsCard}
											key={
												newsCard.fields.myDiscountsTitle
											}
										/>
									))}
						</div>
					</div>
				</div>
			</>
		);
	}
}
