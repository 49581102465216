import React, { Component } from 'react';
import './css/totalBenefits.css';
import './css/slider.css';
import BDContentMX from './Benefits/BenefitsDiscountsContentMX';
import BDContentCA from './Benefits/BenefitsDiscountsContentCA';
import BDContentUSA from './Benefits/BenefitsDiscountsContentUSA';
import BDContentMX2 from './Benefits/BenefitsDiscountsContentMX2';
// import isTravelBenefitsActive from '../../utils/isTravelBenefitsActive';

export default class BenefitsDiscounts extends Component {
	state = {
		loaded: false,
		location: localStorage.getItem('location')
		// location: 'USA'
	};

	componentDidMount() {
		this.setState({ loaded: true });
	}

	selectCountry(country) {
		// alert('country: ', country)
		switch (country) {
			case 'CAN':
				return <BDContentCA />;
			case 'USA':
				return <BDContentUSA />;
			default:
				return <BDContentMX2 />;

		}
	}

	render() {
		if (localStorage.getItem('tipo_empleado_AM')) {
			return (
				<React.Fragment>
					{this.state.loaded &&
						this.selectCountry(this.state.location)}
				</React.Fragment>
			);
		} else {
			return null;
		}
	}
}
