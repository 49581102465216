import React, { Component } from 'react';
import arrow from '../../../assets/images/arrow-blue.svg';
import Modal from 'react-awesome-modal';
import close from '../../../assets/logo/close.svg';
import i18next from 'i18next';
import { Trans } from 'react-i18next';

var noScroll = require('no-scroll');

export default class TravelBenefits extends Component {
	constructor(props) {
		super(props);
		this.state = {
			visible: false
		};
		this.escFunction = this.escFunction.bind(this);
		this.setWrapperRef = this.setWrapperRef.bind(this);
	}

	componentDidMount() {
		setTimeout(() => this.setState({ loading: false }), 1500);
		document.addEventListener('keydown', this.escFunction, false);
	}

	UNSAFE_componentWillMount() {
		document.addEventListener('mousedown', this.handleClick, false);
	}
	escFunction(event) {
		if (event.keyCode === 27) {
			this.setState({
				modalIsShow: false,
				modalIsShowIdiomas: false,
				visible: false
			});
			noScroll.off();
		}
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.escFunction, false);
		document.removeEventListener('mousedown', this.handleClick, false);
	}
	handleClick = e => {
		if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
			this.setState({
				visible: false
			});
			noScroll.off();
		}
	};
	setWrapperRef(node) {
		this.wrapperRef = node;
	}

	openModal() {
		this.setState({
			visible: true
		});
		noScroll.on();
	}

	closeModal() {
		this.setState({
			visible: false
		});
		noScroll.off();
	}

	render() {
		return (
			<div>
				<Modal
					visible={this.state.visible}
					width="auto"
					height="auto"
					effect="fadeInUp"
					onClickAway={() => this.closeModal()}
				>
					<div className="modalWindowAncillaries upgradeModalHeight">
						<div
							className="closeModal"
							onClick={() => this.closeModal()}
						>
							<img src={close}alt='cerrado'></img>
						</div>

						<div className="topBox">
							<div className="header">
								<div className="imageCircle asientoupgrade"></div>
								<div className="textLeftCircle">
									<p className="title">
										{i18next.t(
											'MiExperAM.travel.ancillares.upgrade.title'
										)}
									</p>
									<p className="subtitle">
										{i18next.t(
											'MiExperAM.travel.ancillares.upgrade.sub'
										)}
									</p>
								</div>
							</div>

							<div className="contentType upgrade">
								<h3>
									<Trans i18nKey="MiExperAM.travel.ancillares.upgrade.2posibilidades"></Trans>
								</h3>
								<h5
									subtitle={i18next.t(
										'MiExperAM.travel.ancillares.upgrade.especial'
									)}
								>
									<Trans i18nKey="MiExperAM.travel.ancillares.upgrade.48horas"></Trans>
								</h5>
								<p style={{ marginBottom: '20px' }}>
									<ul>
										<li>
											{i18next.t(
												'MiExperAM.travel.ancillares.upgrade.clasef'
											)}
										</li>
										<li>
											{i18next.t(
												'MiExperAM.travel.ancillares.upgrade.beneficiarios'
											)}
										</li>
										<li>
											{i18next.t(
												'MiExperAM.travel.ancillares.upgrade.maximo'
											)}
										</li>
									</ul>
									<p
										style={{
											marginLeft: 0,
											marginRight: 0,
											marginBottom: '10px'
										}}
									>
										<strong>
											{i18next.t(
												'MiExperAM.travel.ancillares.upgrade.precios'
											)}
										</strong>
									</p>
									<div className="tableWidthPremier">
										<div className="columnPremier">
											<ul>
												<li>
													<span>
														{i18next.t(
															'MiExperAM.travel.ancillares.upgrade.tabla.asia'
														)}
													</span>
													<p>
														{i18next.t(
															'MiExperAM.travel.ancillares.upgrade.tabla.precio1'
														)}
													</p>
												</li>
												<li>
													<span>
														{i18next.t(
															'MiExperAM.travel.ancillares.upgrade.tabla.centro'
														)}
													</span>
													<p>
														{i18next.t(
															'MiExperAM.travel.ancillares.upgrade.tabla.precio2'
														)}
													</p>
												</li>
												<li>
													<span>
														{i18next.t(
															'MiExperAM.travel.ancillares.upgrade.tabla.domes'
														)}
													</span>
													<p>
														{i18next.t(
															'MiExperAM.travel.ancillares.upgrade.tabla.precio3'
														)}
													</p>
												</li>
												<li className="noborder">
													<span>
														{i18next.t(
															'MiExperAM.travel.ancillares.upgrade.tabla.europa'
														)}
													</span>
													<p>
														{i18next.t(
															'MiExperAM.travel.ancillares.upgrade.tabla.precio4'
														)}
													</p>
												</li>
											</ul>
										</div>
										<div className="columnPremier">
											<ul>
												<li>
													<span>
														{i18next.t(
															'MiExperAM.travel.ancillares.upgrade.tabla.sudame'
														)}
													</span>
													<p>
														{i18next.t(
															'MiExperAM.travel.ancillares.upgrade.tabla.precio5'
														)}
													</p>{' '}
													<br />
													<span>
														{i18next.t(
															'MiExperAM.travel.ancillares.upgrade.tabla.sh'
														)}
													</span>
													<p>
														{i18next.t(
															'MiExperAM.travel.ancillares.upgrade.tabla.precio6'
														)}
													</p>
												</li>
												<li>
													<span>
														{i18next.t(
															'MiExperAM.travel.ancillares.upgrade.tabla.usa'
														)}
													</span>
													<p>
														{i18next.t(
															'MiExperAM.travel.ancillares.upgrade.tabla.precio7'
														)}
													</p>{' '}
													<br />
													<span>
														{i18next.t(
															'MiExperAM.travel.ancillares.upgrade.tabla.canada'
														)}
													</span>
													<p>
														{i18next.t(
															'MiExperAM.travel.ancillares.upgrade.tabla.precio8'
														)}
													</p>
												</li>
											</ul>
										</div>
									</div>
									<span>
										{i18next.t(
											'MiExperAM.travel.ancillares.upgrade.tabla.montos'
										)}
									</span>
									<br />
									<br />
									<Trans i18nKey="MiExperAM.travel.ancillares.upgrade.tramite"></Trans>
								</p>
								<h5
									subtitle={i18next.t(
										'MiExperAM.travel.ancillares.upgrade.sincosto'
									)}
								>
									<strong>
										{i18next.t(
											'MiExperAM.travel.ancillares.upgrade.1hora'
										)}
									</strong>
								</h5>
								<p style={{ marginBottom: '20px' }}>
									<ul>
										<li>
											{i18next.t(
												'MiExperAM.travel.ancillares.upgrade.punto3'
											)}
										</li>
										<li>
											{i18next.t(
												'MiExperAM.travel.ancillares.upgrade.punto4'
											)}
										</li>
									</ul>
									<Trans i18nKey="MiExperAM.travel.ancillares.upgrade.tramiteauto"></Trans>
									<br />
									<br />
									<span style={{ fontSize: '14px' }}>
										<Trans i18nKey="MiExperAM.travel.ancillares.upgrade.importante"></Trans>
									</span>
									<span className="fakeAlert">
										<div className="fakeIcon"></div>
										<Trans i18nKey="MiExperAM.travel.ancillares.upgrade.warning"></Trans>
									</span>
								</p>

								<hr />
								<h3>
									{i18next.t(
										'MiExperAM.travel.ancillares.upgrade.terminos.title'
									)}
								</h3>
								<ul>
									<li>
										{i18next.t(
											'MiExperAM.travel.ancillares.upgrade.terminos.t1'
										)}
									</li>
									<li>
										{i18next.t(
											'MiExperAM.travel.ancillares.upgrade.terminos.t2'
										)}
									</li>
								</ul>
							</div>
						</div>
					</div>
				</Modal>

				<a
					className="totalBenefisTravel__card upgradePremier"
					onClick={() => this.openModal()}
				>
					<div className="totalBenefitsTravel__card__image" />
					<h5 className="totalBenefitsTravel__card__title">
						{i18next.t('MiExperAM.travel.ancillares.upgrade.title')}
					</h5>
					<p className="totalBenefitsTravel__card__text">
						{i18next.t(
							'MiExperAM.travel.ancillares.upgrade.preview'
						)}
					</p>
					<a
						className="travel-link flex items-center justify-start"
						to="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/eabautista_aeromexico_com/EeYuMCfE4xJCotcla-TwkXYB9FWIIjWNY9It6l5h4oMkOQ?e=Z7ZRmC"
					>
						<h6 className="totalBenefitsTravel__card__more">
							{i18next.t('MiExperAM.travel.ancillares.conoce')}
						</h6>
						<img
							className="blue-arrow mr-2"
							src={arrow}
							alt="blue-arrow"
						/>{' '}
					</a>
				</a>
			</div>
		);
	}
}
