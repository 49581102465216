import React, { Fragment, Component } from 'react';
import request from 'superagent';

// const endPoint = 'https://mi-aeromexico.herokuapp.com';

var email = localStorage.getItem('email');

export default class ModalBackground extends Component {
	constructor() {
		super();
		this.state = {
			nickname: '',
			work_area: ''
		};
	}

	handleChange = evt => {
		this.setState({
			[evt.target.name]: evt.target.value
		});
	};

	saveUserInfo = () => {
		// console.log({endPoint,email});
		// request
		//   .post(`${endPoint}/api/settings/${email}`)
		//   .set({
		//     "Content-Type": "application/json"
		//   })
		//   .send({
		//     nickname: this.state.nickname,
		//     work_area: this.state.work_area
		//   })
		//   .then(res => {
		//     console.log(res);
		//     if (res.ok) {
		//       this.setState({
		//         isShow: false
		//       });
		//       this.props.history.push("/total-rewards/profile");
		//     }
		//   });
	};
	render() {
		
		return (
			<Fragment>
				<p className="modal-title">Work information</p>
				<p className="modal-info-label">
					name:
					<span className="modal-info-text">Ernesto Alejandro</span>
				</p>
				<p className="modal-info-label">
					last name:<span className="modal-info-text">Mejia </span>
				</p>
				<p className="modal-info-label">
					location:<span className="modal-info-text">México</span>
				</p>
				<p className="modal-info-label">
					payment group:<span className="modal-info-text">8</span>
				</p>
				<p className="modal-info-label">
					position:<span className="modal-info-text">Jefe</span>
				</p>
				<span className="modal-sub-subtitle">
					This information cannot be changed, if something is wrong
					please contact{' '}
					<a href="mailto:comunicacioninterna@aeromexico.com">
						comunicacioninterna@aeromexico.com
					</a>
				</span>
				<div className="wrap-input100 validate-input modal-input">
					<input
						id="email"
						className="form-control input100"
						name="nickname"
						onChange={this.handleChange}
					/>
					<span
						className="focus-input100"
						for="nickname"
						data-placeholder="Nickname"
					/>
				</div>
				<div className="wrap-input100 validate-input modal-input">
					<input
						id="email"
						className="form-control input100"
						name="work_area"
						onChange={this.handleChange}
					/>
					<span
						className="focus-input100"
						for="work_area"
						data-placeholder="Work Area"
					/>
				</div>
				<button onClick={this.saveUserInfo}>SAVE SETTINGS</button>
			</Fragment>
		);
	}
}
