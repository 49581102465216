import React from "react";
import CurrencyFormat from "react-currency-format";

const FakeResultTableFC = ({
	anual,
	borderColor = "#3D5069",
	color = "#030D75",
	monthly,
	sub,
	title,
	withThree = true
}) => {
	return (
		<div
			className="grid grid-cols-3 gap-0 rounded-md text-white shadow-md"
			style={{ background: color, height: 60 }}>
			<div
				className={`flex flex-col items-start justify-center border-r px-4 ${
					withThree ? "col-span-1" : "col-span-2"
				}`}
				style={{
					borderColor: borderColor
				}}>
				<p className="text-sm font-semibold">{title}</p>
				<p className="text-xs font-semibold opacity-50">{sub}</p>
			</div>
			{withThree ? (
				<div
					className="justify-left col-span-1 flex items-center border-r px-4"
					style={{
						borderColor: "#3D5069"
					}}>
					<CurrencyFormat
						decimalSeparator="."
						decimalScale="2"
						fixedDecimalScale={true}
						value={monthly}
						displayType={"text"}
						thousandSeparator=","
						prefix="$"
						renderText={(value) => <p className="text-sm font-semibold text-white">{value}  </p>}
					/>
				</div>
			) : null}
			<div className="justify-left col-span-1 flex items-center px-4">
				<CurrencyFormat
					decimalSeparator="."
					decimalScale="2"
					fixedDecimalScale={true}
					value={anual}
					displayType={"text"}
					thousandSeparator=","
					prefix="$"
					renderText={(value) => <p className="text-sm font-semibold text-white">{value}  </p>}
				/>
			</div>
		</div>
	);
};

export default FakeResultTableFC;