import React, { Component } from 'react';
import './css/identidad.css';
import pdf from '../../assets/identidad/pdf.svg';
import styled from 'styled-components';
import Header from '../../components/Header';
import Index from '../../components/Footer/index';
import i18next from 'i18next';
import {
	r2022,
	r2021,
	r2020,
	r2019,
	r2018,
	r2017,
	r2016,
	r2015,
	r2014,
	r2013,
	r2012
} from './jsons/reporteOperacional';
import { animateScroll as scroll} from "react-scroll";

const ReporteSusten = styled.div`
	.wrapperIdenditad {
		grid-template-columns: repeat(5, 1fr);
	}
`;

export default class SliderSwiper extends Component {
	
	componentDidMount(){
		scroll.scrollToTop();
	}

	render() {
		return (
			<div style={{ background: '#EDF6FB' }}>
				{/* <Header/> */}
				<div class="nosotrosWrapper">
					<div class="nosotrosHeader">
						<h4>{i18next.t('Reportes.Operacional.titulo')}</h4>
						<p>{i18next.t('Reportes.Trimestral.subtitulo')}</p>
						<p
							className="mt-2 underline"
							style={{
								fontSize: '15px',
								textDecorationColor: 'red',
								textUnderlineOffset: '3px'
							}}
						>
							Si requieres más información, ingresa a:{' '}
							<a
								style={{
									textDecorationColor: 'red'
								}}
								href="https://aeromexico.com/es-mx/inversionistas/reportes-financieros"
							>
								reportes financieros
							</a>
						</p>
					</div>
				</div>

				<ReporteSusten>
					<div
						className="wrapperDownload"
						style={{ background: '#EDF6FB' }}
					>
						<div className="wrapperIdenditad">
							<h3 className="fulltitle">2022</h3>
							{r2022.map((item, index) => {
								if (item.url !== '' || item.urlES !== '') {
									return (
										<div
											key={index}
											className="wrapperbuttonBig"
										>
											<div className="wrapperTop">
												<div className="wrapperTopCell">
													<img
														className="noTextImg"
														src={pdf}
														alt="PDF"
													/>
													<p style={{color:'#035cf7',fontWeight:'bold'}}>
														{i18next.language ===
														'es'
															? item.month.es
															: item.month
																	.en}{' '}
														2022 
													</p>
												</div>
											</div>
											<a
												href={
													i18next.language === 'es'
														? item.urlES
														: item.url
												}
												className="wrapperBottom"
												target="_blank"
												rel="noopener noreferrer"
											>
												<div className="downloadIdenti">
													<span></span>
													<p style={{color:'#035cf7',fontWeight:'bold'}}>Descargar</p>
												</div>
											</a>
										</div>
									);
								}
							})}
							<h3 className="fulltitle">2021</h3>
							{r2021.map((item, index) => (
								<div key={index} className="wrapperbuttonBig">
									<div className="wrapperTop">
										<div className="wrapperTopCell">
											<img
												className="noTextImg"
												src={pdf}
												alt="PDF"
											/>
											<p style={{color:'#035cf7',fontWeight:'bold'}}>
												{i18next.language === 'es'
													? item.month.es
													: item.month.en}{' '}
												2021
											</p>
										</div>
									</div>
									<a
										href={
											i18next.language === 'es'
												? item.urlES
												: item.url
										}
										className="wrapperBottom"
										target="_blank"
										rel="noopener noreferrer"
									>
										<div className="downloadIdenti">
											<span></span>
											<p style={{color:'#035cf7',fontWeight:'bold'}}>Descargar</p>
										</div>
									</a>
								</div>
							))}
							<h3 className="fulltitle">2020</h3>
							{r2020.map((item, index) => (
								<div key={index} className="wrapperbuttonBig">
									<div className="wrapperTop">
										<div className="wrapperTopCell">
											<img
												className="noTextImg"
												src={pdf}
												alt="PDF"
											/>
											<p style={{color:'#035cf7',fontWeight:'bold'}}>
												{i18next.language === 'es'
													? item.month.es
													: item.month.en}{' '}
												2020
											</p>
										</div>
									</div>
									<a
										href={
											i18next.language === 'es'
												? item.urlES
												: item.url
										}
										className="wrapperBottom"
										target="_blank"
										rel="noopener noreferrer"
									>
										<div className="downloadIdenti">
											<span></span>
											<p style={{color:'#035cf7',fontWeight:'bold'}}>Descargar</p>
										</div>
									</a>
								</div>
							))}
							<h3 className="fulltitle">2019</h3>
							{r2019.map((item, index) => (
								<div className="wrapperbuttonBig" key={index}>
									<div className="wrapperTop">
										<div className="wrapperTopCell">
											<img
												className="noTextImg"
												src={pdf}
												alt="PDF"
											/>
											<p style={{color:'#035cf7',fontWeight:'bold'}}>
												{i18next.language === 'es'
													? `Reporte Operacional ${item.month.es} `
													: `Operational Report ${item.month.en} `}
												2019
											</p>
										</div>
									</div>
									<a
										className="wrapperBottom"
										href={
											i18next.language === 'es'
												? item.urlES
												: item.url
										}
										target="_blank"
										rel="noopener noreferrer"
									>
										<div className="downloadIdenti">
											<span></span>
											<p style={{color:'#035cf7',fontWeight:'bold'}}>Descargar</p>
										</div>
									</a>
								</div>
							))}
							<h3 className="fulltitle">2018</h3>
							{r2018.map((item, index) => (
								<div className="wrapperbuttonBig" key={index}>
									<div className="wrapperTop">
										<div className="wrapperTopCell">
											<img
												className="noTextImg"
												src={pdf}
												alt="PDF"
											/>
											<p style={{color:'#035cf7',fontWeight:'bold'}}>
												{i18next.language === 'es'
													? `Reporte Operacional ${item.month.es} `
													: `Operational Report ${item.month.en} `}
												2018
											</p>
										</div>
									</div>
									<a
										className="wrapperBottom"
										href={
											i18next.language === 'es'
												? item.urlES
												: item.url
										}
										target="_blank"
										rel="noopener noreferrer"
									>
										<div className="downloadIdenti">
											<span></span>
											<p style={{color:'#035cf7',fontWeight:'bold'}}>Descargar</p>
										</div>
									</a>
								</div>
							))}
							<h3 className="fulltitle">2017</h3>
							{r2017.map((item, index) => (
								<div className="wrapperbuttonBig" key={index}>
									<div className="wrapperTop">
										<div className="wrapperTopCell">
											<img
												className="noTextImg"
												src={pdf}
												alt="PDF"
											/>
											<p style={{color:'#035cf7',fontWeight:'bold'}}>
												{i18next.language === 'es'
													? `Reporte Operacional ${item.month.es} `
													: `Operational Report ${item.month.en} `}
												2017
											</p>
										</div>
									</div>
									<a
										className="wrapperBottom"
										href={
											i18next.language === 'es'
												? item.urlES
												: item.url
										}
										target="_blank"
										rel="noopener noreferrer"
									>
										<div className="downloadIdenti">
											<span></span>
											<p style={{color:'#035cf7',fontWeight:'bold'}}>Descargar</p>
										</div>
									</a>
								</div>
							))}
							<h3 className="fulltitle">2016</h3>
							{r2016.map((item, index) => (
								<div className="wrapperbuttonBig" key={index}>
									<div className="wrapperTop">
										<div className="wrapperTopCell">
											<img
												className="noTextImg"
												src={pdf}
												alt="PDF"
											/>
											<p style={{color:'#035cf7',fontWeight:'bold'}}>
												{i18next.language === 'es'
													? `Reporte Operacional ${item.month.es} `
													: `Operational Report ${item.month.en} `}
												2016
											</p>
										</div>
									</div>
									<a
										className="wrapperBottom"
										href={
											i18next.language === 'es'
												? item.urlES
												: item.url
										}
										target="_blank"
										rel="noopener noreferrer"
									>
										<div className="downloadIdenti">
											<span></span>
											<p style={{color:'#035cf7',fontWeight:'bold'}}>Descargar</p>
										</div>
									</a>
								</div>
							))}
							<h3 className="fulltitle">2015</h3>
							{r2015.map((item, index) => (
								<div className="wrapperbuttonBig" key={index}>
									<div className="wrapperTop">
										<div className="wrapperTopCell">
											<img
												className="noTextImg"
												src={pdf}
												alt="PDF"
											/>
											<p style={{color:'#035cf7',fontWeight:'bold'}}>
												{i18next.language === 'es'
													? `Reporte Operacional ${item.month.es} `
													: `Operational Report ${item.month.en} `}
												2015
											</p>
										</div>
									</div>
									<a
										className="wrapperBottom"
										href={
											i18next.language === 'es'
												? item.urlES
												: item.url
										}
										target="_blank"
										rel="noopener noreferrer"
									>
										<div className="downloadIdenti">
											<span></span>
											<p style={{color:'#035cf7',fontWeight:'bold'}}>Descargar</p>
										</div>
									</a>
								</div>
							))}
							<h3 className="fulltitle">2014</h3>
							{r2014.map((item, index) => (
								<div className="wrapperbuttonBig" key={index}>
									<div className="wrapperTop">
										<div className="wrapperTopCell">
											<img
												className="noTextImg"
												src={pdf}
												alt="PDF"
											/>
											<p style={{color:'#035cf7',fontWeight:'bold'}}>
												{i18next.language === 'es'
													? `Reporte Operacional ${item.month.es} `
													: `Operational Report ${item.month.en} `}
												2014
											</p>
										</div>
									</div>
									<a
										className="wrapperBottom"
										href={
											i18next.language === 'es'
												? item.urlES
												: item.url
										}
										target="_blank"
										rel="noopener noreferrer"
									>
										<div className="downloadIdenti">
											<span></span>
											<p style={{color:'#035cf7',fontWeight:'bold'}}>Descargar</p>
										</div>
									</a>
								</div>
							))}
							<h3 className="fulltitle">2013</h3>
							{r2013.map((item, index) => (
								<div className="wrapperbuttonBig" key={index}>
									<div className="wrapperTop">
										<div className="wrapperTopCell">
											<img
												className="noTextImg"
												src={pdf}
												alt="PDF"
											/>
											<p style={{color:'#035cf7',fontWeight:'bold'}}>
												{i18next.language === 'es'
													? `Reporte Operacional ${item.month.es} `
													: `Operational Report ${item.month.en} `}
												2013
											</p>
										</div>
									</div>
									<a
										className="wrapperBottom"
										href={
											i18next.language === 'es'
												? item.urlES
												: item.url
										}
										target="_blank"
										rel="noopener noreferrer"
									>
										<div className="downloadIdenti">
											<span></span>
											<p style={{color:'#035cf7',fontWeight:'bold'}}>Descargar</p>
										</div>
									</a>
								</div>
							))}
							<h3 className="fulltitle">2012</h3>
							{r2012.map((item, index) => (
								<div className="wrapperbuttonBig" key={index}>
									<div className="wrapperTop">
										<div className="wrapperTopCell">
											<img
												className="noTextImg"
												src={pdf}
												alt="PDF"
											/>
											<p style={{color:'#035cf7',fontWeight:'bold'}}>
												{i18next.language === 'es'
													? `Reporte Operacional ${item.month.es} `
													: `Operational Report ${item.month.en} `}
												2012
											</p>
										</div>
									</div>
									<a
										className="wrapperBottom"
										href={
											i18next.language === 'es'
												? item.urlES
												: item.url
										}
										target="_blank"
										rel="noopener noreferrer"
									>
										<div className="downloadIdenti">
											<span></span>
											<p style={{color:'#035cf7',fontWeight:'bold'}}>Descargar</p>
										</div>
									</a>
								</div>
							))}
						</div>
					</div>
				</ReporteSusten>
				{/* <Index/> */}
			</div>
		);
	}
}
