import React from 'react';
import { motion } from 'framer-motion';
import IconSelector from './IconSelector';
import i18next from 'i18next';

function GridItem({ delay = 0, image, title, cat, description, handleClick }) {
	const delaytion = delay * 0.2;
	return (
		<motion.div
			initial={{ opacity: 0, top: 100 }}
			animate={{ opacity: 1, top: 0 }}
			transition={{ delay: delaytion }}
			className="relative flex h-40 w-full scale-100 transform cursor-pointer items-center overflow-hidden rounded-lg bg-amBlack ring-2 ring-amBlack ring-offset-4 ring-offset-amBlack transition duration-200 hover:ring-amLightBlue"
			style={{
				willChange: 'transform, opacity, top'
			}}
			onClick={handleClick}
		>
			<div className="card-content relative h-full w-1/2 bg-white p-4 md:w-2/3">
				<p className="text-lg font-bold text-black">{title}</p>
				<p className="mb-2 text-xs font-bold leading-tight text-amRed">
					{description}
				</p>
				<IconSelector icon={cat} />
				<p className="absolute left-4 bottom-4 text-sm font-bold text-amBlue">
					{i18next.language === 'es'?"Conoce más":"Learn more"}
				</p>
			</div>
			<div
				className="h-full w-1/2 border-l border-gray-300 md:w-1/3"
				style={{
					backgroundImage: `url(${image})`,
					backgroundColor: '#0B2342',
					backgroundSize: 'cover',
					backgroundPosition: 'center'
				}}
			>
				<img
					src={image}
					alt={title}
					className="w-extraFull h-full object-cover"
				/>
			</div>
		</motion.div>
	);
}

export default GridItem;
