import React, { Component } from "react";

import Modal from "react-awesome-modal";
import close from "../../../assets/logo/close.svg";
import * as contentful from "../../../lib/contentful";
import getLocale from "../../../utils/getLocale";

var noScroll = require("no-scroll");

export default class Aside extends Component {
	constructor() {
		super();
		// Remplazar en el estado el email para hacer la consulta
		this.state = {
			loading: true,
			entries: null,
			error: null,
			visible: true,
			charging: true
		};
		this.escFunction = this.escFunction.bind(this);
		this.setWrapperRef = this.setWrapperRef.bind(this);
	}

	openModal() {
		this.setState({
			visible: true
		});
		noScroll.on();
	}

	closeModal() {
		this.setState({
			visible: false
		});
		noScroll.off();
	}

	componentDidMount() {
		setTimeout(() => this.setState({ loading: false }), 1500);
		this.fetchEntries();
		document.addEventListener("keydown", this.escFunction, false);
		window.addEventListener("scroll", this.handleScroll);
	}

	UNSAFE_componentWillMount() {
		document.addEventListener("mousedown", this.handleClick, false);
	}
	escFunction(event) {
		if (event.keyCode === 27) {
			this.setState({
				visible: false
			});
			noScroll.off();
		}
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.escFunction, false);
		document.removeEventListener("mousedown", this.handleClick, false);
	}

	handleClick = e => {
		if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
			this.setState({
				visible: false
			});
			noScroll.off();
		}
	};

	setWrapperRef(node) {
		this.wrapperRef = node;
	}

	fetchEntries = () => {
		contentful.client
			.getEntries({
				// content_type: "articleClass",
				// include: 2,
				// order: '-sys.updatedAt',
				locale: getLocale(),
				content_type: "myBenefits",
				"fields.myBenefitTitle[all]": "Transportación Interna"
			})
			.then(response => response.items)
			.then(data => {
				this.setState({
					loading: false,
					entries: data
				});
			})
			.catch(error => {
				this.setState({
					loading: false,
					error: error
				});
			})
			.catch(err => console.error(err));
	};

	render() {
		const { loading, charging, generalInfo, entries, error } = this.state;
		return (
			<div>
				{!loading &&
					entries &&
					entries.map(transportacion => (
						<Modal
							visible={this.state.visible}
							width="auto"
							height="auto"
							effect="fadeInDown"
							onClickAway={() => this.closeModal()}
							className="container-modal-notification modal-minicard-benefits"
							key={transportacion.sys.id}
						>
							<div
								className="modal-notification"
								ref={this.setWrapperRef}
							>
								<div
									class="closeModal"
									onClick={() => this.closeModal()}
								>
									<img src={close} alt="cerrado" />
								</div>
								<div
									className="background-benefits"
									style={{
										backgroundImage: `url(${transportacion.fields.myBenefitBackground.fields.file.url})`
									}}
								>
									{/* <span>{transportacion.fields.myBenefitCategoryTitleEs}</span> */}
									<span
										className={
											transportacion.fields
												.myBenefitCategoryClass +
											" " +
											transportacion.fields.paymentGroupEmployee
												.toString()
												.replace(",", " ")
										}
									>
										{
											transportacion.fields
												.myBenefitCategoryTitleEs
										}
									</span>
								</div>
								<div className="content-modal">
									<div className="head-modal">
										<div>
											<img
												className="icon-benefits"
												src={
													transportacion.fields
														.myBenefitIcon.fields
														.file.url
												}
											/>
										</div>
										<div>
											<p>
												{
													transportacion.fields
														.myBenefitTitle
												}
											</p>
											<p>
												{
													transportacion.fields
														.myBenefitIntro
												}
											</p>
										</div>
									</div>

									<div className="BenefitContent">
										<div
											dangerouslySetInnerHTML={{
												__html:
													transportacion.fields
														.myBenefitContentLong
											}}
										/>
										{/* <p>{transportacion.fields.myBenefitContentLong}</p> */}
										<a
											className={
												transportacion.fields
													.myBenefitMoreInformationClass
											}
											href={
												transportacion.fields
													.myBenefitInformation
											}
											target="_blank"
										>
											Conoce más
										</a>
									</div>
								</div>

								<div className="termsConditions">
									<p>Terminos y condiciones</p>
									<p>
										{
											transportacion.fields
												.myBenefitTermsLong
										}
									</p>
								</div>
							</div>
						</Modal>
					))}
			</div>
		);
	}
}
