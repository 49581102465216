import React from 'react';
import i18next from 'i18next';
import { Trans } from 'react-i18next';
// assets
import accesTravel01 from '../../../assets/images/arrow-blue.svg';
import internaicon from '../../../assets/ventaespecial/interna.svg';

function VentaEspecialExtraordinaria() {
	return (
		<>
			<div className="BannerEspecialDestinos2 bg-gradient-to-r from-amYellow to-amDarkerYellow flex items-center justify-center">
				<p
					dangerouslySetInnerHTML={{
						__html: i18next.t('VentaEspecial.maintitleExtra')
					}}
					style={{ fontSize: '20px' }}
				></p>
				<div className="EventaEspecialCambios"></div>
			</div>
			<div className="wrapperFlexVentaEspecial">
				<div className="leftVentaEspecial">
					<div className="gridColumnsEspecial">
						<div className="optionGrid">
							<div className="imgVenta"></div>
							<p className="titleVenta">
								{i18next.t('VentaEspecial.titlecatego1extra')}
							</p>
							<p
								className="subTitleVenta"
								dangerouslySetInnerHTML={{
									__html: i18next.t(
										'VentaEspecial.desccatego1extra'
									)
								}}
							></p>
						</div>

						<div className="optionGrid">
							<div className="imgVenta"></div>
							<p className="titleVenta">
								{i18next.t('VentaEspecial.titlecatego2extra')}
							</p>
							<p className="subTitleVenta">
								{i18next.t('VentaEspecial.desccatego2extra')}
							</p>
						</div>

						{/* <div className="optionGrid">
							<div className="imgVenta"></div>
							<p className="titleVenta">
								{i18next.t('VentaEspecial.titlecatego3')}
							</p>
							<p className="subTitleVenta">
								{i18next.t('VentaEspecial.desccatego3')}
							</p>
							<a
								data-toggle="modal"
								data-target="#modalLearnFamily"
								className="LearnmoreFamily"
							>
								{i18next.t('VentaEspecial.textlink')}
							</a>
						</div> */}

						<div className="optionGrid empresa">
							<div className="imgVenta"></div>
							<p className="titleVenta">
								{i18next.t('VentaEspecial.titlecatego4')}
							</p>
							<p className="subTitleVenta">
								{i18next.t('VentaEspecial.desccatego4extra')}
							</p>
						</div>

						{/* <div className="optionGrid">
							<div className="imgVenta"></div>
							<p className="titleVenta">
								{i18next.t('VentaEspecial.titlecatego5')}
							</p>
							<p className="subTitleVenta">
								{i18next.t('VentaEspecial.desccatego5')}
							</p>
						</div> */}

						<div className="optionGrid ruta">
							<div className="imgVenta"></div>
							<p className="titleVenta">
								{i18next.t('VentaEspecial.titlecatego6')}
							</p>
							<p className="subTitleVenta">
								{i18next.t('VentaEspecial.desccatego6extra')}
							</p>
						</div>

						<div className="optionGrid redondo">
							<div className="imgVenta"></div>
							<p className="titleVenta">
								{i18next.t('VentaEspecial.titlecatego7')}
							</p>
							{/* <p className="subTitleVenta">
								{i18next.t('VentaEspecial.desccatego7')}
							</p> */}
						</div>

						<div className="optionGrid disponibilidad">
							<div className="imgVenta"></div>
							<p className="titleVenta">
								{i18next.t('VentaEspecial.titlecatego8')}
							</p>
							<p className="subTitleVenta">
								{i18next.t('VentaEspecial.desccatego8')}
							</p>
						</div>

						<div className="optionGrid equipaje">
							<div className="imgVenta"></div>
							<p className="titleVenta">
								{i18next.t('VentaEspecial.titlecatego9')}
							</p>
						</div>

						<div className="optionGrid embargo">
							<div className="imgVenta"></div>
							<p className="titleVenta">
								{i18next.t('VentaEspecial.titlecatego10extra')}
							</p>
							<p className="subTitleVenta">
								{i18next.t('VentaEspecial.desccatego10extra')}
							</p>
							{/* <a
								href={i18next.t('VentaEspecial.embargo')}
								target="_blank"
								rel="noopener noreferrer"
								className="LearnmoreFamily"
							>
								{i18next.t('VentaEspecial.textlink')}
							</a> */}
						</div>

						<div className="optionGrid reembolso">
							<div className="imgVenta"></div>
							<p className="titleVenta">
								{i18next.t('VentaEspecial.titlecatego11extra')}
							</p>
						</div>

						<div className="optionGrid permite-cambio">
							<div className="imgVenta"></div>
							<p className="titleVenta">
								{i18next.t('VentaEspecial.titlecatego12extra')}
							</p>
						</div>

						<div className="optionGrid no-infantes">
							<div className="imgVenta"></div>
							<p className="titleVenta">
								{i18next.t('VentaEspecial.titlecatego13extra')}
							</p>
						</div>
					</div>

					<p className="termsVentaEspecialConsulta">
						<Trans i18nKey="VentaEspecial.termstext">
							terminos <span>{i18next.t('1')}</span>
						</Trans>
					</p>
				</div>

				<div className="rightVentaEspecial">
					<div className="stickyElementVenta ventaespecialDestinosModales">
						<div className="tittleHeaderAccede">
							<p className="titleAccede">
								{i18next.t('VentaEspecial.accede')}
							</p>
						</div>

						<div
							className="boletosEmbargo"
							id="noclasesBoletosEmbargo"
						>
							{/* <VentaEspecialModal />
							<VentaEspecialModalInterna /> */}
							<DestinoInternacional />
						</div>
					</div>

					<div className="stickyElementVenta ventaespecialLinc cursor-auto">
						<div
							className="boletosEmbargo cursor-auto"
							id="noclasesBoletosEmbargo"
						>
							<p
								className="linealincTitulo cursor-auto"
								dangerouslySetInnerHTML={{
									__html: i18next.t(
										'VentaEspecial.linctituloExtra'
									)
								}}
							></p>
							<div className="lineaLincImg"></div>
							<p className="schedule cursor-auto">
								<Trans i18nKey="VentaEspecial.horario">
									horario <span>{i18next.t('1')}</span>
								</Trans>
							</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default VentaEspecialExtraordinaria;

const DestinoInternacional = () => (
	<a
		href={`${process.env.REACT_APP_SRC_URL}miam_docs/venta-especial/002Tarifas_VEspecial.pdf`}
		className="ventaEspecialModalesCambios"
		target="_blank"
		rel="noopener noreferrer"
	>
		<div className="cardWrapperBoletos">
			<img
				className="icon marginTopIconNew"
				src={internaicon}
				alt="Tarifas"
			></img>
			<div className="ticketsText iconTextnoNecesario">
				<p>{i18next.t('InterModal.title')}</p>
				<p>{i18next.t('InterModal.desdeExtra')}</p>
				<a className="viewPricesTarifas">
					{i18next.t('NacionalModal.tarifas')}
				</a>
			</div>
			<img className="arrow" src={accesTravel01} alt="Travel01" />
		</div>
	</a>
);
