import React, { Component } from 'react';
import CurrencyFormat from 'react-currency-format';
// import { PDFExport } from '@progress/kendo-react-pdf';
// import html2canvas from 'html2canvas';
// import jsPdf from 'jspdf';
// import htmlPdf from 'html-pdf';
import '../css/totalrewards.css';
// import { taxonomia } from './tax';
// import Print from './Report/Print';
import getTax from './getTax';

export default class EmployeeInfo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			nombre: this.props.generalInfo.nombre,
			materno: this.props.generalInfo.apellidoMaterno,
			paterno: this.props.generalInfo.apellidoPaterno,
			generalInfo: this.props.generalInfo,
			noEmpleado: this.props.generalInfo.noEmpleado,
			compania: this.props.generalInfo.compania,
			nivel: this.props.generalInfo.nivel,
			ubicacion: this.props.generalInfo.ubicacion,
			fechaContra: this.props.generalInfo.fechaContra,
			antiguedadEmpleado: this.props.generalInfo.antiguedadEmpleado,
			direccion: this.props.generalInfo.direccion,
			salario: this.props.privateData.salario,
			taxonomia: [{ code: 0, email: '', es: '', en: '' }],
			salarioAnual: this.props.privateData.salarioAnual,
			sumaMensual: this.props.privateData.sumaMensual,
			sumaAnual: this.props.privateData.sumaAnual,
			puesto: this.props.generalInfo.posicion,
			showPdf: false,
			numPages: null,
			pageNumber: 1
		};
	}

	componentDidMount() {
		localStorage.getItem('nivel');
		const taxSelected = getTax(
			localStorage.getItem('nivel'),
			localStorage.getItem('posicion')
		);
		this.setState({
			taxonomia: taxSelected
		});

		/*
    const taxSelected = taxonomia.filter(
      item => item.code === localStorage.getItem("nivel")
    );
    if (taxSelected.length > 1) {
      const taxEspecial = taxSelected.filter(
        item => item.email === localStorage.getItem("email")
      );
      this.setState({
        taxonomia: taxEspecial
      });
    } else {
      const taxEspecial = taxSelected;
      this.setState({
        taxonomia: taxEspecial
      });
    }
  */
	}

	// exportPDF = () => {
	// 	this.resume.save();
	// };
	// pdfPrint = () => {
	// 	const input = document.getElementById('container-print');
	// 	html2canvas(input).then(canvas => {
	// 		const imgData = canvas.toDataURL('image/png');

	// 		// Document of a4WidthMm wide and inputHeightMm high

	// 		// elongated a4 (system print dialog will handle page breaks)
	// 		const pdf = new jsPdf('p', 'mm', 768, 6000);

	// 		pdf.addImage(imgData, 'PNG', 0, 0);
	// 		pdf.save(`test.pdf`);
	// 	});
	// };
	// printPdf = () => {
	// 	const domElement = document.getElementById('container-print');

	// 	const pdf = new jsPdf('p', 'pt', 'a4');
	// 	//const pdf = new jsPdf()
	// 	const specialElementHandlers = {
	// 		'#bypassme': function(element, renderer) {
	// 			return true;
	// 		}
	// 	};
	// 	const margins = {
	// 		top: 20,
	// 		bottom: 20,
	// 		left: 20
	// 	};

	// 	pdf.fromHTML(
	// 		domElement,
	// 		margins.left, // x coord
	// 		margins.top,
	// 		{
	// 			// y coord
	// 			width: 612,
	// 			elementHandlers: specialElementHandlers
	// 		},

	// 		function(dispose) {
	// 			pdf.save('Prueba.pdf');
	// 		},
	// 		margins
	// 	);
	// };
	// loadPdf() {
	// 	var contenido = `
	// <h1>Esto es un test de html-pdf</h1>
	// <p>Estoy generando PDF a partir de este código HTML sencillo</p>
	// `;

	// 	htmlPdf.create(contenido).toFile('./salida.pdf', function(err, res) {
	// 		if (err) {
	// 			console.log(err);
	// 		} else {
	// 			console.log(res);
	// 		}
	// 	});
	// }
	render() {
		console.log(this.state);
		return (
			<div className="container-info-employee">
				<div className="PositionFixed">
					<h1 className="rewards_title">Personal Information</h1>
					<div className="personalInformation">
						<div className="rowPersonal">
							<p className="subjectPersonal">Employee number</p>
							<p className="resultPersonal">
								{this.state.noEmpleado}
							</p>
						</div>
						<div className="rowPersonal">
							<p className="subjectPersonal">Company</p>
							<p className="resultPersonal">
								{this.state.compania}
							</p>
						</div>
						<div className="rowPersonal">
							<p className="subjectPersonal">Level</p>
							<p className="resultPersonal">
								L{this.state.nivel}
							</p>
						</div>
						<div className="rowPersonal">
							<p className="subjectPersonal">Taxonomy</p>
							<p className="resultPersonal taxonomia">
								{this.state.taxonomia[0].en}
								<br />
								<span>{this.state.taxonomia[0].es}</span>
							</p>
						</div>
						<div className="rowPersonal">
							<p className="subjectPersonal">Location</p>
							<p className="resultPersonal locationtext">
								{this.state.ubicacion}
							</p>
						</div>
						<div className="rowPersonal">
							<p className="subjectPersonal">Hiring Date</p>
							<p className="resultPersonal">
								{this.state.fechaContra}
							</p>
						</div>
						<div className="rowPersonal">
							<p className="subjectPersonal">Tenure</p>
							<p className="resultPersonal">
								{this.state.antiguedadEmpleado}
							</p>
						</div>
						<div className="rowPersonal">
							<p className="subjectPersonal">Base Salary</p>
							<p className="resultPersonal">
								{/* $ {this.state.salario}{' '} */}
								<CurrencyFormat
									decimalSeparator="."
									decimalScale="2"
									fixedDecimalScale={true}
									value={this.state.salario}
									displayType="text"
									thousandSeparator=","
									prefix="$"
								/>
							</p>
						</div>
					</div>
					<div className="personalInformation margintopTerms">
						<p>
							The data and concepts presented are merely
							informative and intended for employee use only, and
							are subject to GAM policies and current applicable
							law.{' '}
						</p>
					</div>
				</div>
			</div>
		);
	}
}
