import React from 'react';
import i18next from 'i18next';

const LearningTable = ({ subjects }) => {
	const months = [
		{ title: i18next.t('MyLearning.months.one'), index: 'one' },
		{ title: i18next.t('MyLearning.months.two'), index: 'two' },
		{ title: i18next.t('MyLearning.months.three'), index: 'three' },
		{ title: i18next.t('MyLearning.months.four'), index: 'four' }
	];
	return (
		<>
			<table className="desktop-learning-table hidden w-full table-auto overflow-hidden rounded-md lg:block">
				<thead>
					<tr>
						<th></th>
						{months.map((month, index) =>
							index < months.length - 1 ? (
								<th className="rounded-md border-l-4 border-r-4 border-white bg-gray-300 text-center text-amBlack">
									{month.title}
								</th>
							) : (
								<th className="rounded-md border-l-4 border-white bg-gray-300 text-center text-amBlack">
									{month.title}
								</th>
							)
						)}
					</tr>
				</thead>
				<tbody>
					{subjects.map((subject) => {
						return (
							<tr key={subject.title}>
								{subject.title ? (
									<td className="my-learning-subject rounded-md border-t-4 border-b-4 border-white bg-amDarkBlue font-bold text-white">
										{subject.title}
									</td>
								) : (
									<td></td>
								)}

								{subject.one.url !== null ? (
									<td className="my-learning-td relative rounded-md border-l-4 border-t-4 border-r-4 border-b-4 border-white bg-gray-100">
										<a
											href={subject.one.url}
											target="_blank"
											rel="noopener noreferrer"
											className="absolute left-0 top-0 flex h-full w-full flex-col items-start justify-center p-3 hover:bg-yellow-100"
										>
											{subject.one.text}
											<br />
											<strong>
												{subject.one.duration}
											</strong>
										</a>
									</td>
								) : subject.one.is_duration ? (
									<td className="border-l-4 border-t-4 border-r-4 border-b-4 border-white bg-gray-100 text-center font-bold">
										{subject.one.text}
									</td>
								) : (
									<td className="border-l-4 border-t-4 border-r-4 border-b-4 border-white bg-gray-100"></td>
								)}
								{subject.two.url !== null ? (
									<td className="my-learning-td relative rounded-md border-l-4 border-t-4 border-r-4 border-b-4 border-white bg-gray-100">
										<a
											href={subject.two.url}
											target="_blank"
											rel="noopener noreferrer"
											className="absolute left-0 top-0 flex h-full w-full flex-col items-start justify-center p-3 hover:bg-yellow-100"
										>
											{subject.two.text}
											<br />
											<strong>
												{subject.two.duration}
											</strong>
										</a>
									</td>
								) : subject.two.is_duration ? (
									<td className="border-l-4 border-t-4 border-r-4 border-b-4 border-white bg-gray-100 text-center font-bold">
										{subject.two.text}
									</td>
								) : (
									<td className="border-l-4 border-t-4 border-r-4 border-b-4 border-white bg-gray-100"></td>
								)}
								{subject.three.url !== null ? (
									<td className="my-learning-td relative rounded-md border-l-4 border-t-4 border-r-4 border-b-4 border-white bg-gray-100">
										<a
											href={subject.three.url}
											target="_blank"
											rel="noopener noreferrer"
											className="absolute left-0 top-0 flex h-full w-full flex-col items-start justify-center p-3 hover:bg-yellow-100"
										>
											{subject.three.text}
											<br />
											<strong>
												{subject.three.duration}
											</strong>
										</a>
									</td>
								) : subject.three.is_duration ? (
									<td className="border-l-4 border-t-4 border-r-4 border-b-4 border-white bg-gray-100 text-center font-bold">
										{subject.three.text}
									</td>
								) : (
									<td className="border-l-4 border-t-4 border-r-4 border-b-4 border-white bg-gray-100"></td>
								)}
								{subject.four.url !== null ? (
									<td className="my-learning-td relative rounded-md border-l-4 border-t-4 border-r-4 border-b-4 border-white bg-gray-100">
										<a
											href={subject.four.url}
											target="_blank"
											rel="noopener noreferrer"
											className="absolute left-0 top-0 flex h-full w-full flex-col items-start justify-center p-3 hover:bg-yellow-100"
										>
											{subject.four.text}
											<br />
											<strong>
												{subject.four.duration}
											</strong>
										</a>
									</td>
								) : subject.four.is_duration ? (
									<td className="border-l-4 border-t-4 border-r-4 border-b-4 border-white bg-gray-100 text-center font-bold">
										{subject.four.text}
									</td>
								) : (
									<td className="border-l-4 border-t-4 border-r-4 border-b-4 border-white bg-gray-100"></td>
								)}
							</tr>
						);
					})}
				</tbody>
			</table>
			<div className="block w-full lg:hidden">
				{months.map((month) => (
					<>
						<div className="rounded-t-md border-b-4 border-white bg-amBlue py-2 text-center font-bold text-white">
							{month.title}
						</div>
						{subjects.map((subject) => (
							<>
								{subject.title !== null ? (
									<>
										{subject[month.index].url !== null && (
											<div className="my-learning-td relative border-b-4 border-white bg-gray-100">
												<a
													href={
														subject[month.index].url
													}
													className="flex items-center justify-center py-2 px-4 hover:bg-yellow-100"
												>
													<span className="w-full">
														{
															subject[month.index]
																.text
														}
													</span>
													<span className="flex w-4/12 flex-col items-end justify-center text-right">
														<span className="inline-flex items-center whitespace-nowrap text-xxs font-medium text-amBlue">
															{subject.title}
														</span>
														<strong>
															{
																subject[
																	month.index
																].duration
															}
														</strong>
													</span>
												</a>
											</div>
										)}
									</>
								) : (
									<div className="mb-3 flex items-center justify-end rounded-b-md bg-gray-300 px-4 py-2 text-center">
										<span className="mr-2">Total:</span>
										<span className="font-bold">
											{subject[month.index].text}
										</span>
									</div>
								)}
							</>
						))}
					</>
				))}
			</div>
		</>
	);
};

export default LearningTable;
