import React, { Component } from "react";
import * as contentful from "../../../lib/contentful";
import { Link } from "react-router-dom";
import SliderWork from "./swiperwook"

import "./css/worktools.css";
import arrowBlue from "../../../assets/images/arrow-blue.svg"
import getLocale from "../../../utils/getLocale";
import i18next from "i18next";


export default class WorkTools extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      error: null,
      entries: null
    };
  }
  componentDidMount() {
    this.fetchEntries();
  }
  fetchEntries = () => {
    contentful.client
      .getEntries({
        content_type: "workTools",
        include: 2,
        locale: getLocale(),
      })
      .then(response => response.items)
      .then(data => {
        
        this.setState({
          loading: false,
          entries: data
        });
      })
      .catch(error => {
        this.setState({
          loading: false,
          error: error
        });
      })
      .catch(err => console.error(err));
  };
  render() {
    const { loading, entries, error } = this.state;
    return (
      <div className="LatestWrap">
        <div className="LatestGrid">
          <div className="LatestTitle">{i18next.t('Inicio.herramientas.title')}</div>
          {/*<Link to="/">Ver todas</Link>*/}
          <p className="slideMore">{i18next.t('Inicio.herramientas.desliza')} <img className="slideMoreArrow" src={arrowBlue}></img></p>
          
        </div>

        <div className="Worktools-container">
          {/*<div className="WorkToolsWrap">
          
          {/*
            {!loading &&
              entries &&
              entries.slice(0, 6).map(worktool => (

                <div key={worktool.sys.id} className="WorkToolWrap">
                  <a href={worktool.fields.linkTool} target="_blank">
                    <img
                      className="img-woork-tool"
                      src={worktool.fields.imageTool.fields.file.url}
                      alt="work tool image"
                    />
                    <div className="descriptionWorkTool">
                      <div className="padding">
                        
                        <p>{worktool.fields.workDescription}</p>
                      </div>
                    </div>
                  </a>
                </div>
                
              ))}
              

          

          
          </div>*/}

          <div className="workToolsSliderWrapper">
            <SliderWork/>
          </div>

        </div>
      </div>
    );
  }
}
