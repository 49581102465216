import React, { useCallback, useEffect, useState } from 'react';
import * as contentful from '../../../lib/contentful';
import { Swiper, SwiperSlide } from 'swiper/react';
// import arrow from "../../assets/images/arrow-blue.svg";

// Import Swiper styles
import '../../../../node_modules/swiper/';
import getLocale from '../../../utils/getLocale';


import FeaturedSlideInside from './FeaturedSlideInside';
import Modal from 'react-awesome-modal';
import './modal.css';
import CloseIMG from '../../../assets/logo/close.svg';
import i18next from 'i18next';
import Arrow from '../../../assets/images/arrow-blue.svg';

const noScroll = require('no-scroll');

const FeaturedSwiper = () => {
	const [show, setShow] = useState(false);
	const [theIndex, setTheIndex] = useState(false);
	const [benefits, setBenefits] = useState([]);
	const [loading, setLoading] = useState(true);

	const fetchSlides = useCallback(() => {
		contentful.client
			.getEntries({
				content_type: 'myDiscounts',
				include: 2,
				order: '-sys.updatedAt',
				locale: getLocale(),
			})
			.then((response) => response.items)
			.then((data) => {
				/* console.log('FEATURED BENEFITS: ', data); */
				setBenefits(data);
				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);
				console.error(error);
			});
	}, []);

	useEffect(() => {
		if (benefits.length > 0) {return;}else{ fetchSlides();}
		
	}, []);

	return (
		<>
		<Swiper slidesPerView={3} spaceBetween={30}>
			{loading ? (
				<>
					{Array.from({ length: 3 }, (_, i) => (
						<SwiperSlide className="footerProfile__newsCard">
						
						</SwiperSlide>
					))} 
				</>
			) : (
				<>
					{/* {alert("Benefits: " + benefits.length)} */}

					{benefits.map((item, index) => (
						<SwiperSlide
							key={`${item.sys.id}`}
							className={`footerProfile__newsCard relative flex flex-col items-start justify-start p-4 text-white`}
							onClick={() => {
								setShow(true);
								setTheIndex(index)
							}}
						>
							<FeaturedSlideInside item={item} />
						</SwiperSlide>
					))}
				</>
			)}
		</Swiper>
		{show ? (
			<Modal
				visible={show}
				width="auto"
				height="auto"
				effect="fadeInDown"
				onClickAway={() => {
					noScroll.off();
					setShow(false);
				}}
				className="container-modal-notification modal-minicard-benefits"
			>
				<div>
					<div className="modal-notification">
						<div
							class="closeModal"
							onClick={() => setShow(false)}
						>
							<img src={CloseIMG} alt="close icon" />
						</div>
						<div
							className="background-benefits"
							style={{
								backgroundImage: `url(${benefits[theIndex].fields.myDiscountsImageCard.fields.file.url})`
							}}
						>
							<span
								className={benefits[theIndex].fields.myDiscountsImageTypeClass + 'px-3 py-1'}
								style={{
									background: 'rgba(0,0,0,0.5)',
									borderRadius: '20px'
								}}
							>
								{benefits[theIndex].fields.myDiscountsTitle}
							</span>
						</div>
						<div className="content-modal">
							<div className="head-modal">
								<div>
									<p>{benefits[theIndex].fields.myDiscountsTitle}</p>
								</div>
							</div>

							<div className="BenefitContent">
								<div className='text-sm pb-2' dangerouslySetInnerHTML={{ __html: benefits[theIndex].fields.myDiscountsIntro}} />
								<div
									dangerouslySetInnerHTML={{
										__html: benefits[theIndex].fields.myDiscountsExtraInformation
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		) : null}
		</>
	);
};

export default FeaturedSwiper;
