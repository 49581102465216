import i18next from "i18next";
import { Component } from "react";
import AuthService from "../../services/auth.service";
import GraphService from "../../services/graph.service";
import close from "../../assets/logo/close.svg";
import ModalLang from "react-awesome-modal";
import "./css/styles.css";
import logoSVG from "../../assets/images/logo/logo-aeromexico-white.svg";
import logoPNGMobile from "../../assets/icon-mobile.jpg";
import avionbg from "../../assets/images/login/avion-bg.png";
import { ReactComponent as GateOne } from "./assets/gateone.svg";
import NewWindow from "react-new-window";
import { Trans } from "react-i18next";
import LoginOktaReact from "../../auth/LoginOktaReact";
import { isLocal, isFromDev, isFromQA } from './../../core/Ambientes';

class IndexUpdate extends Component {
    constructor() {
		super();
		this.authService = new AuthService();
		this.graphService = new GraphService();
		this.state = {
			user: null,
			userInfo: null,
			apiCallFailed: false,
			loginFailed: false,
			// prueba: "",
			redireccion: false,
			displayableId: "",
			explorer: false,
			edge: false,
			lang: "",
			langVisible: false,
			langClass: "non",
			langMovilVisible: false,
			gateOnePop: false,
			isLocalHost: window.location.hostname === "localhost"
		};
	}

    openModalLang() {
		this.setState({
			langVisible: true,
			langMovilVisible: false,
			langClass: "non"
		});
	}

	openModalLangMovil() {
		var vis = !this.state.langMovilVisible;
		this.setState({
			langVisible: false,
			langMovilVisible: vis,
			langClass: vis ? "activeLang" : "non"
		});
	}

	closeModalLang() {
		this.setState({
			langVisible: false,
			langMovilVisible: false,
			langClass: "non"
		});
	}

	onLangSelectedEvent = (l) => {
		if (this.state.lang === l) {
			this.setState({
				langVisible: false,
				langMovilVisible: false,
				langClass: "non"
			});
			return;
		}
		i18next.changeLanguage(l);
		this.setState({
			lang: l,
			langVisible: false,
			langMovilVisible: false
		});
		//window.location.reload();
	};

    render() {
		//const { id } = useParams();
		// const isLocal = process.env.REACT_APP_ISLOCAL; // Tomara entorno local
		// const isFromDev = process.env.REACT_APP_ISFROMDEV; //Se pondra en true para ambiente DEV
		// const isFromQA = process.env.REACT_APP_ISFROMQA; //Se pondra en true para ambiente QA

		const urlCapiLocal = `${process.env.REACT_APP_CAPI_LOCALV3}`;
		const urlCapiDEV = `${process.env.REACT_APP_CAPI_DEV}`;
		const urlCapiQA = `${process.env.REACT_APP_CAPI_QA}`;
		const urlCapiProd = `${process.env.REACT_APP_CAPI_PROD}`;

		const urlValidCapi = isLocal===true ? urlCapiLocal : isFromDev===true ? urlCapiDEV : isFromQA===true ? urlCapiQA : urlCapiProd;
 

		const urlValid = `${urlValidCapi}/gate-one?fLogin=TRUE`;
		// const urlDev = `${urlValidCapi}/gate-one?fLogin=TRUE`;
		// const urlValid = urlFiltrered;

		return (
			<div className="LoginPage">
				<div className="TopLoginPage">
					<div className="MidLoginContent">
						<div className="MidLoginContentCell">
							<div className="languageFlightPlan login desktop" onClick={() => this.openModalLang()}>
								<p style={{fontFamily:'Garnett,Helvetica,Arial',fontWeight:'bold'}}>{i18next.t("Lang.lang")}</p>
								<div className={`languageFlag ${this.state.lang}`}></div>
							</div>
							<div className="languageFlightPlan login movil" onClick={() => this.openModalLangMovil()}>
								<p style={{fontFamily:'Garnett,Helvetica,Arial',fontWeight:'bold'}}>{i18next.t("Lang.lang")}</p>
								<div className={`languageFlag ${this.state.lang}`}></div>
								<div className={`container-language ${this.state.langClass}`}>
									<div className="notification-column">
										<a className="notification-item" onClick={() => this.onLangSelectedEvent("es")}>
											<p className="notification-text"> ES </p>
										</a>
										<a className="notification-item" onClick={() => this.onLangSelectedEvent("en")}>
											<p className="notification-text"> EN </p>
										</a>
									</div>
								</div>
							</div>

							<img className="LogoMiAM1" src={logoSVG} alt="Logo Mi Aeromexico" />

							<img className="LogoMiAM1Mobile" src={logoPNGMobile} alt="Logo Mi Aeromexico" />

							<div className="ContentWrap">
								<h1 className="WelcomeSite">
									{" "}
									<div>
										<span  className="text-amLightBlue">{i18next.t("Login.slider.t1")}</span>
										<span  className="text-amLightBlue">{i18next.t("Login.slider.t2")}</span>
										<span  className="text-amLightBlue">{i18next.t("Login.slider.t3")}</span>
										<span  className="text-amLightBlue">{i18next.t("Login.slider.t4")}</span>
									</div>
									<br />
									<span style={{fontFamily:'Garnett'}}>{i18next.t("Login.slider.t5")}</span>
								</h1>

								<h1 style={{fontFamily:'Garnett'}} className="WelcomeSiteMobile">
									{i18next.t("Login.welcome.p1")} <br />
									{i18next.t("Login.welcome.p2")}
								</h1>

								<div className="wrapper-disclaimer hidden">
									{this.state.explorer && (
										<p style={{fontFamily:'Garnett'}} className="disclaimer">{i18next.t("Login.noExplorer")}</p>
									)}
									{this.state.edge && <p className="disclaimer">{/* No uses Edge */}</p>}
								</div>
								<p 
									className="WelcomeIntro my-5 lg:my-10"
									dangerouslySetInnerHTML={{
										__html: i18next.t("Login.login")
									}}></p>
								<div className="buttons-holder" style={{ display: "flex" }}>
									<button
										type="button"
										className="fake-btn gate-btn border-1 flex items-center justify-center space-x-3 outline-none ring-0 focus:outline-none focus:ring-0"
										onClick={() => this.setState({ ...this.state, gateOnePop: true })}>
										<GateOne className="h-7 w-auto fill-current text-white" />
										<p className="my-0">{i18next.language === "es" ? "Iniciar sesión" : "Login"}</p>
									</button>
									{/* {this.state.isLocalHost ? (
										<button className="WelcomeButton mt-3" onClick={this.login}>
											<span />
											{i18next.t("Login.loginBtn")}
										</button>
									) : null} */}
								</div>
								{this.state.loginFailed && <p className="failed-user">{i18next.t("Login.failed")}</p>}

								{/* <form onSubmit={this.welcome}>
                  <input
                    placeholder="ingresa tu email"
                    onChange={this.handleChanges}
                    name="prueba"
                  />
                  <button>Ingresa</button>
                </form> */}
							</div>
						</div>
					</div>
				</div>
				<div className="FilterLoginPage bg-amBlack" />
				<div className="BgLoginPage">
					<video className="VideoLogin" autoPlay muted loop playsInline>
						{/* <source src={avionWebm} type="video/webm" /> */}
						<source src={`${process.env.REACT_APP_SRC_URL}miam-videos/avion.mp4`} type="video/mp4" />
					</video>
					<img className="ImgLoginPageLogin" src={avionbg} alt="Bg Login page" />
				</div>

				{this.state.gateOnePop ? (
					<NewWindow
						// url={urlValid}
						// url="https://capi.miaeromexico.com/gate-one?fLogin=TRUE"
						container={<LoginOktaReact/>}
						name="Iniciar Sesión con Gate One"
						title="Iniciar Sesión con Gate One"
						features={{
							height: 520,
							width: 400
						}}
						center="parent"
						closeOnUnmount
						onUnload={() => {
							if (localStorage.getItem("oktaToken")) {
								//this.props.history.push("/inicio");
								window.location.href = "/inicio";
							}
						}}
					/>
				) : null}

				{/* MODAL IDIOMA */}
				<ModalLang
					visible={this.state.langVisible}
					width="auto"
					height="auto"
					effect="fadeInDown"
					onClickAway={() => this.closeModalLang()}
					className="container-modal-notification">
					<div className="selectLangugeModal menuModal">
						<div className="whiteModal">
							<div className="closeModal" onClick={() => this.closeModalLang()}>
								<img src={close} alt="Cerrar"></img>
							</div>
							<div className="padding">
								<h4>
									<Trans i18nKey="Lang.selecciona"></Trans>
								</h4>
								<div className="gridFlags">
									<a className="contFlag" onClick={() => this.onLangSelectedEvent("es")}>
										<div className="flags spanish"></div>
										<p>{i18next.t("Lang.es")}</p>
									</a>
									<a className="contFlag" onClick={() => this.onLangSelectedEvent("en")}>
										<div className="flags english"></div>
										<p>{i18next.t("Lang.en")}</p>
									</a>
								</div>
							</div>
						</div>
					</div>
				</ModalLang>
				{/* FIN MODAL IDIOMA */}
			</div>
		);
	}

}
export default IndexUpdate;