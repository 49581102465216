import React, { Component } from "react";
import { Link } from "react-router-dom";
import i18next from "i18next";
export default class ItemCArd extends Component {
	getEntryTag(article) {
		if (i18next.language === "es") {
			return article.fields.articleTagES;
		} else {
			return article.fields.articleTagEN;
		}
	}

	render() {
		const { article } = this.props;
		return (
			<Link to={`/articles/${article.sys.id}`} id={article.fields.articleClass} className="wrapperNewsLogros">
				<div className="ArticleImageWrap relative">
					{/* <p className={article.fields.articleClass}> */}
					<p className="absolute left-2 top-2 z-10 rounded-md bg-[#035cf7] px-2 py-1 text-xs font-semibold text-white">
					{this.getEntryTag(article)}
					</p>
					<img
						className="ArticleImage"
						src={article.fields.articlePicture.fields.file.url}
						alt={article.fields.articleTittle}
					/>
				</div>
				<div className="ArticleTitle text-[#020c41]">
					<p>{article.fields.articleTittle}</p>
				</div>
				<div className="ArticleIntro">
					<p>{article.fields.articleIntro}</p>
				</div>
			</Link>
		);
	}
}
