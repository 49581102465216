/**
 * @param {string} email :Email del usuario
 * @param {string} ubicacion :Valor de 'ubicacion' recibido del API
 * @param {string} compania :Valor de 'compania' recibido del API
 *
 * @returns {string} 'CAN' para usuarios de Canada, 'USA' para usuarios de Estados Unidos, 'DEF' para otras ubicaciones
 */

import locations from './locations/locations';
import usa_emails from './foreigns/usa_emails';
import can_emails from './foreigns/can_emails';
export default function userLocation(email, ubicacion, compania) {
	var res = 'DEF';

	// HACK para usuarios de prueba
	if (email === 'amusercan@aeromexico.com') {
		return 'CAN';
	}
	if (email === 'amuserusa@aeromexico.com') {
		return 'USA';
	}
	// FIN HACK para usuarios de prueba

	var usa_users = usa_emails.users;
	let result_us = usa_users.find(l => l.email === email);
	if (result_us !== undefined) {
		return 'USA';
	} else {
		var can_users = can_emails.users;
		let result_can = can_users.find(l => l.email === email);
		if (result_can !== undefined) {
			return 'CAN';
		}
	}

	if (compania !== null && ubicacion !== null) {
		// indicador de usuarios internacionales
		if (compania.startsWith('99')) {
			var data = locations.locations;
			let loc = data.find(l => l.loc === ubicacion);
			if (loc !== undefined) {
				res = loc.cc;
			}
		}
	}
	return res;
}
