import React, { Component } from 'react';
import './css/identidad.css';
import pdf from '../../assets/identidad/pdf.svg';
import styled from 'styled-components';
import i18next from 'i18next';
import WrapperButtonBig from './componentes/WrapperButtonBig';
import { animateScroll as scroll} from "react-scroll";

const ReporteSusten = styled.div`
	.wrapperIdenditad {
		grid-template-columns: repeat(5, 1fr);
	}
`;

export default class SliderSwiper extends Component {
	componentDidMount(){
		scroll.scrollToTop();
	}
	render() {
		return (
			<div style={{ background: '#EDF6FB' }}>
				{/* <Header/> */}
				<div class="nosotrosWrapper">
					<div class="nosotrosHeader">
						<h4>{i18next.t('Reportes.Anual.titulo')}</h4>
						<p>{i18next.t('Reportes.Trimestral.subtitulo')}</p>
						<p
							className="mt-2 underline"
							style={{
								fontSize: '15px',
								textDecorationColor: 'red',
								textUnderlineOffset: '3px'
							}}
						>
							Si requieres más información, ingresa a:{' '}
							<a
								style={{
									textDecorationColor: 'red'
								}}
								href="https://aeromexico.com/es-mx/inversionistas/reportes-financieros"
							>
								reportes financieros
							</a>
						</p>
					</div>
				</div>

				<ReporteSusten>
					<div className="wrapperDownload">
						<div className="wrapperIdenditad">
							<WrapperButtonBig
								pdf={pdf}
								year={
									i18next.language === 'es'
										? 'Año 2021'
										: 'Year 2021'
								}
								url="https://aeromexico.com/cms/sites/default/files/Reporte_Anual_2021_VF_compressed.pdf"
								download={i18next.t(
									'Sostenib.informes.descargar'
								)}
							/>
							<WrapperButtonBig
								pdf={pdf}
								year={
									i18next.language === 'es'
										? 'Año 2020'
										: 'Year 2020'
								}
								url="https://aeromexico.com/cms/sites/default/files/20210430_-_Annual_Report_2020_-_bundle_completo_VF_compressed.pdf"
								download={i18next.t(
									'Sostenib.informes.descargar'
								)}
							/>
							<WrapperButtonBig
								pdf={pdf}
								year={
									i18next.language === 'es'
										? 'Año 2019'
										: 'Year 2019'
								}
								url="https://vuela.aeromexico.com/inversionistas/20200429-AnnualReport2019-SPA-Final.pdf?_ga=2.95903834.778667251.1658425407-1657288109.1658329138&_gac=1.37407380.1658330157.EAIaIQobChMIhozU4t6H-QIVySCtBh3vGATpEAAYAyAAEgJjEfD_BwE"
								download={i18next.t(
									'Sostenib.informes.descargar'
								)}
							/>
							<div className="wrapperbuttonBig">
								<div className="wrapperTop">
									<div className="wrapperTopCell">
										<img
											className="noTextImg"
											src={pdf}
										></img>
										<p style={{color:'#035cf7',fontWeight:'bold'}}>
											{i18next.t('Reportes.Anual.repor8')}
										</p>
									</div>
								</div>
								<a
									className="wrapperBottom"
									href="https://www.aeromexico.com/cms/sites/default/files/Reporte_anual-2018-v2.pdf"
									target="_blank"
									rel="noopener noreferrer"
								>
									<div className="downloadIdenti">
										<span></span>
										<p style={{color:'#035cf7',fontWeight:'bold'}}>
											{i18next.t(
												'Sostenib.informes.descargar'
											)}
										</p>
									</div>
								</a>
							</div>

							<div className="wrapperbuttonBig">
								<div className="wrapperTop">
									<div className="wrapperTopCell">
										<img
											className="noTextImg"
											src={pdf}
										></img>
										<p style={{color:'#035cf7',fontWeight:'bold'}}>
											{i18next.t('Reportes.Anual.repor7')}
										</p>
									</div>
								</div>
								<a
									className="wrapperBottom"
									href="https://www.aeromexico.com/cms/sites/default/files/20180430_GAM_-_ANUAL_2017_-_Final-.pdf"
									target="_blank"
									rel="noopener noreferrer"
								>
									<div className="downloadIdenti">
										<span></span>
										<p style={{color:'#035cf7',fontWeight:'bold'}}>
											{i18next.t(
												'Sostenib.informes.descargar'
											)}
										</p>
									</div>
								</a>
							</div>

							<div className="wrapperbuttonBig">
								<div className="wrapperTop">
									<div className="wrapperTopCell">
										<img
											className="noTextImg"
											src={pdf}
											alt="noTextImg"
										/>
										<p style={{color:'#035cf7',fontWeight:'bold'}}>
											{i18next.t('Reportes.Anual.repor6')}
										</p>
									</div>
								</div>
								<a
									className="wrapperBottom"
									href="https://www.aeromexico.com/cms/sites/default/files/reporte-anual-2016-act.pdf"
									target="_blank"
									rel="noopener noreferrer"
								>
									<div className="downloadIdenti">
										<span></span>
										<p style={{color:'#035cf7',fontWeight:'bold'}}>
											{i18next.t(
												'Sostenib.informes.descargar'
											)}
										</p>
									</div>
								</a>
							</div>

							<div className="wrapperbuttonBig">
								<div className="wrapperTop">
									<div className="wrapperTopCell">
										<img
											className="noTextImg"
											src={pdf}
										></img>
										<p style={{color:'#035cf7',fontWeight:'bold'}}>
											{i18next.t('Reportes.Anual.repor5')}
										</p>
									</div>
								</div>
								<a
									className="wrapperBottom"
									href="https://www.aeromexico.com/cms/sites/default/files/Reporte%20Anual%20AM%202015-%20Website.pdf"
									target="_blank"
									rel="noopener noreferrer"
								>
									<div className="downloadIdenti">
										<span></span>
										<p style={{color:'#035cf7',fontWeight:'bold'}}>
											{i18next.t(
												'Sostenib.informes.descargar'
											)}
										</p>
									</div>
								</a>
							</div>

							<div className="wrapperbuttonBig">
								<div className="wrapperTop">
									<div className="wrapperTopCell">
										<img
											className="noTextImg"
											src={pdf}
										></img>
										<p style={{color:'#035cf7',fontWeight:'bold'}}>
											{i18next.t('Reportes.Anual.repor4')}
										</p>
									</div>
								</div>
								<a
									className="wrapperBottom"
									href="https://www.aeromexico.com/cms/sites/default/files/Reporte_Anual_2014_0.pdf"
									target="_blank"
									rel="noopener noreferrer"
								>
									<div className="downloadIdenti">
										<span></span>
										<p style={{color:'#035cf7',fontWeight:'bold'}}>
											{i18next.t(
												'Sostenib.informes.descargar'
											)}
										</p>
									</div>
								</a>
							</div>

							<div className="wrapperbuttonBig">
								<div className="wrapperTop">
									<div className="wrapperTopCell">
										<img
											className="noTextImg"
											src={pdf}
										></img>
										<p style={{color:'#035cf7',fontWeight:'bold'}}>
											{i18next.t('Reportes.Anual.repor3')}
										</p>
									</div>
								</div>
								<a
									className="wrapperBottom"
									href="https://www.aeromexico.com/cms/sites/default/files/reporte%20anual%202013.pdf"
									target="_blank"
									rel="noopener noreferrer"
								>
									<div className="downloadIdenti">
										<span></span>
										<p style={{color:'#035cf7',fontWeight:'bold'}}>
											{i18next.t(
												'Sostenib.informes.descargar'
											)}
										</p>
									</div>
								</a>
							</div>

							<div className="wrapperbuttonBig">
								<div className="wrapperTop">
									<div className="wrapperTopCell">
										<img
											className="noTextImg"
											src={pdf}
										></img>
										<p style={{color:'#035cf7',fontWeight:'bold'}}>
											{i18next.t('Reportes.Anual.repor2')}
										</p>
									</div>
								</div>
								<a
									className="wrapperBottom"
									href="https://www.aeromexico.com/cms/sites/default/files/reporte_anual_2012.pdf"
									target="_blank"
									rel="noopener noreferrer"
								>
									<div className="downloadIdenti">
										<span></span>
										<p style={{color:'#035cf7',fontWeight:'bold'}}>
											{i18next.t(
												'Sostenib.informes.descargar'
											)}
										</p>
									</div>
								</a>
							</div>

							<div className="wrapperbuttonBig">
								<div className="wrapperTop">
									<div className="wrapperTopCell">
										<img
											className="noTextImg"
											src={pdf}
										></img>
										<p style={{color:'#035cf7',fontWeight:'bold'}}>
											{i18next.t('Reportes.Anual.repor1')}
										</p>
									</div>
								</div>
								<a
									className="wrapperBottom"
									href="https://www.aeromexico.com/cms/sites/default/files/reporte%20anual%202011.pdf"
									target="_blank"
									rel="noopener noreferrer"
								>
									<div className="downloadIdenti">
										<span></span>
										<p style={{color:'#035cf7',fontWeight:'bold'}}>
											{i18next.t(
												'Sostenib.informes.descargar'
											)}
										</p>
									</div>
								</a>
							</div>
						</div>
					</div>
				</ReporteSusten>
				{/* <Index/> */}
			</div>
		);
	}
}
