import React from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';

function FaqsInternacional({ faq, index, language }) {
	return (
		<Disclosure as="div" key={`${faq.question}-${index}`} className="pt-6">
			{({ open }) => (
				<>
					<dt className="text-md">
						<Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400 outline-none focus:outline-none ring-0 focus:ring-0">
							<span className="font-medium text-gray-900">
								{language !== 'es'
									? faq.localizations[0].question
									: faq.question}
							</span>
							<span className="ml-6 h-7 flex items-center">
								<ChevronDownIcon
									className={`${
										open ? '-rotate-180' : 'rotate-0'
									} h-6 w-6 transform`}
									aria-hidden="true"
								/>
							</span>
						</Disclosure.Button>
					</dt>
					<Disclosure.Panel as="dd" className="mt-2 pr-12">
						<div
							className="prose text-gray-500 max-w-full"
							dangerouslySetInnerHTML={{
								__html:
									language !== 'es'
										? faq.localizations[0].answer
										: faq.answer
							}}
						></div>
					</Disclosure.Panel>
				</>
			)}
		</Disclosure>
	);
}

export default FaqsInternacional;
