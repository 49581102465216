import React, { Component } from 'react';
import Swiper from 'react-id-swiper';
import * as contentful from '../../../lib/contentful';

import '../../Slider/css/swiper.css';
import getLocale from '../../../utils/getLocale';

export default class SliderWork extends Component {
	constructor() {
		super();
		this.state = {
			loading: true,
			error: null,
			entries: null,
			level: parseInt(localStorage.getItem('nivel'))
		};
	}
	componentDidMount() {
		this.fetchEntries();
	}

	fetchEntries = () => {
		contentful.client
			.getEntries({
				content_type: 'workTools',
				include: 2,
				// order: 'sys.updatedAt',
				order: 'fields.prioridadWork',
				locale: getLocale()
			})
			.then(response => response.items)
			.then(data => {
				 
				this.setState({
					loading: false,
					entries: data
				});
			})
			.catch(error => {
				this.setState({
					loading: false,
					error: error
				});
			})
			.catch(err => console.error(err));
	};

	render() {
		const { loading, entries } = this.state;

		const params = {
			slidesPerView: 'auto',
			spaceBetween: 25,

			// autoplay: {
			//   delay: 50000,
			//   disableOnInteraction: true
			// },

			pagination: {
				el: '.swiper-pagination',
				type: 'bullets',
				clickable: true,
				dynamicBullets: true
			},
			mousewheel: true,
			freeMode: true,

			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev'
			}
		};

		return (
			<React.Fragment>
				{!loading &&
					entries &&
					entries.map(worktool => (
						<div key={worktool.sys.id} className="WorkToolWrap">
							<a
								href={worktool.fields.linkTool}
								target="_blank"
								rel="noopener noreferrer"
							>
								<img
									className="img-woork-tool"
									src={
										worktool.fields.imageTool.fields.file
											.url
									}
									alt="work tool"
								/>
								<div className="descriptionWorkTool">
									<div className="padding">
										{/*<h4>Name program</h4>*/}
										<p>{worktool.fields.workDescription}</p>
									</div>
								</div>
							</a>
						</div>
					))}
			</React.Fragment>
		);

		// return (
		//   <React.Fragment>
		//     {!loading && entries && (
		//       <Swiper {...params} className="swiper-home-slider">
		//         {!loading &&
		//           entries &&
		//           entries.map(worktool => (

		//             /*entries.slice(0, 6).map(worktool => (*/

		//             <div key={worktool.sys.id} className="WorkToolWrap">
		//               <a href={worktool.fields.linkTool} target="_blank">
		//                 <img
		//                   className="img-woork-tool"
		//                   src={worktool.fields.imageTool.fields.file.url}
		//                   alt="work tool image"
		//                 />
		//                 <div className="descriptionWorkTool">
		//                   <div className="padding">
		//                     {/*<h4>Name program</h4>*/}
		//                     <p>{worktool.fields.workDescription}</p>
		//                   </div>
		//                 </div>
		//               </a>
		//             </div>

		//           ))}
		//       </Swiper>
		//     )}
		//   </React.Fragment>
		// )
	}
}
