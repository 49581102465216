import { UTILS_COMPANIES_GLOSARY } from "../../../utils/glosarioCompanias";

export const aguinaldoTier = (tooltip) => {
	const company = localStorage.getItem("compania");
	const nivel = parseInt(localStorage.getItem("nivel"));

	switch (company) {
		case "Aeroméxico Cargo":
			return `31 ${tooltip}`;
		case "Aeroméxico Servicios":
			return `31 ${tooltip}`;
		case "Aeroméxico":
			return `30 ${tooltip}`;
		case "Aeroméxico Connect":
			if(nivel === 8 || nivel === 10){
				return `30 ${tooltip}`;
			}
			if(nivel < 8){
				return `22 ${tooltip}`;
			}
			return 0;
		case "Aeroméxico Formación":
			if(nivel === 10){
				return `30 ${tooltip}`;
			}
			if(nivel < 8){
				return `15 ${tooltip}`;
			}
			return 0;
		case "Centro de Servicios Compartidos":
			return `15 ${tooltip}`;
		default:
			return tooltip;
	}
}

// export const primaVacacionalTier = () => {
// 	const company = localStorage.getItem("compania");
// 	const nivel = parseInt(localStorage.getItem("nivel"));

// 	switch (company) {
// 		case "Aeroméxico":
// 			return "MiExperAM.compensacion.garantizada.prima.tier1"
// 		case "Aeroméxico Cargo":
// 			return "MiExperAM.compensacion.garantizada.prima.tier4";
// 		case "Aeroméxico Connect":
// 			if(nivel < 9) return "MiExperAM.compensacion.garantizada.prima.tier3";
// 			if(nivel === 10) return "MiExperAM.compensacion.garantizada.prima.tier2";
// 			return 0;
// 		case "Aeroméxico Servicios":
// 			return "MiExperAM.compensacion.garantizada.prima.tier4";
// 		case "Aeroméxico Formación":
// 			return "MiExperAM.compensacion.garantizada.prima.tier5"
// 		case "Centro de Servicios Compartidos":
// 			return "MiExperAM.compensacion.garantizada.prima.tier3";
// 		default:
// 			break;
// 	}
// }

export const fondoAhorroTier = ()=>{
	const company = localStorage.getItem("compania");

	switch (company) {
		case "Aeroméxico":
			return "MiExperAM.compensacion.garantizada.ahorroTip1";
		case "Aeroméxico Connect":
			return "MiExperAM.compensacion.garantizada.ahorroTip2";
		case "Aeroméxico Servicios":
			return "MiExperAM.compensacion.garantizada.ahorroTip2";
		case "Aeroméxico Cargo":
			return "MiExperAM.compensacion.garantizada.ahorroTip2";
		case "Aeroméxico Formación":
			return "MiExperAM.compensacion.garantizada.ahorroTip2";
		default:
			return "";
	}
};

export const compensacionVariableTier = () => {
	const level = parseInt(localStorage.getItem("nivel"));

	switch(level){
		case 6:
			return "Target de 16.66% de sueldo anual";
		case 7:
			return "Target de 24% de sueldo anual";
		case 8:
			return "Target de 24% de sueldo anual";
		case 9:
			return "Target de 30% de sueldo anual";
		case 10:
			return "Target de 45% de sueldo anual";
		case 11:
			return "Target de 50% de sueldo anual";
		case 12:
			return "Target de 50% de sueldo anual";
		default:
			return "";
	}
}

export const primaVacacionalTier = () => {
	const company = localStorage.getItem("codigo_empresa_AM");
	const selected = UTILS_COMPANIES_GLOSARY.find(item => item.code === company);


	switch (selected.fullName) {
		case "Aeroméxico":
			return amxPrima;
		case "AMC":
			return amcPrima;
		case "PLM":
			return amxPrima;
		case "Cargo":
			return cargoPrima;
		case "Sistem":
			return cscPrima;
		case "AFI":
			return afiPrima;
		case "CSC":
			return cscPrima;
		default:
			break;
		// 	return "MiExperAM.compensacion.garantizada.prima.tier1"
		// case "Aeroméxico Cargo":
		// 	return "MiExperAM.compensacion.garantizada.prima.tier4";
		// case "Aeroméxico Connect":
		// 	if(nivel < 9) return "MiExperAM.compensacion.garantizada.prima.tier3";
		// 	if(nivel === 10) return "MiExperAM.compensacion.garantizada.prima.tier2";
		// 	return 0;
		// case "Aeroméxico Servicios":
		// 	return "MiExperAM.compensacion.garantizada.prima.tier4";
		// case "Aeroméxico Formación":
		// 	return "MiExperAM.compensacion.garantizada.prima.tier5"
		// case "Centro de Servicios Compartidos":
		// 	return "MiExperAM.compensacion.garantizada.prima.tier3";
		// default:
		// 	break;
	}
}

const amxPrima = "De acuerdo a la política vigente.<br/>1 año: 8.4 días <br/>2 años: 9.8 días<br/>3 años: 11.2 días<br/>4 años: 12.6 días<br/>5 años: 17 días<br/>6-10 años: 19.8 días<br/>11-15 años: 21.6 días<br/>16-20 años: 23.4 días<br/>21-25 años: 25.2 días<br/>26-30 años: 27 días<br/>31-35 años: 28.8 días<br/>36-40 años: 30.6 días<br/>";
const amcPrima = "De acuerdo a la política vigente.<br/>1 año: 8.4 días <br/>2 años: 9.8 días<br/>3 años: 11.2 días<br/>4 años: 12.6 días<br/>5 años: 14 días<br/>6-10 años: 15.4 días<br/>11-15 años: 16.8 días<br/>16-20 años: 18.2 días<br/>21-25 años: 19.6 días<br/>26-30 años: 21 días<br/>31-35 años: 22.4 días<br/>36-40 años: 30.6 días<br/>";
const cargoPrima = "De acuerdo a la política vigente.<br/>1 año: 8.4 días <br/>2 años: 9.8 días<br/>3 años: 11.2 días<br/>4 años: 12.6 días<br/>5 años: 14 días<br/>6-10 años: 15.4 días<br/>11-15 años: 16.8 días<br/>16-20 años: 18.2 días<br/>21-25 años: 19.6 días<br/>26-30 años: 21 días<br/>31-35 años: 22.4 días<br/>36-40 años: 30.6 días<br/>";
const afiPrima = "De acuerdo a la política vigente.<br/>1 año: 8.4 días <br/>2 años: 9.8 días<br/>3 años: 11.2 días<br/>4 años: 12.6 días<br/>5 años: 14 días<br/>6-10 años: 15.4 días<br/>11-15 años: 16.8 días<br/>16-20 años: 18.2 días<br/>21-25 años: 19.6 días<br/>26-30 años: 21 días<br/>31-35 años: 22.4 días";
const cscPrima = "De acuerdo a la política vigente.<br/>1 año: 3 días <br/>2 años: 3.5 días<br/>3 años: 4 días<br/>4 años: 4.5 días<br/>5 años: 5 días<br/>6-10 años: 5.5 días<br/>11-15 años: 6 días<br/>16-20 años: 6.5 días<br/>21-25 años: 7 días<br/>26-30 años: 7.5 días<br/>31-35 años: 8 días";