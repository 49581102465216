import React, { Fragment, useEffect } from "react";
// import { motion } from "framer-motion";
import Header from "./components/Header";
import Arriba from "../../components/Header/Arriba";
import Nom from "./components/Nom";
import Colaborar from "./components/Colaborar";
import Ayuda from "./components/Ayuda";
import Contacto from "./components/Contacto";
import ReplicaCompliance from "./components/ReplicaCompliance";
import Politica from "./components/Politica";
/* import Index from "../Login"; */
import Index from "../../components/Footer/index";


function Nom035() {

	useEffect(() => { 
		window.scrollTo(0, 0);
	  }, []); 
	  
	return (
		<Fragment> 
			<Header />
			<Nom />
			<Colaborar />
			<Ayuda />
			<Contacto />
			<ReplicaCompliance />
			<Politica /> 
		</Fragment>
		
	);
}

export default Nom035;
