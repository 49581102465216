import React, { Component } from 'react';
import LogoAvion from '../../assets/images/NotFount/plane.png';
import { Link } from 'react-router-dom';

import '../404/css/style.css';

export default class Notfound extends Component {
	render() {
		return (
			<div className="search-container-notfound">
				<div className="box-container-search-notfound">
					<div
						className="planeNotFound"
						style={{
							backgroundImage: `url(
              ${LogoAvion}
            )`
						}}
					/>
					<div className="MidNofFound">
						<div className="MidNofFoundCell">
							<p className="title-search-notfound">
								No encontramos la página que estás buscando
							</p>

							<p className="subtitle-search-notfound">
								Algunos links que pueden ayudarte
							</p>
							<div className="Info-search-notfound">
								<ul className="item-list-search-notfound">
									<li className="search-list-notfound">
										<Link
											className="a-search-notfound"
											to="/inicio"
										>
											Inicio
										</Link>
									</li>
									<li className="search-list-notfound">
										<Link
											className="a-search-notfound"
											to="/recompensa-total/oportunidades-carrera"
										>
											Experiencia Aeroméxico
										</Link>
									</li>
									<li className="search-list-notfound">
										<Link
											className="a-search-notfound"
											to="/noticias"
										>
											Noticias
										</Link>
									</li>
									<li className="search-list-notfound">
										<a
											className="a-search-notfound"
											href="https://www.portal-aeromexico.com/asi-vamos"
											target="_blank"
										>
											Políticas
										</a>
									</li>
									{/* <li className="search-list-notfound">
                    <Link
                      className="a-search-notfound"
                      to="/recompensa-total/my-hr"
                    >
                      My HR
                    </Link>
                  </li> */}
								</ul>
							</div>
							<div>
								<Link to="/buscar">
									<span>
										Tambien te sugerimos usar nuestro
										buscador
									</span>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
