import React, { createContext } from "react";
import InfoEnvironment from "../../utils/info.environment";

const sha1 = require("sha1");

export const ServicioContext = createContext();

export function ServicioProvider({ children }) {
	const infoEnvironment = new InfoEnvironment();
	const serviceData = {
		token: sha1(
			`NOMBREHOST|${localStorage.getItem("email")}`
		).toUpperCase(),
		url: infoEnvironment.api_url({ host: window.location.hostname })
	};

	return (
		<ServicioContext.Provider value={{ serviceData }}>
			{children}
		</ServicioContext.Provider>
	);
}
