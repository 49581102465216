import React, { Component } from "react";
import "../flightplan/css/flightplan.css";
import LoadingScreen from "react-loading-screen";
import close from "../../assets/logo/close.svg"; 
import { Link } from "react-router-dom";
import Modal from "react-awesome-modal";
import i18next from "i18next";
import DOMPurify from "dompurify";
import { isLocal, isFromDev, isFromQA } from './../../core/Ambientes';

var noScroll = require("no-scroll");

export default class vision extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modalIsShow: true,
			modalIsShowIdiomas: false,
			language: i18next.language,
			loading: true,
			loadedApi: false,
			error: null,
			entries: null,
			visible: false,
			content: null
		};

		this.escFunction = this.escFunction.bind(this);
		this.setWrapperRef = this.setWrapperRef.bind(this);
	}

	async componentDidMount() {
		setTimeout(() => this.setState({ loading: false }), 1500);
		document.addEventListener("keydown", this.escFunction, false);

			// # Variables que switchean las ligas que se consumen segun sea elcaso
// const isLocal = process.env.REACT_APP_ISLOCAL; // Tomara entorno local
// const isFromDev = process.env.REACT_APP_ISFROMDEV; //Se pondra en true para ambiente DEV
// const isFromQA = process.env.REACT_APP_ISFROMQA; //Se pondra en true para ambiente QA
/* Para productivo se ponen todas en falso */

// #URl de ambiente
const urlLocal = `${process.env.REACT_APP_LOCAL}`;
const urlDev = `${process.env.REACT_APP_CADMIN_DEV}`;
const urlQA = `${process.env.REACT_APP_CADMIN_QA}`
const urlProd = `${process.env.REACT_APP_CADMIN_PROD}`

const tokenLocal = `${process.env.REACT_APP_TOKEN_LOCAL}`;
const tokenDev   = `${process.env.REACT_APP_CADMIN_DEV_TOKEN}`;
const tokenQA 	 = `${process.env.REACT_APP_CADMIN_QA_TOKEN}`;
const tokenProd  = `${process.env.REACT_APP_API_TOKEN}`

// # Liga validada
const urlValid = isLocal === true ? urlLocal 
	: isFromDev === true ? urlDev
	: isFromQA  === true? urlQA
	: urlProd

// Se genera el token segun el ambiente
const tokenValid = isLocal === true ? tokenLocal
	: isFromDev === true ? tokenDev
	: isFromQA === true ? tokenQA
	: tokenProd

	const cabecera = new Headers({
		"Content-type": "application/json",
		Authorization: "Bearer " + tokenValid
	});

		await fetch(`${urlValid}traduccion-${i18next.language}`, {headers:cabecera})
			.then(response => {
				return response.json();
			})
			.then(async json => {
				if (this.state.language === "es") {
					await this.setState({
						...this.state,
						loadedApi: true,
						content: json.data.attributes.data.Inicio.planVuelo.version_2.es
					});
				} else {
					await this.setState({
						...this.state,
						loadedApi: true,
						content: json.data.attributes.data.inicio.planVuelo.version_2.en
					});
				}
				
			});
	}

	UNSAFE_componentWillMount() {
		document.addEventListener("mousedown", this.handleClick, false);
	}
	escFunction(event) {
		if (event.keyCode === 27) {
			this.setState({
				modalIsShow: false,
				modalIsShowIdiomas: false,
				visible: false
			});
			noScroll.off();
		}
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.escFunction, false);
		document.removeEventListener("mousedown", this.handleClick, false);
	}
	handleClick = e => {
		if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
			this.setState({
				modalIsShow: false,
				modalIsShowIdiomas: false
			});
			noScroll.off();
		}
	};
	setWrapperRef(node) {
		this.wrapperRef = node;
	}

	showAlertModal = () => {
		this.setState({
			modalIsShow: !this.state.modalIsShow
		});
		// noScroll.toggle();
	};

	showAlertModal01 = () => {
		this.setState({
			modalIsShowIdiomas: !this.state.modalIsShowIdiomas
		});
		noScroll.toggle();
	};

	openModal() {
		this.setState({
			visible: true
		});
		noScroll.on();
	}

	closeModal() {
		this.setState({
			visible: false
		});
		noScroll.off();
	}

	render() {
		const { loading } = this.state;
		const dirtyHTML = `this.state.content.safety`;
		const cleanHTML = DOMPurify.sanitize(dirtyHTML);
		const employeeExpreienceCleanHTML = DOMPurify.sanitize(`this.state.content.ex`);
		const custExperienceHTML = DOMPurify.sanitize(`this.state.content.cx`);
		const eficienciaHTML = DOMPurify.sanitize(`this.state.content.create`);
		const aerolineaFavHTML = DOMPurify.sanitize(`this.state.content.carrier`);
		const ejecucionImpecableHTML = DOMPurify.sanitize(`this.state.content.execution`);

		return (
			<LoadingScreen
				loading={loading}
				bgColor="#f5f5f5"
				spinnerColor="#007dc3"
				textColor="#0b2343"
				//logoSrc={avion}
				text={i18next.t("News.cargando")}
			>
				<div>
					{/*this.state.modalIsShow === true ? (
						<div className="flightVideoModal">
							<div className="videoModalWindow" ref={this.setWrapperRef}>
								<div className="closeModal" onClick={this.showAlertModal}>
									<img src={close}></img>
								</div>
								<ReactPlayer
									url="https://aeromexico-2.wistia.com/medias/tywer1t21g"
									playing="false"
									width="100%"
									height="100%"
									controls="true"
									volume=".1"
								/>
							</div>
						</div>
					) : (
						''
					)*/}

					<Modal
						visible={this.state.visible}
						width="auto"
						height="auto"
						effect="fadeInDown"
						onClickAway={() => this.closeModal()}
						className="container-modal-notification"
					>
						<div className="selectLangugeModal">
							<div className="whiteModal">
								<div
									className="closeModal"
									onClick={() => this.closeModal()}
								>
									<img src={close} alt="cerrar"></img>
								</div>

								<div className="padding">
									<h4>Selecciona un idioma</h4>
									<div className="gridFlags">
										<Link
											className="contFlag"
											to="/nosotros/plan-de-vuelo"
										>
											<div className="flags spanish"></div>
											<p>Español</p>
										</Link>

										<Link
											className="contFlag"
											to="/nosotros/plan-de-vuelo/ingles"
										>
											<div className="flags english"></div>
											<p>Inglés</p>
										</Link>

										<Link
											className="contFlag"
											to="/nosotros/plan-de-vuelo/frances"
										>
											<div className="flags french"></div>
											<p>Francés</p>
										</Link>

										<Link
											className="contFlag"
											to="/nosotros/plan-de-vuelo/holandes"
										>
											<div className="flags dutch"></div>
											<p>Holandés</p>
										</Link>

										<Link
											className="contFlag"
											to="/nosotros/plan-de-vuelo/portugues"
										>
											<div className="flags portuguese"></div>
											<p>Portugués</p>
										</Link>

										<Link
											className="contFlag"
											to="/nosotros/plan-de-vuelo/chino"
										>
											<div className="flags mandarin"></div>
											<p>Mandarín</p>
										</Link>

										<Link
											className="contFlag"
											to="/nosotros/plan-de-vuelo/coreano"
										>
											<div className="flags korean"></div>
											<p>Coreano</p>
										</Link>

										<Link
											className="contFlag"
											to="/nosotros/plan-de-vuelo/japones"
										>
											<div className="flags japan"></div>
											<p>Japonés</p>
										</Link>
									</div>
								</div>
							</div>
						</div>
					</Modal>
					{this.state.loadedApi && (
						<div className="flightplanWrapper">
							<div className="midflightplan">
								<h1>
									{this.state.content.title}
									<br></br>
									{this.state.content.year}
								</h1>

								<a
									className="download"
									href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EYI4lo9olZdNjsgYMLeFwugBAMMhHXTOT1yS73_GUoAwxA?e=hcFzDw"
									target="_blank"
								>
									<p>{this.state.content.download}</p>
								</a>

								<div className="linenamebottom"></div>

								<div
									className="languageFlightPlan"
									onClick={() => this.openModal()}
								>
									<p>Español</p>
									<div className="languageFlag"></div>
								</div>

								<div className="circlePlaneFlight">
									<h4 className="title color01">Seguridad</h4>
									<p className="securityFirst">
										<span
											className="text"
											dangerouslySetInnerHTML={{
												__html: cleanHTML
											}}
										></span>
										<span className="arrow down"></span>
									</p>

									<h4 className="title color02">
										Experiencia del Empleado
									</h4>
									<p className="employeeExperience">
										<span
											className="text"
											dangerouslySetInnerHTML={{
												__html: employeeExpreienceCleanHTML
											}}
										></span>
										<span className="arrow right top"></span>
									</p>

									<h4 className="title color03">
										Experiencia del Cliente
									</h4>
									<p className="cutomerExperience">
										<span
											className="text"
											dangerouslySetInnerHTML={{
												__html: custExperienceHTML
											}}
										></span>
										<span className="arrow left top"></span>
									</p>

									<h4 className="title color04">
										Crear Eficiencia
									</h4>
									<p className="crearEficiencia">
										<span
											className="text"
											dangerouslySetInnerHTML={{
												__html: eficienciaHTML
											}}
										></span>
										<span className="arrow right bottom"></span>
									</p>

									<h4 className="title color05">
										Aerolínea Favorita
									</h4>
									<p className="aerolineaFavorita has-arrow left">
										<span
											className="text"
											dangerouslySetInnerHTML={{
												__html: aerolineaFavHTML
											}}
										></span>
										<span className="arrow left bottom"></span>
									</p>

									<h4 className="title color06">
										Ejecución Impecable
									</h4>
									<p className="ejecucionImpecable">
										<span
											className="text"
											dangerouslySetInnerHTML={{
												__html: ejecucionImpecableHTML
											}}
										></span>
										<span className="arrow upper"></span>
									</p>
									<span className="planeholder">
										<div className="planeitem"></div>
										<div className="planebg"></div>
									</span>
								</div>
							</div>
						</div>
					)}
				</div>
			</LoadingScreen>
		);
	}
}
