import React from "react";
import { PoliticaStyled } from "../styles/Styled";
import i18next from 'i18next';

function Politica() {
	const isSpanish = i18next.language === 'es';
	return (
		<PoliticaStyled>
			<div className="container">
				<div className="row w-100 align-items-center d-flex justify-content-center">
					<div className="col-12 col-md-6 col-lg-9 py-0 mx-0 px-0 text-lg-right text-center">
						<p>
						{isSpanish?"Conoce más detalles en la página oficial del Gobierno de México":"Learn more details on the official website of the Government of Mexico" }
						</p>
					</div>
					<div className="col-12 col-md-6 col-lg text-lg-left text-center">
						<a
							href="https://www.gob.mx/stps/documentos/guia-informativa-nom-035"
							className="fake-pink-button mt-2"
							target="_blank"
							rel="noopener noreferrer"
							style={{background:"#0000e3"}}
						>
							{isSpanish?"Ir al sitio":"Go to the site"}
						</a>
					</div>
				</div>
			</div>
		</PoliticaStyled>
	);
}

export default Politica;
