import React, { Component } from "react";
import * as contentful from "../../lib/contentful";
import { Link } from "react-router-dom";
import getLocale from "../../utils/getLocale";

import ItemCard from "../Home/Cards/ItemCard";
import "../Home/Cards/css/articleCards.css";
import "../Home/Cards/css/tag.css";

export default class ArticleCards extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      error: null,
      entries: null
    };
  }
  componentDidMount() {
    this.fetchEntries();
  }
  fetchEntries = () => {
    contentful.client
      .getEntries({
        // content_type: "articleClass",
        // include: 2,
        // order: '-sys.updatedAt',
        order: '-fields.fechaDeLaPublicacion',
        locale:getLocale(),
        content_type: "articleClass", 'fields.articleTagES[all]': 'Experiencia al Cliente', 'fields.logroCx[all]': 'Si es un logro'
      })
      .then(response => response.items)
      .then(data => {
        this.setState({
          loading: false,
          entries: data
        });
      })
      .catch(error => {
        this.setState({
          loading: false,
          error: error
        });
      })
      .catch(err => console.error(err));
  };
  renderList = articles => {
    const list = articles.map(article => {
      return <ItemCard key={article.sys.id} article={article}/>;
    });
    return list;
  };

  render() {
    const { loading, entries, error } = this.state;
    // console.log(entries);
    return (
      <div className="LatestWrap">
        <div className="ArticleCardWrap cxLogrosWrapperhidden">
          {!loading && entries && this.renderList(entries)}
        </div>
      </div>
    );
  }
}
