import React, { useState, createContext } from "react";

export const PrivateContext = createContext();

export function PrivateProvider({ children }) {
	const [isTruePriv, setIsTruePriv] = useState(false);
	const [dataPriv, setDataPriv] = useState({
		salario: null,
		ratio: null,
		salarioAnual: null,
		aguinaldo: null,
		primaVacacional: null,
		fondoAhorro: null,
		compensacion: null,
		compensacionMensual: null,
		valesDespensa: null,
		valesMensual: null,
		bonoUtiles: null,
		bonoUtilesMensual: null,
		bonoAutomovil: null,
		bonoAutomovilMensual: null,
		bonoDesempeno: null,
		lti: null,
		sumaMensual: null,
		sumaAnual: null,
		sumaAnualTotal: null
	});

	return (
		<PrivateContext.Provider
			value={{ dataPriv, setDataPriv, isTruePriv, setIsTruePriv }}
		>
			{children}
		</PrivateContext.Provider>
	);
}
