import React, { Component } from "react";
import { UncontrolledTooltip } from "reactstrap";
import "./css/seguridad.css";
import GamReportInside from "../../components/Home/GamReport/gamreportinside";
import ScrollmenuSeguridad from "../Header/scrollmenuSeguridad";
// import ScrollableAnchor from "react-scrollable-anchor";
// import wave from '../../assets/wave.svg';
// import flecha from '../../assets/images/slider/arrow-blue.svg';
import { Parallax } from "react-parallax";
import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Slide";
import {
	// Link,
	// DirectLink,
	Element
	// Events,
	// animateScroll as scroll,
	// scrollSpy,
	// scroller
} from "react-scroll";

import "../CustomerExperience/css/CXevolution.css";
import i18next from "i18next";
import { Trans } from "react-i18next";
import initLang from "../../utils/initLang"; 
import Index from "../Footer";
export default class SliderSwiper extends Component {
	
	constructor() {
		super();
		this.state = {
			tabActive: "seguridad",
			lang: "",
			language: i18next.language
		};
	}

	tabSelector = (evt) => {
		this.setState({
			tabActive: evt.currentTarget.attributes.name.textContent
		});
	};

	handleSecurityUrl = (e) => { 
		return false;
	};

	componentDidMount() {
		initLang(this);
		this.redirectGeneral();
		 
	}
	componentDidUpdate() {
		this.redirectGeneral();
		window.addEventListener('popstate', function() {
			// alert('Cambio en el hash de la URL:', window.location.hash);
		  });
	}

	redirectGeneral() {
		const url = new URL(window.location.href);
		const fragmento = url.hash;
		const id = fragmento.substring(1);
		//alert(id)
		if (id === "cultura-justa") {
			const culturaJustaElement = document.getElementById("cultura-justa");
			if (culturaJustaElement) {
				culturaJustaElement.scrollIntoView({ behavior: "smooth" });
			}
		}
		if (id === "") {
			const culturaJustaElement = document.getElementById("seguridad-inicio");
			if (culturaJustaElement) {
				culturaJustaElement.scrollIntoView({ behavior: "smooth" });
			}
		}
		if (id === "principios-de-seguridad") {
			const culturaJustaElement = document.getElementById("principios-de-seguridad");
			if (culturaJustaElement) {
				culturaJustaElement.scrollIntoView({ behavior: "smooth" });
			}
		}
		if (id === "areas-de-seguridad") {
			const culturaJustaElement = document.getElementById("areas-de-seguridad");
			if (culturaJustaElement) {
				culturaJustaElement.scrollIntoView({ behavior: "smooth" });
			}
		}

	}
 

	render() {
		return (
			<div className="seguridad">
				<Element name="test1" className="element">
					{/* <ScrollableAnchor id={'inicioSeguridad'}><div></div></ScrollableAnchor> */}
					<div id={"seguridad-inicio"}>
						<ScrollmenuSeguridad />
					</div>
					<div id="safetyfirst seguridad-inicio">
						<div className="SeguridadSectionOne">
							<div className="wrapperMid">
								<div className="seguridadMeaning">
									<div className={"logoSeguridadFirst " + this.state.lang}>
										{/* <img src=""></img> */}
									</div>
									<h2>{i18next.t("Seguridad.main")}</h2>
									<p>
										<Trans i18nKey="Seguridad.sub"></Trans>
									</p>
									{/* <p>Reconocemos la seguridad como un valor primordial que guía nuestro comportamiento día con día, buscando la seguridad y el bienestar de nuestros empleados y clientes. </p>                             */}
									{/* <Link to="test2" spy={true} smooth={true} duration={500} offset={-250}>
                                <span class="slider-read ourhovera"><a>Explorar más</a><img class="blue-arrow" src={flecha} alt="blue-arrow" /></span>
                            </Link> */}

									{/* <h4>En Grupo Aeroméxico vivimos la seguridad en un ambiente de confianza, transparencia y de Cultura Justa. </h4> */}
								</div>
							</div>
						</div>
					</div>
				</Element>

				<Element name="test2" className="element with-special-background-green" id="reporta-denuncia">
					{/* <div className="wave" aria-hidden="true"><img className="svgWave" src={wave}></img></div> */}
					<div id={"gamreport"} className="with-special-background-green">
						<div id="gamreportDiv" className="with-special-background-green">
							<div className="gamreportMid with-special-background-green">
								<GamReportInside />
							</div>
						</div>
					</div>
				</Element>

				<Element name="test3" className="element">
					{/* <ScrollableAnchor id={"justculture"}> */}
					<div></div>
					{/* </ScrollableAnchor> */}
					<Parallax
						bgImage={require("../../assets/seguridad/widthimage01.jpg")}
						bgImageAlt="the cat"
						strength={-350}
						className="parallax-back"
						// className="parallax-back green"
						bgImageStyle={{ height: "1300px", objectFit: "cover" }}>
						<div id="cultura-justa">
							<div className="SeguridadSectionTwo safetyCulturaJusta">
								<div className="wrapperMid">
									<div className="wrpperWidth">
										<Fade left cascade>
											<div className="textWrapper">
												<h3 className="!text-[#07E8CD]">
													<Trans i18nKey="Seguridad.cultura.title">
														cultura <span>{i18next.t("1")}</span>
													</Trans>
												</h3>
												<div className="text-holder-grid grid w-full grid-cols-1 gap-6 lg:grid-cols-2">
													<p className="col-span-1 w-full">
														{" "}
														{i18next.t("Seguridad.cultura.p1")}{" "}
													</p>
													<p className="col-span-1 w-full">
														<Trans i18nKey="Seguridad.cultura.p2"></Trans>
													</p>
												</div>
											</div>
										</Fade>
									</div>
								</div>
							</div>
						</div>
					</Parallax>

					<div id="widthBackground">
						<div className="SeguridadSectionTwo">
							<div className="wrapperMid wrapperWidthall">
								<div className="borderDiv">
									<Fade bottom>
										<div className="iconCulturaJusta"></div>
										<p className="Intro"> {i18next.t("Seguridad.protec")} </p>
										{/* <p className="Intro">Nuestra Cultura Justa se basa en la protección, trato justo y no punitividad de quien reporta.  </p> */}
										<p className="small"> {i18next.t("Seguridad.protecSub")} </p>
									</Fade>
								</div>
							</div>
						</div>
						<div className="blueFilter"></div>
					</div>
				</Element>

				<div  id="principios-de-seguridad">
				<Element name="test4" className="element">
					<div id="principios-de-seguridad2">
						{/* <ScrollableAnchor id={"safetyprinciples"}> */}
						<div></div>
						{/* </ScrollableAnchor> */}

						<div id="ok2">
							<div className="middleSectionBlue">
								<div className="wrapperMid">
									<Slide right>
										<div className="text-float">
											<h3 className="!text-[#07E8CD]">
												{" "}
												{i18next.t("Seguridad.principios.title.1")} <br />
												{i18next.t("Seguridad.principios.title.2")}{" "}
											</h3>
										</div>
									</Slide>
									<div className="grid-icons">
										<Fade bottom>
											<div className="IconsOpcs">
												<div class="iconsImg"></div>
												<p>{i18next.t("Seguridad.principios.lider.title")}</p>
												<p>{i18next.t("Seguridad.principios.lider.sub")}</p>
											</div>

											<div className="IconsOpcs">
												<div class="iconsImg"></div>
												<p>{i18next.t("Seguridad.principios.reporta.title")}</p>
												<p>{i18next.t("Seguridad.principios.reporta.sub")}</p>
											</div>

											<div className="IconsOpcs">
												<div class="iconsImg"></div>
												<p>{i18next.t("Seguridad.principios.promueve.title")}</p>
												<p>{i18next.t("Seguridad.principios.promueve.sub")}</p>
											</div>
										</Fade>

										<Fade bottom>
											<div className="IconsOpcs">
												<div class="iconsImg"></div>
												<p>{i18next.t("Seguridad.principios.aprende.title")}</p>
												<p>{i18next.t("Seguridad.principios.aprende.sub")}</p>
											</div>

											<div className="IconsOpcs">
												<div class="iconsImg"></div>
												<p>{i18next.t("Seguridad.principios.vive.title")}</p>
												<p>{i18next.t("Seguridad.principios.vive.sub")}</p>
											</div>

											<div className="IconsOpcs">
												<div class="iconsImg"></div>
												<p>{i18next.t("Seguridad.principios.trabaja.title")}</p>
												<p>{i18next.t("Seguridad.principios.trabaja.sub")}</p>
											</div>
										</Fade>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Element>
				</div>

				<Element name="test5" className="element">
					{/* <ScrollableAnchor id={"safetyareas"}> */}
					<div></div>
					{/* </ScrollableAnchor> */}
					<div id="areas-de-seguridad">
						<div className="SeguridadSectionThree">
							<div className="wrapperMid">
								<Slide right>
									<h3 className="!text-[#07E8CD]">{i18next.t("Seguridad.areas.title")}</h3>
									<p className="center">{i18next.t("Seguridad.areas.sub")}</p>
								</Slide>
							</div>

							<div className="tabs" id="securityTabs">
								<div className="tabsReporteWrapper">
									<div className="midTabs">
										<div className="tabResponsive">
											<div
												className={
													this.state.tabActive === "seguridad"
														? "tabBox tabBoxActive"
														: "tabBox"
												}
												onClick={this.tabSelector}
												name="seguridad">
												<p>{i18next.t("Seguridad.areas.seguridad.title")}</p>
											</div>

											<div
												className={
													this.state.tabActive === "proteccion"
														? "tabBox tabBoxActive"
														: "tabBox"
												}
												onClick={this.tabSelector}
												name="proteccion">
												<p>{i18next.t("Seguridad.areas.protec.title")}</p>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="wrapperMid">
								{this.state.tabActive === "seguridad" ? (
									<div>
										{/* <div className="titleSecurityHeader">
											<p className="titleSeguridadTipoArea">
												{i18next.t(
													'Seguridad.areas.protec.title'
												)}
											</p>
											<div className="lineTitle"></div>
										</div> */}

										{/* <div
											className="areas justone flex items-center justify-center py-10"
											id="proteccionCorporativa"
										> */}
										{/* <div className="opcAreas securityNews gridpositionTop">
												<div className="padding">
													<div className="icon">
														<img src=""></img>
													</div>
													<p className="title">
														{i18next.t(
															'Seguridad.areas.protec.corpo.title'
														)}
													</p>
													<p className="subtitle">
														{i18next.t(
															'Seguridad.areas.protec.corpo.sub'
														)}
													</p>
												</div>
											</div> */}
										{/* <div className="in-middle flex items-start justify-center">
												<div className="opcAreas securityNews card-item-security-area">
													<div className="padding">
														<div className="icon" />
														<p className="title">
															{i18next.t(
																'Seguridad.areas.protec.segu.title'
															)}
														</p>
														<p className="subtitle">
															{i18next.t(
																'Seguridad.areas.protec.segu.sub'
															)}
														</p>
													</div>
												</div>
											</div> */}
										{/* </div> */}

										<div className="titleSecurityHeader">
											<p className="titleSeguridadTipoArea !text-[#07E8CD]">
												{i18next.t("Seguridad.areas.seguridad.title")}
											</p>
											<div className="lineTitle"></div>
										</div>

										<div className="areas">
											<div className="opcAreas">
												<div className="padding">
													<div className="icon">
														<img src=""></img>
													</div>
													<p className="title">
														{i18next.t("Seguridad.areas.seguridad.safety.title")}
													</p>
													<p className="subtitle">
														{i18next.t("Seguridad.areas.seguridad.safety.sub")}
													</p>
													{/* <a href="">Conoce más</a> */}
												</div>
											</div>

											<div className="opcAreas">
												<div className="padding">
													<div className="icon">{/* <img src=""></img> */}</div>
													<p className="title">
														{i18next.t("Seguridad.areas.seguridad.secu.title")}
													</p>
													<p className="subtitle">
														{i18next.t("Seguridad.areas.seguridad.secu.sub")}
													</p>
													<a
														onClick={(e) => {
															this.handleSecurityUrl(e);
														}}
														href="https://seguridadcorporativa.portal-aeromexico.com/?g=6d0e0dc6fc833e6a"
														target="_blank"
														rel="noopener noreferrer">
														{i18next.t("Seguridad.areas.seguridad.secu.portal")}
													</a>
												</div>
											</div>

											<div className="opcAreas">
												<div className="padding">
													<div className="icon">
														<img src=""></img>
													</div>
													<p className="title">
														{i18next.t("Seguridad.areas.seguridad.salud.title")}
													</p>
													<p className="subtitle">
														{i18next.t("Seguridad.areas.seguridad.salud.sub")}
													</p>
													{/* <a href="">Conoce más</a> */}
												</div>
											</div>

											<div className="opcAreas" id="OperativeSecurity">
												<UncontrolledTooltip
													placement="auto"
													autohide={false}
													target="OperativeSecurity"
													delay={{ show: 0, hide: 0 }}>
													{i18next.t("Seguridad.areas.seguridad.opera.tooltip")}
												</UncontrolledTooltip>
												<div className="padding">
													<div className="icon">
														<img src=""></img>
													</div>
													<p className="title">
														{i18next.t("Seguridad.areas.seguridad.opera.title")}
													</p>
													<p className="subtitle">
														{i18next.t("Seguridad.areas.seguridad.opera.sub")}
													</p>
													{/* <a href="">Conoce más</a> */}
												</div>
											</div>

											<div className="opcAreas" id="EmergencyAns">
												<UncontrolledTooltip
													placement="auto"
													autohide={false}
													target="EmergencyAns"
													delay={{ show: 0, hide: 0 }}>
													{i18next.t("Seguridad.areas.seguridad.emergen.tooltip")}
												</UncontrolledTooltip>
												<div className="padding">
													<div className="icon">
														<img src=""></img>
													</div>
													<p className="title">
														{i18next.t("Seguridad.areas.seguridad.emergen.title")}
													</p>
													<p className="subtitle">
														{i18next.t("Seguridad.areas.seguridad.emergen.sub")}
													</p>
													{/* <a href="">Conoce más</a> */}
												</div>
											</div>

											<div className="opcAreas">
												<div className="padding">
													<div className="icon">
														<img src=""></img>
													</div>
													<p className="title">
														{i18next.t("Seguridad.areas.seguridad.calidad.title")}
													</p>
													<p className="subtitle">
														{i18next.t("Seguridad.areas.seguridad.calidad.sub")}
													</p>
													{/* <a href="">Conoce más</a> */}
												</div>
											</div>

											<div className="opcAreas">
												<div className="padding">
													<div className="icon">
														<img src=""></img>
													</div>
													<p className="title">
														{i18next.t("Seguridad.areas.seguridad.conti.title")}
													</p>
													<p className="subtitle">
														{i18next.t("Seguridad.areas.seguridad.conti.sub")}
													</p>
													{/* <a href="">Conoce más</a> */}
												</div>
											</div>

											{/* <div className="opcAreas">
												<div className="padding">
													<div className="icon">
														<img src=""></img>
													</div>
													<p className="title">
														{i18next.t(
															'Seguridad.areas.seguridad.cumpli.title'
														)}
													</p>
													<p className="subtitle">
														{i18next.t(
															'Seguridad.areas.seguridad.cumpli.sub'
														)}
													</p>
												</div>
											</div> */}
										</div>
									</div>
								) : "" || this.state.tabActive === "proteccion" ? (
									<div className="areas">
										<Fade bottom>
											<div className="opcAreas securityNews">
												<div className="padding">
													<div className="icon">
														<img src=""></img>
													</div>
													<p className="title">
														{i18next.t("Seguridad.areas.protec.corpo.title")}
													</p>
													<p className="subtitle">
														{i18next.t("Seguridad.areas.protec.corpo.sub")}
													</p>
													{/* <a href="">Conoce más</a> */}
												</div>
											</div>

											<div className="opcAreas securityNews">
												<div className="padding">
													<div className="icon">
														<img src=""></img>
													</div>
													<p className="title">
														{i18next.t("Seguridad.areas.protec.segu.title")}
													</p>
													<p className="subtitle">
														{i18next.t("Seguridad.areas.protec.segu.sub")}
													</p>
													{/* <a href="">Conoce más</a> */}
												</div>
											</div>
										</Fade>
									</div>
								) : (
									""
								)}
							</div>

							<div className="blueTop"></div>
						</div>
					</div>
				</Element>
				{/* <section className="compliance-end-alert-seguridad white-bg">
					<p>
						{this.state.language === "es"
							? "Conoce en Aeroméxico cómo vivimos la seguridad"
							: "Know how we live security in Aeromexico"}
					</p>
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:f:/g/personal/amcomunicacioninterna_aeromexico_com/EjSGnBXwCQhGgF94-QvnaLgB57GMFuaea62112PzIfwSxw?e=e3uqIe"
						className="fake-red-button"
					>
						{this.state.language === "es"
							? "Visita la galería"
							: "View gallery"}
					</a>
				</section> */}
				{/* <Index /> */}
			</div>
		);
	}
}
