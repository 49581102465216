export default function setLevelMockUser(){
    var email = localStorage.getItem("email");
    switch(email){
        case "amuserl1@aeromexico.com":
            localStorage.setItem("nivel", 1);
            break;
        case "amuserl2@aeromexico.com":
            localStorage.setItem("nivel", 2);
            break;
        case "amuserl3@aeromexico.com":
            localStorage.setItem("nivel", 3);
            break;
        case "amuserl4@aeromexico.com":
            localStorage.setItem("nivel", 4);
            break;
        case "amuserl5@aeromexico.com":
            localStorage.setItem("nivel", 5);
            break;
        case "amuserl6@aeromexico.com":
            localStorage.setItem("nivel", 6);
            break;
        case "amuserl7@aeromexico.com":
            localStorage.setItem("nivel", 7);
            break;
        case "amuserl8@aeromexico.com":
            localStorage.setItem("nivel", 8);
            break;
        case "amuserl9@aeromexico.com":
            localStorage.setItem("nivel", 9);
            break;
        case "amaccesoinvitado@aeromexico.com":
        case "amuserl10@aeromexico.com":
            localStorage.setItem("nivel", 10);
            break;
        case "amuserl11@aeromexico.com":
            localStorage.setItem("nivel", 11);
            break;
        case "amuserl12@aeromexico.com":
            localStorage.setItem("nivel", 12);
            break;
        default:
            localStorage.setItem("nivel", 9);
            break;
    }
};