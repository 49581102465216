import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import { Link, NavLink } from "react-router-dom";
import { Tooltip } from 'reactstrap';

import * as contentful from '../../../lib/contentful';
import './css/style.css';
import imageArrow01 from '../../../assets/images/arrow-green.svg';
import imageArrow02 from '../../../assets/images/arrow-red.svg';

// import imageArrow02b from '../../../assets/images/arrow-greenb.svg';
// import imageArrow01b from '../../../assets/images/arrow-redb.svg';
import getLocale from '../../../utils/getLocale';
import i18next from 'i18next';

export default class Stateairline extends Component {
	constructor() {
		super();
		this.toggle = this.toggle.bind(this);
		this.state = {
			loading: true,
			error: null,
			entries: null,
			tooltipOpen: false
		};
	}
	componentDidMount() {
		this.fetchEntries();
	}

	toggle() {
		this.setState({
			tooltipOpen: !this.state.tooltipOpen
		});
	}

	fetchEntries = () => {
		contentful.client
			.getEntries({
				content_type: 'stateOfAirline',
				include: 2,
				locale: getLocale()
			})
			.then(response => response.items)
			.then(data => {
				
				this.setState({
					loading: false,
					entries: data
				});
			})
			.catch(error => {
				this.setState({
					loading: false,
					error: error
				});
			})
			.catch(err => console.error(err));
	};

	render() {
		const { loading, entries } = this.state;
		return (
			<div className="StatesAirline">
				<div className="TitleState">
					<p>Net Promoter Score (NPS) </p>
				</div>
				{!loading &&
					entries &&
					entries.map(stateAriline => (
						<div key={stateAriline.sys.id}>
							{/**
              <div className="AirlineNew">
              {/*}
                <p className="AirTitle">{stateAriline.fields.newsTitle}</p>
                <div className="AirIntro">
                  <p>{stateAriline.fields.newsIntro} </p>
                </div>
                <Link to={stateAriline.fields.newsUrlLink}>
                  {stateAriline.fields.newsUrlText}
          </Link>

                
              </div> */}

							{/* <div className="OpsDEpartureHover SecurityBoxWrapper">
                <h1>Seguridad</h1>

                <div className="DeparturesNumbers DOHover">
                  <div className="tooltipCompensationsNPS tooltipDO">
                    <div>
                      <span />
                      <p className="toolTipNPS tooltipnps">
                        Número de eventos de Seguridad Operacional
                        (Accidentes/Incidentes) por cada 1,000 (mil) operaciones
                        de Grupo Aeroméxico.
                      </p>
                    </div>
                  </div>

                  <div className="DepartureWrap">
                    <img
                      className="IconImageDeparture"
                      src={stateAriline.fields.departureImage.fields.file.url}
                      alt="departure image"
                    />
                    <div className="TextWrapDeparture">
                      <div className="wrapperOpsNumber">
                        <p className="TBD NumerDeparture">
                          {stateAriline.fields.seguridadOperacional}
                        </p>

                        <span
                          className={
                            stateAriline.fields.seguridadOperacionalUpdown
                          }
                        >
                          <img src={imageArrow01} className="img01Arrowok" />
                          <img src={imageArrow02} className="img02Arrowok" />
                        </span>
                      </div>

                      <p className="DescriptionDeparture">
                        Eventos de Seguridad Operacional
                      </p>
                    </div>
                  </div>
                </div>

                <div className="DeparturesNumbers AOHover">
                  <div className="tooltipCompensationsNPS tooltipAO">
                    <div>
                      <span />
                      <p className="toolTipNPS tooltipnps">
                        Número de reportes efectivos que identifican peligros a
                        la Seguridad Operacional de Grupo Aeroméxico (Cultura de
                        Reporte Safety).
                      </p>
                    </div>
                  </div>

                  <div className="DepartureWrap">
                    <img
                      className="IconImageDeparture"
                      src={stateAriline.fields.completionImage.fields.file.url}
                      alt="arrival image"
                    />
                    <div className="TextWrapDeparture">
                      <div className="wrapperOpsNumber">
                        <p className="TBD NumerDeparture">
                          {stateAriline.fields.reportesSmsNumero}
                        </p>

                        <span className={stateAriline.fields.reportesSmsUpdown}>
                          <img src={imageArrow01} className="img01Arrowok" />
                          <img src={imageArrow02} className="img02Arrowok" />
                        </span>
                      </div>

                      <p className="DescriptionDeparture">Reportes SMS</p>
                    </div>
                  </div>
                </div>

                <div className="DeparturesNumbers CFHover">
                  <div className="tooltipCompensationsNPS tooltipCF">
                    <div>
                      <span />
                      <p className="toolTipNPS tooltipnps">
                        Desviaciones de procedimientos en operaciones en Estados
                        Unidos por 1000 operaciones que ocasionaron multas.
                      </p>
                    </div>
                  </div>

                  <div className="DepartureWrap">
                    <img
                      className="IconImageDeparture"
                      src={stateAriline.fields.arrivalImage.fields.file.url}
                      alt="completion image"
                    />
                    <div className="TextWrapDeparture">
                      <div className="wrapperOpsNumber">
                        <p className="TBD NumerDeparture">
                          {stateAriline.fields.securityComplianceIndexNumber}
                        </p>

                        <span
                          className={
                            stateAriline.fields.securityComplianceIndexUpdown
                          }
                        >
                          <img src={imageArrow01} className="img01Arrowok" />
                          <img src={imageArrow02} className="img02Arrowok" />
                        </span>
                      </div>

                      <p className="DescriptionDeparture">
                        Índice de cumplimiento de seguridad
                      </p>
                    </div>
                  </div>
                </div>

                <div className="DeparturesNumbers CFHover">
                  <div className="tooltipCompensationsNPS tooltipCF">
                    <div>
                      <span />
                      <p className="toolTipNPS tooltipnps">
                        Número de accidentes entre la cantidad de horas
                        laboradas al mes
                      </p>
                    </div>
                  </div>

                  <div className="DepartureWrap">
                    <img
                      className="IconImageDeparture"
                      src={stateAriline.fields.arrivalImage.fields.file.url}
                      alt="completion image"
                    />
                    <div className="TextWrapDeparture">
                      <div className="wrapperOpsNumber">
                        <p className="TBD NumerDeparture">
                          {stateAriline.fields.seguridadOcupacionalNumber}
                        </p>

                        <span
                          className={
                            stateAriline.fields.seguridadOcupacionalUpdown
                          }
                        >
                          <img src={imageArrow01b} className="img01Arrowok" />
                          <img src={imageArrow02b} className="img02Arrowok" />
                        </span>
                      </div>

                      <p className="DescriptionDeparture">
                        Seguridad Ocupacional
                      </p>
                    </div>
                  </div>
                </div>


              </div> */}

							{/*AQUI EMPIEZA OPS*/}

							<Link
								className="NpsWrap"
								id="TooltipExample"
								to="/experiencia-cliente/net-promoter-score#npsnumber"
							>
								<Tooltip
									placement="auto"
									isOpen={this.state.tooltipOpen}
									autohide={false}
									target="TooltipExample"
									toggle={this.toggle}
								>
									{i18next.t('Inicio.netTip')}
								</Tooltip>
								{/*<div className="tooltipCompensationsNPS">
                  <div>
                    <span />
                    <p className="toolTipNPS tooltipnps">
                      Con el NPS medimos la satisfacción de nuestros clientes y
                      qué tan probable es que escojan volar con nosotros en un
                      futuro. Da clic para conocer más.
                    </p>
                  </div>
                        </div>*/}

								<div className="NpsNumber">
									<p>{stateAriline.fields.npsNumber}</p>
									<span className={stateAriline.fields.npsUpdown}>
										<img
											src={imageArrow01}
											className="img01Arrowok"
											alt="Arrow"
										/>
										<img
											src={imageArrow02}
											className="img02Arrowok"
											alt="Arrow"
										/>
									</span>
								</div>
								<p className="NpsDescription">{stateAriline.fields.npsTitle}</p>
								<p className="NpsUpdatedAt">
									{stateAriline.fields.npsUpdatedAt}
								</p>

								<div>{i18next.t('Inicio.netMas')}</div>
							</Link>

							{/* <div className="OpsDEpartureHover">
                <h1>Operacionales</h1>
                <div className="DeparturesNumbers DOHover">
                  
                  
                  
                  <div className="tooltipCompensationsNPS tooltipDO">
                    <div>
                      <span />
                      <p className="toolTipNPS tooltipnps">
                        Puntualidad de salida al minuto. Son los vuelos que
                        salen a tiempo o antes de tiempo de la hora programada.
                      </p>
                    </div>
                  </div>

                  <div className="DepartureWrap">
                    <img
                      className="IconImageDeparture"
                      src={stateAriline.fields.departureImage.fields.file.url}
                      alt="departure image"
                    />
                    <div className="TextWrapDeparture">
                      <div className="wrapperOpsNumber">
                        <p className="TBD NumerDeparture">
                          {stateAriline.fields.departureNumber}
                        </p>

                        <span
                          className={stateAriline.fields.departureNumberUpDown}
                        >
                          <img src={imageArrow01} className="img01Arrowok" />
                          <img src={imageArrow02} className="img02Arrowok" />
                        </span>
                      </div>

                      <p className="DescriptionDeparture">
                        <span>D0</span> Departure on time
                      </p>
                    </div>
                  </div>
                </div>

                <div className="DeparturesNumbers AOHover">
                  <div className="tooltipCompensationsNPS tooltipAO">
                    <div>
                      <span />
                      <p className="toolTipNPS tooltipnps">
                        Puntualidad de llegada al minuto. Son los vuelos que
                        llegan en tiempo o antes de tiempo.
                      </p>
                    </div>
                  </div>

                  <div className="DepartureWrap">
                    <img
                      className="IconImageDeparture"
                      src={stateAriline.fields.completionImage.fields.file.url}
                      alt="arrival image"
                    />
                    <div className="TextWrapDeparture">
                      <div className="wrapperOpsNumber">
                        <p className="TBD NumerDeparture">
                          {stateAriline.fields.arrivalNumber}
                        </p>

                        <span
                          className={stateAriline.fields.arrivalNumberUpDown}
                        >
                          <img src={imageArrow01} className="img01Arrowok" />
                          <img src={imageArrow02} className="img02Arrowok" />
                        </span>
                      </div>

                      <p className="DescriptionDeparture">
                        <span>A0</span> Arrival on time
                      </p>
                    </div>
                  </div>
                </div>

                <div className="DeparturesNumbers CFHover">
                  <div className="tooltipCompensationsNPS tooltipCF">
                    <div>
                      <span />
                      <p className="toolTipNPS tooltipnps">
                        Confiabilidad: Es el porcentaje de vuelos efectuados
                        respecto a los que se tienen programados.
                      </p>
                    </div>
                  </div>

                  <div className="DepartureWrap">
                    <img
                      className="IconImageDeparture"
                      src={stateAriline.fields.arrivalImage.fields.file.url}
                      alt="completion image"
                    />
                    <div className="TextWrapDeparture">
                      <div className="wrapperOpsNumber">
                        <p className="TBD NumerDeparture">
                          {stateAriline.fields.completionNumber}
                        </p>

                        <span
                          className={stateAriline.fields.completionNumberUpDown}
                        >
                          <img src={imageArrow01} className="img01Arrowok" />
                          <img src={imageArrow02} className="img02Arrowok" />
                        </span>
                      </div>

                      <p className="DescriptionDeparture">
                        <span>CF</span> Completion Factor
                      </p>
                    </div>
                  </div>
                </div>
              </div> */}

							{/*AQUI TERMINA OPS*/}
						</div>
					))}
			</div>
		);
	}
}
