export const rt2022 = [
	{
		title: {
			es: 'Cuarto Trimestre',
			en: 'Fourth Quarter'
		},
		urlES: '',
		url: ''
	},
	{
		title: {
			es: 'Tercer Trimestre',
			en: 'Third Quarter'
		},
		urlES: '',
		url: ''
	},
	{
		title: {
			es: 'Segundo Trimestre',
			en: 'Second Quarter'
		},
		urlES: 'https://aeromexico.com/cms/sites/default/files/Aeromexico_2T22_ESP_180722_VF.pdf',
		url: 'https://aeromexico.com/cms/sites/default/files/Aeromexico_2T22_ESP_180722_VF.pdf'
	},
	{
		title: {
			es: 'Primer Trimestre',
			en: 'First Quarter'
		},
		urlES: 'https://aeromexico.com/cms/sites/default/files/Aeromexico_1T22_ESP_-_FV.pdf',
		url: 'https://aeromexico.com/cms/sites/default/files/Aeromexico_1T22_ESP_-_FV.pdf'
	}
];
export const rt2021 = [
	{
		title: {
			es: 'Cuarto Trimestre',
			en: 'Fourth Quarter'
		},
		urlES: 'https://aeromexico.com/cms/sites/default/files/Aeromexico_4T21_VF.pdf',
		url: 'https://aeromexico.com/cms/sites/default/files/Aeromexico_4T21_VF.pdf'
	},
	{
		title: {
			es: 'Tercer Trimestre',
			en: 'Third Quarter'
		},
		urlES: 'https://aeromexico.com/cms/sites/default/files/Aeromexico_3T21_D9.pdf',
		url: 'https://aeromexico.com/cms/sites/default/files/Aeromexico_3T21_D9.pdf'
	},
	{
		title: {
			es: 'Segundo Trimestre',
			en: 'Second Quarter'
		},
		urlES: 'https://aeromexico.com/cms/sites/default/files/Aeromexico_2T21_Final.pdf',
		url: 'https://aeromexico.com/cms/sites/default/files/Aeromexico_2T21_Final.pdf'
	},
	{
		title: {
			es: 'Primer Trimestre',
			en: 'First Quarter'
		},
		urlES: 'https://aeromexico.com/cms/sites/default/files/Aeromexico_1T21_Final.pdf',
		url: 'https://aeromexico.com/cms/sites/default/files/Aeromexico_1T21_Final.pdf'
	}
];
export const rt2020 = [
	{
		title: {
			es: 'Tercer Trimestre',
			en: 'Third Quarter'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2020/espan%CC%83ol/Aeromexico%203T20.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2020/Aeromexico%203Q20.pdf`
	},
	{
		title: {
			es: 'Segundo Trimestre',
			en: 'Second Quarter'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2020/espan%CC%83ol/20200728%20Aeromexico%202T20%20-%20D21.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2020/20200728%20Aeromexico%202Q20%20-%20D21.pdf`
	},
	{
		title: {
			es: 'Primer Trimestre',
			en: 'First Quarter'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2020/espan%CC%83ol/Aeromexico_1T20.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2020/Aeromexico_1Q20.pdf`
	}
];
export const rt2019 = [
	{
		title: {
			es: 'Cuarto Trimestre',
			en: 'Fourth Quarter'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2019/espan%CC%83ol/20200430_Aeromexico_4T19_-Auditado.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2019/4Q19_-_ENG.pdf`
	},
	{
		title: {
			es: 'Tercer Trimestre',
			en: 'Third Quarter'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2019/espan%CC%83ol/Aeromexico_3T19.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2019/Aeromexico_3Q19.pdf`
	},
	{
		title: {
			es: 'Segundo Trimestre',
			en: 'Second Quarter'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2019/espan%CC%83ol/AM_2T19.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2019/AM_2Q19.pdf`
	},
	{
		title: {
			es: 'Primer Trimestre',
			en: 'First Quarter'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2019/espan%CC%83ol/Aeromexico_1T19_0.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2019/Aeromexico_1Q19_0.pdf`
	}
];
export const rt2018 = [
	{
		title: {
			es: 'Cuarto Trimestre',
			en: 'Fourth Quarter'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2018/espan%CC%83ol/AM_4T18-_SPA_.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2018/AM_4Q18_-_ENG_.pdf`
	},
	{
		title: {
			es: 'Tercer Trimestre',
			en: 'Third Quarter'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2018/espan%CC%83ol/20181015_AM_3T18_-_SPA_-_Draft_14.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2018/20181015_AM_3Q18_-_ENG_-_Draft_14.pdf`
	},
	{
		title: {
			es: 'Segundo Trimestre',
			en: 'Second Quarter'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2018/espan%CC%83ol/AM_2T18_-_SPA.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2018/AM_2Q18_-_ENG.pdf`
	},
	{
		title: {
			es: 'Primer Trimestre',
			en: 'First Quarter'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2018/espan%CC%83ol/AM_1T18_-_SPA-v2.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2018/AM_1Q18_-_ENG.pdf`
	}
];
export const rt2017 = [
	{
		title: {
			es: 'Cuarto Trimestre',
			en: 'Fourth Quarter'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2017/espan%CC%83ol/AM_4T17_espanol-v2.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2017/AM_4Q17_english-v2.pdf`
	},
	{
		title: {
			es: 'Tercer Trimestre',
			en: 'Third Quarter'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2017/espan%CC%83ol/Aeromexico_3Q17_spanish.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2017/Aeromexico_3Q17_english.pdf`
	},
	{
		title: {
			es: 'Segundo Trimestre',
			en: 'Second Quarter'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2017/espan%CC%83ol/2T17_AM_Reporte_Trimestral.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2017/2Q17_AM-_Quarterly_Report.pdf`
	},
	{
		title: {
			es: 'Primer Trimestre',
			en: 'First Quarter'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2017/espan%CC%83ol/Aeromexico_1T17_espanol_0.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2017/Aeromexico_1Q17_english_0.pdf`
	}
];
export const rt2016 = [
	{
		title: {
			es: 'Cuarto Trimestre',
			en: 'Fourth Quarter'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2016/espan%CC%83ol/am-_4t16_earnings_release.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2016/am-4q16_earnings_release_1.pdf`
	},
	{
		title: {
			es: 'Tercer Trimestre',
			en: 'Third Quarter'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2016/espan%CC%83ol/3T16%20AM%20Reporte%20Trimestral.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2016/3Q16.pdf`
	},
	{
		title: {
			es: 'Segundo Trimestre',
			en: 'Second Quarter'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2016/espan%CC%83ol/2T16_espa%C2%A4ol_0.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2016/2Q16.pdf`
	},
	{
		title: {
			es: 'Primer Trimestre',
			en: 'First Quarter'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2016/espan%CC%83ol/1T16_0.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2016/1Q16.pdf`
	}
];
export const rt2015 = [
	{
		title: {
			es: 'Cuarto Trimestre',
			en: 'Fourth Quarter'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2015/espan%CC%83ol/4T15_0.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2015/4Q15.pdf`
	},
	{
		title: {
			es: 'Tercer Trimestre',
			en: 'Third Quarter'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2015/espan%CC%83ol/3T15_0.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2015/3Q15_aeromexico_1.pdf`
	},
	{
		title: {
			es: 'Segundo Trimestre',
			en: 'Second Quarter'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2015/espan%CC%83ol/2T15_0.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2015/2T15.pdf`
	},
	{
		title: {
			es: 'Primer Trimestre',
			en: 'First Quarter'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2015/espan%CC%83ol/1T15__0.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2015/1T15.pdf`
	}
];
export const rt2014 = [
	{
		title: {
			es: 'Cuarto Trimestre',
			en: 'Fourth Quarter'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2014/espan%CC%83ol/4T14.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2014/4T14_0.pdf`
	},
	{
		title: {
			es: 'Tercer Trimestre',
			en: 'Third Quarter'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2014/espan%CC%83ol/3T14.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2014/3T14_0.pdf`
	},
	{
		title: {
			es: 'Segundo Trimestre',
			en: 'Second Quarter'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2014/espan%CC%83ol/2T14.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2014/2Q14.pdf`
	},
	{
		title: {
			es: 'Primer Trimestre',
			en: 'First Quarter'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2014/espan%CC%83ol/1T14.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2014/1T14_0.pdf`
	}
];
export const rt2013 = [
	{
		title: {
			es: 'Cuarto Trimestre',
			en: 'Fourth Quarter'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2013/espan%CC%83ol/4T13.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2013/4Q13.pdf`
	},
	{
		title: {
			es: 'Tercer Trimestre',
			en: 'Third Quarter'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2013/espan%CC%83ol/3T13.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2013/3T13_0.pdf`
	},
	{
		title: {
			es: 'Segundo Trimestre',
			en: 'Second Quarter'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2013/espan%CC%83ol/2T13.PDF`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2013/2Q13.PDF`
	},
	{
		title: {
			es: 'Primer Trimestre',
			en: 'First Quarter'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2013/espan%CC%83ol/1T13.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2013/1T13_.pdf`
	}
];
export const rt2012 = [
	{
		title: {
			es: 'Cuarto Trimestre',
			en: 'Fourth Quarter'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2012/espan%CC%83ol/4T12.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2012/4Q12.pdf`
	},
	{
		title: {
			es: 'Tercer Trimestre',
			en: 'Third Quarter'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2012/espan%CC%83ol/3T12.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2012/3Q12.pdf`
	},
	{
		title: {
			es: 'Segundo Trimestre',
			en: 'Second Quarter'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2012/espan%CC%83ol/2T12.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2012/2Q12.pdf`
	},
	{
		title: {
			es: 'Primer Trimestre',
			en: 'First Quarter'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2012/espan%CC%83ol/1Q12.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_trimestrales/2012/1Q12_0.pdf`
	}
];
