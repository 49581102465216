import React, { Component } from 'react';
import CurrencyFormat from 'react-currency-format';
// import { PDFExport } from '@progress/kendo-react-pdf';
// import html2canvas from 'html2canvas';
// import jsPdf from 'jspdf';
// import htmlPdf from 'html-pdf';
import '../css/totalrewards.css';
// import { taxonomia } from './tax';
// import Print from './Report/Print';
import getTax from './getTax';
import i18next from 'i18next';
import { taxonomiaDef } from './tax';

export default class EmployeeInfo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			nombre: localStorage.getItem('nombre'),
			materno: localStorage.getItem('apellidoPaterno'),
			paterno: localStorage.getItem('apellidoMaterno'),
			generalInfo: this.props.generalInfo,
			noEmpleado: localStorage.getItem('noEmpleado'),
			compania: localStorage.getItem('compania'),
			nivel: localStorage.getItem('nivel'),
			ubicacion: localStorage.getItem('ubicacion'),

			fechaContra: localStorage.getItem('fechaContra'),
			antiguedadEmpleado: localStorage.getItem('antiguedadEmpleado'),
			
			direccion: localStorage.getItem('direccion'),
			salario: this.props.privateData.salario,
			taxonomia: [{ code: 0, email: '', es: '', en: '' }],
			salarioAnual: this.props.privateData.salarioAnual,
			aguinaldo: this.props.privateData.aguinaldo,
			primaVacacional: this.props.privateData.primaVacacional,
			fondoAhorro: this.props.privateData.fondoAhorro,
			compensacion: this.props.privateData.compensacion,
			valesDespensa: this.props.privateData.valesDespensa,
			bonoUtiles: this.props.privateData.bonoUtiles,
			bonoAutomovil: this.props.privateData.bonoAutomovil,
			bonoDesempeno: this.props.privateData.bonoDesempeno,
			lti: this.props.privateData.lti,
			sumaAnualTotal: this.props.privateData.sumaAnualTotal,
			sumaMensual: this.props.privateData.sumaMensual,
			sumaAnual: this.props.privateData.sumaAnual,
			puesto: localStorage.getItem('posicion'),
			showPdf: false,
			numPages: null,
			pageNumber: 1,
			isLocalHost: false
		};
		
	}

	componentDidMount() {
		//localStorage.getItem('nivel');
		const taxSelected = getTax(
			localStorage.getItem('nivel'),
			localStorage.getItem('posicion')
		);
		this.setState({
			taxonomia: taxSelected
		});
		if (window.location.hostname) {
			this.setState({
				...this.state,
				isLocalHost: true
			});
		}

		/*
    const taxSelected = taxonomia.filter(
      item => item.code === localStorage.getItem("nivel")
    );
    if (taxSelected.length > 1) {
      const taxEspecial = taxSelected.filter(
        item => item.email === localStorage.getItem("email")
      );
      this.setState({
        taxonomia: taxEspecial
      });
    } else {
      const taxEspecial = taxSelected;
      this.setState({
        taxonomia: taxEspecial
      });
    }
  */
	}

	// exportPDF = () => {
	// 	this.resume.save();
	// };
	// pdfPrint = () => {
	// 	const input = document.getElementById('container-print');
	// 	html2canvas(input).then(canvas => {
	// 		const imgData = canvas.toDataURL('image/png');

	// 		// Document of a4WidthMm wide and inputHeightMm high

	// 		// elongated a4 (system print dialog will handle page breaks)
	// 		const pdf = new jsPdf('p', 'mm', 768, 6000);

	// 		pdf.addImage(imgData, 'PNG', 0, 0);
	// 		pdf.save(`test.pdf`);
	// 	});
	// };
	// printPdf = () => {
	// 	const domElement = document.getElementById('container-print');

	// 	const pdf = new jsPdf('p', 'pt', 'a4');
	// 	//const pdf = new jsPdf()
	// 	const specialElementHandlers = {
	// 		'#bypassme': function(element, renderer) {
	// 			return true;
	// 		}
	// 	};
	// 	const margins = {
	// 		top: 20,
	// 		bottom: 20,
	// 		left: 20
	// 	};

	// 	pdf.fromHTML(
	// 		domElement,
	// 		margins.left, // x coord
	// 		margins.top,
	// 		{
	// 			// y coord
	// 			width: 612,
	// 			elementHandlers: specialElementHandlers
	// 		},

	// 		function(dispose) {
	// 			pdf.save('Prueba.pdf');
	// 		},
	// 		margins
	// 	);
	// };
	// loadPdf() {
	// 	var contenido = `
	// <h1>Esto es un test de html-pdf</h1>
	// <p>Estoy generando PDF a partir de este código HTML sencillo</p>
	// `;

	// 	htmlPdf.create(contenido).toFile('./salida.pdf', function(err, res) {
	// 		if (err) {
	// 			console.log(err);
	// 		} else {
	// 			console.log(res);
	// 		}
	// 	});
	// }

	taxonomiaResolved() {
		
		//console.log("todo el state",this.state);
		//console.log("nivel local",parseInt(localStorage.getItem('nivel')));
		//console.log("nivel state",parseInt(this.state.nivel));
		const resolved = taxonomiaDef.filter(
			(i) => i.code === parseInt(localStorage.getItem('nivel'))
		);
		//console.log(resolved);
		//console.log(i18next.language);
		//console.log(resolved[0].en);
		return i18next.language === 'es' ? resolved[0].es : resolved[0].en;
	}
	// nivelTaxo =this.state.nivel;
	
	// lang3w = i18next.language;
	//  taxonomiaResolved(nivelTaxo, lang3w ) {
	// 	
	// 	const resolved = taxonomiaDef.find(item => item.code === parseInt(nivelTaxo));
	// 	if (resolved) {
	// 	  return resolved.taxonomiaResolved(lang3w);
	// 	} else {
	// 	  return '';
	// 	}
	//   }

	taxonomiaInfo() {

		if (i18next.language === 'es') {
			return this.state.taxonomia[0].es;
		} else {
			return this.state.taxonomia[0].en;
		}
	}

	taxonomiaInfoSub() {
		if (i18next.language === 'es') {
			return this.state.taxonomia[0].en;
		} else {
			return this.state.taxonomia[0].es;
		}
	}

	render() {
		return (
			<div className="container-info-employee">
				<div className="PositionFixed">
					<h1 className="rewards_title">
						{i18next.t('MiExperAM.compensacion.info.title')}
					</h1>
					<div className="personalInformation">
						<div className="rowPersonal">
							<p className="subjectPersonal">
								{i18next.t('MiExperAM.compensacion.info.num')}
							</p>
							<p className="resultPersonal">
								{this.state.noEmpleado}
							</p>
						</div>
						<div className="rowPersonal">
							<p className="subjectPersonal">
								{i18next.t(
									'MiExperAM.compensacion.info.compania'
								)}
							</p>
							<p className="resultPersonal">
								{this.state.compania}
							</p>
						</div>
						<div className="rowPersonal">
							<p className="subjectPersonal">
								{i18next.t('MiExperAM.compensacion.info.nivel')}
							</p>
							<p className="resultPersonal">
								L{this.state.nivel}
							</p>
						</div>
						<div className="rowPersonal">
							<p className="subjectPersonal">
								{i18next.t(
									'MiExperAM.compensacion.info.taxonomia'
								)}
							</p>
							<p className="resultPersonal taxonomia">
								{this.taxonomiaResolved()}
								{/* {this.taxonomiaInfo()}
								<br />
								<span>{this.taxonomiaInfoSub()}</span> */}
							</p>
						</div>
						<div className="rowPersonal">
							<p className="subjectPersonal">
								{i18next.t(
									'MiExperAM.compensacion.info.ubicacion'
								)}
							</p>
							<p className="resultPersonal locationtext">
								{this.state.ubicacion}
							</p>
						</div>
						<div className="rowPersonal">
							<p className="subjectPersonal">
								{i18next.t(
									'MiExperAM.compensacion.info.contrata'
								)}
							</p>
							<p className="resultPersonal">
								{this.state.fechaContra}
							</p>
						</div>
						<div className="rowPersonal">
							<p className="subjectPersonal">
								{i18next.t(
									'MiExperAM.compensacion.info.antiguedad'
								)}
							</p>
							<p className="resultPersonal">
								{this.state.antiguedadEmpleado}
							</p>
						</div>
						<div className="rowPersonal">
							<p className="subjectPersonal">
								{i18next.t(
									'MiExperAM.compensacion.info.sueldoMensual'
								)}
							</p>
							<p className="resultPersonal">
								{/* $ {this.state.salario}{' '} */}
								<CurrencyFormat
									decimalSeparator="."
									decimalScale="2"
									fixedDecimalScale={true}
									value={this.state.salario}
									displayType="text"
									thousandSeparator=","
									prefix="$"
								/>
							</p>
						</div>
					</div>
					<div className="personalInformation margintopTerms">
						<p>{i18next.t('MiExperAM.compensacion.datos')} </p>
					</div>
				</div>
			</div>
		);
	}
}
