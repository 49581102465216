import React from "react";
import NomItem from "./NomItem";
import { SectionNOM } from "../styles/Styled";
import i18next from 'i18next';

function Nom() {
	const isSpanish = i18next.language === 'es';
	return (
		<SectionNOM>
			<article className="mb-5">
				<h2 align="center" className="mb-4">
					{isSpanish?"¿Qué son los factores de riesgo psicosocial?":"What are psychosocial risk factors?"}
				</h2>
				<p align="center" style={{marginTop:'0%',fontSize:'1.5rem'}}>
					{isSpanish?"Son aquellas condiciones que puedan afectar la salud y el desempeño laboral de nuestros compañeros:":"They are those conditions that may affect the health and work performance of our colleagues:"}
				</p>
			</article>
			<article className="container-fluid with-background">
				<div className="nom-items container">
					<NomItem
						text={isSpanish?"Ambiente de trabajo":"Work environment"}
						backgroundImage="https://images.unsplash.com/photo-1485965373059-f07657e9f841?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80"
						content={isSpanish?"Se refieren a condiciones peligrosas e inseguras o deficientes e insalubres; es decir, a las condiciones del lugar de trabajo, que bajo ciertas circunstancias exigen del trabajador un esfuerzo adicional de adaptación.":"They refer to dangerous and unsafe or deficient and unhealthy conditions; That is, to the conditions of the workplace, which under certain circumstances require the worker to make an additional effort to adapt."}
					/>
					<NomItem
						backgroundImage="https://images.unsplash.com/photo-1520333789090-1afc82db536a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1351&q=80"
						text={isSpanish?"Relación trabajo y familia":"Work and family relationship"}
						content={isSpanish?"Surge cuando existe conflicto entre las actividades familiares o personales y las responsabilidades laborales; es decir, cuando de manera constante se tienen que atender responsabilidades laborales durante el tiempo dedicado a la vida familiar y personal, o se tiene que laborar fuera del horario de trabajo.":"It arises when there is a conflict between family or personal activities and work responsibilities; That is, when you constantly have to attend to work responsibilities during the time dedicated to family and personal life, or you have to work outside of work hours."}
					/>
					<NomItem
						backgroundImage="https://images.unsplash.com/photo-1468487422149-5edc5034604f?ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80"
						text={isSpanish?"Cargas de trabajo":"Workloads"}
						content={isSpanish?"Se refieren a las exigencias que el trabajo impone al trabajador y que exceden su capacidad, pueden ser de diversa naturaleza, como cuantitativas, cognitivas o mentales, emocionales, de responsabilidad, así como cargas contradictorias o inconsistentes.":"They refer to the demands that the job imposes on the worker and that exceed their capacity; they can be of diverse nature, such as quantitative, cognitive or mental, emotional, responsibility, as well as contradictory or inconsistent loads."}
					/>
					<NomItem
						backgroundImage="https://images.unsplash.com/photo-1541844053589-346841d0b34c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80"
						text={isSpanish?"Liderazgo y relaciones":"Leadership and relationships"}
						content={isSpanish?"El liderazgo negativo en el trabajo hace referencia al tipo de relación que se establece entre el patrón o sus representantes y los trabajadores, cuyas características influyen en la forma de trabajar y en las relaciones de un área de trabajo y que está directamente relacionado con la actitud agresiva y/o impositiva; falta de claridad de las funciones en las actividades, y escaso o nulo reconocimiento y retroalimentación del desempeño.":"Negative leadership at work refers to the type of relationship established between the employer or his representatives and the workers, whose characteristics influence the way of working and the relationships in a work area and which is directly related to the attitude aggressive and/or imposing; lack of clarity of roles in activities, and little or no performance recognition and feedback."}
						p2={isSpanish?"El concepto de relaciones negativas en el trabajo se refiere a la interacción que se establece en el contexto laboral y abarca aspectos como la imposibilidad de interactuar con los compañeros de trabajo para la solución de problemas relacionados con el trabajo, y características desfavorables de estas interacciones en aspectos funcionales como deficiente o nulo trabajo en equipo y apoyo social.":"The concept of negative relationships at work refers to the interaction that is established in the work context and covers aspects such as the impossibility of interacting with coworkers to solve work-related problems, and unfavorable characteristics of these interactions in functional aspects such as poor or no teamwork and social support."}
					/>
					<NomItem
						backgroundImage="https://images.unsplash.com/photo-1513128034602-7814ccaddd4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=675&q=80"
						text={isSpanish?"Control sobre el trabajo":"Control over work"}
						content={isSpanish?"La falta de control se denomina como la escasa o inexistente posibilidad que tiene el trabajador para influir y tomar decisiones sobre los diversos aspectos que intervienen en la realización de sus actividades.":"Lack of control is called the limited or non-existent possibility that the worker has to influence and make decisions on the various aspects involved in carrying out their activities."}
					/>
					<NomItem
						backgroundImage="https://images.unsplash.com/photo-1593436043673-2f6646e4cb39?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60"
						text={isSpanish?"Violencia laboral":"Workplace violence"}
						content={isSpanish?"La violencia laboral, de conformidad con lo siguiente:":"Workplace violence, in accordance with the following:"}
					>
						<ul>
							<li>{isSpanish?"Acoso, acoso psicológico: Aquellos actos que dañan la estabilidad psicológica, la personalidad, la dignidad o integridad del trabajador. Consiste en acciones de intimidación sistemática y persistente, tales como: descrédito, insultos, humillaciones, devaluación, marginación, indiferencia, comparaciones destructivas, rechazo, restricción a la autodeterminación y amenazas, las cuales llevan al trabajador a la depresión, al aislamiento, a la pérdida de su autoestima. Para efectos de esta Norma no se considera el acoso sexual":"Harassment, psychological harassment: Those acts that damage psychological stability, personality, dignity or integrity of the worker. Consists of intimidation actions systematic and persistent, such as: discredit, insults, humiliations, devaluation, marginalization, indifference, destructive comparisons, rejection, restriction to self-determination and threats, which lead the worker to depression, isolation, loss of self-esteem. For the purposes of this Rule, harassment is not considered sexual"}
								
							</li>
							<li>
								{isSpanish?"Hostigamiento: El ejercicio de poder en una relación de subordinación real de la víctima frente al agresor en el ámbito laboral, que se	expresa en conductas verbales, físicas o ambas":"Harassment: The exercise of power in a real subordination relationship of the victim against the aggressor in the workplace, who expressed in verbal, physical behaviors or both"}
							</li>
							<li>
								{isSpanish?"Malos tratos: Aquellos actos consistentes en insultos, burlas, humillaciones y/o ridiculizaciones del trabajador, realizados de	manera continua y persistente (más de una vez y/o en diferentes ocasiones).":"Abuse: Those acts consisting of insults, ridicule, humiliation and/or ridicule of the worker, carried out in continuously and persistently (more than once and/or on different occasions)."}
							</li>
						</ul>
					</NomItem>
					<NomItem
						backgroundImage="https://images.unsplash.com/photo-1508962914676-134849a727f0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60"
						text={isSpanish?"Jornadas de trabajo":"Workdays"}
						content={isSpanish?"Representan una exigencia de tiempo laboral que se hace al trabajador en términos de la duración y el horario de la jornada, se convierte en factor de riesgo psicosocial cuando se trabaja con extensas jornadas, con frecuente rotación de turnos o turnos nocturnos, sin pausas y descansos periódicos claramente establecidos y ni medidas de prevención y protección del trabajador para detectar afectación de su salud, de manera temprana":"They represent a demand for working time that is made to the worker in terms of the duration and schedule of the day; it becomes a psychosocial risk factor when working long hours, with frequent rotation of shifts or night shifts, without breaks and breaks. clearly established periodic measures and no prevention and protection measures for the worker to detect health effects early."}
					/>
				</div>
			</article>
			<article className="container-fluid floating-text" style={{background:'#1b869e'}}>
				<div className="row align-items-center text-center justify-content-center">
						<p className="col-12 text-center text-lg" style={{fontSize: '1.7rem'}}>
							{isSpanish
							? <>Un correcto estado de los factores psicosociales nos permite facilitar nuestro trabajo,<br/>desarrollar competencias laborales y favorecer los niveles de productividad y motivación.</>
							: <>A correct state of psychosocial allows us to facilitate our work, develop job skills and promote levels of productivity and motivation.</>
							// "Un correcto estado de los factores psicosociales nos permite facilitar nuestro trabajo, desarrollar	competencias laborales y favorecer los niveles de productividad y motivación.":"A correct state of psychosocial allows us to facilitate our work, develop job skills and promote levels of productivity and motivation."}
							}
						</p>
					</div>
			</article>
		</SectionNOM>
	);
}

export default Nom;
