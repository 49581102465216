import { taxonomiaDef } from './tax';
import { taxonomiaEspecial } from './taxSp';

export default function getTax(nivelStr, posicion) {
	var pos = '';
	const nivel = parseInt(nivelStr);
	var def = [{ code: 0, es: '', en: '' }];

	if (posicion !== null && posicion !== '') {
		pos = posicion.toLowerCase();
	}

	if (
		pos === 'asistente administrativa' ||
		pos === 'asistente ejecutiva' ||
		pos === 'asistente de direccion general'
	) {
		const taxSelected = taxonomiaEspecial.filter(
			(item) => item.code === nivel
		);

		if (taxSelected === []) {
			const taxSelected = taxonomiaDef.filter(
				(item) => item.code === nivel
			);

			if (taxSelected === []) {
				return def;
			}
		}
		return taxSelected;
	} else {
		const taxSelected = taxonomiaDef.filter((item) => item.code === nivel);
		if (taxSelected === []) {
			return def;
		}
		return taxSelected;
	}
}
