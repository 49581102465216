import * as Msal from 'msal'

export default class AuthService {
  constructor () {
    // let PROD_REDIRECT_URI = 'https://sunilbandla.github.io/react-msal-sample/';
    let PROD_REDIRECT_URI = 'https://miaeromexico.com/inicio'
    let redirectUri = window.location.origin
    if (window.location.hostname !== '127.0.0.1') {
      redirectUri = PROD_REDIRECT_URI
    }
    this.applicationConfig = {
      clientID: '21dd1c71-a432-4727-9e9d-82ef287eb99d',
      scopes: ['user.read'],
      persistLoginPastSession: true,
      popUp: false
    }
    this.app = new Msal.UserAgentApplication(
      this.applicationConfig.clientID,
      null,
      null
    )
  }
  login = () => {
    return this.app.loginPopup(this.applicationConfig.scopes).then(
      idToken => {
        const user = this.app.getUser()
        if (user) {
          return user
        } else {
          return null
        }
      },
      () => {
        return null
      }
    )
  }
  logout = () => {
    this.app.logout()
  }
  // getToken = () => {
  //   return this.app.acquireTokenSilent(this.applicationConfig.graphScopes).then(
  //     accessToken => {
  //       return accessToken
  //     },
  //     error => {
  //       return this.app
  //         .acquireTokenPopup(this.applicationConfig.graphScopes)
  //         .then(
  //           accessToken => {
  //             return accessToken
  //           },
  //           err => {
  //             console.error(err)
  //           }
  //         )
  //     }
  //   )
  // }
}
