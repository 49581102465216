import React, { Component } from "react";
// import ScrollableAnchor from "react-scrollable-anchor";
// import { configureAnchors } from "react-scrollable-anchor";
import "../../components/Nosotros/css/transformacion.css";
import LoadingScreen from "react-loading-screen";
import flecha from "../../assets/images/slider/arrow-blue.svg";
import close from "../../assets/logo/close.svg";
import ReactPlayer from "react-player";
import Scrollmenuours from "../Header/scrollmenuours";
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from "react-scroll";
import i18next from "i18next";
import NosotrosCultura from "./jsons/nosotros.cultura.json";
import initLang from "../../utils/initLang";
import SeguridadIMG from "./assets/valores/seguridad.png";
import IntegridadIMG from "./assets/valores/integridad.png";
import ConsistenciaIMG from "./assets/valores/consistencia.png";
import InnovacionIMG from "./assets/valores/innovacion.png";
import CalidezMexicanaIMG from "./assets/valores/calidezMexicana.png";
import Index from "../Footer";



var noScroll = require("no-scroll");

// Offset all anchors by -60 to account for a fixed header
// and scroll more quickly than the default 400ms
// configureAnchors({ offset: -100, scrollDuration: 200 });

export default class vision extends Component {
	constructor() {
		super();
		this.state = {
			modalIsShow: false,
			loading: true,
			error: null,
			entries: null,
			lang: ""
		};
		this.escFunction = this.escFunction.bind(this);
		this.setWrapperRef = this.setWrapperRef.bind(this);
	}

	componentDidMount() {
		initLang(this);
		document.addEventListener("keydown", this.escFunction, false);
		window.addEventListener("scroll", this.handleScroll);
		setTimeout(() => this.setState({ loading: false }), 1500);
		scroll.scrollToTop();
	
	}

	handleScroll = (event) => {
		const scrollTop = window.pageYOffset;
		if (scrollTop > 35) {
			this.setState({ hasScrolled: true });
		} else {
			this.setState({ hasScrolled: false });
		}
	};

	UNSAFE_componentWillMount() {
		document.addEventListener("mousedown", this.handleClick, false);
	}
	escFunction(event) {
		if (event.keyCode === 27) {
			this.setState({
				modalIsShow: false
			});
			noScroll.off();
		}
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.escFunction, false);
		document.removeEventListener("mousedown", this.handleClick, false);
	}
	handleClick = (e) => {
		if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
			this.setState({
				modalIsShow: false
			});
			noScroll.off();
		}
	};
	setWrapperRef(node) {
		this.wrapperRef = node;
	}

	showAlertModal = () => {
		this.setState({
			modalIsShow: !this.state.modalIsShow
		});
		noScroll.toggle();
	};

	render() {
		const { loading } = this.state;
		return (
			<div className="nosotrosPage" id="nuestra-vision">
				<Element name="test1" className="element">
					{/* <div id="inicio-nosotros"><div></div></div> */}
					<Scrollmenuours />

					{this.state.modalIsShow === true ? (
						<div className="flightVideoModal">
							<div className="videoModalWindow" ref={this.setWrapperRef}>
								<div className="closeModal" onClick={this.showAlertModal}>
									<img src={close}></img>
								</div>

								<ReactPlayer
									url="https://aeromexico-1.wistia.com/medias/xo59w2ssl1"
									playing="false"
									width="100%"
									height="100%"
									controls="true"
									volume=".1"
									played=".06"
								/>
							</div>
						</div>
					) : (
						""
					)}

					<div className="topOurPage text-white">
						<div className="padding">
							<div className="headerTitle">
								<div className="imgGpo-res"></div>
								<div className="textWrapperGpo">
									<p className="titleGpoAM">{i18next.t("Nosotros.gTitle")}</p>
									<p
										className="smallText"
										dangerouslySetInnerHTML={{ __html: i18next.t("Nosotros.somos") }}></p>
								</div>
								<div className="imgGpo"></div>
							</div>

							<div className="visionPageLeft">
								<div className="flex w-full items-center justify-between">
									<p className="titlevis">{i18next.t("Nosotros.vTitle")}</p>
									<div className="numberone"></div>
								</div>
								<p
									className="visionBody"
									dangerouslySetInnerHTML={{
										__html: i18next.t("Nosotros.vision")
									}}></p>
								<div className="mt-10 flex w-full items-center justify-between">
								{/* { alert(i18next.t("Nosotros.propoTitle")) } */}
									<p className="titlevis">{ i18next.t("Nosotros.propoTitle") }</p>
									{/* <div className="propositoIMG"></div> */}
								</div>
								<p
									className="visionBody"
									dangerouslySetInnerHTML={{
										__html: i18next.t("Nosotros.proposito")
									}}></p>
									
								<a
									href={i18next.t("Nosotros.propositoBtnUrl")}
									target="_blank"
									rel="noopener noreferrer"
									className="hover-text-white mt-2 block h-auto rounded-full bg-amRed px-4 py-2 text-center text-sm text-white hover:bg-red-600">
									{i18next.t("Nosotros.propositoBtn")}
								</a>
							</div>

							<div className="paddingRightVision">
								<p className="titlevis"> {i18next.t("Nosotros.valTitle")} </p>
								{/* <p
									className="visionBody"
									dangerouslySetInnerHTML={{
										__html: i18next.t("Nosotros.valores")
									}}></p> */}
								<div className="mt-4 grid grid-cols-1 gap-4 md:grid-cols-2">
									<div className="col-span-1 flex w-1/2 items-center justify-start">
										<img className="mr-2 h-10 w-10" src={SeguridadIMG} alt="Seguridad" />
										<p className="text-lg text-white">Seguridad</p>
									</div>
									<div className="col-span-1 flex w-1/2 items-center justify-start">
										<img className="mr-2 h-10 w-10" src={IntegridadIMG} alt="Integridad" />
										<p className="text-lg text-white">Integridad</p>
									</div>
									<div className="col-span-1 flex w-1/2 items-center justify-start">
										<img className="mr-2 h-10 w-10" src={ConsistenciaIMG} alt="Consistencia" />
										<p className="text-lg text-white">Consistencia</p>
									</div>
									<div className="col-span-1 flex w-1/2 items-center justify-start">
										<img className="mr-2 h-10 w-10" src={InnovacionIMG} alt="Innovacion" />
										<p className="text-lg text-white">Innovación</p>
									</div>
									<div className="col-span-1 flex w-1/2 items-center justify-start">
										<img
											className="mr-2 h-10 w-10"
											src={CalidezMexicanaIMG}
											alt="Calidez Mexicana"
										/>
										<p className="whitespace-nowrap text-lg text-white">Calidez Mexicana</p>
									</div>
								</div>
								<p
									className="titlevis comportamientosTitle"
									dangerouslySetInnerHTML={{
										__html: i18next.t("Nosotros.comporta")
									}}></p>
								<div className="comportamientosCX grid grid-cols-1 gap-x-0 gap-y-2 md:grid-cols-2 lg:grid-cols-3">
									<div className={"imgcomportamiento col-span-1 " + this.state.lang}></div>
									<div className={"imgcomportamiento col-span-1 " + this.state.lang}></div>
									<div className={"imgcomportamiento col-span-1 " + this.state.lang}></div>
									<div className={"imgcomportamiento col-span-1 " + this.state.lang}></div>
									<div className={"imgcomportamiento col-span-1 " + this.state.lang}></div>
									<div className={"imgcomportamiento col-span-1 " + this.state.lang}></div>
								</div>
							</div>
						</div>
					</div>
				</Element>

				<div className="tablewrapper bg-white text-[#020C41]">
					<div className="wrapperMidTable">
						<div className="wrapperFlex">
							<div className="fixedMenuNosotros">
								<div className="leftWrapperMenu">
									<ol>
										<li>
											<a href="#nuestravision"> {i18next.t("Nosotros.leftmenu.vis2")} </a>
										</li>
										<li>
											<a href="#nuestracultura">{i18next.t("Nosotros.leftmenu.cultura")}</a>
										</li>

										<ul>
											<li>
												<a href="#nuestrosvalores">{i18next.t("Nosotros.leftmenu.val2")}</a>
											</li>
											<li>
												<a href="#nuestroscomportamientos">
													{i18next.t("Nosotros.leftmenu.comp2")}
												</a>
											</li>
										</ul>

										<li>
											<a href="#subsidiariasgrupoaeromexico">
												{i18next.t("Nosotros.leftmenu.subsi")}
											</a>
										</li>
										<li>
											<a href="#skyteamalianza">{i18next.t("Nosotros.leftmenu.sky")}</a>
										</li>
										<li>
											<a href="#nuestrosaeropuertos">{i18next.t("Nosotros.leftmenu.aero")}</a>
										</li>
										<ul>
											<li>
												<a href="#ciudaddemexico">{i18next.t("Nosotros.leftmenu.hub")}</a>
											</li>
											<li>
												<a href="#extrasaeropuertos">
													{i18next.t("Nosotros.leftmenu.masaero")}
												</a>
											</li>
										</ul>

										<li>
											<a href="#flotadestinos">{i18next.t("Nosotros.leftmenu.destinos")}</a>
										</li>
									</ol>
								</div>
							</div>

							<div className="rightWrappermenu">
								{/* <div id="caballero">
                  <div className="titleNosotros">
                    <div className="circuloImage"></div>
                    <h3>Caballero Águila</h3>
                  </div>

                  <div className="visionFrase">
                    <div className="numberOne numberVision"></div>
                    <p className="cxTyping">Entre los aztecas había una organización militar en la que destacaban los Caballeros Águilas, que eran guerreros escogidos porque ya habían probado en la batalla su valentía, arrojo y heroismo. </p>
                    <p className="cxTyping"><strong>Con esta fuerza luchamos todos los días por ser la mejor aerolínea, socialmente responsable y que ofrezca el servicio que sus usuarios merecen.</strong></p>
                  </div>

                </div> */}

								{/* <Element name="test2" className="element">
									<div id="vision" className="sections">
										<div className="titleNosotros">
											<div className="circuloImage"></div>
											<h3> {i18next.t("Nosotros.vTitle")} </h3>
										</div>
										<p> {i18next.t("Nosotros.vision2")} </p>

										<div className="visionFrase">
											<div className="numberOne"></div>
											<p
												className="fraseVision"
												dangerouslySetInnerHTML={{
													__html: i18next.t("Nosotros.visionFrase")
												}}></p>
										</div>

										<button
											type="button"
											className="flex items-center justify-center rounded-full border-2 border-amRed bg-amRed px-4 py-3 text-xs font-bold text-white outline-none transition-all duration-200 hover:-translate-y-2"
											onClick={this.showAlertModal}>
											<span className="-mt-1">{i18next.t("Nosotros.vTitle")} </span>
											<img class="h-5 w-5" src={flecha} alt="blue-arrow" />
										</button>
									</div>
								</Element> */}

								<Element name="test3" className="element">
									<div id="nuestra-cultura">
										<div></div>
									</div>
									<div id="cultura">
										<div className="titleNosotros">
											<div className="circuloImage"></div>
											<h3>{i18next.t("Nosotros.cultuTitle")}</h3>
										</div>

										<p
											dangerouslySetInnerHTML={{
												__html: i18next.t("Nosotros.cultura1")
											}}></p>
										{/* <div className="imageCultura" src=""></div> */}

										{/* <p><Trans i18nKey="Nosotros.cultura2"></Trans></p> */}
										<br />
										<p
											dangerouslySetInnerHTML={{
												__html: i18next.t("Nosotros.cultura2")
											}}></p>
										{/* */}
										{/* <div id="valores"></div> */}
										{/* <h4>{i18next.t("Nosotros.valoresTitle")}</h4> */}

										{/* <div className="visionFrase">
											<div className="numberOne numberVision"></div>
											<p
												className="fraseVision frasevisionOK"
												dangerouslySetInnerHTML={{
													__html: i18next.t("Nosotros.valoresFrase")
												}}></p>
										</div> */}

										<p className="values"></p>
										{/* */}
										<div id="comportamientos"></div>
										<h4>{i18next.t("Nosotros.comp.title")}</h4>
										<div className="comportamientoWrapper seguridad-comp">
											<div className="leftComportaImage">
												<div className={"circuloCompo " + this.state.lang}></div>
											</div>
											<div className="textRightWrap">
												<h6 className="text-color-seguridad">
													{i18next.t("Nosotros.comp.seguridad.title")}
												</h6>
												<p
													className="mt-2"
													dangerouslySetInnerHTML={{
														__html: i18next.t("Nosotros.comp.seguridad.sub")
													}}
												/>

												{/* <h6 className="smallgray">{NosotrosCultura.condEsp}</h6>
												<div className="prose">
													<ul>
														{NosotrosCultura.seguridad.cond.map((item, index) => (
															<li
																key={`
																	${NosotrosCultura.seguridad.title}-
																	${index}
																`}
																dangerouslySetInnerHTML={{
																	__html: item
																}}
															/>
														))}
													</ul>
												</div> */}
											</div>
										</div>

										<div className="comportamientoWrapper integridad-comp">
											<div className="leftComportaImage">
												<div className={"circuloCompo " + this.state.lang}></div>
											</div>
											<div className="textRightWrap">
												<h6 className="text-color-vive">
													{i18next.t("Nosotros.comp.vive.title")}
												</h6>
												<p
													className="mt-2"
													dangerouslySetInnerHTML={{
														__html: i18next.t("Nosotros.comp.vive.sub")
													}}
												/>

												{/* <h6 className="smallgray">{NosotrosCultura.condEsp}</h6>
												<div className="prose">
													<ul>
														{NosotrosCultura.sirve.cond.map((item, index) => (
															<li
																key={`
																	${NosotrosCultura.sirve.title}-
																	${index}
																`}
																dangerouslySetInnerHTML={{
																	__html: item
																}}
															/>
														))}
													</ul>
												</div> */}
											</div>
										</div>

										<div className="comportamientoWrapper excelencia-comp">
											<div className="leftComportaImage">
												<div className={"circuloCompo " + this.state.lang}></div>
											</div>
											<div className="textRightWrap">
												<h6>{i18next.t("Nosotros.comp.sirve.title")}</h6>
												<p
													className="mt-2"
													dangerouslySetInnerHTML={{
														__html: i18next.t("Nosotros.comp.sirve.sub")
													}}
												/>

												{/* <h6 className="smallgray">{NosotrosCultura.condEsp}</h6>
												<div className="prose">
													<ul>
														{NosotrosCultura.vive.cond.map((item, index) => (
															<li
																key={`
																	${NosotrosCultura.vive.title}-
																	${index}
																`}
																dangerouslySetInnerHTML={{
																	__html: item
																}}
															/>
														))}
													</ul>
												</div> */}
											</div>
										</div>

										<div className="comportamientoWrapper lidera-comp new-circles-nosotros">
											<div className="leftComportaImage relative">
												<div className={"circuloCompo " + this.state.lang}></div>
											</div>
											<div className="textRightWrap">
												<h6 className="text-color-lidera">
													{i18next.t("Nosotros.comp.lidera.title")}
												</h6>
												<p
													className="mt-2"
													dangerouslySetInnerHTML={{
														__html: i18next.t("Nosotros.comp.lidera.sub")
													}}
												/>

												{/* <h6 className="smallgray">{NosotrosCultura.condEsp}</h6>
												<div className="prose">
													<ul>
														{NosotrosCultura.agil.cond.map((item, index) => (
															<li
																key={`
																	${NosotrosCultura.agil.title}-
																	${index}
																`}
																dangerouslySetInnerHTML={{
																	__html: item
																}}
															/>
														))}
													</ul>
												</div> */}
											</div>
										</div>

										<div className="comportamientoWrapper agil-comp new-circles-nosotros">
											<div className="leftComportaImage relative">
												<div className={"circuloCompo " + this.state.lang}></div>
											</div>
											<div className="textRightWrap">
												<h6 className="text-color-agil">
													{i18next.t("Nosotros.comp.agil.title")}
												</h6>
												<p
													className="mt-2"
													dangerouslySetInnerHTML={{
														__html: i18next.t("Nosotros.comp.agil.sub")
													}}
												/>

												{/* <h6 className="smallgray">{NosotrosCultura.condEsp}</h6>
												<div className="prose">
													<ul>
														{NosotrosCultura.fomenta.cond.map((item, index) => (
															<li
																key={`
																	${NosotrosCultura.fomenta.title}-
																	${index}
																`}
																dangerouslySetInnerHTML={{
																	__html: item
																}}
															/>
														))}
													</ul>
												</div> */}
											</div>
										</div>

										<div className="comportamientoWrapper disfruta-comp new-circles-nosotros">
											<div className="leftComportaImage relative">
												<div className={"circuloCompo " + this.state.lang}></div>
											</div>
											<div className="textRightWrap">
												<h6 className="text-purple-600">
													{i18next.t("Nosotros.comp.disfruta.title")}
												</h6>
												<p
													className="mt-2"
													dangerouslySetInnerHTML={{
														__html: i18next.t("Nosotros.comp.disfruta.sub")
													}}
												/>

												{/* <h6 className="smallgray">{NosotrosCultura.condEsp}</h6>
												<div className="prose">
													<ul>
														{NosotrosCultura.disfruta.cond.map((item, index) => (
															<li
																key={`
																	${NosotrosCultura.disfruta.title}-
																	${index}
																`}
																dangerouslySetInnerHTML={{
																	__html: item
																}}
															/>
														))}
													</ul>
												</div> */}
											</div>
										</div>
									</div>
								</Element>

								<Element name="test4" className="element" id="subsidiarias-aeromexico">
									<div id="subsidiarias-aeromexico">
										<div></div>
									</div>
									<div id="subsidiarias" className="sections">
										<div className="titleNosotros">
											<div className="circuloImage"></div>
											<h3>{i18next.t("Nosotros.subsi.title")}</h3>
										</div>

										<p>{i18next.t("Nosotros.subsi.sub")}</p>

										<div className="logoSubsidiarias">
											<div className="SubsidiariaCont">
												<div className="imgSub"></div>
												<p>{i18next.t("Nosotros.subsi.cargo")}</p>
											</div>

											<div className="SubsidiariaCont">
												<div className="imgSub"></div>
												<p>{i18next.t("Nosotros.subsi.conn")}</p>
											</div>

											<div className="SubsidiariaCont">
												<div className="imgSub"></div>
												<p>{i18next.t("Nosotros.subsi.forma")}</p>
											</div>

											<div className="SubsidiariaCont">
												<div className="imgSub"></div>
												<p>{i18next.t("Nosotros.subsi.serv")}</p>
											</div>

											<div className="SubsidiariaCont">
												<div className="imgSub"></div>
												<p>{i18next.t("Nosotros.subsi.prem")}</p>
											</div>

											<div className="SubsidiariaCont">
												<div className="imgSub"></div>
												<p>{i18next.t("Nosotros.subsi.csc")}</p>
											</div>
										</div>
									</div>
								</Element>

								{/* <div id="skyteam-alianza"><div></div></div>
            <Element name="test5" className="element" >
            <div id="skyteam" className="sections">
              <div className="titleNosotros">
                <div className="circuloImage"></div>
                <h3>{i18next.t('Nosotros.vTitle')}</h3>
              </div>
              <h4>S{i18next.t('Nosotros.vTitle')}</h4>
              <p>{i18next.t('Nosotros.vTitle')}</p>
              <div className="planesSkyteam"></div>
              <p>{i18next.t('Nosotros.vTitle')}
            <a className="link" href="https://www.aeromexico.com/es-mx/vuela-con-nosotros/otras-alianzas" target="_blank">{i18next.t('Nosotros.vTitle')}</a>
              </p>

              <div className="skyTeamImage"></div>
            </div>
            </Element> */}

								<Element name="test7" className="element" id="destinos-flota">
									<div id="destinos-flota">
										<div></div>
									</div>
									<div id="flota-destinos" className="sections">
										<div className="titleNosotros">
											<div className="circuloImage"></div>
											<h3>{i18next.t("Nosotros.destinos.title")}</h3>
										</div>
										<p>{i18next.t("Nosotros.destinos.sub")} </p>

										<div className="destinosFlota">
											<a
												className="gridDestinosFlota"
												href="https://www.aeromexico.com/es-mx/destinos"
												target="_blank"
												rel="noopener noreferrer">
												<div className="iconDestino"></div>
												<div className="tableDestino">
													<div className="tableCellDestino">
														<p>{i18next.t("Nosotros.destinos.dest")}</p>
														<a
															href="https://www.aeromexico.com/es-mx/destinos"
															target="_blank"
															className="!text-[#035cf7]"
															rel="noopener noreferrer">
															{i18next.t("Nosotros.conoceMas")}
														</a>
													</div>
												</div>
											</a>

											<a
												className="gridDestinosFlota"
												href="https://www.aeromexico.com/es-mx/vuela-con-nosotros/flota-aeromexico"
												target="_blank"
												rel="noopener noreferrer">
												<div className="iconDestino"></div>
												<div className="tableDestino">
													<div className="tableCellDestino">
														<p>{i18next.t("Nosotros.destinos.flota")}</p>
														<a
															href="https://www.aeromexico.com/es-mx/vuela-con-nosotros/flota-aeromexico"
															className="!text-[#035cf7]"
															target="_blank"
															rel="noopener noreferrer">
															{i18next.t("Nosotros.conoceMas")}
														</a>
													</div>
												</div>
											</a>
										</div>
									</div>
								</Element>

								<Element name="test6" className="element" id="nuestros-aeropuertos">
									<div id="nuestros-aeropuertos">
										<div></div>
									</div>
									<div id="aeropuertos" className="sections">
										<div className="titleNosotros">
											<div className="circuloImage"></div>
											<h3>{i18next.t("Nosotros.aero.title")}</h3>
										</div>

										<p>{i18next.t("Nosotros.aero.sub")}</p>

										{/* */}
										<div id="cdmx"></div>
										<h4>{i18next.t("Nosotros.aero.cdmx")}</h4>
										<p>{i18next.t("Nosotros.aero.cdmx1")}</p>

										<p>
											{i18next.t("Nosotros.aero.cdmx2")}
											<a
												className="link"
												href="https://www.aeromexico.com/es-mx/informacion-de-vuelos/aeropuertos/ciudad-de-mexico"
												target="_blank">
												{i18next.t("Nosotros.conoceMas")}
											</a>
										</p>

										<div className="imgCDMX"></div>

										{/* <div id="destinos"></div>
                  <h4>Más aeropuertos</h4>
                  <p>Conoce toda la información que necesitas acerca de la distribución, servicios y transporte.</p>
                  <div className="airportsGrid">
                    <a className="gridOption" href="https://www.aeromexico.com/es-mx/informacion-de-vuelos/aeropuertos/cancun" target="_blank">
                      <div className="gridText"><p>Cancún</p></div>
                    </a>

                    <a className="gridOption" href="https://www.aeromexico.com/es-mx/informacion-de-vuelos/aeropuertos/monterrey" target="_blank">
                      <div className="gridText"><p>Monterrey</p></div>
                    </a>

                    <a className="gridOption" href="https://www.aeromexico.com/es-mx/informacion-de-vuelos/aeropuertos/tijuana" target="_blank">
                      <div className="gridText"><p>Tijuana</p></div>
                    </a>

                    <a className="gridOption" href="https://www.aeromexico.com/es-mx/informacion-de-vuelos/aeropuertos/guadalajara" target="_blank">
                      <div className="gridText"><p>Guadalajara</p></div>

                    </a>
                  </div> */}
									</div>
								</Element>
							</div>
						</div>
					</div>
				</div>

				<div></div>
				
			</div>
		);
	}
}
