import React, { Component } from "react";
import Background from "../../../assets/compliance/linea-fondo@3x.png";
import EticaLogo from "../../../assets/compliance/linea-etica-logo.svg";
import i18next from 'i18next';

export default class ReplicaCompliance extends Component {
	state = {
		tabs: ["e-Learning", "Políticas", "Manuales", "Guías"],
		currentTab: 0,
		level: parseInt(localStorage.getItem("nivel")),
		language: "es",
		nivel: parseInt(localStorage.getItem("nivel")),
		loading: true
	};

	async UNSAFE_componentWillMount() {
		await fetch(`${process.env.REACT_APP_CAPI}/api/compliance`)
			.then(response => {
				return response.json();
			})
			.then(async json => {
				
				const temp = json.data[0];
				await this.setState({
					...this.state,
					loading: false,
					data: temp,
					benefits: json.benefits,
					ambits: json.ambits,
					learning: json.learning,
					politics: json.politics,
					manuals: json.manuals,
					guides: json.guides,
					eDescription:
						this.state.language === "es"
							? temp.our_program.ethics.description.es.split(", ")
							: temp.our_program.ethics.description.en.split(", ")
				});
				
			});
	}

	render() {
		const isSpanish = i18next.language === 'es';
		// if (!this.state.loading) {
		if(this.state.loading){
			return (
				<section
					className="line"
					style={{
						background: `url(${Background})`,
						marginBottom: "0px"
					}}
				>
					<div className="left-side">
						<img
							className="guerreroAguilaSm"
							src={EticaLogo}
							alt="Línea Ética"
						/>
						<h2>
							<i className="etica_title">
								{/* {this.state.language === "es"
									? this.state.data.our_program.ethics.title
											.es
									: this.state.data.our_program.ethics.title
											.en} */}
											{"Línea Ética Aeroméxico"}
							</i>
							<img
								className="guerreroAguila"
								src={EticaLogo}
								alt="Línea Ética"
							/>
						</h2>
						<p>
							<strong>MEX</strong>{" "}
							{"800 112 0585"}
							{/* {this.state.data.our_program.ethics.phone.mex} */}
						</p>
						<p>
							<strong>USA</strong>{" "}
							{"(00) 1 800 921 2240"}
							{/* {this.state.data.our_program.ethics.phone.usa} */}
						</p>
						<a
							href={
								"mailto:" +
								// (this.state.language === "es"
								// 	? this.state.data.our_program.ethics.email
								// 			.mex
								// 	: this.state.data.our_program.ethics.email
								// 			.usa)
								(this.state.language === "es"
									? "lineaeticaaeromexico@resguarda.com"
									: "lineaeticaaeromexico@resguarda.com"
								)
							}
						>
							{this.state.language==="es"
								? "lineaeticaaeromexico@resguarda.com"
								: "lineaeticaaeromexico@resguarda.com"}
							{/* {this.state.language === "es"
								? this.state.data.our_program.ethics.email.mex
								: this.state.data.our_program.ethics.email.usa} */}
						</a>
					</div>
					<div className="right-side">
						<p style={{ fontSize: "18px" }}>
							<strong>
								{isSpanish?"O puedes levantar un reporte anónimo si te":"Or you can file an anonymous report if you"}
								<br />
								{isSpanish?"encuentras en alguna de estas situaciones:":"find yourself in any of these situations:"}
							</strong>
						</p>
						<ul>
						 
							{/* {this.state.eDescription.map(item => {
								return <li>{item}</li>;
							})} */}
							<li>Actos de corrupción</li>
							<li>Sobornos</li>
							<li>Discriminación</li>
							<li>Acoso</li>
							<li>Negligencias</li>
							<li>Violencia Laboral</li>
						</ul>
						<a
							className="fake-pink-button mt-2 bg-[#FA0073]"
							// href="https://www.tipsanonimos.com/aeromexico/View/Home/"
							href="https://etica.resguarda.com/aeromexico/es/main.html"
							target="_blank"
							rel="noopener noreferrer"
						>
						{/* Corregir esta parte de abajo pertenecientes a botones para ingles español */}
							{i18next.language === "es" 
								? "DENUNCIA CONFIDENCIAL"
								: "CONFIDENTIAL COMPLAINT"}
						</a>
					</div>
				</section>
			);
		} else {
			return <h1>CARGANDO</h1>;
		}
	}
}
