import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import i18next from "i18next";

export default class cxLive extends Component {
  render() {
    return (
     <div className="titleCXEvolution">
        <h2>{i18next.t('ClienteEx.logros')}</h2>
        <Link to="/experiencia-cliente/logros" className="viewAllAchie">{i18next.t('ClienteEx.verLogros')}</Link>
      </div>
    );
  }
}
