import React, { Component } from "react";
import { Link } from "react-router-dom";
import arrow from "../../../assets/images/slider/arrow-blue.svg";

export default class ItemSearch extends Component {
  getName = data => {
    let contentType = data.sys.contentType.sys.id;
    switch (contentType) {
      case "headerArticlePage":
        return data.fields.firstArticleTitle;
      case "workTools":
        return data.fields.nameTool;
      case "articleClass":
        return data.fields.articleTittle;
      case "articleSlider":
        return data.fields.articleTittle;
      case "stateOfAirline":
        return data.fields.introANps;
      case "myDiscountsFeatured":
        return data.fields.myDiscountsTitle;
      case "myDiscounts":
        return data.fields.myDiscountsTitle;
      case "myBenefits":
        return data.fields.myBenefitTitle;
      case "achievements":
        return data.fields.titleAchievement;
      case "campaigns":
        return data.fields.titleCampaign;
      case "cXevolution":
        return data.fields.titleCampaign;
      case "fastLinksHome":
        return data.fields.imageText;
      case "homeNotification":
        return data.fields.notifyType;
      case "myBenetifsFeatured":
        return data.fields.myBenefitTitle;
      case "newsCategory":
        return data.fields.category;
      case "paginasVacantes":
        return data.fields.titlePage;
      case "seguridad":
        return data.fields.titlePageSeg;
      case "myTotalRewards":
        return data.fields.titlePageTotal;
      case "paginasNosotros":
        return data.fields.titlePageTotal;
      case "paginasLogoIdentidad":
        return data.fields.tituloLogos;
      case "triquesTruques":
            return data.fields.titleTrique;
      case "oportunidadesDeCarrera":
        return data.fields.nombreVacante;
      default:
        return "";
    }
  };

  renderLink = data => {
    if (data.sys.contentType.sys.id === "stateOfAirline") {
      return (
        <Link
          className="item-result-link"
          to="/experiencia-cliente/net-promoter-score"
        >
          /experiencia-cliente/net-promoter-score
        </Link>
      );
    }
    // if (data.sys.contentType.sys.id === "customerExperienceSite") {
    //   return (
    //     <Link
    //       className="item-result-link"
    //       to="/experiencia-cliente/net-promoter-score"
    //     >
    //       /experiencia-cliente/net-promoter-score
    //     </Link>
    //   );
    // }
    // if (data.sys.contentType.sys.id === "seguridad") {
    //   return (
    //     <Link className="item-result-link" to="/seguridad">
    //       /seguridad
    //     </Link>
    //   );
    // }

    // if (data.sys.contentType.sys.id === "myTotalRewards") {
    //   return (
    //     <Link className="item-result-link" to="/recompensa-total/perfil">
    //       /recompensa-total/perfil
    //     </Link>
    //   );
    // }

    // if (data.sys.contentType.sys.id === "paginasNosotros") {
    //   return (
    //     <Link className="item-result-link" to="/nosotros">
    //       /nosotros
    //     </Link>
    //   );
    // }

    // if (data.sys.contentType.sys.id === "paginasLogoIdentidad") {
    //   return (
    //     <Link className="item-result-link" to="/nosotros/logos-templates">
    //       /nosotros
    //     </Link>
    //   );
    // }

    // if (data.sys.contentType.sys.id === "paginasVacantes") {
    //   return (
    //     <Link className="item-result-link" to="/recompensa-total/oportunidades-carrera">
    //       /recompensa-total/oportunidades-carrera
    //     </Link>
    //   );
    // }


    if (data.sys.contentType.sys.id === "articleClass") {
      return (
        <Link className="item-result-link" to={"/articles/" + data.sys.id}>
          {"/articles/" + data.sys.id}
        </Link>
      );
    }

    if (data.sys.contentType.sys.id === "articleSlider") {
      return (
        <Link
          className="item-result-link"
          to={"/articles/slider/" + data.sys.id}
        >
          {"/articles/slider/" + data.sys.id}
        </Link>
      );
    }

    // if (data.sys.contentType.sys.id === "workTools") {
    //   return (
    //     <a
    //       className="item-result-link"
    //       target="_blank"
    //       href={data.fields.linkTool}
    //     >
    //       {data.fields.linkTool}
    //     </a>
    //   );
    // }

    if (data.sys.contentType.sys.id === "myDiscounts") {
      return (
        <Link className="item-result-link" to={"/recompensa-total/perfil/mis-beneficios"}>
          /recompensa-total/perfil/mis-beneficios
        </Link>
      );
    }

    if (
      data.sys.contentType.sys.id === "myBenefits" &&
      data.fields.myBenefitCategoryClass === "lovedBenefits"
    ) {
      return (
        <Link
          className="item-result-link"
          to={"/recompensa-total/perfil/mis-beneficios/cuida-de-ti"}
        >
          {"/recompensa-total/perfil/mis-beneficios/cuida-de-ti"}
        </Link>
      );
    }
    if (
      data.sys.contentType.sys.id === "myBenefits" &&
      data.fields.myBenefitCategoryClass === "enjoyBenefits"
    ) {
      return (
        <Link
          className="item-result-link"
          to={"/recompensa-total/perfil/mis-beneficios/disfruta-tu-trabajo"}
        >
          {"/recompensa-total/perfil/mis-beneficios/disfruta-tu-trabajo"}
        </Link>
      );
    }

    if (
      data.sys.contentType.sys.id === "myBenefits" &&
      data.fields.myBenefitCategoryClass === "financeBenefits"
    ) {
      return (
        <Link
          className="item-result-link"
          to={"/recompensa-total/perfil/mis-beneficios/administra-tus-finanzas"}
        >
          {"/recompensa-total/perfil/mis-beneficios/administra-tus-finanzas"}
        </Link>
      );
    }

    if (
      data.sys.contentType.sys.id === "myBenefits" &&
      data.fields.myBenefitCategoryClass === "aeromexicoBenefits"
    ) {
      return (
        <Link
          className="item-result-link"
          to={"/recompensa-total/perfil/mis-beneficios/vive-aeromexico"}
        >
          {"/recompensa-total/perfil/mis-beneficios/vive-aeromexico"}
        </Link>
      );
    }

    if (data.sys.contentType.sys.id === "myDiscountsFeatured") {
      return (
        <Link
          className="item-result-link"
          to={"/recompensa-total/mis-beneficios/descuentos/"}
        >
          /recompensa-total/mis-beneficios/descuentos
        </Link>
      );
    }

    if (data.sys.contentType.sys.id === "triquesTruques") {
      return (
        <Link
          className="item-result-link"
          to={"/buy-and-sell"}
        >
          /buy-and-sell
        </Link>
      );
    }

    if (data.sys.contentType.sys.id === "oportunidadesDeCarrera") {
      return (
        <Link
          className="item-result-link"
          to={"/recompensa-total/oportunidades-carrera"}
        >
          /recompensa-total/oportunidades-carrera
        </Link>
      );
    }
  };

  renderLink2 = data => {
    if (data.sys.contentType.sys.id === "stateOfAirline") {
      return (
        <Link
          className="item-result-link"
          to="/experiencia-cliente/net-promoter-score"
        >
          <img className="blue-arrow-search" src={arrow} alt="arro" />
          <span>Ir al sitio</span>
        </Link>
      );
    }
    // if (data.sys.contentType.sys.id === "customerExperienceSite") {
    //   return (
    //     <Link
    //       className="item-result-link"
    //       to="/experiencia-cliente/net-promoter-score"
    //     >
    //       <img className="blue-arrow-search" src={arrow} alt="arro" />
    //       <span>Ir al sitio</span>
    //     </Link>
    //   );
    // }
    // if (data.sys.contentType.sys.id === "seguridad") {
    //   return (
    //     <Link className="item-result-link" to="/seguridad">
    //       <img className="blue-arrow-search" src={arrow} alt="arro" />
    //       <span>Ir al sitio</span>
    //     </Link>
    //   );
    // }

    // if (data.sys.contentType.sys.id === "paginasVacantes") {
    //   return (
    //     <Link className="item-result-link" to="/recompensa-total/oportunidades-carrera">
    //       <img className="blue-arrow-search" src={arrow} alt="arro" />
    //       <span>Ir al sitio</span>
    //     </Link>
    //   );
    // }

    // if (data.sys.contentType.sys.id === "myTotalRewards") {
    //   return (
    //     <Link className="item-result-link" to="/recompensa-total/perfil">
    //       <img className="blue-arrow-search" src={arrow} alt="arro" />
    //       <span>Ir al sitio</span>
    //     </Link>
    //   );
    // }

    // if (data.sys.contentType.sys.id === "paginasNosotros") {
    //   return (
    //     <Link className="item-result-link" to="/nosotros">
    //       <img className="blue-arrow-search" src={arrow} alt="arro" />
    //       <span>Ir al sitio</span>
    //     </Link>
    //   );
    // }

    // if (data.sys.contentType.sys.id === "paginasLogoIdentidad") {
    //   return (
    //     <Link className="item-result-link" to="/nosotros/logos-templates">
    //       <img className="blue-arrow-search" src={arrow} alt="arro" />
    //       <span>Ir al sitio</span>
    //     </Link>
    //   );
    // }

    if (data.sys.contentType.sys.id === "articleClass") {
      return (
        <Link className="item-result-link" to={"/articles/" + data.sys.id}>
          <img className="blue-arrow-search" src={arrow} alt="arro" />
          <span>Ir al sitio</span>
        </Link>
      );
    }

    if (data.sys.contentType.sys.id === "articleSlider") {
      return (
        <Link
          className="item-result-link"
          to={"/articles/slider/" + data.sys.id}
        >
          <img className="blue-arrow-search" src={arrow} alt="arro" />
          <span>Ir al sitio</span>
        </Link>
      );
    }

    // if (data.sys.contentType.sys.id === "workTools") {
    //   return (
    //     <a
    //       className="item-result-link"
    //       target="_blank"
    //       href={data.fields.linkTool}
    //     >
    //       <img className="blue-arrow-search" src={arrow} alt="arro" />
    //       <span>Ir al sitio</span>
    //     </a>
    //   );
    // }

    if (data.sys.contentType.sys.id === "myDiscounts") {
      return (
        <Link className="item-result-link" to={"/recompensa-total/perfil/mis-beneficios"}>
          <img className="blue-arrow-search" src={arrow} alt="arro" />
          <span>Ir al sitio</span>
        </Link>
      );
    }

    if (
      data.sys.contentType.sys.id === "myBenefits" &&
      data.fields.myBenefitCategoryClass === "lovedBenefits"
    ) {
      return (
        <Link
          className="item-result-link"
          to={"/recompensa-total/perfil/mis-beneficios/cuida-de-ti"}
        >
          <img className="blue-arrow-search" src={arrow} alt="arro" />
          <span>Ir al sitio</span>
        </Link>
      );
    }
    if (
      data.sys.contentType.sys.id === "myBenefits" &&
      data.fields.myBenefitCategoryClass === "enjoyBenefits"
    ) {
      return (
        <Link
          className="item-result-link"
          to={"/recompensa-total/perfil/mis-beneficios/disfruta-tu-trabajo"}
        >
          <img className="blue-arrow-search" src={arrow} alt="arro" />
          <span>Ir al sitio</span>
        </Link>
      );
    }
    if (
      data.sys.contentType.sys.id === "myBenefits" &&
      data.fields.myBenefitCategoryClass === "financeBenefits"
    ) {
      return (
        <Link
          className="item-result-link"
          to={"/recompensa-total/perfil/mis-beneficios/administra-tus-finanzas"}
        >
          <img className="blue-arrow-search" src={arrow} alt="arro" />
          <span>Ir al sitio</span>
        </Link>
      );
    }
    if (
      data.sys.contentType.sys.id === "myBenefits" &&
      data.fields.myBenefitCategoryClass === "aeromexicoBenefits"
    ) {
      return (
        <Link
          className="item-result-link"
          to={"/recompensa-total/perfil/mis-beneficios/vive-aeromexico"}
        >
          <img className="blue-arrow-search" src={arrow} alt="arro" />
          <span>Ir al sitio</span>
        </Link>
      );
    }
    if (data.sys.contentType.sys.id === "myDiscountsFeatured") {
      return (
        <Link
          className="item-result-link"
          to={
            "/recompensa-total/mis-beneficios/descuentos/" +
            data.fields.myDiscountsClass
          }
        >
          <img className="blue-arrow-search" src={arrow} alt="arro" />
          <span>Ir al sitio</span>
        </Link>
      );
    }

    if (data.sys.contentType.sys.id === "triquesTruques") {
      return (
        <Link
          className="item-result-link"
          to={"/buy-and-sell"}
        >
          <img className="blue-arrow-search" src={arrow} alt="arro" />
          <span>Ir al sitio</span>
        </Link>
      );
    }

    if (data.sys.contentType.sys.id === "oportunidadesDeCarrera") {
      return (
        <Link
          className="item-result-link"
          to={"/recompensa-total/oportunidades-carrera"}
        >
          <img className="blue-arrow-search" src={arrow} alt="arro" />
          <span>Ir al sitio</span>
        </Link>
      );
    }
  };

  render() {
    const { data } = this.props;
    return (
      <li className="item-search-result">
        <div className="item-result-wrapper">
          <p className="item-result-title">{this.getName(data)}</p>
          {this.renderLink(data)}
        </div>
        <div className="visit-site-wrapper">{this.renderLink2(data)}</div>
      </li>
    );
  }
}
