import React from 'react';
import FakeTableRowSmallFC from './FakeTableRowSmallFC';

const FakeTableSmallerFC = ({ data, even = false, isGreen = true }) => {
	const tableClass = isGreen
		? 'fake-table-element-green'
		: 'fake-table-element-red';

	return (
		<div
			className={`${tableClass} ${
				even ? 'even-table' : 'odd-table'
			} m-0 grid h-auto w-full grid-cols-1 gap-0 overflow-hidden rounded-md bg-white shadow-md`}
			style={{
				color: '#999999'
			}}
		>
			{data.elementos.map((el, index) => el.cifra > 0 ? (
				<FakeTableRowSmallFC
					key={el.titulo + index}
					anual={el.cifra}
					border={index < data.elementos.length - 1}
					color={el.color}
					icon={el.icon}
					title={el.titulo}
					sub={el.sub}
				/>
			):null)}
		</div>
	);
};

export default FakeTableSmallerFC;
