import React from 'react';
import CurrencyFormat from 'react-currency-format';

const FakeTableRowSmallFC = ({
	anual,
	border = true,
	color,
	icon,
	title,
	sub
}) => {
	return (
		<div
			className={`fake-row-element group relative relative grid w-full grid-cols-3 gap-0 ${
				border ? 'border-b' : ''
			}`}
			style={{ borderColor: '#E3E3E3', height: 60 }}
		>
			{!!color ? (
				<span
					className="absolute left-0 top-0 h-full"
					style={{
						background: color,
						width: 5
					}}
				/>
			) : null}
			<div
				className="justify-left col-span-2 flex items-center border-r px-4"
				style={{ borderColor: '#E3E3E3' }}
			>
				{icon ? (
					<img
						src={icon}
						className="mr-2"
						alt={title}
						style={{ height: 25, width: 25, opacity: 0.5 }}
					/>
				) : null}
				<div className="items-left flex flex-col justify-center">
					<p className="text-sm group-hover:text-amRegularBlue">
						{title}
					</p>
					{!!sub ? <p className="text-xs">{sub}</p> : null}
				</div>
			</div>
			<div className="col-span-1 flex flex-col items-start justify-center px-4">
				<CurrencyFormat
					decimalSeparator="."
					decimalScale="2"
					fixedDecimalScale={true}
					value={anual}
					displayType={'text'}
					thousandSeparator=","
					prefix="$"
					renderText={(value) => (
						<p className="text-sm font-semibold text-amBlack group-hover:text-amRegularBlue">
							{value}
						</p>
					)}
				/>
			</div>
		</div>
	);
};

export default FakeTableRowSmallFC;
