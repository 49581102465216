export default function initLang(component) {
	//var lang = i18next.language;
	var lang = localStorage.getItem('i18nextLng');
	if (lang === 'es-US' || lang === 'undefined' || lang === null) {
		lang = 'es';
		localStorage.setItem('i18nextLng', lang);
	}
	
	component.setState({
		lang: lang
	});
}
