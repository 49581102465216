import React, { Component } from 'react'; 

import * as contentful from '../../lib/contentful'; 
import FeaturedCard from './BenefitDiscount/BenefitCard';

import './css/footerProfile.css';
import './css/modalCard.css';
import '../Slider/css/swiper.css';
import getLocale from '../../utils/getLocale';
import Loader from '../Loader/Loader';

export default class FooterProfile extends Component {
	constructor() {
		super();
		this.state = {
			loading: true,
			error: null,
			entries: null,
			params: {
				slidesPerView: 2,
				navigation: {
					nextEl: '.swiper-button-next.swiper-button',
					prevEl: '.swiper-button-prev.swiper-button'
				},
				spaceBetween: 30
			}
		};
	}
	componentDidMount() {
		this.fetchEntries();
	}
	fetchEntries = () => {
		contentful.client
			.getEntries({
				content_type: 'myDiscounts',
				include: 2,
				order: '-sys.updatedAt',
				locale: getLocale()
			})
			.then(response => response.items)
			.then(data => {
				this.setState({
					loading: false,
					entries: data
				});
			})
			.catch(error => {
				this.setState({
					loading: false,
					error: error
				});
			})
			.catch(err => console.error(err));
	};
	render() {
		return (
			<React.Fragment>
				{this.state.loading && <Loader />}
				{!this.state.loading && (
					<div className="featuredSwipper">
						<div className="featuredSwipperMin">
							<div className="featuredSwipperHolder">
								{this.state.entries &&
									this.state.entries.map(
										(discounts, index) => {
											if (discounts.fields.forMexico) {
												return (
													<FeaturedCard
														key={index}
														data={discounts}
													/>
												);
											}
										}
									)}
							</div>
						</div>
					</div>
				)}
			</React.Fragment>
		);
	}
}
