import React, { Component } from "react";
import "./css/triques.css";
import * as contentful from "../../lib/contentful";
import Modal from "react-awesome-modal";
import TriquesContenful from "./triquesContenful";
import getLocale from "../../utils/getLocale";


export default class SliderSwiper extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			error: null,
			entries: null,
			visible: false
		};
	}

	openModal() {
		this.setState({
			visible: true
		});
	}

	closeModal() {
		this.setState({
			visible: false
		});
	}

	componentDidMount() {
		this.fetchEntries();
	}

	UNSAFE_componentWillMount() {
		document.addEventListener("mousedown", this.handleClick, false);
	}
	escFunction(event) {
		if (event.keyCode === 27) {
			this.setState({
				visible: false
			});
		}
	}

	fetchEntries = () => {
		contentful.client
			.getEntries({
				content_type: "triquesTruques",
				include: 2,
				order: "-sys.updatedAt",
				locale: getLocale()
			})
			.then(response => response.items)
			.then(data => {
					this.setState({
					loading: false,
					entries: data
				});
			})
			.catch(error => {
				this.setState({
					loading: false,
					error: error
				});
			})
			.catch(err => console.error(err));
	};

	render() {
		const { category } = this.props;
		const { loading, entries } = this.state; 

		/* console.log(entries); */
		return (
			<div>
				<div
					className="midWrap midWrapTriques triquesautosDisplay"
					style={{ overflow: "visible" }}
				>
					{
					 category !== 'todos' ? 
					 !loading &&
						entries &&
						entries.filter(item=>item.fields.categoriaTrique === category).map(triques => (
							<TriquesContenful category={this.props.category} data={triques} />
						)) 
						:
						!loading &&
						entries &&
						entries.map(triques => (
							<TriquesContenful category={this.props.category} data={triques} />
						)) 	
					}
				</div>
			</div>
		);
	}
}
