import React, { Component } from 'react';
import i18next from 'i18next';
import isBefore from 'date-fns/isBefore';
import Header from '../Header';
import Footer from '../Footer';
import AmbotComponent from '../AMbot/AmbotComponent';
import { ReactComponent as Warning } from '../../assets/travel/important.svg';
import './newsbanner.style.css';
import { Link } from 'react-router-dom';
import { isLocal, isFromDev, isFromQA } from './../../core/Ambientes';
import { HeartIcon } from '@heroicons/react/outline';


// # Variables que switchean las ligas que se consumen segun sea elcaso
// const isLocal = true//process.env.REACT_APP_ISLOCAL; // Tomara entorno local
// const isFromDev = process.env.REACT_APP_ISFROMDEV; //Se pondra en true para ambiente DEV
// const isFromQA = process.env.REACT_APP_ISFROMQA; //Se pondra en true para ambiente QA

const urlLocal = `${process.env.REACT_APP_LOCAL}`;
const urlDev = `${process.env.REACT_APP_CADMIN_DEV}`;
const urlQA = `${process.env.REACT_APP_CADMIN_QA}`
const urlProd = `${process.env.REACT_APP_CADMIN_PROD}`

const tokenLocal = `${process.env.REACT_APP_TOKEN_LOCAL}`;
const tokenDev   = `${process.env.REACT_APP_CADMIN_DEV_TOKEN}`;
const tokenQA 	 = `${process.env.REACT_APP_CADMIN_QA_TOKEN}`;
const tokenProd  = `${process.env.REACT_APP_API_TOKEN}`

// # Liga validada
const urlValid = isLocal === true ? urlLocal 
	: isFromDev === true? urlDev
	: isFromQA ===true ? urlQA
	: urlProd

// Se genera el token segun el ambiente
const tokenValid = isLocal ? tokenLocal
	: isFromDev ? tokenDev
	: isFromQA  ? tokenQA
	: tokenProd

export default class Layout extends Component {
	state = {
		language: i18next.language,
		today: new Date(),
		sub_alert: {
			validUntil: new Date(
				i18next.t('Cintillo.fecha.year'),
				i18next.t('Cintillo.fecha.month'),
				i18next.t('Cintillo.fecha.day'),
				i18next.t('Cintillo.fecha.hour'),
				i18next.t('Cintillo.fecha.minute')
			),
			show: false
		},
		main_alert: {
			show: false,
			title: '',
			message: ''
		}
	};
	componentDidMount() {
		if (isBefore(this.state.today, this.state.sub_alert.validUntil)) {
			this.setState({
				...this.state,
				sub_alert: {
					...this.state.sub_alert,
					show: true
				}
			});
		}
		const cabecera = new Headers({
			'Content-type': 'application/json',
			'Authorization': `Bearer ${tokenValid}`
		});
		fetch(urlValid+`traduccion-${i18next.language}`, { headers: cabecera })
			.then(res => res.json())
			.then(res => {
				
				if (res.status === 200) {
					
					this.setState({
						main_alert: {
							show:   res.data.attributes.data.MyHR.toplevel_alert.show,
							message:res.data.attributes.data.MyHR.toplevel_alert.message,
							title:  res.data.attributes.data.MyHR.toplevel_alert.title
						}
					});
				}
			});
	}
	render() {
		return (
			<div className="Layout">
				{/* {window.location.pathname!=="/defendamos-nuestro-cielo" && window.location.pathname!=="/lets-defend-our-sky" ?  */}
				<Header />
				{/* : ""} */}
				<main className="relative" style={{ marginTop: '60px' }}>
					{/* {console.log(this.props)} */}
					{this.props.children}
					{this.props.location.pathname !== '/inicio' &&
					this.state.sub_alert.show ? (
						<NewsBanner
							message={this.state.sub_alert.message}
							message2={this.state.sub_alert.message2}
						/>
					) : null}
					</main>

				{/* {window.location.pathname!=="/defendamos-nuestro-cielo" && window.location.pathname!=="/lets-defend-our-sky" ?  */}
				<AmbotComponent />
				<Footer />
				{/* MAIN ALERT TOP LEVEL */}
				{this.state.main_alert.show && (
					<div
						className="fixed left-0 w-full bg-amYellow h-16 z-40 top-level-alert flex items-center justify-center"
						style={{ top: '60px' }}
					>
						<Warning className="h-6 w-6 text-amBlack fill-current mr-2" />
						<p className="text-amBlack text-left">
							<strong>{this.state.main_alert.title}:</strong>{' '}
							{this.state.main_alert.message}
						</p>
					</div>
				)}
				{/* : ""} */}
			</div>
		);
	}
}

const NewsBanner = ({ message, message2 }) => {
	return (
		<div className="news-banner-sticky sticky left-0 bottom-0 z-50 h-16 w-screen overflow-x-hidden">
			<div className="absolute left-0 top-0 z-10 flex h-full items-center justify-center bg-amRed pl-4 pr-0 font-bold">
				<span className="z-20 uppercase text-white">Avisos AM :</span>
				<span className="top-1/2 right-0 z-10 h-full w-8 translate-x-4 -skew-x-12 transform bg-amRed"></span>
			</div>
			<div className="absolute left-0 top-0 z-0 h-full w-full bg-amYellow">
				<p
					className="text-news-banner absolute left-0 z-0 m-0 flex h-full w-auto -translate-y-10 items-center whitespace-nowrap px-4 text-left text-lg font-bold"
					style={{
						animationDuration: '28s'
					}}
				>
					{i18next.t('Cintillo.mensaje')}
					<Link
						to={i18next.t('Cintillo.link')}
						className="ml-2 rounded-full bg-amRed px-4 py-1 text-xs text-white"
					>
						{i18next.t('Cintillo.linkText')}
					</Link>
				</p>
			</div>
		</div>
	);
};