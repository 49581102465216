import React, { Component } from "react";
import "../flightplan/css/flightplan.css";
import LoadingScreen from "react-loading-screen";
import avion from "../../assets/images/Spinner/avion.gif";
import close from "../../assets/logo/close.svg";
import { Link } from "react-router-dom";
import Modal from "react-awesome-modal";

var noScroll = require("no-scroll");

export default class vision extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modalIsShow: false,
			modalIsShowIdiomas: false,
			loading: true,
			error: null,
			entries: null,
			visible: false
		};

		this.escFunction = this.escFunction.bind(this);
		this.setWrapperRef = this.setWrapperRef.bind(this);
	}

	componentDidMount() {
		setTimeout(() => this.setState({ loading: false }), 1500);
		document.addEventListener("keydown", this.escFunction, false);
	}

	UNSAFE_componentWillMount() {
		document.addEventListener("mousedown", this.handleClick, false);
	}
	escFunction(event) {
		if (event.keyCode === 27) {
			this.setState({
				modalIsShow: false,
				modalIsShowIdiomas: false,
				visible: false
			});
		}
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.escFunction, false);
		document.removeEventListener("mousedown", this.handleClick, false);
	}
	handleClick = e => {
		if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
			this.setState({
				modalIsShow: false,
				modalIsShowIdiomas: false
			});
		}
	};
	setWrapperRef(node) {
		this.wrapperRef = node;
	}

	showAlertModal = () => {
		this.setState({
			modalIsShow: !this.state.modalIsShow
		});
	};

	showAlertModal01 = () => {
		this.setState({
			modalIsShowIdiomas: !this.state.modalIsShowIdiomas
		});
	};

	openModal() {
		this.setState({
			visible: true
		});
		noScroll.on();
	}

	closeModal() {
		this.setState({
			visible: false
		});
		noScroll.off();
	}

	render() {
		const { loading } = this.state;
		return (
			<LoadingScreen
				loading={loading}
				bgColor="#f5f5f5"
				spinnerColor="#007dc3"
				textColor="#0b2343"
				//logoSrc={avion}
				text="Chargement de votre expérience"
			>
				<div>
					{this.state.modalIsShow === true ? (
						<div className="flightVideoModal">
							<div
								className="videoModalWindow"
								ref={this.setWrapperRef}
							>
								<div
									className="closeModal"
									onClick={this.showAlertModal}
								>
									<img src={close} alt="cerrar"></img>
								</div>
								<iframe
									width="100%"
									height="100%"
									src="https://www.youtube.com/embed/5mt3FdPdUT0"
									frameborder="0"
									allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
									allowfullscreen
								></iframe>
							</div>
						</div>
					) : (
						""
					)}

					<Modal
						visible={this.state.visible}
						width="auto"
						height="auto"
						effect="fadeInDown"
						onClickAway={() => this.closeModal()}
						className="container-modal-notification"
					>
						<div className="selectLangugeModal">
							<div className="whiteModal">
								<div
									className="closeModal"
									onClick={() => this.closeModal()}
								>
									<img src={close} alt="cerrar"></img>
								</div>

								<div className="padding">
									<h4>Selecciona un idioma</h4>
									<div className="gridFlags">
										<Link
											className="contFlag"
											to="/nosotros/plan-de-vuelo"
										>
											<div className="flags spanish"></div>
											<p>Español</p>
										</Link>

										<Link
											className="contFlag"
											to="/nosotros/plan-de-vuelo/ingles"
										>
											<div className="flags english"></div>
											<p>Inglés</p>
										</Link>

										<Link
											className="contFlag"
											to="/nosotros/plan-de-vuelo/frances"
										>
											<div className="flags french"></div>
											<p>Francés</p>
										</Link>

										<Link
											className="contFlag"
											to="/nosotros/plan-de-vuelo/holandes"
										>
											<div className="flags dutch"></div>
											<p>Holandés</p>
										</Link>

										<Link
											className="contFlag"
											to="/nosotros/plan-de-vuelo/portugues"
										>
											<div className="flags portuguese"></div>
											<p>Portugués</p>
										</Link>

										<Link
											className="contFlag"
											to="/nosotros/plan-de-vuelo/chino"
										>
											<div className="flags mandarin"></div>
											<p>Mandarín</p>
										</Link>

										<Link
											className="contFlag"
											to="/nosotros/plan-de-vuelo/coreano"
										>
											<div className="flags korean"></div>
											<p>Coreano</p>
										</Link>

										<Link
											className="contFlag"
											to="/nosotros/plan-de-vuelo/japones"
										>
											<div className="flags japan"></div>
											<p>Japonés</p>
										</Link>
									</div>
								</div>
							</div>
						</div>
					</Modal>

					<div className="flightplanWrapper">
						<div className="midflightplan">
							<h1>
								Plan de Vol <br></br>2019
							</h1>

							<a
								className="download"
								href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EdBg9i_By-FKqjS9y8IFs-ABd5gBhfCHyevBQ90C6LMjKw?e=3FlUmL"
								rel="noopener noreferrer"
							>
								<p>Télécharger Plan de Vol</p>
							</a>

							<div className="linenamebottom"></div>

							{/*<div className="downloadFlightPlan">
                <div className="downloadIcon"></div>
                <div className="textdownload">
                  <h6>Plan de Vuelo 2019</h6>
                  <p>Descarga el Plan de Vuelo completo </p>
                </div>
          </div>*/}

							<div
								className="languageFlightPlan"
								onClick={() => this.openModal()}
							>
								<p>Francais</p>
								<div className="languageFlag francia"></div>
							</div>

							<div className="circlePlaneFlight">
								<h4 className="title color01">Sécurité</h4>
								<p className="securityFirst">
									<span></span>Mettre la sécurité en premier
									plan, toujours
								</p>

								<h4 className="title color02">
									Expérience des employés
								</h4>
								<p className="employeeExperience">
									<span></span>Vivre nos valeurs
									etcomportements avec intégrité
								</p>

								<h4 className="title color03">
									Expérience du client
								</h4>
								<p className="cutomerExperience">
									<span></span>Offrir un service d’excellence
									et implémenter le programme de visionnement
									unique du client (Single View of the
									Customer)
								</p>

								<h4 className="title color04">
									Créer de l’efficacité
								</h4>
								<p className="crearEficiencia">
									<span></span>Présenter les résultats
									financiers de manière régulière et renforcer
									notre productivité
								</p>

								<h4 className="title color05">
									Exécution impeccable
								</h4>
								<p className="ejecucionImpecable">
									<span></span>Avoir la meilleure opération du
									secteur, avec une mentalité d’amélioration
									continue
								</p>

								<div className="planeitem"></div>
								<div className="planebg planebgfrance"></div>
							</div>
						</div>
					</div>
				</div>
			</LoadingScreen>
		);
	}
}
