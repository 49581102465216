const fateVarER = [
    {
     contrato: "A",
     detalles: {
            prima_vacacional_actual: "30",// valor en porcentaje
            garantia_actual:"57",// valor en porcentaje
            bono_reconocimiento_actual:"0",// valor en porcentaje
            clausula_sindical_actual:"10",// valor en porcentaje
            
            caja_ahorro_actual:"5",// valor en porcentaje
            
            tienda_sindical_actual:"0",// valor en porcentaje
            salario_ajustado_actual: "0", // valor en porcentaje
            despensa_actual: "30", // valor en dias
            aguinaldo_actual: "20", /// valor en dias
            fondo_ahorro_actual: "13", // valor en porcentaje
            bono_prod_mensual_actual: "0", // valor en porcentaje
            bono_prod_anual_actual: "0", // valor en porcentaje
            bono_disponibilidad_actual: "0", // valor en porcentaje
            TX_mensual_actual: "3",// valor en porcentaje
            TX_excendente_actual: "4",// valor en porcentaje
            TX_vuelo_diario_actual: "2",// valor en porcentaje
            TX_servicio_diario_actual: "1",// valor en porcentaje
            TX_nocturno_actual: "0.30",// valor en porcentaje
            dias_festivos_actual: "2",// valor en porcentaje
            viaticos_actual: "80",// valor en porcentaje
            prima_vacacional_propuesta: "30",// valor en porcentaje
            garantia_propuesta:"57",// valor en porcentaje
            bono_reconocimiento_propuesta:"0",// valor en porcentaje
           
            clausula_sindical_propuesta:"15",// valor en porcentaje
            
            caja_ahorro_propuesta:"8",// valor en porcentaje
            tienda_sindical_propuesta:"100",// valor en porcentaje
            salario_ajustado_propuesta: "7",// valor en porcentaje
            despensa_propuesta: "30", // valor en dias
            aguinaldo_propuesta: "20", /// valor en dias
            fondo_ahorro_propuesta: "13", // valor en porcentaje
            bono_prod_mensual_propuesta: "3.3", // valor en porcentaje
            bono_prod_anual_propuesta: "8.5", // valor en porcentaje
            bono_disponibilidad_propuesta: "3", // valor en porcentaje
            TX_mensual_propuesta: "3",// valor en porcentaje
            TX_excendente_propuesta: "4",// valor en porcentaje
            TX_vuelo_diario_propuesta: "2",// valor en porcentaje
            TX_servicio_diario_propuesta: "1",// valor en porcentaje
            TX_nocturno_propuesta: "0.30",// valor en porcentaje
            dias_festivos_propuesta: "2",// valor en porcentaje
            viaticos_propuesta: "113",// valor en porcentaje
        }
    },
    {
        contrato: "B",
        detalles: {
            prima_vacacional_actual: "30",// valor en porcentaje
            garantia_actual:"24",// valor en porcentaje
            bono_reconocimiento_actual:"6",// valor en porcentaje
            clausula_sindical_actual:"6",// valor en porcentaje
            
            caja_ahorro_actual:"5",// valor en porcentaje
            
            tienda_sindical_actual:"0",// valor en porcentaje
            salario_ajustado_actual: "0", // valor en porcentaje
            despensa_actual: "30", // valor en dias
            aguinaldo_actual: "20", /// valor en dias
            fondo_ahorro_actual: "13", // valor en porcentaje
            bono_prod_mensual_actual: "0", // valor en porcentaje
            bono_prod_anual_actual: "0", // valor en porcentaje
            bono_disponibilidad_actual: "0", // valor en porcentaje
            TX_mensual_actual: "3",// valor en porcentaje
            TX_excendente_actual: "4",// valor en porcentaje
            TX_vuelo_diario_actual: "1",// valor en porcentaje
            TX_servicio_diario_actual: "1",// valor en porcentaje
            TX_nocturno_actual: "0.60",// valor en porcentaje
            dias_festivos_actual: "2",// valor en porcentaje
            viaticos_actual: "80",// valor en porcentaje
            prima_vacacional_propuesta: "30",// valor en porcentaje
            garantia_propuesta:"34",// valor en porcentaje
            bono_reconocimiento_propuesta:"6",// valor en porcentaje
            clausula_sindical_propuesta:"6",// valor en porcentaje
            caja_ahorro_propuesta:"5",// valor en porcentaje
            tienda_sindical_propuesta:"0",// valor en porcentaje
            salario_ajustado_propuesta: "7",// valor en porcentaje
            despensa_propuesta: "30", // valor en dias
            aguinaldo_propuesta: "20", /// valor en dias
            fondo_ahorro_propuesta: "13", // valor en porcentaje
            bono_prod_mensual_propuesta: "0", // valor en porcentaje
            bono_prod_anual_propuesta: "0", // valor en porcentaje
            bono_disponibilidad_propuesta: "0", // valor en porcentaje
            TX_mensual_propuesta: "3",// valor en porcentaje
            TX_excendente_propuesta: "4",// valor en porcentaje
            TX_vuelo_diario_propuesta: "1.25",// valor en porcentaje
            TX_servicio_diario_propuesta: "1",// valor en porcentaje
            TX_nocturno_propuesta: "0.30",// valor en porcentaje
            dias_festivos_propuesta: "2",// valor en porcentaje
            viaticos_propuesta: "113",// valor en porcentaje
           }
       },
  ];

  export default fateVarER;
