import React, { Component, Fragment } from "react";
import "./css/modal.css";
import ModalPicture from "./ModalPicture";
import ModalInformation from "./ModalInformation";
import ModalBackground from "./ModalBackground";
import opc01 from "../../assets/profile.svg";
import opc02 from "../../assets/back.svg";
import { Link, NavLink } from "react-router-dom";
import i18next from "i18next";


class ProfileModal extends Component {
  constructor() {
    super();
    this.state = {
      tabActive: "picture",
      isActive: false,
    };
  }

  tabSelector = evt => {
    this.setState({
      tabActive: evt.currentTarget.attributes.name.textContent,
      isActive: !this.state.isActive
    });
  };

  render() {
    return (

      <div>
      <div className="modal-container">


        <div className="modal-menu-left">
          <h2 className="SettingsTitle">{i18next.t('Profile.config')}</h2>
          <ul>

            {/* <NavLink to="#background-picture">
            <li
              className="picture settings-item"
            >
              <img src={opc01} />
              <p>Imagen de perfil</p>
            </li>
            </NavLink> */}

            <li
              className={
                this.state.tabActive === "picture"
                  ? "settings-item menu-active"
                  : "settings-item"
              }
              onClick={this.tabSelector}
              name="picture"
            >
              <img src={opc01} />
              <p>{i18next.t('Profile.miPerfil')}</p>
            </li>
            {/* <li
              className={
                this.state.tabActive === "background"
                  ? "settings-item menu-active"
                  : "settings-item"
              }
              onClick={this.tabSelector}
              name="background"
            >
              <img src={opc02} />
              <p>Fondo de perfil</p>
            </li> */}
          </ul>
        </div>
        <div className="modal-content">
        <div className="scrollModal">
          {this.state.tabActive === "picture" ? (
            <ModalPicture />
          ) : "" || this.state.tabActive === "background" ? (
            <ModalBackground />
          ) : "" || this.state.tabActive === "information" ? (
            <ModalInformation />
          ) : (
            ""
          )}
        </div>
        </div>
      </div>
      </div>
    );
  }
}

export default ProfileModal;
