import { LOADED_PUBLIC_USER, GET_PUBLIC_USER } from '../actions/actions-public';

const initialState = {
	publicUserData: false,
	publicData: {},
	publicLoadedAt: null
};

export default function(state = initialState, action) {
	const { type, data } = action;
	switch (type) {
		// case LOADED_PUBLIC_USER:
		// 	return {
		// 		...state,
		// 		publicUserData: !state.publicUserData
		// 	};
		case GET_PUBLIC_USER:
			return {
				...state,
				publicData: data,
				publicUserData: true,
				publicLoadedAt: new Date()
			};
		default:
			return state;
	}
}
