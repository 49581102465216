export const ri2022 = [
	{
		month: {
			en: 'December',
			es: 'Diciembre'
		},
		url: ''
	},
	{
		month: {
			en: 'November',
			es: 'Noviembre'
		},
		url: ''
	},
	{
		month: {
			en: 'October',
			es: 'Octubre'
		},
		url: ''
	},
	{
		month: {
			en: 'September',
			es: 'Septiembre'
		},
		url: ''
	},
	{
		month: {
			en: 'August',
			es: 'Agosto'
		},
		url: ''
	},
	{
		month: {
			en: 'July',
			es: 'Julio'
		},
		url: ''
	},
	{
		month: {
			en: 'June',
			es: 'Junio'
		},
		url: `${process.env.REACT_APP_SRC_URL}reportes_industriales/2022/06._Bolet%C3%ADn_de_Industria_JUNIO_2022.pdf`
		//url: 'https://miaeromexico.sfo2.digitaloceanspaces.com/reportes_industriales/2022/06._Bolet%C3%ADn_de_Industria_JUNIO_2022.pdf'
	},
	{
		month: {
			en: 'May',
			es: 'Mayo'
		},
		url: `${process.env.REACT_APP_SRC_URL}reportes_industriales/2022/05._Bolet%C3%ADn_de_Industria_MAYO_2022.pdf`
		//url: 'https://miaeromexico.sfo2.digitaloceanspaces.com/reportes_industriales/2022/05._Bolet%C3%ADn_de_Industria_MAYO_2022.pdf'
	},
	{
		month: {
			en: 'April',
			es: 'Abril'
		},
		url: `${process.env.REACT_APP_SRC_URL}reportes_industriales/2022/04._Bolet%C3%ADn_de_Industria_abril_2022.pdf`
		//url: 'https://miaeromexico.sfo2.digitaloceanspaces.com/reportes_industriales/2022/04._Bolet%C3%ADn_de_Industria_abril_2022.pdf'
	},
	{
		month: {
			en: 'March',
			es: 'Marzo'
		},
		url: `${process.env.REACT_APP_SRC_URL}reportes_industriales/2022/03.%20Bolet%C3%ADn%20de%20Industria%20MARZO%202022%20%281%29.pdf`
		//url: 'https://miaeromexico.sfo2.digitaloceanspaces.com/reportes_industriales/2022/03.%20Bolet%C3%ADn%20de%20Industria%20MARZO%202022%20%281%29.pdf'
	},
	{
		month: {
			en: 'February',
			es: 'Febrero'
		},
		url: `${process.env.REACT_APP_SRC_URL}reportes_industriales/2022/02._Bolet%C3%ADn_de_Industria_FEBRERO_2022%20%281%29.pdf`
		//url: 'https://miaeromexico.sfo2.digitaloceanspaces.com/reportes_industriales/2022/02._Bolet%C3%ADn_de_Industria_FEBRERO_2022%20%281%29.pdf'
	},
	{
		month: {
			en: 'January',
			es: 'Enero'
		},
		url: `${process.env.REACT_APP_SRC_URL}reportes_industriales/2022/01._Bolet%C3%ADn_de_Industria_ENERO_2022_%281%29.pdf`
		//url: 'https://miaeromexico.sfo2.digitaloceanspaces.com/reportes_industriales/2022/01._Bolet%C3%ADn_de_Industria_ENERO_2022_%281%29.pdf'
	}
];
export const ri2021 = [
	{
		month: {
			en: 'December',
			es: 'Diciembre'
		},
		url: `${process.env.REACT_APP_SRC_URL}reportes_industriales/2021/12.%20Bolet%C3%ADn%20de%20Industria%20diciembre%202020.pdf`
		//url: 'https://miaeromexico.sfo2.digitaloceanspaces.com/reportes_industriales/2021/12.%20Bolet%C3%ADn%20de%20Industria%20diciembre%202020.pdf'
	},
	{
		month: {
			en: 'November',
			es: 'Noviembre'
		},
		url: ''
	},
	{
		month: {
			en: 'October',
			es: 'Octubre'
		},
		url: `${process.env.REACT_APP_SRC_URL}reportes_industriales/2021/10._Bolet%C3%ADn_de_Industria_OCTUBRE_2021.pdf`
		//url: 'https://miaeromexico.sfo2.digitaloceanspaces.com/reportes_industriales/2021/10._Bolet%C3%ADn_de_Industria_OCTUBRE_2021.pdf'
	},
	{
		month: {
			en: 'September',
			es: 'Septiembre'
		},
		url: `${process.env.REACT_APP_SRC_URL}reportes_industriales/2021/09._Bolet%C3%ADn_de_Industria_SEPT_2021.pdf`
		//url: 'https://miaeromexico.sfo2.digitaloceanspaces.com/reportes_industriales/2021/09._Bolet%C3%ADn_de_Industria_SEPT_2021.pdf'
	},
	{
		month: {
			en: 'August',
			es: 'Agosto'
		},
		url: `${process.env.REACT_APP_SRC_URL}reportes_industriales/2021/08._Bolet%C3%ADn_de_Industria_AGOSTO_2021.pdf`
		//url: 'https://miaeromexico.sfo2.digitaloceanspaces.com/reportes_industriales/2021/08._Bolet%C3%ADn_de_Industria_AGOSTO_2021.pdf'
	},
	{
		month: {
			en: 'July',
			es: 'Julio'
		},
		url: `${process.env.REACT_APP_SRC_URL}reportes_industriales/2021/07._Bolet%C3%ADn_de_Industria_JULIO_2021.pdf`
		//url: 'https://miaeromexico.sfo2.digitaloceanspaces.com/reportes_industriales/2021/07._Bolet%C3%ADn_de_Industria_JULIO_2021.pdf'
	},
	{
		month: {
			en: 'June',
			es: 'Junio'
		},
		url: `${process.env.REACT_APP_SRC_URL}reportes_industriales/2021/06._Bolet%C3%ADn_industria_JUNIO_2021.pdf`
		//url: 'https://miaeromexico.sfo2.digitaloceanspaces.com/reportes_industriales/2021/06._Bolet%C3%ADn_industria_JUNIO_2021.pdf'
	},
	{
		month: {
			en: 'May',
			es: 'Mayo'
		},
		url: `${process.env.REACT_APP_SRC_URL}reportes_industriales/2021/05._Bolet%C3%ADn%20de%20Industria%20mayo%202021.pdf`
		//url: 'https://miaeromexico.sfo2.digitaloceanspaces.com/reportes_industriales/2021/05._Bolet%C3%ADn%20de%20Industria%20mayo%202021.pdf'
	},
	{
		month: {
			en: 'April',
			es: 'Abril'
		},
		url: `${process.env.REACT_APP_SRC_URL}reportes_industriales/2021/04._Bolet%C3%ADn%20de%20Industria%20abril%202021.pdf`
		//url: 'https://miaeromexico.sfo2.digitaloceanspaces.com/reportes_industriales/2021/04._Bolet%C3%ADn%20de%20Industria%20abril%202021.pdf'
	},
	{
		month: {
			en: 'March',
			es: 'Marzo'
		},
		url: `${process.env.REACT_APP_SRC_URL}reportes_industriales/2021/03._Bolet%C3%ADn%20de%20Industria%20marzo%202021.pdf`
		//url: 'https://miaeromexico.sfo2.digitaloceanspaces.com/reportes_industriales/2021/03._Bolet%C3%ADn%20de%20Industria%20marzo%202021.pdf'
	},
	{
		month: {
			en: 'February',
			es: 'Febrero'
		},
		url: `${process.env.REACT_APP_SRC_URL}reportes_industriales/2021/02._Bolet%C3%ADn%20de%20Industria%20febrero%202021.pdf`
		//url: 'https://miaeromexico.sfo2.digitaloceanspaces.com/reportes_industriales/2021/02._Bolet%C3%ADn%20de%20Industria%20febrero%202021.pdf'
	},
	{
		month: {
			en: 'January',
			es: 'Enero'
		},
		url: `${process.env.REACT_APP_SRC_URL}reportes_industriales/2021/01._Bolet%C3%ADn_Industria_enero_2021.pdf`
		//url: 'https://miaeromexico.sfo2.digitaloceanspaces.com/reportes_industriales/2021/01._Bolet%C3%ADn_Industria_enero_2021.pdf'
	}
];
export const ri2020 = [
	{
		month: {
			en: 'October',
			es: 'Octubre'
		},
		url: `${process.env.REACT_APP_SRC_URL}reportes_industriales/10.%20Boleti%CC%81n%20de%20Industria%20octubre%202020.pdf`
		//url: 'https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/reportes_industriales/10.%20Boleti%CC%81n%20de%20Industria%20octubre%202020.pdf'
	},
	{
		month: {
			en: 'September',
			es: 'Septiembre'
		},
		url: `${process.env.REACT_APP_SRC_URL}reportes_industriales/9.%20Boleti%CC%81n%20de%20Industria%20septiembre%202020%20.pdf`
		//url: 'https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/reportes_industriales/9.%20Boleti%CC%81n%20de%20Industria%20septiembre%202020%20.pdf'
	},
	{
		month: {
			en: 'August',
			es: 'Agosto'
		},
		url: `${process.env.REACT_APP_SRC_URL}reportes_industriales/8.%20Boleti%CC%81n%20de%20Industria%20agosto%202020.pdf`
		//url: 'https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/reportes_industriales/8.%20Boleti%CC%81n%20de%20Industria%20agosto%202020.pdf'
	},
	{
		month: {
			en: 'July',
			es: 'Julio'
		},
		url: `${process.env.REACT_APP_SRC_URL}reportes_industriales/7.%20Boleti%CC%81n%20de%20Industria%20julio%202020.pdf`
		//url: 'https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/reportes_industriales/7.%20Boleti%CC%81n%20de%20Industria%20julio%202020.pdf'
	},
	{
		month: {
			en: 'June',
			es: 'Junio'
		},
		url: `${process.env.REACT_APP_SRC_URL}reportes_industriales/6.%20Boleti%CC%81n%20de%20Industria%20junio%202020.pdf`
		//url: 'https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/reportes_industriales/6.%20Boleti%CC%81n%20de%20Industria%20junio%202020.pdf'
	},
	{
		month: {
			en: 'May',
			es: 'Mayo'
		},
		url: `${process.env.REACT_APP_SRC_URL}reportes_industriales/5.%20Boleti%CC%81n%20de%20Industria%20mayo%202020%20.pdf`
		//url: 'https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/reportes_industriales/5.%20Boleti%CC%81n%20de%20Industria%20mayo%202020%20.pdf'
	},
	// {
	// 	month: {
	// 		en: "April",
	// 		es: "Abril"
	// 	},
	// 	url: ""
	// },
	{
		month: {
			en: 'March',
			es: 'Marzo'
		},
		url: `${process.env.REACT_APP_SRC_URL}reportes_industriales/3.%20Boleti%CC%81n%20de%20Industria%20marzo%202020.pdf`
		//url: 'https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/reportes_industriales/3.%20Boleti%CC%81n%20de%20Industria%20marzo%202020.pdf'
	},
	{
		month: {
			en: 'February',
			es: 'Febrero'
		},
		url: `${process.env.REACT_APP_SRC_URL}reportes_industriales/2.%20Boleti%CC%81n%20de%20Industria%20febrero%202020.pdf`
		//url: 'https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/reportes_industriales/2.%20Boleti%CC%81n%20de%20Industria%20febrero%202020.pdf'
	},
	{
		month: {
			en: 'January',
			es: 'Enero'
		},
		url: `${process.env.REACT_APP_SRC_URL}reportes_industriales/1.%20Boleti%CC%81n%20de%20Industria%20enero%202020.pdf`
		//url: 'https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/reportes_industriales/1.%20Boleti%CC%81n%20de%20Industria%20enero%202020.pdf'
	}
];

export const ri2018 = [
	{
		month: {
			en: 'December',
			es: 'Diciembre'
		},
		url: ''
	},
	{
		month: {
			en: 'November',
			es: 'Noviembre'
		},
		url: ''
	},
	{
		month: {
			en: 'October',
			es: 'Octubre'
		},
		url: ''
	},
	{
		month: {
			en: 'September',
			es: 'Septiembre'
		},
		url: ''
	},
	{
		month: {
			en: 'August',
			es: 'Agosto'
		},
		url: ''
	},
	{
		month: {
			en: 'July',
			es: 'Julio'
		},
		url: ''
	},
	{
		month: {
			en: 'June',
			es: 'Junio'
		},
		url: ''
	},
	{
		month: {
			en: 'May',
			es: 'Mayo'
		},
		url: ''
	},
	{
		month: {
			en: 'April',
			es: 'Abril'
		},
		url: ''
	},
	{
		month: {
			en: 'March',
			es: 'Marzo'
		},
		url: ''
	},
	{
		month: {
			en: 'February',
			es: 'Febrero'
		},
		url: ''
	},
	{
		month: {
			en: 'January',
			es: 'Enero'
		},
		url: ''
	}
];
export const ri2017 = [
	{
		month: {
			en: 'December',
			es: 'Diciembre'
		},
		url: ''
	},
	{
		month: {
			en: 'November',
			es: 'Noviembre'
		},
		url: ''
	},
	{
		month: {
			en: 'October',
			es: 'Octubre'
		},
		url: ''
	},
	{
		month: {
			en: 'September',
			es: 'Septiembre'
		},
		url: ''
	},
	{
		month: {
			en: 'August',
			es: 'Agosto'
		},
		url: ''
	},
	{
		month: {
			en: 'July',
			es: 'Julio'
		},
		url: ''
	},
	{
		month: {
			en: 'June',
			es: 'Junio'
		},
		url: ''
	},
	{
		month: {
			en: 'May',
			es: 'Mayo'
		},
		url: ''
	},
	{
		month: {
			en: 'April',
			es: 'Abril'
		},
		url: ''
	},
	{
		month: {
			en: 'March',
			es: 'Marzo'
		},
		url: ''
	},
	{
		month: {
			en: 'February',
			es: 'Febrero'
		},
		url: ''
	},
	{
		month: {
			en: 'January',
			es: 'Enero'
		},
		url: ''
	}
];
export const ri2016 = [
	{
		month: {
			en: 'December',
			es: 'Diciembre'
		},
		url: ''
	},
	{
		month: {
			en: 'November',
			es: 'Noviembre'
		},
		url: ''
	},
	{
		month: {
			en: 'October',
			es: 'Octubre'
		},
		url: ''
	},
	{
		month: {
			en: 'September',
			es: 'Septiembre'
		},
		url: ''
	},
	{
		month: {
			en: 'August',
			es: 'Agosto'
		},
		url: ''
	},
	{
		month: {
			en: 'July',
			es: 'Julio'
		},
		url: ''
	},
	{
		month: {
			en: 'June',
			es: 'Junio'
		},
		url: ''
	},
	{
		month: {
			en: 'May',
			es: 'Mayo'
		},
		url: ''
	},
	{
		month: {
			en: 'April',
			es: 'Abril'
		},
		url: ''
	},
	{
		month: {
			en: 'March',
			es: 'Marzo'
		},
		url: ''
	},
	{
		month: {
			en: 'February',
			es: 'Febrero'
		},
		url: ''
	},
	{
		month: {
			en: 'January',
			es: 'Enero'
		},
		url: ''
	}
];
export const ri2015 = [
	{
		month: {
			en: 'December',
			es: 'Diciembre'
		},
		url: ''
	},
	{
		month: {
			en: 'November',
			es: 'Noviembre'
		},
		url: ''
	},
	{
		month: {
			en: 'October',
			es: 'Octubre'
		},
		url: ''
	},
	{
		month: {
			en: 'September',
			es: 'Septiembre'
		},
		url: ''
	},
	{
		month: {
			en: 'August',
			es: 'Agosto'
		},
		url: ''
	},
	{
		month: {
			en: 'July',
			es: 'Julio'
		},
		url: ''
	},
	{
		month: {
			en: 'June',
			es: 'Junio'
		},
		url: ''
	},
	{
		month: {
			en: 'May',
			es: 'Mayo'
		},
		url: ''
	},
	{
		month: {
			en: 'April',
			es: 'Abril'
		},
		url: ''
	},
	{
		month: {
			en: 'March',
			es: 'Marzo'
		},
		url: ''
	},
	{
		month: {
			en: 'February',
			es: 'Febrero'
		},
		url: ''
	},
	{
		month: {
			en: 'January',
			es: 'Enero'
		},
		url: ''
	}
];
export const ri2014 = [
	{
		month: {
			en: 'December',
			es: 'Diciembre'
		},
		url: ''
	},
	{
		month: {
			en: 'November',
			es: 'Noviembre'
		},
		url: ''
	},
	{
		month: {
			en: 'October',
			es: 'Octubre'
		},
		url: ''
	},
	{
		month: {
			en: 'September',
			es: 'Septiembre'
		},
		url: ''
	},
	{
		month: {
			en: 'August',
			es: 'Agosto'
		},
		url: ''
	},
	{
		month: {
			en: 'July',
			es: 'Julio'
		},
		url: ''
	},
	{
		month: {
			en: 'June',
			es: 'Junio'
		},
		url: ''
	},
	{
		month: {
			en: 'May',
			es: 'Mayo'
		},
		url: ''
	},
	{
		month: {
			en: 'April',
			es: 'Abril'
		},
		url: ''
	},
	{
		month: {
			en: 'March',
			es: 'Marzo'
		},
		url: ''
	},
	{
		month: {
			en: 'February',
			es: 'Febrero'
		},
		url: ''
	},
	{
		month: {
			en: 'January',
			es: 'Enero'
		},
		url: ''
	}
];
export const ri2013 = [
	{
		month: {
			en: 'December',
			es: 'Diciembre'
		},
		url: ''
	},
	{
		month: {
			en: 'November',
			es: 'Noviembre'
		},
		url: ''
	},
	{
		month: {
			en: 'October',
			es: 'Octubre'
		},
		url: ''
	},
	{
		month: {
			en: 'September',
			es: 'Septiembre'
		},
		url: ''
	},
	{
		month: {
			en: 'August',
			es: 'Agosto'
		},
		url: ''
	},
	{
		month: {
			en: 'July',
			es: 'Julio'
		},
		url: ''
	},
	{
		month: {
			en: 'June',
			es: 'Junio'
		},
		url: ''
	},
	{
		month: {
			en: 'May',
			es: 'Mayo'
		},
		url: ''
	},
	{
		month: {
			en: 'April',
			es: 'Abril'
		},
		url: ''
	},
	{
		month: {
			en: 'March',
			es: 'Marzo'
		},
		url: ''
	},
	{
		month: {
			en: 'February',
			es: 'Febrero'
		},
		url: ''
	},
	{
		month: {
			en: 'January',
			es: 'Enero'
		},
		url: ''
	}
];
export const ri2012 = [
	{
		month: {
			en: 'December',
			es: 'Diciembre'
		},
		url: ''
	},
	{
		month: {
			en: 'November',
			es: 'Noviembre'
		},
		url: ''
	},
	{
		month: {
			en: 'October',
			es: 'Octubre'
		},
		url: ''
	},
	{
		month: {
			en: 'September',
			es: 'Septiembre'
		},
		url: ''
	},
	{
		month: {
			en: 'August',
			es: 'Agosto'
		},
		url: ''
	},
	{
		month: {
			en: 'July',
			es: 'Julio'
		},
		url: ''
	},
	{
		month: {
			en: 'June',
			es: 'Junio'
		},
		url: ''
	},
	{
		month: {
			en: 'May',
			es: 'Mayo'
		},
		url: ''
	},
	{
		month: {
			en: 'April',
			es: 'Abril'
		},
		url: ''
	},
	{
		month: {
			en: 'March',
			es: 'Marzo'
		},
		url: ''
	},
	{
		month: {
			en: 'February',
			es: 'Febrero'
		},
		url: ''
	},
	{
		month: {
			en: 'January',
			es: 'Enero'
		},
		url: ''
	}
];
