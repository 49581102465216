import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import Fetch from 'i18next-fetch-backend';
import { initReactI18next } from 'react-i18next';
import ChainedBackend from 'i18next-chained-backend';
import { isLocal, isFromDev, isFromQA } from './core/Ambientes';


// # Variables que switchean las ligas que se consumen segun sea elcaso
// const isLocal = process.env.REACT_APP_ISLOCAL; // Tomara entorno local
// const isFromDev = process.env.REACT_APP_ISFROMDEV; //Se pondra en true para ambiente DEV
// const isFromQA = process.env.REACT_APP_ISFROMQA; //Se pondra en true para ambiente QA
/* Para productivo se ponen todas en falso */

// #URl de ambiente
const urlLocal = `${process.env.REACT_APP_LOCAL}`;
const urlDev = `${process.env.REACT_APP_CADMIN_DEV}`;
const urlQA = `${process.env.REACT_APP_CADMIN_QA}`
const urlProd = `${process.env.REACT_APP_CADMIN_PROD}`

const tokenLocal = `${process.env.REACT_APP_TOKEN_LOCAL}`;
const tokenDev   = `${process.env.REACT_APP_CADMIN_DEV_TOKEN}`;
const tokenQA 	 = `${process.env.REACT_APP_CADMIN_QA_TOKEN}`;
const tokenProd  = `${process.env.REACT_APP_API_TOKEN}`

// # Liga validada
const urlValid = isLocal === true ? urlLocal 
	: isFromDev === true ? urlDev
	: isFromQA  === true? urlQA
	: urlProd;
	
// Se genera el token segun el ambiente
const tokenValid = isLocal === true ? tokenLocal
	: isFromDev === true ? tokenDev
	: isFromQA === true ? tokenQA
	: tokenProd;

i18n.use(LanguageDetector)
	.use(initReactI18next)
	.use(ChainedBackend)
	.init({
		backend: {
			backends: [
				Fetch, // primary - fetch
				Backend // fallback - xhr backenc
			],
			backendOptions: [
				{
					loadPath: function(lng) {
						return `${urlValid}traduccion-${lng}`;
					}, 
					requestOptions:{
						headers:{
							'Content-type': 'application/json',
							'Authorization':'Bearer ' + tokenValid
						}
					},
					parse: function(data) {
						var obj = JSON.parse(data);
						var trans = obj.data.attributes.data.content; 
						/* console.log(trans); */
						return trans;
					}
				},
				{
					loadPath: '/locales/{{lng}}.json'
				}
			]
		},

		detection: {
			lookupLocalStorage: 'i18nextLng',
			order: ['localStorage'],
			caches: ['localStorage']
		},

		/*
    resources: {
      es,
      en
    },
    */

		fallbackLng: ['es', 'en'],
		debug: false,
		ns: ['translation'],
		defaultNS: 'translation',
		react: {
			wait: true
		},

		interpolation: {
			escapeValue: false // not needed for react as it escapes by default
		}
	});

export default i18n;
