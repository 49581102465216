import React, { Component } from 'react';
import './css/servicio.css';
import i18next from 'i18next';
import { Trans } from 'react-i18next';
import { animateScroll as scroll} from "react-scroll";

export default class GamTalksPage extends Component {
	componentDidMount(){
		scroll.scrollToTop();
	}
	render() {
		return (
			<div className="servicioPageAM">
				{/* <Header/> */}
				<div  className="headerServicioPage">
					<div className="midPage">
						<div className="leftHeader">
							<div className="iconPases"></div>
							<p style={{fontFamily:'Garnett,Helvetica,Arial',fontWeight:'bold'}} className="title">
								{i18next.t('ViajesCom.main')}
							</p>
							<p style={{fontFamily:'Garnett,Helvetica,Arial'}} className="subtitle">
								{i18next.t('ViajesCom.sub')}
							</p>
						</div>

						<div className="rightHeader">
							<div class="grid-container">
								<div className="windowWhite">
									<div className="StepOneBenefits firstmarginRight">
										<p style={{fontWeight:'bold'}} className="stepPasesServicio">
											{i18next.t('ViajesCom.paso')} 1
										</p>
										<p style={{fontFamily:'Garnett,Helvetica,Arial',fontWeight:'bold'}} className="titleRight">
											<Trans i18nKey="ViajesCom.paso1.title"></Trans>
										</p>
										<ol>
											<li>
												<p className="smallOl">
													{i18next.t(
														'ViajesCom.paso1.tel'
													)}{' '}
													1
												</p>
												<p style={{font:'Arial'}}className="phone">
													(55) 9132 4488 opción 2
												</p>
											</li>
										{/* 	<li>
												<p className="smallOl">
													{i18next.t(
														'ViajesCom.paso1.tel'
													)}{' '}
													2
												</p>
												<p className="phone">
													01 800 737 4700
												</p>
											</li> */}
											<li>
												<p  className="horario">
													<span style={{fontFamily:'Garnett,Helvetica,Arial',fontWeight:'bold'}}>
														{i18next.t(
															'ViajesCom.paso1.horario'
														)}
													</span>{' '}
													<br />
													{i18next.t(
														'ViajesCom.paso1.horas'
													)}
												</p>
											</li>
										</ol>
									</div>
								</div>
								<div className="windowWhite">
									<div className="StepOneBenefits">
										<div className="yoouwillNeed bg-white">
											<div className="important">
												<p style={{fontFamily:'Garnett,Helvetica,Arial',fontWeight:'bold'}}className="stepPasesServicio">
													{i18next.t(
														'ViajesCom.paso'
													)}{' '}
													2
												</p>
												<p style={{fontFamily:'Garnett,Helvetica,Arial',fontWeight:'bold'}}>
													{i18next.t(
														'ViajesCom.paso2.title'
													)}{' '}
												</p>
												<ul>
													<li style={{fontFamily:'Garnett,Helvetica,Arial'}}>
														{i18next.t(
															'ViajesCom.paso2.sub1'
														)}
													</li>
													<li style={{fontFamily:'Garnett,Helvetica,Arial'}}>
														{i18next.t(
															'ViajesCom.paso2.sub2'
														)}
													</li>
												</ul>
												<div className="icon"></div>
											</div>
										</div>
									</div>
								</div>
								<div className="bannerBottomServicio">
									<div className="bannerBox">
										<div className="top">
											<div className="padding">
												<p style={{fontFamily:'Garnett,Helvetica,Arial',fontWeight:'bold'}}className="stepPasesServicio">
													{i18next.t(
														'ViajesCom.paso'
													)}{' '}
													3
												</p>

												<div className="leftNewBox">
													<div className="logoCore"></div>
													<p style={{fontFamily:'Garnett,Helvetica,Arial'}}className="inside">
														<Trans i18nKey="ViajesCom.paso3.title"></Trans>
													</p>
												</div>

												<a style={{fontFamily:'Garnett,Helvetica,Arial',fontWeight:'bold'}}
													className="verManual"
													href={`${process.env.REACT_APP_SRC_URL}miam_docs/viajes_aeromexico/Proceso%20Cobus.pdf`}
													target="_blank"
													rel="noopener noreferrer"
												>
													{i18next.t(
														'ViajesCom.paso3.ver'
													)}
												</a>
											</div>
										</div>
										<div className="bottom">
											<p style={{fontFamily:'Garnett,Helvetica,Arial',fontWeight:'bold'}} className="note">
												{i18next.t(
													'ViajesCom.paso3.sub'
												)}
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>

						<a style={{fontFamily:'Garnett,Helvetica,Arial',fontWeight:'bold'}}
							className="btnPolitica"
							href={`${process.env.REACT_APP_SRC_URL}miam_docs/viajes_aeromexico/S_PO_GACP_01.pdf`}
							target="_blank"
							rel="noopener noreferrer"
						>
							{i18next.t('ViajesCom.politica')}
						</a>
					</div>
				</div>
				{/* <Index/> */}
			</div>

			
		);
	}
}
