import {
	// LOADED_PRIVATE_USER,
	GET_PRIVATE_USER
} from '../actions/actions-private';

const initialState = {
	privateUserData: false,
	privateData: {},
	privateLoadedAt: null
};

export default function(state = initialState, action) {
	const { type, data } = action;
	switch (type) {
		// case LOADED_PRIVATE_USER:
		// 	return {
		// 		...state,
		// 		privateUserData: !state.privateUserData
		// 	};
		case GET_PRIVATE_USER:
			return {
				...state,
				privateData: data,
				privateUserData: true,
				privateLoadedAt: new Date()
			};
		default:
			return state;
	}
}
