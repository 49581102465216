import React, { Component } from "react";
import { Link } from "react-router-dom";

import netImage01 from "../../assets/networks/fb.svg";
import netImage02 from "../../assets/networks/twitter.svg";
import netImage03 from "../../assets/networks/instragram.svg";
import netImage04 from "../../assets/networks/linkedin.svg";
import netImage05 from "../../assets/networks/youtube.svg";
import netImage06 from "../../assets/networks/spotify.svg";
import i18next from "i18next";

import AMX_TOP from "./assets/AMX_top.png";

import "./css/footernew.css";
import { Trans } from "react-i18next";
import initLang from "../../utils/initLang";
import { useLocation } from "react-router-dom";
export default class Index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			lang: ""
		};
	}

	componentDidMount() {
		initLang(this);
	}

	PrivacyLink(lng, location) {
		if (lng === "es") {
			return (
				<a
					style={{ color: "#FFFFFF" }}
					href="https://www.aeromexico.com/es-mx/informacion-legal"
					target="_blank"
					rel="noopener noreferrer">
					Aviso de privacidad
				</a>
			);
		} else {
			if (location === "CAN") {
				return (
					<a
						style={{ color: "#FFFFFF" }}
						href="https://www.aeromexico.com/en-ca/legal-information"
						target="_blank"
						rel="noreferrer">
						Privacy Policy
					</a>
				);
			} else {
				return (
					<a
						style={{ color: "#FFFFFF" }}
						href="https://www.aeromexico.com/en-us/legal-information"
						target="_blank"
						rel="noopener noreferrer">
						Privacy Policy
					</a>
				);
			}
		}
	}

	render() {
		return (
			<div className="relative">
				<ShowTopEmployer>
					<div className="fake-container-footer flex items-center justify-center border-t border-gray-200 py-4">
						<img src={AMX_TOP} className="h-20 w-auto" alt="Top Employer Mexico" />
					</div>
				</ShowTopEmployer>
				<div className="footerWrapper bg-[#020C41]">
					<div className="footerBottom bg-[#030D75]">
						<div className="midWrapper">
							<div className="miamlogo" />
							<p className="Language">
								{this.state.lang === "es" ? "Español" : "English"}
								<span />
							</p>
							<p className="terms">
								{this.PrivacyLink(this.state.lang, localStorage.getItem("location"))}
							</p>
							<p className="legal">
								© {new Date().getFullYear()} {i18next.t("Footer.derechos")}
							</p>
						</div>
					</div>

					<div className="footerMidWrapper">
						<div className="wrapperleftfotter">
							<div className="Contactus">
								<h3>
									<strong>{i18next.t("Footer.contacta")}</strong>
								</h3>
								<p>
									<span className="image"></span>
									<span className="Text">
										<Trans i18nKey="Footer.sugerencia"></Trans>
									</span>
								</p>
								<a className="button mt-4" href="mailto:amcomunicacioninterna@aeromexico.com">
									{i18next.t("Footer.escribeBtn")}
								</a>
							</div>

							<h3>{i18next.t("Footer.siguenos")}</h3>
							<div className="socialNetwork">
								<a
									className="boxCont"
									href="https://www.facebook.com/AeromexicoMX"
									target="_blank"
									rel="noopener noreferrer">
									<img src={netImage01} alt="img01" />
								</a>
								<a
									className="boxCont"
									href="https://twitter.com/aeromexico"
									target="_blank"
									rel="noopener noreferrer">
									<img src={netImage02} alt="img02" />
								</a>
								<a
									className="boxCont"
									href="https://instagram.com/aeromexico/"
									target="_blank"
									rel="noopener noreferrer">
									<img src={netImage03} alt="netimage03" />
								</a>
								<a
									className="boxCont"
									href="https://www.linkedin.com/company/18870/"
									target="_blank"
									rel="noopener noreferrer">
									<img src={netImage04} alt="netImage04" />
								</a>
								<a
									className="boxCont"
									href="https://www.youtube.com/user/AeromexicoTV"
									target="_blank"
									rel="noopener noreferrer">
									<img src={netImage05} alt="netImage05" />
								</a>
								<a
									className="boxCont"
									href="https://open.spotify.com/user/129263959/playlist/7FEorPX4s8rQFqbE2dMxzx"
									target="_blank"
									rel="noopener noreferrer">
									<img src={netImage06} alt="netImage06" />
								</a>
							</div>
						</div>

						<div className="wrapperrightfotter">
							<div className="WrappedGrid">
								<div className="gridOption">
									<h3><strong>{i18next.t("Footer.reporta")}</strong></h3>

									<ul>
										<li>
											<span />
											<Link to="/seguridad#gamreport">
												<Trans i18nKey="Footer.denuncia"></Trans>
											</Link>
										</li>

										<Link className="button" to="/seguridad#gamreport">
											{i18next.t("Footer.denunciaBtn")}
										</Link>
									</ul>
								</div>

								<div className="gridOption">
									<div className="newList">
										<h3><strong>{i18next.t("Footer.enlaces")}</strong></h3>
										<ul className="AMExperience">
											{/* <li>
                        <a href="/noticias/nuestragente">
                        <span />
                        Noticias</a>
                      </li> */}
											{/* <li><a href="/total-rewards/profile">
                        <span />
                        Mi Recompensa Total</a>
                      </li> */}

											{/* <li>
												<Link to="/recompensa-total/my-hr">
													<span />
													{i18next.t('Footer.hr')}
												</Link>
											</li> */}
											<li>
												<Link to="/oportunidades-carrera">
													<span />
													{i18next.t("Footer.vacantes")}
												</Link>
											</li>
											<li>
												<a
													href="https://autoservicio.aeromexico.com/"
													target="_blank"
													rel="noopener noreferrer">
													<span />
													{i18next.t("Footer.auto")}
												</a>
											</li>

											{/* <li><a href="">
                        <span />
                        Ver más</a>
                      </li> */}
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/*
        <div className="main-footer">
          <FooterMiddle />
          <FooterBottom />
        </div>*/}
			</div>
		);
	}
}

const ShowTopEmployer = ({ children }) => {
	const location = useLocation();
	return location.pathname !== "/inicio" ? children : null;
};
