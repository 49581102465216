import React, { Component } from "react";
import "./css/cxevolution.css";
import flecha from "../../assets/images/slider/arrow-blue.svg";
import fotos from "../../assets/images/slider/galeria.svg";
import ReactPlayer from "react-player";
import "../CustomerExperience/css/CXevolution.css";
import close from "../../assets/logo/close.svg";
import Slidercx from "../../components/CXevolution/swippercx";
import CxEvolutionGallery from "./cxevolutionGallery";
import videoCX from "../../assets/video/cx-evo.mp4";
import { Trans } from "react-i18next";
import i18next from "i18next";

export default class SliderSwiper extends Component {
	constructor() {
		super();
		this.state = {
			modalIsShow: true,
			modalIsShowGallery: false,
			loading: true,
			error: null,
			entries: null
		};
		this.escFunction = this.escFunction.bind(this);
		this.setWrapperRef = this.setWrapperRef.bind(this);
	}

	componentDidMount() {
		document.addEventListener("keydown", this.escFunction, false);
		window.addEventListener("scroll", this.handleScroll);
		setTimeout(() => this.setState({ loading: false }), 1500);
	}

	UNSAFE_componentWillMount() {
		document.addEventListener("mousedown", this.handleClick, false);
	}

	escFunction(event) {
		if (event.keyCode === 27) {
			this.setState({
				modalIsShow: false,
				modalIsShowGallery: false
			});
		}
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.escFunction, false);
		document.removeEventListener("mousedown", this.handleClick, false);
	}
	handleClick = e => {
		if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
			this.setState({
				modalIsShow: false,
				modalIsShowGallery: false
			});
		}
	};
	setWrapperRef(node) {
		this.wrapperRef = node;
	}

	showAlertModal = () => {
		this.setState({
			modalIsShow: !this.state.modalIsShow
		});
	};

	showAlertModalGallery = () => {
		this.setState({
			modalIsShowGallery: !this.state.modalIsShowGallery
		});
	};

	render() {
		const { loading } = this.state;
		return (
			<div>
				{this.state.modalIsShow === true ? (
					<div className="flightVideoModal">
						<div
							className="videoModalWindow"
							ref={this.setWrapperRef}
						>
							<div
								className="closeModal"
								onClick={this.showAlertModal}
							>
								<img src={close} alt="cerrar"></img>
							</div>

							<ReactPlayer
								url="https://aeromexico-1.wistia.com/medias/ake594oqur"
								playing="true"
								width="100%"
								height="100%"
								controls="true"
								volume=".1"
							/>
						</div>
					</div>
				) : (
					""
				)}

				{this.state.modalIsShowGallery === true ? (
					<div className="flightVideoModal">
						<div
							className="videoModalWindow"
							ref={this.setWrapperRef}
						>
							<div
								className="closeModal"
								onClick={this.showAlertModalGallery}
							>
								<img src={close} alt="cerrar"></img>
							</div>

							{!loading && <CxEvolutionGallery />}
						</div>
					</div>
				) : (
					""
				)}

				<div className="cxDataTop">
					<div className="cxBackgroundImage">
						<div className="cxBackgroundBlue">
							<div className="midWrapperCx">
								<div className="cxLeftWrapper">
									<div className="cxBlockVideo">
										<div className="videoTextTop">
											<div className="cxLogo"></div>
											<h2>
												{" "}
												<Trans i18nKey="CXEvolution.main"></Trans>{" "}
											</h2>

											<p className="subH3">
												<Trans i18nKey="CXEvolution.sub1"></Trans>
											</p>

											<p className="subH3">
												<b>
													{" "}
													{i18next.t(
														"CXEvolution.sub2"
													)}{" "}
												</b>
											</p>

											<div className="gridButtonsCX">
												<span
													onClick={
														this.showAlertModal
													}
													class="slider-read ourhovera"
												>
													<a>
														{i18next.t(
															"CXEvolution.conoceBtn"
														)}
													</a>
													<img
														class="blue-arrow"
														src={flecha}
														alt="blue-arrow"
													/>
												</span>
												<span
													onClick={
														this
															.showAlertModalGallery
													}
													class="slider-read ourhovera"
												>
													<img
														class="blue-arrow galeria"
														src={fotos}
														alt="blue-arrow"
													/>
													<a>
														{i18next.t(
															"CXEvolution.fotosBtn"
														)}
													</a>
													<img
														class="blue-arrow"
														src={flecha}
														alt="blue-arrow"
													/>
												</span>
											</div>
										</div>

										<div className="filterBlue"></div>

										<div className="videoCXEvolution">
											<video
												className="video"
												autoPlay
												muted
												loop
												playsInline
											>
												<source src={videoCX} />
											</video>
										</div>
									</div>

									<div class="idQuotes">
										<div className="midQuotes">
											<p class="titleTop">
												{i18next.t(
													"CXEvolution.gente.title"
												)}
											</p>
											{!loading && <Slidercx />}
										</div>
									</div>

									<div className="cxWrapperData divCardWhite">
										<div className="cxMiddleWrap">
											<p className="titleTop">
												{i18next.t(
													"CXEvolution.gam.title"
												)}
											</p>
											<div className="cxGraphAvance">
												<div className="cxGraphRed"></div>
											</div>
											<div className="cxDataNumbers">
												<div className="cxDataWrapper">
													<p className="titleTop">
														{i18next.t(
															"CXEvolution.gam.asistentes"
														)}
													</p>
													<p className="cxNumber">
														8,138
													</p>
												</div>
												<div className="cxDataWrapper">
													<p className="titleTop">
														{i18next.t(
															"CXEvolution.gam.sesiones"
														)}
													</p>
													<p className="cxNumber">
														91
													</p>
												</div>
												<div className="cxDataWrapper">
													<p className="titleTop">
														{i18next.t(
															"CXEvolution.gam.porcentaje"
														)}
													</p>
													<p className="cxNumber">
														51%
													</p>
												</div>
											</div>

											<div className="asisteWrapeCx">
												<div className="iconImgCX"></div>
												<p>
													{i18next.t(
														"CXEvolution.gam.trans"
													)}
												</p>
												<p>
													<Trans i18nKey="CXEvolution.gam.hrbp"></Trans>
												</p>
											</div>
										</div>
									</div>
								</div>

								{/* <div className="cxRightWrapper">
                  <div className="wrapperFixedCX">
                    <div className="wrapperIconsCX">
                    
                    <div class="quejasFrecuente">
                      <div class="quejaIcon"></div>
                      <div class="quejaText">
                        <p class="number">13</p>
                        <p class="description">Aeropuertos</p>
                      </div>
                    </div>

                    <div class="quejasFrecuente">
                      <div class="quejaIcon"></div>
                      <div class="quejaText">
                        <p class="number">30</p>
                        <p class="description">Call Centers</p>
                      </div>
                    </div>

                    <div class="quejasFrecuente">
                      <div class="quejaIcon"></div>
                      <div class="quejaText">
                        <p class="number">9</p>
                        <p class="description">Cargo</p>
                      </div>
                    </div>

                    <div class="quejasFrecuente">
                      <div class="quejaIcon"></div>
                      <div class="quejaText">
                        <p class="number">9</p>
                        <p class="description">Mantenimiento</p>
                      </div>
                    </div>

                    <div class="quejasFrecuente">
                      <div class="quejaIcon"></div>
                      <div class="quejaText">
                        <p class="number">29</p>
                        <p class="description">Personal Administrativo</p>
                      </div>
                    </div>

                    <div class="quejasFrecuente">
                      <div class="quejaIcon"></div>
                      <div class="quejaText">
                        <p class="number">39</p>
                        <p class="description">Pilotos</p>
                      </div>
                    </div>

                    <div class="quejasFrecuente">
                      <div class="quejaIcon"></div>
                      <div class="quejaText">
                        <p class="number">24</p>
                        <p class="description">Sobrecargos</p>
                      </div>
                    </div>


                    </div>
                  </div>
                </div> */}
							</div>
						</div>
					</div>
				</div>

				{/* <div className="calendarioSesiones">
          <div className="calWrapper">
            <div className="calWrapperCell">
              
              <div className="calButton">
                <a>
                  <div className="circleImage">
                  </div>
                  <div className="TextWrapper">
                    <h4>Calendario de sesiones</h4>
                    <p>Si fuiste programado y no vas ya no serás reagendado. Es muy importante tu asistencia.<a href="#">Ver calendario</a></p>
                  </div>
                </a>
              </div>

              <div className="cxLogo">
              </div>
            </div>
          </div>
        </div> */}
			</div>
		);
	}
}
