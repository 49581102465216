import React, { Component } from 'react';
import iconDownloadManual from '../../../assets/images/download-icon.svg';
import './../css/moreTravelBenefits.css';
import BtnDown from '../../../assets/images/benefits/download-bg-benefit.jpg';
import i18next from 'i18next';

export default class BenefitManual extends Component {
	render() {
		return (
			<div className="button-download">
				{/* Aeromexico NIVEL 1 */}
				{/* Aeromexico NIVEL 1 */}
				{/* Aeromexico NIVEL 1 */}
				{localStorage.getItem('compania') === 'Aeroméxico' &&
				localStorage.getItem('nivel') === '1' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/ERVXSBqbA3lEitJkUZahpGIB2YTK0nHA6iFj2Ysm8wXLxw?e=CONNKu"
						/* target="_blank" */
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div className="icondownload">
								<img src={iconDownloadManual} alt="icono descargar" />
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/* Aeromexico NIVEL 2 */}
				{/* Aeromexico NIVEL 2 */}
				{/* Aeromexico NIVEL 2 */}
				{localStorage.getItem('compania') === 'Aeroméxico' &&
				localStorage.getItem('nivel') === '2' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EUh9Q-WvMqRFsgYKXNO7F8ABOUKsb-eiowrQkBGdyL6VXA?e=A2cvKE"
						/* target="_blank" */
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div className="icondownload">
								<img src={iconDownloadManual} alt="icono descargar"/>
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/* Aeromexico NIVEL 3 */}
				{/* Aeromexico NIVEL 3 */}
				{/* Aeromexico NIVEL 3 */}
				{localStorage.getItem('compania') === 'Aeroméxico' &&
				localStorage.getItem('nivel') === '3' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/ETUuX100RqNDnUsw_Mf2aRcBiWBcnd7H6rN0BnZEthERkQ?e=eigWZ4"
						/* target="_blank" */
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div className="icondownload">
								<img src={iconDownloadManual} alt="icono descargar" />
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/* Aeromexico NIVEL 4 */}
				{/* Aeromexico NIVEL 4 */}
				{/* Aeromexico NIVEL 4 */}
				{localStorage.getItem('compania') === 'Aeroméxico' &&
				localStorage.getItem('nivel') === '4' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/Ef94zcAu9tNBuUOwS0y2_RoBwCMJeEF5SDfqqFXNYowZug?e=JGp9tD"
						/* target="_blank" */
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div className="icondownload">
								<img src={iconDownloadManual} alt="icono descargar"/>
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/* Aeromexico NIVEL 5 */}
				{/* Aeromexico NIVEL 5 */}
				{/* Aeromexico NIVEL 5 */}
				{localStorage.getItem('compania') === 'Aeroméxico' &&
				localStorage.getItem('nivel') === '5' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EdSkqPkQZpRAsoRBRiZgONYBBXRSquzCsnO5EAHHq69dtg?e=MqtNnE"
						/* target="_blank" */
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div className="icondownload">
								<img src={iconDownloadManual} alt="icono descargar"/>
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/* Aeromexico NIVEL 6 */}
				{/* Aeromexico NIVEL 6 */}
				{/* Aeromexico NIVEL 6 */}
				{localStorage.getItem('compania') === 'Aeroméxico' &&
				localStorage.getItem('nivel') === '6' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EZVUrxYzADBDhp_N-lVhS_gBfc_fdK70QLOs9AXAV5QtEA?e=LcdPM3"
						/* target="_blank" */
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div className="icondownload">
								<img src={iconDownloadManual} alt="icono descargar" />
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/* Aeromexico NIVEL 7 */}
				{/* Aeromexico NIVEL 7 */}
				{/* Aeromexico NIVEL 7 */}
				{localStorage.getItem('compania') === 'Aeroméxico' &&
				localStorage.getItem('nivel') === '7' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EWMk6WLkSwhOtvR2tVXLAXUBpMXquy-B7RqUm5vPSBG4gA?e=AvY4Em"
						/* target="_blank" */
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div className="icondownload">
								<img src={iconDownloadManual} alt="icono descargar"/>
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/* Aeromexico NIVEL 8 */}
				{/* Aeromexico NIVEL 8 */}
				{/* Aeromexico NIVEL 8 */}
				{localStorage.getItem('compania') === 'Aeroméxico' &&
				localStorage.getItem('nivel') === '8' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EZix4l6IQC9AvPVwAPG3YBoBPuv-JjO3gSvOLO3R8uLbXw?e=EY2ZzE"
						/* target="_blank" */
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div className="icondownload">
								<img src={iconDownloadManual} alt="icono descargar"/>
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/* Aeromexico NIVEL 9 */}
				{/* Aeromexico NIVEL 9 */}
				{/* Aeromexico NIVEL 9 */}
				{localStorage.getItem('compania') === 'Aeroméxico' &&
				localStorage.getItem('nivel') === '9' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EVQDsN_myWFBrrYMZ8YdwRgBu4Dec2QOYvWy74LjbIHIBQ?e=zNAH0U"
						/* target="_blank" */
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div className="icondownload">
								<img src={iconDownloadManual} alt="icono descargar" />
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/* Aeromexico NIVEL 10 */}
				{/* Aeromexico NIVEL 10 */}
				{/* Aeromexico NIVEL 10*/}
				{localStorage.getItem('compania') === 'Aeroméxico' &&
				localStorage.getItem('nivel') === '10' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EZYSEN3_zB9Mjp5xgn1Ge0gBxyEQUbLMUuOd2pSb5_mt-w?e=bzsiwM"
						/* target="_blank" */
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div className="icondownload">
								<img src={iconDownloadManual} alt="icono descargar"/>
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/* Aeromexico NIVEL 11 */}
				{/* Aeromexico NIVEL 11 */}
				{/* Aeromexico NIVEL 11 */}
				{localStorage.getItem('compania') === 'Aeroméxico' &&
				localStorage.getItem('nivel') === '11' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EVkiCDD4Y-dPl3SyRhRx100BvLHl8XEDBySzUKnE7Ioqyg?e=Beg2iq"
						/* target="_blank" */
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div className="icondownload">
								<img src={iconDownloadManual} alt="icono descargar"/>
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/* Aeromexico NIVEL 12 */}
				{/* Aeromexico NIVEL 12 */}
				{/* Aeromexico NIVEL 12 */}
				{(localStorage.getItem('compania') === 'Aeroméxico' &&
					localStorage.getItem('nivel') === '12') ||
				(localStorage.getItem('compania') === 'Aeroméxico' &&
					localStorage.getItem('nivel') === '13') ||
				(localStorage.getItem('compania') === 'Aeroméxico' &&
					localStorage.getItem('nivel') === '14') ||
				(localStorage.getItem('compania') === 'Aeroméxico' &&
					localStorage.getItem('nivel') === '15') ||
				(localStorage.getItem('compania') === 'Aeroméxico' &&
					localStorage.getItem('nivel') === '16') ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EfVNow6hNvFLkPQ0ylQcogUBzEcUfjZKU-peMyVMcJMaUg?e=XNbf0O"
						/* target="_blank" */
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div className="icondownload">
								<img src={iconDownloadManual} alt="icono descargar" />
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/* Aeromexico Formacion NIVEL 1 */}
				{/* Aeromexico Formacion NIVEL 1 */}
				{/* Aeromexico Formacion NIVEL 1 */}
				{localStorage.getItem('compania') === 'Aeroméxico Formación' &&
				localStorage.getItem('nivel') === '1' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EVv3PvlpiKFNjvEhUHY-6-QBseRUdJiLzjfkxz7RlSdRXw?e=gLhF5h"
						/* target="_blank" */
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div class="icondownload">
								<img src={iconDownloadManual} alt="icono descargar" />
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/* Aeromexico Formacion NIVEL 2 */}
				{/* Aeromexico Formacion NIVEL 2 */}
				{/* Aeromexico Formacion NIVEL 2 */}
				{localStorage.getItem('compania') === 'Aeroméxico Formación' &&
				localStorage.getItem('nivel') === '2' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EVrhaXszykVEuXv5Vys8Ma8BoGA51UM7d-DDVGrQE34BYA?e=1mrDWn"
						/* target="_blank" */
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div className="icondownload">
								<img src={iconDownloadManual} alt="icono descargar"/>
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/* Aeromexico Formacion NIVEL 3 */}
				{/* Aeromexico Formacion NIVEL 3 */}
				{/* Aeromexico Formacion NIVEL 3 */}
				{localStorage.getItem('compania') === 'Aeroméxico Formación' &&
				localStorage.getItem('nivel') === '3' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EZTAAwDm5EZKoK5FpVSOzRMBu3_liOL79MtgEVT33OrKbw?e=h0yiG6"
						/* target="_blank" */
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div className="icondownload">
								<img src={iconDownloadManual} alt="icono descargar"/>
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/* Aeromexico Formacion NIVEL 4 */}
				{/* Aeromexico Formacion NIVEL 4 */}
				{/* Aeromexico Formacion NIVEL 4 */}
				{localStorage.getItem('compania') === 'Aeroméxico Formación' &&
				localStorage.getItem('nivel') === '4' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EddQ9DhWpT9NhzdvPf-KGLUBhqfuOGJ4Sp2Q-M_saMxJgQ?e=udOt8O"
						/* target="_blank" */
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div className="icondownload">
								<img src={iconDownloadManual} alt="icono descargar" />
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/* Aeromexico Formacion NIVEL 5 */}
				{/* Aeromexico Formacion NIVEL 5 */}
				{/* Aeromexico Formacion NIVEL 5 */}
				{localStorage.getItem('compania') === 'Aeroméxico Formación' &&
				localStorage.getItem('nivel') === '5' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/ESlg_HbbOgdEp56Eqe9z4nMBY1pvZpNnJpL_KQkrX-96kA?e=IDHaZE"
						/* target="_blank" */
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div className="icondownload">
								<img src={iconDownloadManual} alt="icono descargar"/>
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/* Aeromexico Formacion NIVEL 6 */}
				{/* Aeromexico Formacion NIVEL 6 */}
				{/* Aeromexico Formacion NIVEL 6 */}
				{localStorage.getItem('compania') === 'Aeroméxico Formación' &&
				localStorage.getItem('nivel') === '6' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EREpUfsqQrpEm-_r4p3nLHUBBt7u4hBRh2xg10XxRqplYQ?e=MiZxCy"
						/* target="_blank" */
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div className="icondownload">
								<img src={iconDownloadManual} alt="icono descargar"/>
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/* Aeromexico Formacion NIVEL 7 */}
				{/* Aeromexico Formacion NIVEL 7*/}
				{/* Aeromexico Formacion NIVEL 7*/}
				{localStorage.getItem('compania') === 'Aeroméxico Formación' &&
				localStorage.getItem('nivel') === '7' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EVgZiiB83EREjD3NsLeJJw4BUIzMaWorP7xr6dUePK7qIA?e=jODc0q"
						/* target="_blank" */
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div className="icondownload">
								<img src={iconDownloadManual} alt="icono descargar" />
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/* Aeromexico Cargo NIVEL 1 */}
				{/* Aeromexico Cargo NIVEL 1*/}
				{/* Aeromexico Cargo NIVEL 1*/}
				{localStorage.getItem('compania') === 'Aeroméxico Cargo' &&
				localStorage.getItem('nivel') === '1' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/ESHXdI905MpKhOJeDPvZn-MBVuoJLUmIefkzwzv299v5cA?e=ookUHY"
						/* target="_blank" */
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div className="icondownload">
								<img src={iconDownloadManual} alt="icono descargar" />
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/* Aeromexico Cargo NIVEL 2 */}
				{/* Aeromexico Cargo NIVEL 2*/}
				{/* Aeromexico Cargo NIVEL 2*/}
				{localStorage.getItem('compania') === 'Aeroméxico Cargo' &&
				localStorage.getItem('nivel') === '2' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EVRrGQFqBGxNij7J7KCgRIsBASVx_g26JCQEXwHsg-n1yg?e=kXT2At"
						/* target="_blank" */
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div className="icondownload">
								<img src={iconDownloadManual} alt="icono descargar"/>
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/* Aeromexico Cargo NIVEL 3 */}
				{/* Aeromexico Cargo NIVEL 3*/}
				{/* Aeromexico Cargo NIVEL 3*/}
				{localStorage.getItem('compania') === 'Aeroméxico Cargo' &&
				localStorage.getItem('nivel') === '3' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EYUtkJcBh4JCseWUFGu9BTwBIcmUvkETcFtyekMhecwvbQ?e=Z178HL"
						/* target="_blank" */
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div className="icondownload">
								<img src={iconDownloadManual} alt="icono descargar" />
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/* Aeromexico Cargo NIVEL 4 */}
				{/* Aeromexico Cargo NIVEL 4*/}
				{/* Aeromexico Cargo NIVEL 4*/}
				{localStorage.getItem('compania') === 'Aeroméxico Cargo' &&
				localStorage.getItem('nivel') === '4' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EfeYtJDH0kdAouqPWbtVNokBlSM_X1nAqdVb2D7ZrCgoVQ?e=j3q7hv"
						/* target="_blank" */
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div className="icondownload">
								<img src={iconDownloadManual} alt="icono descargar" />
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/* Aeromexico Cargo NIVEL 5 */}
				{/* Aeromexico Cargo NIVEL 5*/}
				{/* Aeromexico Cargo NIVEL 5*/}
				{localStorage.getItem('compania') === 'Aeroméxico Cargo' &&
				localStorage.getItem('nivel') === '5' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/Ef_AjGX-DVZPqMAfTZ7xrcYBr0_MEc4gaKt1LgUiY8-tAw?e=VbzF6G"
						/* target="_blank" */
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div className="icondownload">
								<img src={iconDownloadManual} alt="iconodescarga"/>
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/* Aeromexico Cargo NIVEL 6 */}
				{/* Aeromexico Cargo NIVEL 6*/}
				{/* Aeromexico Cargo NIVEL 6*/}
				{localStorage.getItem('compania') === 'Aeroméxico Cargo' &&
				localStorage.getItem('nivel') === '6' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EYfewx-iSYJKjbd9wo_GiwEBoTw2zzgEzELQ0gM_p03C6w?e=01AyF6"
						/* target="_blank" */
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div className="icondownload">
								<img src={iconDownloadManual} alt="icono dowland"/>
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/* Aeromexico Cargo NIVEL 7 */}
				{/* Aeromexico Cargo NIVEL 7*/}
				{/* Aeromexico Cargo NIVEL 7*/}
				{localStorage.getItem('compania') === 'Aeroméxico Cargo' &&
				localStorage.getItem('nivel') === '7' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EbpEUwPvLa1FnC8oxbOhKtsBbwwE3qCvTW5gGBhmXkQhPA?e=bINYv6"
						/* target="_blank" */
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div className="icondownload">
								<img src={iconDownloadManual} alt="icono descarga" />
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/* Aeromexico Cargo NIVEL 8 */}
				{/* Aeromexico Cargo NIVEL 8*/}
				{/* Aeromexico Cargo NIVEL 8*/}
				{localStorage.getItem('compania') === 'Aeroméxico Cargo' &&
				localStorage.getItem('nivel') === '8' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EZvYClwWK6pGowzxZrU1SE4ByJwyyJgspEu_nbbkFiLSRg?e=Wsvhaa"
						/* target="_blank" */
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div className="icondownload">
								<img src={iconDownloadManual} alt="Icono descarga" />
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/* Aeromexico Cargo NIVEL 9 */}
				{/* Aeromexico Cargo NIVEL 9*/}
				{/* Aeromexico Cargo NIVEL 9*/}
				{localStorage.getItem('compania') === 'Aeroméxico Cargo' &&
				localStorage.getItem('nivel') === '9' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EdfleublWlpOsCuKB-8mzUsBPiShfepeAhERIG6ALytGDg?e=VUVaQU"
						/* target="_blank" */
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div className="icondownload">
								<img src={iconDownloadManual} alt="icono descarga"/>
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/* Aeromexico Cargo NIVEL 10 */}
				{/* Aeromexico Cargo NIVEL 10*/}
				{/* Aeromexico Cargo NIVEL 10*/}
				{localStorage.getItem('compania') === 'Aeroméxico Cargo' &&
				localStorage.getItem('nivel') === '10' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EYSgfJMCNIxNicXM1hC-ItABfvEQ_sXn0w_hljB6O6yXjQ?e=KRyF4Y"
						/* target="_blank" */
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div class="icondownload">
								<img src={iconDownloadManual} alt="icono descarga"/>
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/* Aeromexico Connect NIVEL 1 */}
				{/* Aeromexico Connect NIVEL 1*/}
				{/* Aeromexico Connect NIVEL 1*/}
				{localStorage.getItem('compania') === 'Aeroméxico Connect' &&
				localStorage.getItem('nivel') === '1' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/ERauyNi1zA1NkK3WuMSOak0BRX9tCplLwGQ9aa48q-uIuA?e=AzO8XD"
						/* target="_blank" */
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div className="icondownload">
								<img src={iconDownloadManual} alt="Icono manuel"/>
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/* Aeromexico Connect NIVEL 2 */}
				{/* Aeromexico Connect NIVEL 2*/}
				{/* Aeromexico Connect NIVEL 2*/}
				{localStorage.getItem('compania') === 'Aeroméxico Connect' &&
				localStorage.getItem('nivel') === '2' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/ER8r1Ne48rlIrr-DdWVE3asB6cRhzFoiPeSs1cEueuGYhQ?e=BnIiuV"
						/* target="_blank" */
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div className="icondownload">
								<img src={iconDownloadManual} alt="icono descargar" />
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/* Aeromexico Connect NIVEL 3 */}
				{/* Aeromexico Connect NIVEL 3*/}
				{/* Aeromexico Connect NIVEL 3*/}
				{localStorage.getItem('compania') === 'Aeroméxico Connect' &&
				localStorage.getItem('nivel') === '3' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/Ect5HqJeFKFChOBrd-aviZIB_Ccmh6Lo9nC5n5F8PYYseA?e=ZCEeNY"
						/* target="_blank" */
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div class="icondownload">
								<img src={iconDownloadManual} alt="icono descargar" />
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/* Aeromexico Connect NIVEL 14*/}
				{/* Aeromexico Connect NIVEL 4*/}
				{/* Aeromexico Connect NIVEL 4*/}
				{localStorage.getItem('compania') === 'Aeroméxico Connect' &&
				localStorage.getItem('nivel') === '4' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/ERkyhFMndYJNoLOSZQErCEIBAKdd1a7Hxkmehf4uWK6KxQ?e=r7LmWA"
						/* target="_blank" */
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div className="icondownload">
								<img src={iconDownloadManual} alt="icono descargar"/>
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/* Aeromexico Connect NIVEL 5 */}
				{/* Aeromexico Connect NIVEL 5*/}
				{/* Aeromexico Connect NIVEL 5*/}
				{localStorage.getItem('compania') === 'Aeroméxico Connect' &&
				localStorage.getItem('nivel') === '5' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EZP8gbHlwetFiBSdnINyOSYBPcq1JLJ8t5fHiNFUozLu7Q?e=qQqkKx"
						/* target="_blank" */
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div className="icondownload">
								<img src={iconDownloadManual} alt="icono descargar"/>
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/* Aeromexico Connect NIVEL 6 */}
				{/* Aeromexico Connect NIVEL 6*/}
				{/* Aeromexico Connect NIVEL 6*/}
				{localStorage.getItem('compania') === 'Aeroméxico Connect' &&
				localStorage.getItem('nivel') === '6' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EfhWKdZzNPJKoTx8aTmgsuABRFIxEbVl-bZR5Zi9C9btcA?e=6jzen2"
						/* target="_blank" */
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div class="icondownload">
								<img src={iconDownloadManual} alt="icono descargar"/>
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/* Aeromexico Connect NIVEL 7 */}
				{/* Aeromexico Connect NIVEL 7*/}
				{/* Aeromexico Connect NIVEL 7*/}
				{localStorage.getItem('compania') === 'Aeroméxico Connect' &&
				localStorage.getItem('nivel') === '7' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EfXIYkVWuqtFgCzaDizHnDEBGiu1ChT2NpoA0A5YHrfOmQ?e=NunSi6"
						/* target="_blank" */
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div class="icondownload">
								<img src={iconDownloadManual} alt="icono descargar"/>
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/* Aeromexico Connect NIVEL 8 */}
				{/* Aeromexico Connect NIVEL 8*/}
				{/* Aeromexico Connect NIVEL 8*/}
				{localStorage.getItem('compania') === 'Aeroméxico Connect' &&
				localStorage.getItem('nivel') === '8' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EXVncoKaDbRHpSaYeUaWOikBZbamJLoLQcp0gtK4YTusxg?e=cLC2E1"
						/* target="_blank" */
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div className="icondownload">
								<img src={iconDownloadManual} alt="icono descargar"/>
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/* Aeromexico Connect NIVEL 10 */}
				{/* Aeromexico Connect NIVEL 10*/}
				{/* Aeromexico Connect NIVEL 10*/}
				{localStorage.getItem('compania') === 'Aeroméxico Connect' &&
				localStorage.getItem('nivel') === '10' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EaG1S9k0imdCo856ODkzJcYBXVHpfvL96lm3x2QioBVdVw?e=eRRaIt"
						/* target="_blank" */
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div className="icondownload">
								<img src={iconDownloadManual} alt="icono descargar" />
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/* Aeromexico Servicios NIVEL 1 */}
				{/* Aeromexico Servicios NIVEL 1*/}
				{/* Aeromexico Servicios NIVEL 1*/}
				{localStorage.getItem('compania') === 'Aeroméxico Servicios' &&
				localStorage.getItem('nivel') === '1' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EW1tshZyWphCvdQkObAQEUsBjVZ8V1H5WSBDhXDKOEctIQ?e=UFc28i"
						target="_blank"
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div class="icondownload">
								<img src={iconDownloadManual} />
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/* Aeromexico Servicios NIVEL 2 */}
				{/* Aeromexico Servicios NIVEL 2*/}
				{/* Aeromexico Servicios NIVEL 2*/}
				{localStorage.getItem('compania') === 'Aeroméxico Servicios' &&
				localStorage.getItem('nivel') === '2' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EfnhAeTC2cVKkBpd9lxmxlwBXs1I2KUnGq4fAKOytixhIA?e=5DqsNY"
						target="_blank"
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div class="icondownload">
								<img src={iconDownloadManual} />
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/* Aeromexico Servicios NIVEL 3 */}
				{/* Aeromexico Servicios NIVEL 3*/}
				{/* Aeromexico Servicios NIVEL 3*/}
				{localStorage.getItem('compania') === 'Aeroméxico Servicios' &&
				localStorage.getItem('nivel') === '3' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EZIUB4dLeV1EgCsG_cn21R8BPX8_t3_Igr32MC3e6jEAeg?e=vudLAp"
						target="_blank"
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div class="icondownload">
								<img src={iconDownloadManual} />
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/* Aeromexico Servicios NIVEL 4 */}
				{/* Aeromexico Servicios NIVEL 4*/}
				{/* Aeromexico Servicios NIVEL 4*/}
				{localStorage.getItem('compania') === 'Aeroméxico Servicios' &&
				localStorage.getItem('nivel') === '4' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/Eeq8RozJf-pClPQw5Gz7wY4Bqmw-ZNaluNmJMLdMbfZR2A?e=UFaKF5"
						target="_blank"
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div class="icondownload">
								<img src={iconDownloadManual} />
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/* Aeromexico Servicios NIVEL 5 */}
				{/* Aeromexico Servicios NIVEL 5*/}
				{/* Aeromexico Servicios NIVEL 5*/}
				{localStorage.getItem('compania') === 'Aeroméxico Servicios' &&
				localStorage.getItem('nivel') === '5' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/ER5LktV4cChDo1AE3OHozH4BZOWoxRaMXaFL8Vtp22EdNQ?e=8QfRKR"
						target="_blank"
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div class="icondownload">
								<img src={iconDownloadManual} />
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/* Aeromexico Servicios NIVEL 6 */}
				{/* Aeromexico Servicios NIVEL 6*/}
				{/* Aeromexico Servicios NIVEL 6*/}
				{localStorage.getItem('compania') === 'Aeroméxico Servicios' &&
				localStorage.getItem('nivel') === '6' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/Efa4LSGYOjNKgX3QrqvP7MYBnX467Z3dqL7kEwkHZEC5zA?e=6SgzBj"
						target="_blank"
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div class="icondownload">
								<img src={iconDownloadManual} />
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/* Aeromexico Servicios NIVEL 7 */}
				{/* Aeromexico Servicios NIVEL 7*/}
				{/* Aeromexico Servicios NIVEL 7*/}
				{localStorage.getItem('compania') === 'Aeroméxico Servicios' &&
				localStorage.getItem('nivel') === '7' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/ETZ9joC_KrdPuJ1Lfs7F21kBomEpcD1msyHzZYPGLvZeWg?e=UvkiVy"
						target="_blank"
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div class="icondownload">
								<img src={iconDownloadManual} />
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/* Aeromexico Servicios NIVEL 8 */}
				{/* Aeromexico Servicios NIVEL 8*/}
				{/* Aeromexico Servicios NIVEL 8*/}
				{localStorage.getItem('compania') === 'Aeroméxico Servicios' &&
				localStorage.getItem('nivel') === '8' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EX-hpdsyAyJOtXDg5WmrmtQBAWQ4fbQEud0CtOxW9Gh0Tg?e=fFY2kC"
						target="_blank"
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div class="icondownload">
								<img src={iconDownloadManual} />
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/* Aeromexico Servicios NIVEL 9 */}
				{/* Aeromexico Servicios NIVEL 9*/}
				{/* Aeromexico Servicios NIVEL 9*/}
				{localStorage.getItem('compania') === 'Aeroméxico Servicios' &&
				localStorage.getItem('nivel') === '9' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EcgQSrQY9FVAmElmWa2VqzkBEbAnOloBgGnwVoL0240Ebg?e=ab1onD"
						target="_blank"
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div class="icondownload">
								<img src={iconDownloadManual} />
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/* Aeromexico Servicios NIVEL 10 */}
				{/* Aeromexico Servicios NIVEL 10*/}
				{/* Aeromexico Servicios NIVEL 10*/}
				{localStorage.getItem('compania') === 'Aeroméxico Servicios' &&
				localStorage.getItem('nivel') === '10' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EVsz4vZ5mBpFjSFONbNp3jcBIkHoKQ96FAgbQY6_mTrw4Q?e=GZTItA"
						target="_blank"
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div class="icondownload">
								<img src={iconDownloadManual} />
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/*Centro de Servicios Compartidos NIVEL 2 */}
				{/* Centro de Servicios Compartidos NIVEL 2*/}
				{/*Centro de Servicios Compartidos NIVEL 2*/}
				{localStorage.getItem('compania') ===
					'Centro de Servicios Compartidos' &&
				localStorage.getItem('nivel') === '2' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/Ebt1msQ8aV1GiFWUDUTZgNEBYfnLrWkCKZ-V5QGuTdX93g?e=vuatSF"
						target="_blank"
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div class="icondownload">
								<img src={iconDownloadManual} />
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/*Centro de Servicios Compartidos NIVEL 3 */}
				{/* Centro de Servicios Compartidos NIVEL 3*/}
				{/*Centro de Servicios Compartidos NIVEL 3*/}
				{localStorage.getItem('compania') ===
					'Centro de Servicios Compartidos' &&
				localStorage.getItem('nivel') === '3' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EWo1BlyV0YJEvsSVISU7ESgBsyepatesRTDS0SUtUVYatg?e=2krD4H"
						target="_blank"
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div class="icondownload">
								<img src={iconDownloadManual} />
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/*Centro de Servicios Compartidos NIVEL 5 */}
				{/* Centro de Servicios Compartidos NIVEL 5*/}
				{/*Centro de Servicios Compartidos NIVEL 5*/}
				{localStorage.getItem('compania') ===
					'Centro de Servicios Compartidos' &&
				localStorage.getItem('nivel') === '5' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EfNR9rO-w3VOpVhvoKhdr48BF3cHU0MmVZ45uUlZ5DatfA?e=H3bIly"
						target="_blank"
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div class="icondownload">
								<img src={iconDownloadManual} />
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/*Centro de Servicios Compartidos NIVEL 6*/}
				{/* Centro de Servicios Compartidos NIVEL 6*/}
				{/*Centro de Servicios Compartidos NIVEL 6*/}
				{localStorage.getItem('compania') ===
					'Centro de Servicios Compartidos' &&
				localStorage.getItem('nivel') === '6' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EfRaa-er3OFPgc9C_gggFVUBPcqx_2n0TTV4y_4x3cHKdQ?e=8rV6B3"
						target="_blank"
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div class="icondownload">
								<img src={iconDownloadManual} />
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/*Centro de Servicios Compartidos NIVEL 7 */}
				{/* Centro de Servicios Compartidos NIVEL 7*/}
				{/*Centro de Servicios Compartidos NIVEL 7*/}
				{localStorage.getItem('compania') ===
					'Centro de Servicios Compartidos' &&
				localStorage.getItem('nivel') === '7' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EZEY7rBsFM9Pp5sDC-ioA9sBCaMaazP9YqydfVsv1fXj5Q?e=0P4pYk"
						target="_blank"
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div class="icondownload">
								<img src={iconDownloadManual} />
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}

				{/*Centro de Servicios Compartidos NIVEL 8 */}
				{/* Centro de Servicios Compartidos NIVEL 8*/}
				{/*Centro de Servicios Compartidos NIVEL 8*/}
				{localStorage.getItem('compania') ===
					'Centro de Servicios Compartidos' &&
				localStorage.getItem('nivel') === '8' ? (
					<a
						href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EcOzZ0-qh4RBmzuAG2WhBhgBVZJ8xfLE6eX6YA1XdwfY5A?e=Rs185W"
						target="_blank"
						className="download-button"
						style={{
							backgroundImage: `linear-gradient(rgba(11, 35, 67, 0.1), rgba(11, 35, 67,.01)),url(${BtnDown})`
						}}
					>
						<div className="container-download">
							<div class="icondownload">
								<img src={iconDownloadManual} />
							</div>
							<div className="down-benefits">
								<h3 className="download-benefits">
									{i18next.t('MiExperAM.benef.manualTitle')}
								</h3>
								<h5 className="download-bnf">
									{i18next.t('MiExperAM.benef.manualSub')}
								</h5>
							</div>
						</div>
						<h2 className="download">
							{i18next.t('MiExperAM.benef.descargar')}
						</h2>
					</a>
				) : (
					''
				)}
			</div>
		);
	}
}
