import React, { Component } from "react";
import "./css/gamreport.css";
// import aguila from "../../../assets/logo/aguila.svg"
// import close from "../../../assets/logo/close.svg"
// import cardAnonimo from "../../../assets/logo/anonimo.svg"
// import cardGamReport from "../../../assets/logo/gam-report.svg"
// import GamReportInside from "./gamreportinside"
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";

export default class GamReport extends Component {
	constructor() {
		super();
		this.state = {
			modalIsShow: false,
			loading: true,
			error: null,
			entries: null
		};
		this.escFunction = this.escFunction.bind(this);
		this.setWrapperRef = this.setWrapperRef.bind(this);
	}

	componentDidMount() {
		document.addEventListener("keydown", this.escFunction, false);
		window.addEventListener("scroll", this.handleScroll);
	}

	handleScroll = event => {
		const scrollTop = window.pageYOffset;
		if (scrollTop > 290) {
			this.setState({ hasScrolled: true });
		} else {
			this.setState({ hasScrolled: false });
		}
	};

	UNSAFE_componentWillMount() {
		document.addEventListener("mousedown", this.handleClick, false);
	}
	escFunction(event) {
		if (event.keyCode === 27) {
			this.setState({
				modalIsShow: false
			});
		}
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.escFunction, false);
		document.removeEventListener("mousedown", this.handleClick, false);
	}
	handleClick = e => {
		if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
			this.setState({
				modalIsShow: false
			});
		}
	};
	setWrapperRef(node) {
		this.wrapperRef = node;
	}

	showAlertModal = () => {
		this.setState({
			modalIsShow: !this.state.modalIsShow
		});
	};

	render() {
		return (
			<div>
				<Link
					className={
						this.state.hasScrolled
							? "wrapperContGam wrapperContGamScroll"
							: "wrapperContGam"
					}
					to="/seguridad#gamreport"
				>
					<div className="opneModal">
						<div className="gamreportWrapper"></div>
						<div className="gamTextReport">
							<p>
								<Trans i18nKey="Footer.denuncia"></Trans>
							</p>
						</div>
					</div>
				</Link>

				{/* {this.state.modalIsShow === true ? (
            <div className="modalGamReportWrapper">
                <div ref={this.setWrapperRef} className="windowWhiteGam">
                <GamReportInside />
                    <div className="closeButton" onClick={this.showAlertModal}>
                        <img src={close}></img>
                    </div>
                    
                    <div className="paddingText">
                        <img src={aguila}></img>
                        <h4>¡Queremos escucharte!</h4>
                        <p>Utiliza estas herramientas para reportar temas de seguridad y de cumplimiento. <b>¡Levantar la mano es poner la seguridad primero!</b></p>
                    </div>

                    <div className="optionsGamReport">
                        <a className="cardOptionGamReport">
                            <img src={cardGamReport}></img>
                            <div className="textWrapper">
                              <p className="titleText">GAM e-Report</p>
                              <p className="smallText">¿Has detectado algún riesgo potencial o quieres reportar alguna omisión de seguridad?</p>
                            </div>
                            <div className="buttonGam">
                                <a>Enviar reporte</a>
                            </div>
                        </a>
                        
                        
                        <a className="cardOptionGamReport" href="https://www.tipsanonimos.com/aeromexico/View/Home/" target="_blank">
                            <img src={cardAnonimo}></img>
                            <div className="textWrapper">
                              <p className="titleText">Línea de denuncias anónimas</p>
                              <p className="smallText">¿Quieres denunciar alguna situación que vaya en contra de nuestro Código de Conducta?</p>
                            </div>
                            <div className="buttonGam">
                                <a>Enviar Tip Anónimo</a>
                            </div>
                        </a>
                    </div>


                </div>
            </div>
            ) : (
                ""
              )} */}
			</div>
		);
	}
}
