import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './css/submenu.css';
import i18next from 'i18next';

export default class Index extends Component {
	render() {
		return (
			<div className='sub-menu-container margin-2em'>
				<ul className='column-item-list'>
					<li className='Nav-list'>
						<h2 className='nav-title'> {i18next.t('News.ultimas')} </h2>
					</li>

					<li className='Nav-list all'>
						<Link
							className='link-all floatingmenu'
							to='/noticias/ultimas-noticias'
						>
							{i18next.t('News.todo')}
						</Link>
					</li>
				</ul>
			</div>
		);
	}
}
