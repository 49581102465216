import React, { Component } from "react";
import Modal from "react-awesome-modal";
import { Link } from "react-router-dom";

import arrow from "../../../assets/images/arrow-blue.svg";
import "../css/footerProfile.css";
import "../css/modalCard.css";
import "./css/view.css";
import close from "../../../assets/logo/close.svg";
import i18next from "i18next";
import initLang from "../../../utils/initLang";

var noScroll = require("no-scroll");

export default class DiscountCard extends Component {
	constructor() {
		super();
		this.state = {
			show: false,
			visible: false,
			lang: ""
		};
		this.escFunction = this.escFunction.bind(this);
		this.setWrapperRef = this.setWrapperRef.bind(this);
	}
	componentDidMount() {
		document.addEventListener("keydown", this.escFunction, false);
		document.addEventListener("mousedown", this.handleClick, false);
		initLang(this);
	}
	UNSAFE_componentWillMount() {
		document.addEventListener("mousedown", this.handleClick, false);
	}
	componentWillUnmount() {
		document.removeEventListener("keydown", this.escFunction, false);
		document.removeEventListener("mousedown", this.handleClick, false);
	}
	escFunction(event) {
		if (event.keyCode === 27) {
			this.setState({
				show: false,
				show: false
			});
			noScroll.off();
		}
	}
	handleClick = e => {
		if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
			this.setState({
				show: false
			});
			noScroll.off();
		}
	};
	openModal() {
		this.setState({
			visible: true
		});
		noScroll.on();
	}

	closeModal() {
		this.setState({
			visible: false
		});
		noScroll.off();
	}
	setWrapperRef(node) {
		this.wrapperRef = node;
	}
	show() {
		this.setState({ show: true });
	}
	close() {
		this.setState({ show: false });
	}
	render() {
		return (
			<React.Fragment>
				<div key={this.props.data.sys.id}>
					<div
						onClick={() => this.openModal()}
						className={
							"footerProfile__newsCard " +
							this.props.data.fields.myDiscountsImageTypeClass
						}
					>
						<div
							className={
								"footerProfile__newsCard__textContainer" +
								" " +
								this.props.data.fields
									.myDiscountsTextColorCard +
								" " +
								this.props.data.fields.myDiscountsDiscountEnable
							}
						>
							<h5 className="footerProfile__newsCard__title">
								{this.props.data.fields.myDiscountsTitle}{" "}
							</h5>
							<h6 className="footerProfile__newsCard__information">
								{this.props.data.fields.myDiscountsIntro}
							</h6>

							<div className="descountCircle">
								<h6 className="footerProfile__newsCard__descount">
									{
										this.props.data.fields
											.myDiscountsDiscountNumber
									}
								</h6>
							</div>

							<a
								className="link-benefitss"
								onClick={() => this.openModal()}
							>
								<div className="moreTravel">
									<h6 className="footerProfile__newsCard__more">
										{i18next.t("MiExperAM.benef.conoce")}
									</h6>
									<img
										className="blue-arrow"
										src={arrow}
										alt="blue-arrow"
									/>{" "}
								</div>
							</a>
						</div>
						<div
							className="footerProfile__newsCard__imageContainer"
							style={{
								backgroundImage: `url(${this.props.data.fields.myDiscountsImageCard.fields.file.url})`
							}}
						>
							<img
								className="footerProfile__newsCard__img"
								src={
									this.props.data.fields.myDiscountsImageCard
										.fields.file.url
								}
								alt="some"
							/>
						</div>
					</div>

					<Modal
						visible={this.state.visible}
						width="auto"
						height="auto"
						effect="fadeInDown"
						onClickAway={() => this.closeModal()}
					>
						<div className="container-footer-card-modal">
							<div
								class="closeModal"
								onClick={() => this.closeModal()}
							>
								<img src={close} />
							</div>
							<div className="modal-footer-card-top">
								<div className="modal-image-container">
									<img
										className="modal-image"
										src={
											this.props.data.fields
												.myDiscountsImageModal.fields
												.file.url
										}
										alt="jirafa"
									/>
									<p
										className={
											"discountNumberImg" +
											" " +
											this.props.data.fields
												.myDiscountsDiscountEnable
										}
									>
										-
										{
											this.props.data.fields
												.myDiscountsDiscountNumber
										}
										%
									</p>
								</div>
								<div className="container-content-top-modal">
									<h4 className="type-title">
										{this.state.lang === "es"
											? this.props.data.fields
													.myDiscountsCategory
											: this.props.data.fields
													.myDiscountsCategoryEn}
									</h4>
									<h3 className="type-title-modal">
										{
											this.props.data.fields
												.myDiscountsTitle
										}
									</h3>
									<p className="description-top">
										{
											this.props.data.fields
												.myDiscountsIntro
										}
									</p>
								</div>
							</div>

							<div className="modal-footer-card-content">
								<p
									className={
										"discount-modal" +
										" " +
										this.props.data.fields
											.myDiscountsDiscountEnable
									}
								>
									{
										this.props.data.fields
											.myDiscountsDiscountNumber
									}
									{i18next.t(
										"MiExperAM.benef.descuentos.descuentoPrc"
									)}
								</p>
								<div className="policy-modal">
									<div className="policy-container-modal">
										{/* <div className="policy-content" dangerouslySetInnerHTML={{__html: this.props.data.fields.myDiscountsNeeds}}></div> */}
										<div
											className="policy-content"
											dangerouslySetInnerHTML={{
												__html: this.props.data.fields
													.myDiscountsExtraInformation
											}}
										></div>
									</div>
								</div>
							</div>
						</div>
					</Modal>
				</div>
			</React.Fragment>
		);
	}
}
