import React, { Component, Fragment } from 'react';
import arrow from '../../../assets/images/arrow-blue.svg';
import { Link } from 'react-router-dom';
import * as contentful from '../../../lib/contentful';
import DiscountCard from './DiscountCard';
import './css/view.css';
import getLocale from '../../../utils/getLocale';
import i18next from 'i18next';

export default class DiscountViewSalud extends Component {
	constructor() {
		super();
		this.state = {
			loading: true,
			error: null,
			entries: null
		};
	}
	componentDidMount() {
		this.fetchEntries();
	}

	// componentDidUpdate (prevProps) {
	//   if (
	//     this.props.match.params.discountId !== prevProps.match.params.discountId
	//   ) {
	//     this.fetchEntries()
	//   }
	// }

	fetchEntries = () => {
		contentful.client
			.getEntries({
				// content_type: "myDiscountsFeatured",
				include: 2,
				// order: '-sys.updatedAt',
				order: 'fields.myDiscountsTitle',
				locale: getLocale(),
				content_type: 'myDiscountsFeatured',
				'fields.myDiscountsClass[all]': 'EducationDiscountsReward'
			})

			.then((response) => response.items)
			.then((data) => {
				this.setState({
					loading: false,
					entries: data
				});
			})

			.catch((error) => {
				this.setState({
					loading: false,
					error: error
				});
			})
			.catch((err) => console.error(err));
	};

	// fetchEntries = () => {
	//   contentful.client
	//     .getEntries({
	//       content_type: '',
	//       include: 2,
	//       order: '-sys.updatedAt',
	//       locale: "es-MX"
	//     })
	//     .then(response => response.items)
	//     .then(data => {
	//       console.log(data)
	//       this.setState({
	//         loading: false,
	//         entries: data
	//       })
	//     })
	//     .catch(error => {
	//       this.setState({
	//         loading: false,
	//         error: error
	//       })
	//     })
	//     .catch(err => console.error(err))
	// }

	// renderList = entries => {
	//   const miniCard = entries.filter(item => {
	//     if (this.props.match.params.discountId === item.fields.myDiscountsClass) {
	//       return item
	//     }
	//   })

	//   const list = miniCard.map(item => {
	//     return <DiscountCard key={item.sys.id} data={item} />
	//   })

	//   return list
	// }

	//   discouts = discountClass => {
	//     const classDiscount = {
	//       healthDiscountsReward: 'Salud y Belleza',
	//       EntertainDiscountsReward: 'Entretenimiento',
	//       GymDiscountsReward: 'Gimnasios',
	//       ShoppingDiscountsReward: 'Shopping',
	//       EducationDiscountsReward: 'Educación',
	//       GardenDiscountsReward: 'Guarderías',
	//       RestaurantsDiscountsReward: 'Restaurantes',
	//       HomeDiscountsReward: 'Bienes Raices',
	//       CarsDiscountsReward: 'Autos',
	//       ServicesDiscountsReward: 'Servicios',
	//       LanguagesDiscountsReward: 'Idiomas',
	//       MoreDiscountsReward: 'Más descuentos'
	//     }
	//     return classDiscount[discountClass]
	//   }
	render() {
		const { loading, entries } = this.state;
		return (
			<>
				<div className="blue-header EnjoyWorkColorr">
					<div className="backContainer">
						<div className="backContainerWrapper">
							{!!localStorage.getItem('nivel') ? (
								<Link
									to="/recompensa-total/perfil/mis-beneficios"
									id="backLink"
								>
									<span>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 32 32"
											aria-hidden="true"
											focusable="false"
											class="ay bq ci cb cc cd ce bc ef bj"
										>
											<path d="M23.23 32a1.53 1.53 0 0 0 1.08-2.61L10.93 16 24.32 2.61A1.53 1.53 0 0 0 22.15.45L7.68 14.92a1.53 1.53 0 0 0 0 2.16l14.47 14.47a1.53 1.53 0 0 0 1.08.45z" />
										</svg>
									</span>
									<p>
										{i18next.t(
											'MiExperAM.benef.categorias.regresar'
										)}
									</p>
								</Link>
							) : (
								<Link to="/mis-beneficios" id="backLink">
									<span>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 32 32"
											aria-hidden="true"
											focusable="false"
											class="ay bq ci cb cc cd ce bc ef bj"
										>
											<path d="M23.23 32a1.53 1.53 0 0 0 1.08-2.61L10.93 16 24.32 2.61A1.53 1.53 0 0 0 22.15.45L7.68 14.92a1.53 1.53 0 0 0 0 2.16l14.47 14.47a1.53 1.53 0 0 0 1.08.45z" />
										</svg>
									</span>
									<p>Regresar a Mis Beneficios</p>
								</Link>
							)}
						</div>
					</div>
					<div className="HeaderWrapper">
						<div className="HeaderCell">
							<div className="newsContainer">
								<p>
									<span>
										{i18next.t(
											'MiExperAM.benef.descuentos.educa'
										)}
									</span>
								</p>
								<p className="IntroHeader" />
							</div>
						</div>
					</div>
				</div>
				<div className="wrapper-cont-discounts">
					<div className="footerProfile__middleContainer">
						<div className="footerProfile__newsContainer discount-cards-container">
							{/* {!loading && entries && this.renderList(entries)} */}

							{!loading &&
								entries &&
								entries.map((item) => (
									<DiscountCard data={item} />
								))}
						</div>
					</div>
				</div>
			</>
		);
	}
}
