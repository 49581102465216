import React, { Component } from "react";

import Modal from "react-awesome-modal";
import close from "../../../assets/logo/close.svg";
import Ejecutivo from "../../../assets/compliance/benefits/ejecutivo.jpg";
import Capacitacion from "../../../assets/compliance/benefits/capacitacion.jpg";
import Normativo from "../../../assets/compliance/benefits/normativo.jpg";
import Tecnologia from "../../../assets/compliance/benefits/tecnologia.jpg";
import Seguridad from "../../../assets/compliance/benefits/seguridad.png";
import Security from "../../../assets/compliance/benefits/seguridad-en.png";
import Integridad from "../../../assets/compliance/benefits/integridad.png";
import Integrity from "../../../assets/compliance/benefits/integridad-en.png";
var noScroll = require("no-scroll");

export default class AmbitionBtn extends Component {
	constructor(props) {
		super(props);
		this.state = {
			visible: false,
			backgroundImage: Capacitacion,
			seguridad: this.detectSecurity(),
			integridad: this.detectIntegrity()
		};
		this.escFunction = this.escFunction.bind(this);
		this.setWrapperRef = this.setWrapperRef.bind(this);
	}

	selectImage(evaluador) {
		switch (evaluador) {
			case "Capacitación y Difusión":
				return Capacitacion;
			case "Tecnológico":
				return Tecnologia;
			case "Ejecutivo":
				return Ejecutivo;
			default:
				return Normativo;
		}
	}

	componentDidMount() {
		document.addEventListener("keydown", this.escFunction, false);
		document.addEventListener("mousedown", this.handleClicktest, false);
		this.selectImage();
	}

	UNSAFE_UNSAFE_componentWillMount() {
		document.addEventListener("mousedown", this.handleClicktest, false);
	}
	componentWillUnmount() {
		document.removeEventListener("keydown", this.escFunction, false);
		document.removeEventListener("mousedown", this.handleClicktest, false);
	}
	escFunction(event) {
		if (event.keyCode === 27) {
			this.setState({
				visible: false
			});
			noScroll.off();
		}
	}
	handleClick = e => {
		if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
			this.setState({
				visible: false
			});
			noScroll.off();
		}
	};
	setWrapperRef(node) {
		this.wrapperRef = node;
	}

	openModal() {
		this.setState({
			visible: true
		});
		noScroll.on();
	}

	closeModal() {
		this.setState({
			visible: false
		});
		noScroll.off();
	}

	detectSecurity() {
		if (this.props.language === "es") {
			return Seguridad;
		} else {
			return Security;
		}
	}

	detectIntegrity() {
		if (this.props.language === "es") {
			return Integridad;
		} else {
			return Integrity;
		}
	}

	render() {
		const myImage = this.selectImage(this.props.background);
		return (
			<React.Fragment>
				<div
					className={`a-item ${
						this.props.hasImage ? "overflow-hidden" : ""
					}`}
					onClick={() => {
						this.openModal();
					}}
				>
					{this.props.hasImage && (
						<React.Fragment>
							<div
								className="temp-bg-image"
								style={{
									background: `url(${myImage})`
								}}
							></div>
							<img src={this.props.img} alt={this.props.title} />
						</React.Fragment>
					)}
					{!this.props.hasImage && (
						<React.Fragment>
							{this.props.hasBoth ? (
								<div className="double-img">
									<img
										className="cover-size double"
										src={this.state.seguridad}
										alt="Seguridad"
									/>
									<img
										className="cover-size double"
										src={this.state.integridad}
										alt="Integridad"
									/>
								</div>
							) : (
								<React.Fragment>
									<img
										className="cover-size"
										src={
											this.props.seguridad
												? this.state.seguridad
												: this.state.integridad
										}
										alt={this.props.title}
									/>
								</React.Fragment>
							)}
						</React.Fragment>
					)}

					<h3>{this.props.title}</h3>
					<button className="more">Conoce más</button>
				</div>
				<Modal
					visible={this.state.visible}
					width="auto"
					height="auto"
					effect="fadeInDown"
					onClickAway={() => this.closeModal()}
				>
					<div
						ref={this.setWrapperRef}
						className="container-footer-card-modal compliance-modal-2"
					>
						<div
							class="closeModal"
							onClick={() => this.closeModal()}
						>
							<img src={close} alt="Cerrar modal" />
						</div>

						<div
							className="modal-footer-card-top"
							style={{
								background: `url(${
									this.props.hasImage
										? myImage
										: this.props.background
								})`
							}}
						>
							{this.props.hasImage && (
								<img
									className="modal-image"
									src={this.props.img}
									alt="jirafa"
								/>
							)}
							{!this.props.hasImage && (
								<React.Fragment>
									{this.props.hasBoth ? (
										<div className="two-images">
											<img
												className="cover-size double"
												src={this.state.seguridad}
												alt="Seguridad"
											/>
											<img
												className="cover-size double"
												src={this.state.integridad}
												alt="Integridad"
											/>
										</div>
									) : (
										<img
											className="cover-size"
											src={
												this.props.seguridad
													? this.state.seguridad
													: this.state.integridad
											}
											alt={this.props.title}
										/>
									)}
								</React.Fragment>
							)}
						</div>

						<div className="modal-footer-card-content">
							<h3 className="type-title-modal">
								{this.props.title}
							</h3>
							<div className="actual-modal-content">
								<p
									dangerouslySetInnerHTML={{
										__html: this.props.content
									}}
								></p>
							</div>
						</div>
					</div>
				</Modal>
			</React.Fragment>
		);
	}
}
