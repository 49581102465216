import React, { Component } from "react";
import { Link } from "react-router-dom";
import arrow from "../../../assets/images/arrow-blue.svg";
import Modal from "react-awesome-modal";
import close from "../../../assets/logo/close.svg";
import * as contentful from "../../../lib/contentful";
import Swiper from "react-id-swiper";
import "../../Slider/css/swiper.css";
import getLocale from "../../../utils/getLocale";
import i18next from "i18next";

var noScroll = require("no-scroll");

export default class NewsCard extends Component {
	constructor() {
		super();
		this.state = {
			show: false,
			visible: false,
			loading: true,
			error: null,
			entries: null
		};
		this.escFunction = this.escFunction.bind(this);
		this.setWrapperRef = this.setWrapperRef.bind(this);
	}

	componentDidMount() {
		document.addEventListener("keydown", this.escFunction, false);
		document.addEventListener("mousedown", this.handleClicktest, false);
		this.fetchEntries();
	}

	UNSAFE_UNSAFE_componentWillMount() {
		document.addEventListener("mousedown", this.handleClicktest, false);
	}
	componentWillUnmount() {
		document.removeEventListener("keydown", this.escFunction, false);
		document.removeEventListener("mousedown", this.handleClicktest, false);
	}
	openModal() {
		this.setState({
			visible: true
		});
		noScroll.on();
	}

	closeModal() {
		this.setState({
			visible: false
		});
		noScroll.off();
	}

	escFunction(event) {
		if (event.keyCode === 27) {
			this.setState({
				visible: false
			});
			noScroll.off();
		}
	}
	handleClick = e => {
		if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
			this.setState({
				visible: false
			});
			noScroll.off();
		}
	};
	setWrapperRef(node) {
		this.wrapperRef = node;
	}

	show = () => {
		this.setState({ show: true });
	};

	fetchEntries = () => {
		contentful.client
			.getEntries({
				content_type: "myDiscounts",
				include: 2,
				order: "-sys.updatedAt",
				locale: getLocale()
			})
			.then(response => response.items)
			.then(data => {
				
				this.setState({
					loading: false,
					entries: data
				});
			})
			.catch(error => {
				this.setState({
					loading: false,
					error: error
				});
			})
			.catch(err => console.error(err));
	};

	getTag() {
		if (i18next.language === "es") {
			return this.props.data.fields.myDiscountsCategory;
		} else {
			return this.props.data.fields.myDiscountsCategoryEn;
		}
	}

	render() {
		const { loading, entries } = this.state;
		return (
			<React.Fragment>
				<div>
					<div id={this.props.data.sys.id} className="CardDiscount">
						<div
							onClick={() => this.openModal()}
							className={
								"benefits-big-card benefits" +
								" " +
								this.props.data.fields.myDiscountsImageTypeClass
							}
						>
							<div
								className={
									"container-content-big-card" +
									" " +
									this.props.data.fields
										.myDiscountsTextColorCard
								}
							>
								<p className="smallTextDiscount">
									{this.getTag()}
								</p>
								<h5 className="moreTravelBenefits__card__title">
									{this.props.data.fields.myDiscountsTitle}
								</h5>
								<p className="moreTravelBenefits__card__text">
									{this.props.data.fields.myDiscountsIntro}
								</p>
								<span
									class={
										"descountCircle benefit-card" +
										" " +
										this.props.data.fields
											.myDiscountsDiscountEnable
									}
								>
									{
										this.props.data.fields
											.myDiscountsDiscountNumber
									}
									%
								</span>

								<a
									className="link-benefitss"
									onClick={() => this.openModal()}
								>
									<div className="moreTravel link-more">
										<h6 className="moreTravelBenefits__card__more">
											{i18next.t(
												"MiExperAM.benef.conoce"
											)}{" "}
										</h6>
										<img
											className="blue-arrow"
											src={arrow}
											alt="blue-arrow"
										/>{" "}
									</div>
								</a>
							</div>

							<div
								className="container-image-big-card"
								style={{
									backgroundImage: `url(${this.props.data.fields.myDiscountsImageCard.fields.file.url})`
								}}
							>
								<img
									src={
										this.props.data.fields
											.myDiscountsImageCard.fields.file
											.url
									}
									alt="img-big-card"
									className="img-big-card"
								/>
							</div>
						</div>
					</div>

					<Modal
						visible={this.state.visible}
						width="auto"
						height="auto"
						effect="fadeInDown"
						onClickAway={() => this.closeModal()}
					>
						<div>
							<div>
								<div
									ref={this.setWrapperRef}
									className="container-footer-card-modal"
								>
									<div
										class="closeModal"
										onClick={() => this.closeModal()}
									>
										<img src={close} />
									</div>

									<div className="modal-footer-card-top">
										<div className="modal-image-container">
											<img
												className="modal-image"
												src={
													this.props.data.fields
														.myDiscountsImageModal
														.fields.file.url
												}
												alt="jirafa"
											/>
											<p
												className={
													"discountNumberImg" +
													" " +
													this.props.data.fields
														.myDiscountsDiscountEnable
												}
											>
												-
												{
													this.props.data.fields
														.myDiscountsDiscountNumber
												}
												%
											</p>
										</div>
										<div className="container-content-top-modal">
											<h4 className="type-title">
												{this.getTag()}
											</h4>
											<h3 className="type-title-modal">
												{
													this.props.data.fields
														.myDiscountsTitle
												}
											</h3>
											<p className="description-top">
												{
													this.props.data.fields
														.myDiscountsIntro
												}
											</p>
										</div>
									</div>

									<div className="modal-footer-card-content">
										<p
											className={
												"discount-modal" +
												" " +
												this.props.data.fields
													.myDiscountsDiscountEnable
											}
										>
											{
												this.props.data.fields
													.myDiscountsDiscountNumber
											}
											{i18next.t(
												"MiExperAM.benef.descPrc"
											)}
										</p>
										<div className="policy-modal">
											<div className="policy-container-modal">
												{/* <div className="policy-content" dangerouslySetInnerHTML={{__html: this.props.data.fields.myDiscountsNeeds}}></div> */}
												<div
													className="policy-content"
													dangerouslySetInnerHTML={{
														__html: this.props.data
															.fields
															.myDiscountsExtraInformation
													}}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Modal>
				</div>
			</React.Fragment>
		);
	}
}
