import React, { useState } from "react";
import { Link } from "react-router-dom";
import i18next from "i18next";
import SwiperDiscountModal from "../swiperfeatured";
import FooterProfile from "../FooterProfile";
import profileIcon from "../../../assets/images/profile/icon-mybenefits.svg";
import logobenefit from "../../../assets/images/profile/mybenefitslogo.svg";
import logobenefitEN from "../../../assets/canada/my-benefits.png";
import profileIcon02 from "../../../assets/images/profile/icon-mybenefits-02.svg";
import heart from "../../../assets/images/profile/categoria-01.svg";
import Entretenimiento from "../../../assets/images/profile/categoria-02.svg";
import pesas from "../../../assets/images/profile/categoria-03.svg";
import shopping from "../../../assets/images/profile/categoria-04.svg";
import Educacion from "../../../assets/images/profile/categoria-05.svg";
import restaurante from "../../../assets/images/profile/categoria-07.svg";
import auto from "../../../assets/images/profile/categoria-09.svg";
import Servicios from "../../../assets/images/profile/categoria-10.svg";
import Guarderia from "../../../assets/images/profile/categoria-06.svg";
import { motion } from "framer-motion";
import Arriba from "../../Header/Arriba";
import Index from "../../Footer/index";

function BenefitsOpenMX() {
	const lang = i18next.language;

	return (
		<section className="flex w-full flex-wrap items-start justify-start bg-amLightBlue ">	
		{/* <Arriba/> */}
		<div>
		<div className="flex w-full flex-wrap items-start justify-start bg-amLightBlue px-10 pb-14 lg:px-64">
		<motion.div
				initial={{ opacity: 0, y: 100 }}
				animate={{ opacity: 1, y: 0 }}
				exit={{ opacity: 0, y: 100 }}
				className="totalBenefits__title__container beneficiosfloatright">
				<div className="iconTitleProfile">
					<img src={profileIcon} alt="Profile Icon" />
				</div>
				<h1 className="!text-[#035cf7]">{i18next.t("MiExperAM.benef.destacados")}</h1>

				<div className="logoTabBenefit">
					<img src={lang === "es" ? logobenefit : logobenefitEN} alt="My Benefits" />
				</div>

				<div class="header_small">
					<div class="iconTitleProfile_small">
						<img src={profileIcon} alt="Profile Icon" />
					</div>
					<h1>{i18next.t("MiExperAM.benef.destacados")}</h1>
				</div>
			</motion.div>

			<motion.div
				initial={{ opacity: 0, y: 100 }}
				animate={{ opacity: 1, y: 0 }}
				exit={{ opacity: 0, y: 100 }}
				transition={{ delay: 0.25 }}
				className="swiperDiscountsCont w-full">
				<SwiperDiscountModal />
			</motion.div>

			<motion.div className="discountPerks__container flex flex-wrap items-start justify-start">
				<div className="totalBenefits__title__container">
					<div className="iconTitleProfile">
						<img src={profileIcon02} alt="Profile Icon 02" />
					</div>
					<h1 className="!text-[#035cf7]">{i18next.t("MiExperAM.benef.descuentos.title")}</h1>
				</div>
				<div className="discountsPerks__list__container">
					<ul>
						<li>
							<Link to="/recompensa-total/mis-beneficios/descuentos/salud-y-belleza">
								{" "}
								<img src={heart} className="discount_list" alt="Salud y belleza" />
								<span className="discount_l">{i18next.t("MiExperAM.benef.descuentos.salud")}</span>
							</Link>
						</li>
						<li>
							<Link to="/recompensa-total/mis-beneficios/descuentos/entretenimiento">
								{" "}
								<img src={Entretenimiento} className="discount_list" alt="Entretenimiento" />
								<span className="discount_l">{i18next.t("MiExperAM.benef.descuentos.entrete")}</span>
							</Link>
						</li>
						<li>
							<Link to="/recompensa-total/mis-beneficios/descuentos/gimnasios">
								<img src={pesas} className="discount_list" alt="Gimnasios" />
								<span className="discount_l">{i18next.t("MiExperAM.benef.descuentos.gym")}</span>
							</Link>
						</li>
					</ul>
					<ul>
						<li>
							<Link to="/recompensa-total/mis-beneficios/descuentos/de-compras">
								{" "}
								<img src={shopping} className="discount_list" alt="Shopping" />
								<span className="discount_l">{i18next.t("MiExperAM.benef.descuentos.shopp")}</span>
							</Link>
						</li>
						<li>
							<Link to="/recompensa-total/mis-beneficios/descuentos/educacion">
								<img src={Educacion} className="discount_list" alt="Educación" />
								<span className="discount_l">{i18next.t("MiExperAM.benef.descuentos.educa")}</span>
							</Link>
						</li>
					</ul>
					<ul>
						<li>
							<Link to="/recompensa-total/mis-beneficios/descuentos/restaurantes">
								{" "}
								<img src={restaurante} className="discount_list" alt="Restaurantes" />
								<span className="discount_l">{i18next.t("MiExperAM.benef.descuentos.resta")}</span>
							</Link>
						</li>
						<li>
							<Link to="/recompensa-total/mis-beneficios/descuentos/automoviles">
								{" "}
								<img src={auto} className="discount_list" alt="autos" />
								<span className="discount_l">{i18next.t("MiExperAM.benef.descuentos.auto")}</span>
							</Link>
						</li>
					</ul>
					<ul>
						<li>
							<Link to="/recompensa-total/mis-beneficios/descuentos/servicios">
								<img src={Servicios} className="discount_list" alt="servicios" />
								<span className="discount_l">{i18next.t("MiExperAM.benef.descuentos.servi")}</span>
							</Link>
						</li>
						<li>
							<Link to="/recompensa-total/mis-beneficios/descuentos/guarderias">
								<img src={Guarderia} className="discount_list" alt="Guarderias" />
								<span className="discount_l">{i18next.t("MiExperAM.benef.descuentos.guarde")}</span>
							</Link>
						</li>
					</ul>
				</div>
				
			</motion.div>
			
			<FooterProfile marginBottom="mb-0" />
		</div>
		
		{/* <Index />	 */}
			
	</div>
		
		</section>
		
	);
}

export default BenefitsOpenMX;
