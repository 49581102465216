import React, { Component } from "react";
import "./css/styles.css";
import logoSVG from "../../assets/images/logo/logo-aeromexico-white.svg";
import logoPNGMobile from "../../assets/icon-mobile.jpg";
import avionbg from "../../assets/images/login/avion-bg.png";

import AuthService from "../../services/auth.service";
import GraphService from "../../services/graph.service";
import i18next from "i18next";
import close from "../../assets/logo/close.svg";
import ModalLang from "react-awesome-modal";
import initLang from "../../utils/initLang";
import { Trans } from "react-i18next";
import isUSABlockedUser from "../../utils/isUSABlockedUser";
import NewWindow from "react-new-window";
import { ReactComponent as GateOne } from "./assets/gateone.svg";
import { isLocal, isFromDev, isFromQA } from './../../core/Ambientes';
import OktaSignIn from "@okta/okta-signin-widget"; 
import { Redirect } from "react-router-dom";

class Index extends Component {
	constructor() {
		super();
		this.authService = new AuthService();
		this.graphService = new GraphService();
		this.state = {
			user: null,
			userInfo: null,
			apiCallFailed: false,
			loginFailed: false,
			// prueba: "",
			redireccion: false,
			displayableId: "",
			explorer: false,
			edge: false,
			lang: "",
			langVisible: false,
			langClass: "non",
			langMovilVisible: false,
			gateOnePop: false,
			isLocalHost: window.location.hostname === "localhost",
			existSesionGateone: false
		};
	}

	oktaValidate = async (searchParams) => {
		// # Variables que switchean las ligas que se consumen segun sea elcaso

		// # Variables que switchean las ligas que se consumen segun sea elcaso
		// const isLocal = process.env.REACT_APP_ISLOCAL; // Tomara entorno local
		// const isFromDev = process.env.REACT_APP_ISFROMDEV; //Se pondra en true para ambiente DEV
		// const isFromQA = process.env.REACT_APP_ISFROMQA; //Se pondra en true para ambiente QA
		/* Para productivo se ponen todas en falso */

		// #URl de ambiente
		const urlLocal = `${process.env.REACT_APP_LOCAL}`;
		const urlDev = `${process.env.REACT_APP_CADMIN_DEV}`;
		const urlQA = `${process.env.REACT_APP_CADMIN_QA}`;
		const urlProd = `${process.env.REACT_APP_CADMIN_PROD}`;

		const tokenLocal = `${process.env.REACT_APP_TOKEN_LOCAL}`;
		const tokenDev = `${process.env.REACT_APP_CADMIN_DEV_TOKEN}`;
		const tokenQA = `${process.env.REACT_APP_CADMIN_QA_TOKEN}`;
		const tokenProd = `${process.env.REACT_APP_API_TOKEN}`;

		// # Liga validada
		const urlValid =
			isLocal === true ? urlLocal : isFromDev === true ? urlDev : isFromQA === true ? urlQA : urlProd;

		// Se genera el token segun el ambiente
		const tokenValid =
			isLocal === true ? tokenLocal : isFromDev === true ? tokenDev : isFromQA === true ? tokenQA : tokenProd;

		const cabecera = new Headers({
			"Content-type": "application/json",
			Authorization: "Bearer " + tokenValid
		});

		const oktaData = await fetch(encodeURI(`${urlValid}okta-user/${searchParams.get("email")}`), {
			headers: cabecera
		})
			.then((res) => res.json())
			.then((resData) => {
				localStorage.setItem("oktaToken", searchParams.get("token"));
				localStorage.setItem("token", resData.id);
				localStorage.setItem("email", resData.email);
				localStorage.setItem("okta-user-id", searchParams.get("userID"));
				this.setState({
					// user: searchParams.get('user'),
					redireccion: true
				});

				// const url = window.decodeURIComponent(this.props.location.search);
				// this.redirectUrl(url);
				// alert('should redirect to /inicio');
				// this.props.history.push('/inicio'); // ESTE QUITAR COMENTARIO
				if (searchParams.get("closeWindow") || searchParams.get("closeWindow") === "TRUE") {
					window.close();
				} else {
					this.props.history.push("/inicio");
				}
			})
			.catch((err) => {});
	};

	login = () => {
		this.setState({ loginFailed: false });
		this.authService.login().then(
			(user) => {
				if (user && this.validUser(user.displayableId) && !isUSABlockedUser(user.displayableId)) {
					localStorage.setItem("user", JSON.stringify(user));
					localStorage.setItem("token", user.userIdentifier);
					localStorage.setItem("email", user.displayableId.toLowerCase());

					this.setState({
						user: user,
						redireccion: true
					});
					const url = window.decodeURIComponent(this.props.location.search);
					this.redirectUrl(url);
				} else {
					this.setState({
						loginFailed: true
					});
				}
			},
			() => {
				this.setState({
					loginFailed: true
				});
			}
		);
	};

	validUser = (email) => {
		if (!email) return false;

		var address = email.split("@");
		var domain = address[1];
		switch (domain) {
			case "aeromexico.com":
				return true;

			default:
				return false;
		}
	};

	redirectUrl = (url) => {
		let path = url.split("/");
		let length = path.length;

		if (url !== "") {
			if (length === 2) {
				this.props.history.push("/" + url.split("/")[1] || "/");
			} else if (length === 3) {
				this.props.history.push("/" + url.split("/")[1] + "/" + url.split("/")[2]);
			} else if (length === 4) {
				this.props.history.push(
					"/" + url.split("/")[1] + "/" + url.split("/")[2] + "/" + url.split("/")[3] || "/"
				);
			} else if (length === 5) {
				this.props.history.push(
					"/" +
						url.split("/")[1] +
						"/" +
						url.split("/")[2] +
						"/" +
						url.split("/")[3] +
						"/" +
						url.split("/")[4] || "/"
				);
			} else if (length === 6) {
				this.props.history.push(
					"/" +
						url.split("/")[1] +
						"/" +
						url.split("/")[2] +
						"/" +
						url.split("/")[3] +
						"/" +
						url.split("/")[4] +
						"/" +
						url.split("/")[5] || "/"
				);
			}
		} else {
			this.props.history.push("/inicio");
		}
	};

	handleChanges = (evt) => {
		this.setState({
			[evt.target.name]: evt.target.value
		});
	};

	// welcome = evt => {
	//   evt.preventDefault();
	//   localStorage.setItem("email", this.state.prueba);
	//   this.setState({
	//     redireccion: true
	//   });
	// };

	show() {
		this.setState({ show: true });
	}
	close() {
		this.setState({ show: false });
	}

	componentDidMount() {
		
		// i18n.init();
		// sessionStorage.clear();
		// localStorage.clear();
		// if (localStorage.getItem("email") !== "Anonimo") {
		// 	localStorage.clear();
		// }
		// localStorage.setItem("email", "Anonimo");
		// localStorage.setItem("nombre", "Anonimo");
		// this.getBrowser();
		// initLang(this);
		// const url = window.location.search;
		// const urlParams = new URLSearchParams(url);
		// if (urlParams.get("oktaRedirect") && urlParams.get("oktaRedirect") === "TRUE") {
		// 	sessionStorage.setItem("oktaToken", urlParams.get("token"));
		// 	localStorage.setItem("oktaToken", urlParams.get("token"));
		// 	this.oktaValidate(urlParams);
		// }
		// setTimeout(() => {
		// 	this.onLangSelectedEvent('es');
		// 	console.log('cambio de lenguaje')
		// }, 1500);
		const urlParams = new URLSearchParams(window.location.search);
		const iss = urlParams.get("iss");
		//console.log("Valor de ISS: ", iss);

		if (iss) {
			const productionConfig = {
				baseUrl: `${iss}`,
				//clientId:"0oaw9rnju9yDyiffv0h7" // se desconoce
				//clientId:"00T2o75v1wO0yYGga0h7", // pruebas
				clientId:"00T22w36gvMLBBlWl4x7", // prod
				redirectUri: "/login",
				el: "#okta-login-container",
				logo: "/images/logo-miam.png",
				authParams: {
					issuer: `${iss}`
				}
			};
			const oktaSignIn = new OktaSignIn(productionConfig);
			//console.log("OKTA SIGNIN: ", oktaSignIn); 
			oktaSignIn.session.exists((exists) => {
				//console.log("EXIST SESSION:", exists);
				this.setState({ existSesionGateone: exists });

				oktaSignIn.session.get((data) => {
					//console.log("Session GET: ", data); 

					//console.log("Nuestra variable que valida si hay session en gateone: ", this.state.existSesionGateone );

					if (this.state.existSesionGateone) {
						localStorage.setItem("okta-user-id", data.userId);
						localStorage.setItem("email", data.login);
						localStorage.setItem("token", data.userId);
						localStorage.setItem("oktaToken", data.id);
						localStorage.setItem("accessDate", `${new Date()}`)
						window.location.assign('/inicio')

					}
				});
			});
		}
	}

	componentDidUpdate() {
		let isLoggedIn = localStorage.getItem('oktaToken');
		if(isLoggedIn){
			window.location.href = '/inicio'
		}
	}

	getBrowser = () => {
		// Internet Explorer 6-11
		var isIE = /*@cc_on!@*/ false || !!document.documentMode;
		//var isIE = true;

		// Edge 20+
		var isEdge = !isIE && !!window.StyleMedia;
		//var isEdge = true;

		if (isIE) {
			this.setState({
				explorer: true
			});
		}

		if (isEdge) {
			this.setState({
				edge: true
			});
		}
	};

	openModalLang() {
		this.setState({
			langVisible: true,
			langMovilVisible: false,
			langClass: "non"
		});
	}

	openModalLangMovil() {
		var vis = !this.state.langMovilVisible;
		this.setState({
			langVisible: false,
			langMovilVisible: vis,
			langClass: vis ? "activeLang" : "non"
		});
	}

	closeModalLang() {
		this.setState({
			langVisible: false,
			langMovilVisible: false,
			langClass: "non"
		});
	}

	onLangSelectedEvent = (l) => {
		if (this.state.lang === l) {
			this.setState({
				langVisible: false,
				langMovilVisible: false,
				langClass: "non"
			});
			return;
		}
		i18next.changeLanguage(l);
		this.setState({
			lang: l,
			langVisible: false,
			langMovilVisible: false
		});
		window.location.reload();
	};

	render() {
		//const { id } = useParams();
		// const isLocal = process.env.REACT_APP_ISLOCAL; // Tomara entorno local
		// const isFromDev = process.env.REACT_APP_ISFROMDEV; //Se pondra en true para ambiente DEV
		// const isFromQA = process.env.REACT_APP_ISFROMQA; //Se pondra en true para ambiente QA

		const urlCapiLocal = `${process.env.REACT_APP_CAPI_LOCALV3}`;
		const urlCapiDEV = `${process.env.REACT_APP_CAPI_DEV}`;
		const urlCapiQA = `${process.env.REACT_APP_CAPI_QA}`;
		const urlCapiProd = `${process.env.REACT_APP_CAPI_PROD}`;

		const urlValidCapi =
			isLocal === true
				? urlCapiLocal
				: isFromDev === true
					? urlCapiDEV
					: isFromQA === true
						? urlCapiQA
						: urlCapiProd;

		const urlValid = `${urlValidCapi}/gate-one?fLogin=TRUE`;
		// const urlDev = `${urlValidCapi}/gate-one?fLogin=TRUE`;
		// const urlValid = urlFiltrered;

		return (
			<div className="LoginPage">
				<div className="TopLoginPage">
					<div className="MidLoginContent">
						<div className="MidLoginContentCell">
							<div className="languageFlightPlan login desktop" onClick={() => this.openModalLang()}>
								<p>{i18next.t("Lang.lang")}</p>
								<div className={`languageFlag ${this.state.lang}`}></div>
							</div>
							<div className="languageFlightPlan login movil" onClick={() => this.openModalLangMovil()}>
								<p>{i18next.t("Lang.lang")}</p>
								<div className={`languageFlag ${this.state.lang}`}></div>
								<div className={`container-language ${this.state.langClass}`}>
									<div className="notification-column">
										<a className="notification-item" onClick={() => this.onLangSelectedEvent("es")}>
											<p className="notification-text"> ES </p>
										</a>
										<a className="notification-item" onClick={() => this.onLangSelectedEvent("en")}>
											<p className="notification-text"> EN </p>
										</a>
									</div>
								</div>
							</div>

							<img className="LogoMiAM1" src={logoSVG} alt="Logo Mi Aeromexico" />

							<img className="LogoMiAM1Mobile" src={logoPNGMobile} alt="Logo Mi Aeromexico" />

							<div className="ContentWrap">
								<h1 className="WelcomeSite">
									{" "}
									<div>
										<span className="text-amLightBlue">{i18next.t("Login.slider.t1")}</span>
										<span className="text-amLightBlue">{i18next.t("Login.slider.t2")}</span>
										<span className="text-amLightBlue">{i18next.t("Login.slider.t3")}</span>
										<span className="text-amLightBlue">{i18next.t("Login.slider.t4")}</span>
									</div>
									<br />
									<span>{i18next.t("Login.slider.t5")}</span>
								</h1>

								<h1 className="WelcomeSiteMobile">
									{i18next.t("Login.welcome.p1")} <br />
									{i18next.t("Login.welcome.p2")}
								</h1>

								<div className="wrapper-disclaimer hidden">
									{this.state.explorer && (
										<p className="disclaimer">{i18next.t("Login.noExplorer")}</p>
									)}
									{this.state.edge && <p className="disclaimer">{/* No uses Edge */}</p>}
								</div>
								<p
									className="WelcomeIntro my-5 lg:my-10"
									dangerouslySetInnerHTML={{
										__html: i18next.t("Login.login")
									}}></p>
								<div className="buttons-holder" style={{ display: "flex" }}>
									<button
										type="button"
										className="fake-btn gate-btn border-1 flex items-center justify-center space-x-3 outline-none ring-0 focus:outline-none focus:ring-0"
										onClick={() => this.setState({ ...this.state, gateOnePop: true })}>
										<GateOne className="h-7 w-auto fill-current text-white" />
										<p className="my-0">{i18next.language === "es" ? "Iniciar sesión" : "Login"}</p>
									</button>
									{/* {this.state.isLocalHost ? (
										<button className="WelcomeButton mt-3" onClick={this.login}>
											<span />
											{i18next.t("Login.loginBtn")}
										</button>
									) : null} */}
								</div>
								{this.state.loginFailed && <p className="failed-user">{i18next.t("Login.failed")}</p>}

								{/* <form onSubmit={this.welcome}>
                  <input
                    placeholder="ingresa tu email"
                    onChange={this.handleChanges}
                    name="prueba"
                  />
                  <button>Ingresa</button>
                </form> */}
							</div>
						</div>
					</div>
				</div>
				<div className="FilterLoginPage bg-amBlack" />
				<div className="BgLoginPage">
					<video className="VideoLogin" autoPlay muted loop playsInline>
						{/* <source src={avionWebm} type="video/webm" /> */}
						<source src={`${process.env.REACT_APP_SRC_URL}miam-videos/avion.mp4`} type="video/mp4" />
					</video>
					<img className="ImgLoginPageLogin" src={avionbg} alt="Bg Login page" />
				</div>

				{this.state.gateOnePop ? (
					<NewWindow
					url="/okta"
						// url={urlValid}
						// url="https://capi.miaeromexico.com/gate-one?fLogin=TRUE"
						name="Iniciar Sesión con Gate One"
						title="Iniciar Sesión con Gate One"
						features={{
							height: 420,
							width: 400
						}}
						center="parent"
						closeOnUnmount
						onUnload={() => {
							if (localStorage.getItem("oktaToken")) {
								//this.props.history.push("/inicio");
								window.location.href = "/inicio";
							}
						}}
					/>
				) : null}

				{/* MODAL IDIOMA */}
				<ModalLang
					visible={this.state.langVisible}
					width="auto"
					height="auto"
					effect="fadeInDown"
					onClickAway={() => this.closeModalLang()}
					className="container-modal-notification">
					<div className="selectLangugeModal menuModal">
						<div className="whiteModal">
							<div className="closeModal" onClick={() => this.closeModalLang()}>
								<img src={close} alt="Cerrar"></img>
							</div>
							<div className="padding">
								<h4>
									<Trans i18nKey="Lang.selecciona"></Trans>
								</h4>
								<div className="gridFlags">
									<a className="contFlag" onClick={() => this.onLangSelectedEvent("es")}>
										<div className="flags spanish"></div>
										<p>{i18next.t("Lang.es")}</p>
									</a>
									<a className="contFlag" onClick={() => this.onLangSelectedEvent("en")}>
										<div className="flags english"></div>
										<p>{i18next.t("Lang.en")}</p>
									</a>
								</div>
							</div>
						</div>
					</div>
				</ModalLang>
				{/* FIN MODAL IDIOMA */}
			</div>
		);
	}
}
export default Index;
