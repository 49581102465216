import React, { Component } from 'react';
import Buttons from '../../News/Buttons';

import * as contentful from '../../../lib/contentful';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import getLocale from '../../../utils/getLocale';
import initLang from '../../../utils/initLang';
import { BLOCKS } from '@contentful/rich-text-types';

export var locale;

const options = {
	renderText: text => {
		return text.split('\n').reduce((children, textSegment, index) => {
			return [...children, index > 0 && <br key={index} />, textSegment];
		}, []);
	},

	renderNode: {
		[BLOCKS.EMBEDDED_ASSET]: node => {
			return (
				<img
					className="benefits-article-img"
					src={node.data.target.fields.file.url}
				/>
			);
		}
	}
};
export default class SingleNews extends Component {
	constructor() {
		super();
		this.state = {
			loading: false,
			error: null,
			entry: null,
			loading: true,
			lang: ''
		};
	}

	componentDidMount() {
		initLang(this);
		locale = getLocale();
		this.fetchEntry(this.props.match.params.articleId);
		setTimeout(() => this.setState({ loading: false }), 1500);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (
			this.props.match.params.articleId !== nextProps.match.params.articleId
		) {
			this.fetchEntry(nextProps.match.params.articleId);
			setTimeout(() => this.setState({ loading: false }), 1500);
		}
	}

	fetchEntry = articleId => {
		
		contentful.client
			.getEntry(articleId, { locale: locale })
			.then(entry => {
				
				this.setState({
					loading: false,
					entry: entry
				});
			})
			.catch(error => {
				this.setState({
					loading: false,
					error: error
				});
			})
			.catch(err => console.error(err));
	};

	renderList = entry => {
		return (
			<>
				<div className="article-container">
					<div className="barNavButtons">
						<Buttons />
					</div>

					{/* HEADER IMAGE */}

					<div className="imageHeaderContainer">
						<div class="filterImageHeader" />

						{/* <img
                className="article-big-image"
                src={item.fields.articleCover.fields.file.url}               
              /> */}
						<div
							className="imgDivArticle"
							style={{
								backgroundImage: `url(${entry.fields.backgroundSmall.fields.file.url})`
							}}
						></div>
					</div>

					{/* END HEADER IMAGE */}

					<div className="left-container">
						<div className="paddingarticle">
							<div className="container-scroll-article">
								<div className="paddingTextArticle">
									{/* ARTICLE TYPE */}
									<div className="subtitle-articles">
										<h1 className="Subtitle-art">Benefits USA</h1>
									</div>
									{/* END ARTICLE TYPE */}

									{/* ARTICLE TITLE */}
									<h2 className="desktop-title">{entry.fields.title} </h2>
									{/* END ARTICLE TITLE */}
									{/* Intro for articles */}
									<p className="introTextArticle">{entry.fields.description}</p>
									{/* END Intro for articles */}

									<hr />

									<div className="container-text-article">
										<div className="mobile-buttons">
											<button className="small-font">A</button>
											<button className="big-font">A</button>
										</div>

										<div className="text-article text-article-benefits">
											<div>
												{documentToReactComponents(
													entry.fields.largeContent,
													options
												)}
											</div>
											{entry.fields.forms ? (
												<div className="benefit-forms">
													<hr />
													<h3>Downloadable Forms</h3>
													<ul className="benefit-forms-list">
														{this.renderForms(entry.fields.forms)}
													</ul>
												</div>
											) : (
												''
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	};

	renderForms = forms => {
		if (!forms) return '';
		const list = forms.map(form => {
			return (
				<li>
					{' '}
					<a
						className="download-form"
						href={form.fields.file.url}
						target="_blank"
					>
						{form.fields.title}
					</a>
				</li>
			);
		});
		return list;
	};

	render() {
		const { loading, entry } = this.state;

		return (
			<React.Fragment>
				{!loading && entry && this.renderList(entry)}
			</React.Fragment>
		);
	}
}
