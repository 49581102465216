import React, { Component } from 'react';
import './css/colors.css';
import './css/faces.css';
//import ScrollableAnchor from 'react-scrollable-anchor';
import swal from 'sweetalert';

import './css/alert-cs.css';

import face1 from '../../assets/images/profile/default-profile.svg';
import face2 from '../../assets/images/profile/user-profile01.svg';
import face3 from '../../assets/images/profile/user-profile02.svg';
import face4 from '../../assets/images/profile/user-profile03.svg';
import face5 from '../../assets/images/profile/user-profile04.svg';
import face6 from '../../assets/images/profile/user-profile05.svg';
import face7 from '../../assets/images/profile/user-profile06.svg';
import face8 from '../../assets/images/profile/user-profile07.svg';
import face9 from '../../assets/images/profile/user-profile08.svg';
import face10 from '../../assets/images/profile/user-profile09.svg';
import face11 from '../../assets/images/profile/user-profile10.svg';
import face12 from '../../assets/images/profile/user-profile11.svg';
import face13 from '../../assets/images/profile/user-profile12.svg';
import face14 from '../../assets/images/profile/user-profile13.svg';
import face15 from '../../assets/images/profile/user-profile14.svg';
import face16 from '../../assets/images/profile/user-profile15.svg';
import face17 from '../../assets/images/profile/user-profile16.svg';
import face18 from '../../assets/images/profile/user-profile17.svg';
import face19 from '../../assets/images/profile/user-profile18.svg';
import face20 from '../../assets/images/profile/user-profile19.svg';
import face21 from '../../assets/images/profile/user-profile20.svg';
import face22 from '../../assets/images/profile/user-profile21.svg';
import face23 from '../../assets/images/profile/user-profile22.svg';

import back1 from '../../assets/images/background1.jpg';
import back2 from '../../assets/images/background2.jpg';
import back3 from '../../assets/images/background3.jpg';
import back4 from '../../assets/images/background4.jpg';
import back5 from '../../assets/images/background5.jpg';
import back6 from '../../assets/images/background6.jpg';
import back7 from '../../assets/images/background7.jpg';
import back8 from '../../assets/images/background8.jpg';
import back9 from '../../assets/images/background9.jpg';
import back10 from '../../assets/images/background10.jpg';
import back11 from '../../assets/images/background11.jpg';
import back12 from '../../assets/images/background12.jpg';
import back13 from '../../assets/images/background13.jpg';
import back14 from '../../assets/images/background14.jpg';
import back15 from '../../assets/images/background15.jpg';

import ModalBackground from './ModalBackground';

import request from 'superagent';
import i18next from 'i18next';

// const endPoint = 'https://mi-aeromexico.herokuapp.com';

export default class ModalPicture extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modalIsShow: false,
			colorIsSelected: 'teal',
			isColorSelected: false,
			imageSelected: localStorage.getItem('avatar'),
			colors: ['blue', 'teal', 'green', 'yellow', 'orange', 'red'],
			faces: [
				{ name: 'face1', img: face1 },
				{ name: 'face2', img: face2 },
				{ name: 'face3', img: face3 },
				{ name: 'face4', img: face4 },
				{ name: 'face5', img: face5 },
				{ name: 'face6', img: face6 },
				{ name: 'face7', img: face7 },
				{ name: 'face8', img: face8 },
				{ name: 'face9', img: face9 },
				{ name: 'face10', img: face10 },
				{ name: 'face11', img: face11 },
				{ name: 'face12', img: face12 },
				{ name: 'face13', img: face13 },
				{ name: 'face14', img: face14 },
				{ name: 'face15', img: face15 },
				{ name: 'face16', img: face16 },
				{ name: 'face17', img: face17 },
				{ name: 'face18', img: face18 },
				{ name: 'face19', img: face19 },
				{ name: 'face20', img: face20 },
				{ name: 'face21', img: face21 },
				{ name: 'face22', img: face22 },
				{ name: 'face23', img: face23 }
			],
			backselected: localStorage.getItem('background'),
			backs: [
				{ name: 'back1', img: back1 },
				{ name: 'back2', img: back2 },
				{ name: 'back3', img: back3 },
				{ name: 'back4', img: back4 },
				{ name: 'back5', img: back5 },
				{ name: 'back6', img: back6 },
				{ name: 'back7', img: back7 },
				{ name: 'back8', img: back8 },
				{ name: 'back9', img: back9 },
				{ name: 'back10', img: back10 },
				{ name: 'back11', img: back11 },
				{ name: 'back12', img: back12 },
				{ name: 'back13', img: back13 },
				{ name: 'back14', img: back14 },
				{ name: 'back15', img: back15 }
			]
		};
	}

	logout01 = () => {
		// localStorage.clear();
		// sessionStorage.clear();
		//localStorage.clear("background")
		// localStorage.clear("avatar")
	};

	selectBack = evt => {
		this.setState({
			backselected: evt.currentTarget.attributes.name.textContent
		});
	};

	// showAlertModal = () => {
	//   this.setState({
	//     modalIsShow: !this.state.modalIsShow
	//   });
	// };

	updateSettings = props => {
		// request
		//   .put(`${ endPoint }/api/settings/${ localStorage.getItem('_id')}`)
		//   .set({
		//     'Content-Type': 'application/json'
		//   })
		//   .send({
		//     avatar:this.state.imageSelected,
		//   })
		//   .then(response => {
		//     console.log(response.body)
		//     if (response.ok) {
		//       // this.forceUpdate()
		//       // this.props.history.push("");
		//     //  alert('Tus datos han sido guardados y se verán reflejados en tu proximo inicio de sesión')
		//     }
		//   })
		//   .catch(error => console.error(error));
	};

	updateSettings01 = () => {
		// console.log('entre')
		// request
		//   .put(`${ endPoint }/api/settings/${localStorage.getItem('_id')}`)
		//   .set({
		//     'Content-Type': 'application/json'
		//   })
		//   .send({
		//     background:this.state.backselected,
		//   })
		//   .then(response => {
		//     console.log(response)
		//     if (response.ok) {
		//       // alert('Tus datos han sido guardados y se verán reflejados en tu proximo inicio de sesión')
		//     }
		//   })
		//   .catch(error => console.error(error));
	};

	selectColor = evt => {
		this.setState({
			colorIsSelected: evt.currentTarget.attributes.name.textContent
			// isColorSelected: !this.state.isColorSelected
		});
	};

	selectFace = evt => {
		this.setState({
			imageSelected: evt.currentTarget.attributes.name.textContent
		});
	};

	swalTitle() {
		return i18next.t('Profile.ajustes.swalTitle');
	}
	swalMssg() {
		return i18next.t('Profile.ajustes.swalDesc');
	}

	render() {
		
		return (
			<>
				{/* <p className="modal-title">Accent color</p> */}
				{/* <span className="modal-subtitle">
          Change the primary color in your profile
        </span>
        <div className="modal-circles">
          {this.state.colors.map(circle => {
            return (
              <div
                onClick={this.selectColor}
                name={circle}
                className={
                  this.state.colorIsSelected === circle
                    ? `${circle}-active circle-form`
                    : `${circle} circle-form`
                }
              />
            );
          })}
        </div> */}

				{/* <ScrollableAnchor id={'profile-picture'}>
					<div></div>
				</ScrollableAnchor> */}
				{this.state.modalIsShow === true ? (
					<div className="dataSaved">
						<div className="icon"></div>
						<div className="message">
							<p>{i18next.t('Profile.ajustes.guadados')}</p>
						</div>
					</div>
				) : (
					''
				)}

				<span className="modal-title_profile">
					{i18next.t('Profile.ajustes.perfil')}
				</span>
				<div className="modal-faces">
					{this.state.faces.map(face => {
						return (
							<div className="divPicture">
								<img
									onClick={this.selectFace}
									name={face.name}
									className={
										this.state.imageSelected === face.name
											? 'modal-circle-face active'
											: 'modal-circle-face'
									}
									src={face.img}
								/>
								<span
									className={
										this.state.imageSelected === face.name
											? 'activeProfileArrow active'
											: 'activeProfileArrow'
									}
								></span>
							</div>
						);
					})}

					<div className="backgroundBlueFaces">
						<button
							// onClick={this.handleClick}
							onClick={() => {
								this.updateSettings();
								this.updateSettings01();
								this.logout01();
								swal(
									this.swalTitle(),
									this.swalMssg(),
									'success',
									{ button: false, timer: 5000 }
								);
								// alert.show('Oh look, an alert!')
								// this.showAlertModal();
							}}
							// onClick={function(event){ updateSettings(); updateSettings01()}}
							className="button-save-settings"
						>
							{i18next.t('Profile.ajustes.guardar')}
						</button>
					</div>
				</div>

				{/* <ScrollableAnchor id={'background-picture'}>
					<div className="backgroundImageWrapper">
						<span className="modal-title_profile">
							{i18next.t('Profile.ajustes.fondo')}
						</span>
						<div className="modal-backs">
							{this.state.backs.map(back => {
								return (
									<div className="divPicture">
										<img
											onClick={this.selectBack}
											name={back.name}
											className={
												this.state.backselected ===
												back.name
													? 'modal-circle-back active'
													: 'modal-circle-back'
											}
											src={back.img}
										/>
										<span
											className={
												this.state.backselected ===
												back.name
													? 'activeProfileArrow active'
													: 'activeProfileArrow'
											}
										></span>
									</div>
								);
							})}
						</div>
					</div>
				</ScrollableAnchor> */}
			</>
		);
	}
}
