import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import i18next from 'i18next';
import BenefitCategoryMX from './BenefitCategoryMX';
import * as contentful from '../../../lib/contentful';
import getLocale from '../../../utils/getLocale';
import ProfileIcon02 from '../../../assets/images/profile/icon-mybenefits04.svg';
import './NewBenefitsMX.css';
import profileIcon02 from '../../../assets/images/profile/icon-mybenefits-02.svg';
import heart from '../../../assets/images/profile/categoria-01.svg';
import Entretenimiento from '../../../assets/images/profile/categoria-02.svg';
import pesas from '../../../assets/images/profile/categoria-03.svg';
import shopping from '../../../assets/images/profile/categoria-04.svg';
import Educacion from '../../../assets/images/profile/categoria-05.svg';
import restaurante from '../../../assets/images/profile/categoria-07.svg';
import auto from '../../../assets/images/profile/categoria-09.svg';
import Servicios from '../../../assets/images/profile/categoria-10.svg';
import Guarderia from '../../../assets/images/profile/categoria-06.svg';

// Import Swiper styles
import '../../../../node_modules/swiper/';
import FeaturedSwiper from './FeaturedSwiper';
import { Redirect } from 'react-router-dom';

import Benefit01 from '../../../assets/images/profile/benefit-category-01.jpg';
import Benefit02 from '../../../assets/images/profile/benefit-category-02.jpg';
import Benefit03 from '../../../assets/images/profile/benefit-category-03.jpg';
import Benefit04 from '../../../assets/images/profile/benefit-category-04.jpg';


const data = [
	{
		description:
			'Sabemos que tu familia también es parte de la experiencia Aeroméxico, por eso ponemos a tu alcance programas para cuidarte a ti y a los tuyos.',
		id: 'lovedBenefits',
		image: Benefit01,
		title: 'Cuida de ti y de los que más quieres'
	},
	{
		description:
			'Cuentas con programas de ahorro y servicios que te ayudan a mantener tus finanzas en equilibrio.',
		id: 'financeBenefits',
		image: Benefit02,
		title: 'Administra tus finanzas'
	},
	{
		description:
			'Porque queremos que tengas la mejor experiencia en la compañía, hemos diseñado beneficios y oportunidades exclusivas por ser parte de la familia Aeroméxico.',
		id: 'aeromexicoBenefits',
		image: Benefit03,
		title: 'Vive la experiencia Aeroméxico'
	},
	{
		description:
			'Para nosotros también es importante que disfrutes tu trabajo, por eso contamos con horarios flexibles, transportación interna y otros beneficios más.',
		id: 'enjoyBenefits',
		image: Benefit04,
		title: 'Disfruta tu trabajo'
	}
];

const BDContentMX2 = () => {

    const [credentials, setCredentials] = useState({
		level: !!localStorage.getItem('nivel')
			? parseInt(localStorage.getItem('nivel'))
			: 0,
		companyCode: !!localStorage.getItem('codigo_empresa_AM')
			? localStorage.getItem('codigo_empresa_AM')
			: undefined,
		company: !!localStorage.getItem('compania')
			? localStorage.getItem('compania')
			: undefined
	});
	const [benefits, setBenefits] = useState([]);
	const [amxBenefits, setAmxBenefits] = useState([]);
	const [lovedBenefits, setLovedBenefits] = useState([]);
	const [financeBenefits, setFinanceBenefits] = useState([]);
	const [enjoyBenefits, setEnjoyBenefits] = useState([]);
	const [loading, setLoading] = useState(true);

	const filterList = (validator, item) => {
		switch (validator) {
			case 'aeromexicoBenefits':
				setAmxBenefits((current) => [...current, item]);
				break;
			case 'financeBenefits':
				setFinanceBenefits((current) => [...current, item]);
				break;
			case 'lovedBenefits':
				setLovedBenefits((current) => [...current, item]);
				break;
			default:
				setEnjoyBenefits((current) => [...current, item]);
				break;
		}
	};

	const sendBenefits = (validator) => {
		switch (validator) {
			case 'aeromexicoBenefits':
				return amxBenefits;
			case 'financeBenefits':
				return financeBenefits;
			case 'lovedBenefits':
				return lovedBenefits;
			default:
				return enjoyBenefits;
		}
	};

	const fetchContent = useCallback(() => {
		contentful.client
			.getEntries({
				content_type: 'myBenefits',
				include: 2,
				locale: getLocale(),
				limit: 200
			})
			.then((response) => response.items)
			.then((res) => {
				
				res.forEach((item) =>
					filterList(item.fields.myBenefitCategoryClass, item)
				);
				setBenefits(res);
				setLoading(false);
			})
			.catch(console.error);
	});

	useEffect(() => {
		if (benefits.length > 0){
			
			return;
		}
		fetchContent();
	}, [benefits]);

	const scollerView = (itemID) => {
		const item = document.getElementById(itemID).offsetTop;
		window.scrollTo({top:item  + 200, behavior: "smooth"});
	};

    const scrollView = (itemID) => {
        const item = document.getElementById(itemID);
        if (item) {
            item.scrollIntoView({ behavior: "smooth" });
        } else {
            console.error("Elemento con ID '" + itemID + "' no encontrado.");
        }
    };

	const validateEmail = () => {
		return true;
		// const email = localStorage.getItem("email");
		// if(email === "aberber@aeromexico.com") return true;
		// if(email === "jgarciab@aeromexico.com") return true;
		// if(email === "jtapias@aeromexico.com") return true;
		// if(email === "bfernandezr@aeromexico.com") return true;
		// if(email === "cperedo@aeromexico.com") return true;
		// if(email === "jalonsoug@aeromexico.com") return true;
		// if(email === "fsoler@aeromexico.com") return true;
		// return false;
	}


	return (
		<div>
            <div
				className="totalBenefits__title__container beneficiosfloatright notHidden relative"
				id="testingScroll"
				style={{
					borderBottom: '1px solid #e5e7eb',
					paddingTop: '15px'
				}}
			>
				<div className="holder-sticky-menu sticky" style={{ top: 150 , width:'90%', marginLeft:'5%'}}>
					<ul
						className="fake-benefits-menu flex w-full items-center justify-between rounded-full text-sm font-medium"
						style={{
							background: '#D8E6EB',
							height: '70px'
						}}
					>
						<li className="menu-item-benefits rounded-full">
							<button className='outline-none shadow-none focus:outline-none' onClick={() => scrollView("lovedBenefits")}>
								Cuida de ti y de los que más quieres
							</button>
						</li>
						<li className="menu-item-benefits rounded-full">
							<button className='outline-none shadow-none focus:outline-none' onClick={()=> scrollView("financeBenefits")}>
								Administra tus finanzas
							</button>
						</li>
						<li className="menu-item-benefits rounded-full">
							<button className='outline-none shadow-none focus:outline-none' onClick={()=> scrollView("aeromexicoBenefits")}>Experiencia Aeroméxico</button>
						</li>
						<li className="menu-item-benefits rounded-full">
							<button className='outline-none shadow-none focus:outline-none' onClick={()=> scrollView("enjoyBenefits")}>Disfruta tu trabajo</button>
						</li>
						{/* <li className="menu-item-benefits rounded-full">
							<a href="#descuentos">Mis Descuentos</a>
						</li> */}
					</ul>
				</div>
				{data.map((item) => (
					<BenefitCategoryMX
						key={item.id}
						id={item.id}
						benefits={sendBenefits(item.id)}
						description={item.description}
						image={item.image}
						loading={loading}
						title={item.title}
						level={
							credentials.level < 10
								? `0${credentials.level}`
								: credentials.level.toString()
						}
						companyCode={credentials.companyCode}
						company={credentials.company}
					/>
				))}
			</div>
            {/* ================================================================ */}

            <motion.div className='discountPerks__container flex flex-col'>
            <div className="footerProfile">
				<div className="footerProfile__topContainer flex flex-col">
					<div className="totalBenefits__title__container">
						<div className="iconTitleProfile">
							<img
								src={ProfileIcon02}
								alt="Destacados y recientes"
							/>
						</div>
						<h1>{i18next.t('MiExperAM.benef.anadido')}</h1>
					</div>
					<div className="benefitsSwiper">
						<FeaturedSwiper />
					</div>
				</div>
			</div>
            </motion.div>


            <motion.div className="discountPerks__container flex flex-col">
				<div className="totalBenefits__title__container w-full">
					<div className="iconTitleProfile">
						<img src={profileIcon02} alt="Profile Icon 02" />
					</div>
					<h1>{i18next.t('MiExperAM.benef.descuentos.title')}</h1>
				</div>
				<div className="discountsPerks__list__container w-full">
					<ul>
						<li>
							<Link to="/recompensa-total/mis-beneficios/descuentos/salud-y-belleza">
								{' '}
								<img
									src={heart}
									className="discount_list"
									alt="Salud y belleza"
								/>
								<span className="discount_l">
									{i18next.t(
										'MiExperAM.benef.descuentos.salud'
									)}
								</span>
							</Link>
						</li>
						<li>
							<Link to="/recompensa-total/mis-beneficios/descuentos/entretenimiento">
								{' '}
								<img
									src={Entretenimiento}
									className="discount_list"
									alt="Entretenimiento"
								/>
								<span className="discount_l">
									{i18next.t(
										'MiExperAM.benef.descuentos.entrete'
									)}
								</span>
							</Link>
						</li>
						<li>
							<Link to="/recompensa-total/mis-beneficios/descuentos/gimnasios">
								<img
									src={pesas}
									className="discount_list"
									alt="Gimnasios"
								/>
								<span className="discount_l">
									{i18next.t(
										'MiExperAM.benef.descuentos.gym'
									)}
								</span>
							</Link>
						</li>
					</ul>
					<ul>
						<li>
							<Link to="/recompensa-total/mis-beneficios/descuentos/de-compras">
								{' '}
								<img
									src={shopping}
									className="discount_list"
									alt="Shopping"
								/>
								<span className="discount_l">
									{i18next.t(
										'MiExperAM.benef.descuentos.shopp'
									)}
								</span>
							</Link>
						</li>
						<li>
							<Link to="/recompensa-total/mis-beneficios/descuentos/educacion">
								<img
									src={Educacion}
									className="discount_list"
									alt="Educación"
								/>
								<span className="discount_l">
									{i18next.t(
										'MiExperAM.benef.descuentos.educa'
									)}
								</span>
							</Link>
						</li>
					</ul>
					<ul>
						<li>
							<Link to="/recompensa-total/mis-beneficios/descuentos/restaurantes">
								{' '}
								<img
									src={restaurante}
									className="discount_list"
									alt="Restaurantes"
								/>
								<span className="discount_l">
									{i18next.t(
										'MiExperAM.benef.descuentos.resta'
									)}
								</span>
							</Link>
						</li>
						<li>
							<Link to="/recompensa-total/mis-beneficios/descuentos/automoviles">
								{' '}
								<img
									src={auto}
									className="discount_list"
									alt="autos"
								/>
								<span className="discount_l">
									{i18next.t(
										'MiExperAM.benef.descuentos.auto'
									)}
								</span>
							</Link>
						</li>
					</ul>
					<ul>
						<li>
							<Link to="/recompensa-total/mis-beneficios/descuentos/servicios">
								<img
									src={Servicios}
									className="discount_list"
									alt="servicios"
								/>
								<span className="discount_l">
									{i18next.t(
										'MiExperAM.benef.descuentos.servi'
									)}
								</span>
							</Link>
						</li>
						<li>
							<Link to="/recompensa-total/mis-beneficios/descuentos/guarderias">
								<img
									src={Guarderia}
									className="discount_list"
									alt="Guarderias"
								/>
								<span className="discount_l">
									{i18next.t(
										'MiExperAM.benef.descuentos.guarde'
									)}
								</span>
							</Link>
						</li>
					</ul>
				</div>
			</motion.div>
		</div>
	);
};

export default BDContentMX2;
