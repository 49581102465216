import React from 'react';
import Modal from 'react-awesome-modal';
import close from '../../../assets/logo/close.svg';

import parse from 'html-react-parser';
import i18next from 'i18next';
import { Trans } from "react-i18next";

const noScroll = require('no-scroll');

class TravelOpensUpgrade extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			content: this.props.text,
			fake: this.props.fakeAnchorText,
			visible: false
		};
		this.escFunction = this.escFunction.bind(this);
		this.setWrapperRef = this.setWrapperRef.bind(this);
	}

	escFunction(event) {
		if (event.keyCode === 27) {
			this.setState({
				visible: false
			});
			noScroll.off();
		}
	}
	setWrapperRef(node) {
		this.wrapperRef = node;
	}
	handleClick = () => {
		this.setState({
			visible: true
		});
		noScroll.on();
	};
	closeModal() {
		this.setState({
			visible: false
		});
		noScroll.off();
	}
	render() {
		return (
			<React.Fragment>
				<Modal
					visible={this.state.visible}
					width="auto"
					height="auto"
					effect="fadeInUp"
					onClickAway={() => this.closeModal()}
				>
					<div className="modalWindowAncillaries upgradeModalHeight">
						<div
							className="closeModal"
							onClick={() => this.closeModal()}
						>
							<img src={close} alt='cerrado'></img>
						</div>

						<div className="topBox">
							<div className="header">
								<div className="imageCircle asientoupgrade"></div>
								<div className="textLeftCircle">
									<p className="title">
										{i18next.t('MiExperAM.travel.ancillares.upgrade.title')}
									</p>
									<p className="subtitle">
									{i18next.t('MiExperAM.travel.ancillares.upgrade.sub')}
									</p>
								</div>
							</div>

							<div className="contentType upgrade">
								<h3>
									<Trans i18nKey="MiExperAM.travel.ancillares.upgrade.posibilidades"></Trans>
								</h3>
								<h5
									style={{ marginTop: '20px' }}
									subtitle={i18next.t('MiExperAM.travel.ancillares.upgrade.titanio')}
								>
									<strong>{i18next.t('MiExperAM.travel.ancillares.upgrade.horas')}</strong>
								</h5>
								<p style={{ marginBottom: '20px' }}>
									<ul>
										<li>{i18next.t('MiExperAM.travel.ancillares.upgrade.punto1')}</li>
										<li>{i18next.t('MiExperAM.travel.ancillares.upgrade.punto2')}</li>
									</ul>
									<Trans i18nKey="MiExperAM.travel.ancillares.upgrade.tramite"></Trans>
									<br />
									<br />
									<strong>{i18next.t('MiExperAM.travel.ancillares.upgrade.nodisp')}</strong>
									<br />
									<span style={{ fontSize: '14px' }}>
										{i18next.t('MiExperAM.travel.ancillares.upgrade.contact1')}
										<a href="mailto:amcsi@aeromexico.com">
											amcsi@aeromexico.com
										</a>
										<Trans i18nKey="MiExperAM.travel.ancillares.upgrade.contact2"></Trans><sup>*</sup>
									</span>
									<br />
									<span style={{ fontSize: '14px' }}>
									<sup>*</sup><Trans i18nKey="MiExperAM.travel.ancillares.upgrade.nogarant"></Trans>
									</span>
								</p>
								<h5 subtitle={i18next.t('MiExperAM.travel.ancillares.upgrade.especial')}>
									<Trans i18nKey="MiExperAM.travel.ancillares.upgrade.48horas"></Trans>
								</h5>
								<p style={{ marginBottom: '20px' }}>
									<ul>
										<li>{i18next.t('MiExperAM.travel.ancillares.upgrade.clasef')}</li>
										<li>{i18next.t('MiExperAM.travel.ancillares.upgrade.beneficiarios')}</li>
										<li>{i18next.t('MiExperAM.travel.ancillares.upgrade.maximo')}</li>
									</ul>
									<p
										style={{
											marginLeft: 0,
											marginRight: 0,
											marginBottom: '10px'
										}}
									>
										<strong>{i18next.t('MiExperAM.travel.ancillares.upgrade.precios')}</strong>
									</p>
									<div className="tableWidthPremier">
										<div className="columnPremier">
											<ul>
												<li>
													<span>{i18next.t('MiExperAM.travel.ancillares.upgrade.tabla.asia')}</span>
													<p>{i18next.t('MiExperAM.travel.ancillares.upgrade.tabla.precio1')}</p>
												</li>
												<li>
													<span>{i18next.t('MiExperAM.travel.ancillares.upgrade.tabla.centro')}</span>
													<p>{i18next.t('MiExperAM.travel.ancillares.upgrade.tabla.precio2')}</p>
												</li>
												<li>
													<span>{i18next.t('MiExperAM.travel.ancillares.upgrade.tabla.domes')}</span>
													<p>{i18next.t('MiExperAM.travel.ancillares.upgrade.tabla.precio3')}</p>
												</li>
												<li className="noborder">
													<span>{i18next.t('MiExperAM.travel.ancillares.upgrade.tabla.europa')}</span>
													<p>{i18next.t('MiExperAM.travel.ancillares.upgrade.tabla.precio4')}</p>
												</li>
											</ul>
										</div>
										<div className="columnPremier">
											<ul>
												<li>
													<span>{i18next.t('MiExperAM.travel.ancillares.upgrade.tabla.sudame')}</span>
													<p>{i18next.t('MiExperAM.travel.ancillares.upgrade.tabla.precio5')}</p> <br/>
													<span>{i18next.t('MiExperAM.travel.ancillares.upgrade.tabla.sh')}</span>
													<p>{i18next.t('MiExperAM.travel.ancillares.upgrade.tabla.precio6')}</p>
												</li>
												<li>
													<span>{i18next.t('MiExperAM.travel.ancillares.upgrade.tabla.usa')}</span>
													<p>{i18next.t('MiExperAM.travel.ancillares.upgrade.tabla.precio7')}</p> <br />
													<span>{i18next.t('MiExperAM.travel.ancillares.upgrade.tabla.canada')}</span>
													<p>{i18next.t('MiExperAM.travel.ancillares.upgrade.tabla.precio8')}</p>
												</li>
											</ul>
										</div>
									</div>
									<span>{i18next.t('MiExperAM.travel.ancillares.upgrade.tabla.montos')}</span>
									<br />
									<br />
									<Trans i18nKey="MiExperAM.travel.ancillares.upgrade.tramite"></Trans>
									<br />
									<br />
									<strong>{i18next.t('MiExperAM.travel.ancillares.upgrade.nodisp')}</strong>
									<br />
									<span style={{ fontSize: '14px' }}>
										{i18next.t('MiExperAM.travel.ancillares.upgrade.contact1')}
										<a href="mailto:amcsi@aeromexico.com">
											amcsi@aeromexico.com
										</a>{' '}
										<Trans i18nKey="MiExperAM.travel.ancillares.upgrade.contact3"></Trans><sup></sup>
									</span>
									<br />
									<span style={{ fontSize: '14px' }}>
									{i18next.t('MiExperAM.travel.ancillares.upgrade.ascenso')}
									</span>
								</p>
								<h5 subtitle={i18next.t('MiExperAM.travel.ancillares.upgrade.sincosto')}>
									<strong>{i18next.t('MiExperAM.travel.ancillares.upgrade.1hora')}</strong>
								</h5>
								<p style={{ marginBottom: '20px' }}>
									<ul>
										<li>{i18next.t('MiExperAM.travel.ancillares.upgrade.punto3')}</li>
										<li>{i18next.t('MiExperAM.travel.ancillares.upgrade.punto4')}</li>
									</ul>
									<Trans i18nKey="MiExperAM.travel.ancillares.upgrade.tramiteauto"></Trans>
									<br />
									<br />
									<span style={{ fontSize: '14px' }}>
									<Trans i18nKey="MiExperAM.travel.ancillares.upgrade.importante"></Trans>
									</span>
									<span className="fakeAlert">
										<div className="fakeIcon"></div>
										<Trans i18nKey="MiExperAM.travel.ancillares.upgrade.warning"></Trans>
									</span>
								</p>

								<hr />
								<h3>{i18next.t('MiExperAM.travel.ancillares.upgrade.terminos.title')}</h3>
								<ul>
									<li>{i18next.t('MiExperAM.travel.ancillares.upgrade.terminos.t1')}</li>
									<li>{i18next.t('MiExperAM.travel.ancillares.upgrade.terminos.t2')}</li>
								</ul>
							</div>
						</div>
					</div>
				</Modal>
				<p>
					{parse(this.state.content)}
					<span className="fakeAnchor" onClick={this.handleClick}>
						{this.state.fake}
					</span>
				</p>
			</React.Fragment>
		);
	}
}

export default TravelOpensUpgrade;
