import React, { useRef, useState, useEffect, Fragment } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import i18next from 'i18next';
import arrow from '../../assets/images/slider/arrow-blue.svg';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

//import '../Slider/css/swn.css';
import '../Slider/css/swiper.css';
import '../Slider/css/style.css';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

export default function Swiper2() {
    const progressCircle = useRef(null);
    const progressContent = useRef(null);
    
    const [entries, setEntries] = useState([]);
    const [loading, setLoading] = useState(true);
    const country = localStorage.getItem('pais');
    const isSpanish = i18next.language === 'es';
    const slideLang = (isSpanish) ? 'es' : 'en';

    useEffect(() => {
      async function fetchData() {
        try {
          const cabecera = new Headers({
            'Content-type': 'application/json',
            'Authorization': `Bearer ${process.env.REACT_APP_CADMIN_PROD_TOKEN}` //Se cambia por la env de prod
          });
          const response = await fetch(`${process.env.REACT_APP_CADMIN_PROD}home-slides?populate=*&sort[1]=id&locale=${slideLang}`, { headers: cabecera }); //Se cambia por la env de prod
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          if (data.data.length > 0) {
            setEntries(data.data);
            setLoading(false);
            //console.log("Contendio de carruccel",data.data);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    
      fetchData();
    }, []);

    return (
      <Fragment>
        {!loading && (
          <>
         <Swiper
  spaceBetween={0}
  centeredSlides={true}
  autoplay={{
    delay: 5000,
    disableOnInteraction: false,
  }}
  pagination={{
    clickable: true,
  }}
  navigation={true}
  modules={[Autoplay, Pagination, Navigation]}
  className="swiper-home-slider"
>
  {entries.map((slide, index) => {
    if (
      slide.attributes.available_for === "Everyone" ||
      slide.attributes.available_for === country
    ) {
      return (
        <SwiperSlide key={index}>
          {/* <div className={`hellow${slide.id}`}>
            <div className="height"> */}
              <div className="Swiperfilter"></div>
              <div className="BackFilterBlur"></div>
              <div className="VideoFilter">
                {slide.attributes.video_url ? (
                  <video
                    className="video"
                    autoPlay
                    muted
                    loop
                    playsInline
                  >
                    <source
                      src={encodeURI(slide.attributes.video_url)}
                    />
                  </video>
                ) : null}
              </div>
              {!slide.attributes.video_url && slide.attributes.Img && (
                <>
                  <img
                    className="img-slider SwiperImage noMobile"
                    src={encodeURI(slide.attributes.Img.url)}
                    alt={slide.attributes.Img.name.replace(
                      slide.attributes.Img.ext,
                      ''
                    )}
                  />
                  <img
                    className="img-slider SwiperImage noDesktop"
                    src={encodeURI(slide.attributes.Img.url)}
                    alt={slide.attributes.Img.name.replace(
                      slide.attributes.Img.ext,
                      ''
                    )}
                  ></img>
                </>
              )}
              <div className="SwipeCenter">
                <div className="SwipeCenterCell">
                  <p className="TagSwipe">
                    {isSpanish
                      ? slide.attributes.company
                      : slide.attributes.company}
                  </p>
                  <div className="WrapperTextSwipe">
                    <p className="TitleSwipe">
                      {isSpanish
                        ? slide.attributes.title
                        : slide.attributes.title}
                    </p>
                    <ReactMarkdown className="IntroSwipe">
                      {
                        isSpanish
                        ? slide.attributes.subtitle
                        : slide.attributes.subtitle
                      }
                    </ReactMarkdown>
                    {slide.attributes.url.includes(
                      'https://miaeromexico.com/'
                    ) ? (
                      <Link
                        to={slide.attributes.url.replace(
                          'https://miaeromexico.com/',
                          '/'
                        )}
                        className="slider-read flex items-center justify-center"
                        style={{
                          display: 'flex'
                        }}
                      >
                        {isSpanish? 'Conoce más':'know more'}
                        <img
                          className="blue-arrow"
                          src={arrow}
                          alt="blue-arrow"
                        ></img>
                      </Link>
                    ) : (
                      <a
                        className="slider-read flex items-center justify-center"
                        href={encodeURI(slide.attributes.url)}
                        style={{
                          display: 'flex'
                        }}
                      >
                        {isSpanish ? 'Conoce más' : 'Know more'}
                        <img
                          className="blue-arrow"
                          src={arrow}
                          alt="blue-arrow"
                        ></img>
                      </a>
                    )}
                  </div>
                </div>
              {/* </div>
            </div> */}
          </div>
        </SwiperSlide>
      );
    } else {
      return null;
    }
  })}
  <div className="autoplay-progress" slot="container-end">
    <svg viewBox="0 0 48 48" ref={progressCircle}>
      <circle cx="24" cy="24" r="20"></circle>
    </svg>
    <span ref={progressContent}></span>
  </div>
</Swiper>

        </>
        )}
      </Fragment>
    );
  }
  
  