import React from 'react';
import { Redirect } from 'react-router-dom';
import './css/totalrewards.css';
// import { PDFExport } from '@progress/kendo-react-pdf';

import logobenefit02re from '../../assets/images/profile/mybenefitslogorewards.svg';
import InfoEmployee from './TotalReward/EmployeeInfo';
import InfoEmployeeCan from './TotalReward/EmployeeInfoCan';
import InfoEmployeeUsa from './TotalReward/EmployeeInfoUsa';
// import Guaranteed from './TotalReward/Guaranteed';
import GuaranteedFC from './TotalReward/GuaranteedFC';
import GuaranteedCan from './TotalReward/GuaranteedCan';
import GuaranteedUsa from './TotalReward/GuaranteedUsa';
// import Variable from './TotalReward/Variable';
// import Print from "./TotalReward/Report/Print"
// import userLocation from "../../utils/userLocation";

// const sha1 = require("sha1");
// const parseString = require("xml2js").parseString;
// const soapRequest = require("easy-soap-request");
// import { PrivateContext, PublicContext } from '../../globalState';
import { motion } from 'framer-motion';

function guaranteedLocation(info, publicInfo) {
	const location = localStorage.getItem('location') || "MX";
	// console.log('LOC => ' + location);

	switch (location) {
		case 'CAN':
			return <GuaranteedCan privateInfo={info} publicInfo={publicInfo} />
		case 'USA':
			return <GuaranteedUsa privateInfo={info} publicInfo={publicInfo} />
		default:
			return <GuaranteedFC privateInfo={info} publicInfo={publicInfo} />
	}
}

function infoEmployeeLocation(info, myPrivate) {
	const location = localStorage.getItem('location') || 'MX';

	switch (location) {
		case 'CAN':
			return <InfoEmployeeCan generalInfo={info} privateData={myPrivate} />
		case 'USA':
			return <InfoEmployeeUsa generalInfo={info} privateData={myPrivate} />
		default:
			return <InfoEmployee generalInfo={info} privateData={myPrivate} />
	}
}

const TotalRewards = ({ dataPriv, data }) => {
	const email = localStorage.getItem("email");
	// console.log(dataPriv, data);
	//console.log('INFO PRIVADA', dataPriv);
	//console.log('INFO', data);
	// const { data, isTrue } = useContext(PublicContext);
	// const { dataPriv, isTruePriv } = useContext(PrivateContext);

	const validateEmail = (email) => {
		return true;
		// if(email === "aberber@aeromexico.com") return true;
		// if(email === "jgarciab@aeromexico.com") return true;
		// if(email === "jtapias@aeromexico.com") return true;
		// if(email === "bfernandezr@aeromexico.com") return true;
		// if(email === "cperedo@aeromexico.com") return true;
		// if(email === "jalonsoug@aeromexico.com") return true;
		// if(email === "fsoler@aeromexico.com") return true;
		// return false;
	}

	return validateEmail(email) ? (
		<motion.div
			initial={{ opacity: 0, y: 100 }}
			animate={{ opacity: 1, y: 0 }}
			exit={{ opacity: 0, y: 100 }}
			className="Profile_inform"
		>
			<div className="totalBenefits__title__container">
				<div className="logoTabBenefit">
					<img
						className="Travel"
						src={logobenefit02re}
						alt="Travel"
					/>
				</div>
			</div>
			<div className="Container-rewards">
				<div className="info-employee">
					{infoEmployeeLocation(data, dataPriv)}

					{/* CONTEXT obteniendo COUNTRY de PUBLIC */}
					{/* {infoEmployeeLocation(this.state.generalInfo)} */}
					<div className="type-rewards">
						{guaranteedLocation(dataPriv, data)}

						{/* {isTruePriv && <p>Private Content Loaded</p>} */}
						{/* <Variable privateInfo={dataPriv} /> */}
						{/* Guaranteed */}
						{/* variable */}
					</div>
				</div>
			</div>
		</motion.div>
	) : <Redirect to="/inicio"/>;
};

export default TotalRewards;
