import React, { Component } from 'react';
import './css/identidad.css';
import pdf from '../../assets/identidad/pdf.svg';
import styled from 'styled-components';
import { animateScroll as scroll} from "react-scroll";

const ReporteSusten = styled.div`
	.wrapperIdenditad {
		grid-template-columns: repeat(5, 1fr);
	}
`;

export default class SliderSwiper extends Component {

	componentDidMount(){
		scroll.scrollToTop();
	}

	render() {
		return (
			<div>
				<div class="nosotrosWrapper">
					<div class="nosotrosHeader">
						<h4>Reporte de Sostenibilidad</h4>
						<p>
							En el trabajo diario tomamos en cuenta las prioridades sociales,
							económicas y ambientales de nuestro entorno. Nuestra estrategia de
							sostenibilidad da respuesta a estas prioridades, genera
							oportunidades de valor y contribuye al desarrollo sostenible.
						</p>
					</div>
				</div>

				{/* <div id="logotiposIdentidad">
          <div class="totalBenefits__title__container">
            <h1>Logotipos</h1>
          </div>
        </div> */}
				<ReporteSusten>
					<div className="wrapperDownload">
						<div className="wrapperIdenditad">
							{/* <div className="wrapperbuttonBig">
              <div className="wrapperTop">
                <div className="wrapperTopCell">
                  <img src={text}></img>
                  <p>Tipografía</p>
                </div>
              </div>
              <a className="wrapperBottom">
                <div className="downloadIdenti"><span></span><p>Descargar</p></div>
              </a>
            </div> */}

							<div className="wrapperbuttonBig">
								<div className="wrapperTop">
									<div className="wrapperTopCell">
										<img className="noTextImg" src={pdf}></img>
										<p>Reporte de sostenibilidad 2017</p>
									</div>
								</div>
								<a
									className="wrapperBottom"
									href="https://www.aeromexico.com/cms/sites/default/files/Informe_Anual_Aerome_xico_2017-2.pdf"
									target="_blank"
									rel="noopener noreferrer"
								>
									<div className="downloadIdenti">
										<span></span>
										<p>Descargar</p>
									</div>
								</a>
							</div>

							<div className="wrapperbuttonBig">
								<div className="wrapperTop">
									<div className="wrapperTopCell">
										<img className="noTextImg" src={pdf}></img>
										<p>Reporte de sostenibilidad 2015-2016</p>
									</div>
								</div>
								<a
									className="wrapperBottom"
									href="https://www.aeromexico.com/cms/sites/default/files/sustentablilidad-espanol-sep18.pdf"
									target="_blank"
									rel="noopener noreferrer"
								>
									<div className="downloadIdenti">
										<span></span>
										<p>Descargar</p>
									</div>
								</a>
							</div>

							<div className="wrapperbuttonBig">
								<div className="wrapperTop">
									<div className="wrapperTopCell">
										<img className="noTextImg" src={pdf}></img>
										<p>Reporte de sostenibilidad 2013-2014</p>
									</div>
								</div>
								<a
									className="wrapperBottom"
									href="https://www.aeromexico.com/cms/sites/default/files/Informe-GRI-2015-Espanol.pdf"
									target="_blank"
									rel="noopener noreferrer"
								>
									<div className="downloadIdenti">
										<span></span>
										<p>Descargar</p>
									</div>
								</a>
							</div>
						</div>
					</div>
				</ReporteSusten>
			</div>
		);
	}
}
