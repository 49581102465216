import React, { Component } from 'react';
import '../css/notifications.css';
import { Link } from 'react-router-dom';
import * as contentful from '../../../lib/contentful';
import getLocale from '../../../utils/getLocale';
import i18next from 'i18next';

export default class Notifications extends Component {
	constructor() {
		super();
		this.state = {
			loading: true,
			error: null,
			entries: null
		};
	}
	componentDidMount() {
		this.fetchEntries();
	}
	fetchEntries = () => {
		contentful.client
			.getEntries({
				content_type: 'articleClass',
				include: 2,
				order: '-fields.fechaDeLaPublicacion',
				locale: getLocale()
			})
			.then(response => response.items)
			.then(data => {
				this.setState({
					loading: false,
					entries: data
				});
			})
			.catch(error => {
				this.setState({
					loading: false,
					error: error
				});
			})
			.catch(err => console.error(err));
	};
	render() {
		const { loading, entries } = this.state;

		return (
			<div className="container-notifications">
				<div className="title-notification-wrap">
					<span className="title-notification">Notificaciones</span>
				</div>
				{/* <div className="notification-column">
					{!loading &&
						entries &&
						entries.slice(0, 2).map(notification => (
							<Link
								key={notification.fields.articleTittle}
								to={'/articles/' + notification.sys.id}
								id={notification.fields.articleClass}
								className="notification-item"
							>
								<p className="notification-text">
									{notification.fields.articleTittle}
								</p>
								<p className="notification-text-small">
									{notification.fields.articleIntro}
								</p>
								<span className="notification-link">
									<Link
										to={'/articles/' + notification.sys.id}
										id={notification.fields.articleClass}
										className="wrapperNewsLogros"
									>
										{i18next.t('MainMenu.leerMas')}
									</Link>
									<p>
										{
											notification.fields
												.fechaDeLaPublicacion
										}
									</p>
								</span>
							</Link>
						))}
				</div> */}
			</div>
		);
	}
}
