import styled from "styled-components";
import AirplaneBackground from "../../../assets/compliance/linea-fondo@3x.png";

const lightBlue = "#035cf7";
const vlBlue = "#E9F3F9";
const blue = "#030D75";
const darkBlue = "#020C41";
const green = "#07E8CD";
const yellow = "#FFE873";
const shadowGreen = (opacity) => `rgba(17, 177, 162, ${opacity})`;
const lbRGBA = (opacity) => `rgba(15, 75, 145,${opacity})`;
const yRGBA = (opacity) => `rgba(254, 203, 47,${opacity})`;
const darkRGBA = (opacity) => `rgba(1, 43, 92,${opacity})`;

const query2XL = "1199px";
const queryXL = "1024px";
const queryL = "991px";
const queryM = "767px";
const queryS = "580px";

export const HeaderStyled = styled.header`
	background: linear-gradient(105deg, #035cf7 0%, #020c41 100%);
	height: 500px;
	width: 100%;
	h1 {
		color: #ffffff;
		font-size: 60px;
		font-weight: 700;
		margin: 0.75em 0 0.5em;
		opacity: 1;
		text-align: center;
	}
	img {
		height: auto;
		width: 300px;
	}
	p {
		color: white;
		font-size: 25px;
		text-align: center;
	}
	.container.first {
		align-items: center;
		display: flex;
		height: 90%;
		flex-direction: column;
		justify-content: center;
	}
	.card-video {
		.card-item {
			border-radius: 5px;
			border: 2px solid transparent;
			box-shadow: 0 20px 20px 0 ${lbRGBA(0.2)};
			display: flex;
			height: 200px;
			overflow: hidden;
			.img-holder {
				align-items: center;
				background: ${lightBlue};
				display: flex;
				height: 100%;
				justify-content: center;
				width: 40%;
				svg {
					fill: white;
					height: 120px;
					width: auto;
				}
				img {
					object-fit: contain;
					height: 100%;
					width: 100%;
				}
			}
			.card-intel-holder {
				background: white;
				height: 100%;
				width: 60%;
				overflow: hidden;
				padding: 15px;
				position: relative;
				h3 {
					color: ${darkBlue};
					font-size: 25px;
					font-weight: 700;
				}
				p {
					color: ${lightBlue};
					font-size: 16px;
					text-align: left;
					line-height: 1.1;
					margin-bottom: 1.25em;
				}
				.fake-link {
					background: ${darkBlue};
					border: none;
					border-radius: 30px;
					color: white;
					cursor: pointer;
					font-weight: 700;
					padding: 0.5em 1.5em;
					/* color: ${lightBlue}; */
					/* text-decoration: underline; */
				}
			}
		}
		&.the-qr-card-video-item {
			.card-item {
				background: ${yellow};
				box-shadow: 0 20px 20px 0 ${yRGBA(0.2)};
				.card-intel-holder {
					background: ${yellow};
					text-align: center;
					/* h3 {
						text-align: left;
					} */
					button {
						background: ${darkBlue};
						border: none;
						border-radius: 30px;
						color: white;
						cursor: pointer;
						font-weight: 700;
						padding: 0.5em 1.5em;
					}
					p {
						color: ${darkBlue};
						margin-top: 1em;
						margin-bottom: 0.25em;
						text-align: center;
					}
					&.with-button {
						align-items: center;
						display: flex;
						flex-direction: column;
						justify-content: flex-start;
						h3 {
							width: 100%;
						}
						.bottom {
							align-items: center;
							display: flex;
							flex-direction: column;
							justify-content: center;
							height: 100%;
							p {
								margin: 0 0 0.75em 0;
							}
						}
					}
				}
				.img-holder {
					background: white;
				}
			}
		}
	}
	@media (max-width: ${query2XL}) {
		h1 {
			font-size: 45px;
		}
		.card-video {
			.card-item {
				.img-holder {
					width: 30%;
					svg {
						height: 90px;
					}
					img {
						height: 110px;
					}
				}
				.card-intel-holder {
					width: 70%;
					h3 {
						font-size: 20px;
					}
					p {
						font-size: 14px;
					}
				}
			}
		}
	}
	@media (max-width: ${queryL}) {
		height: 350px;
		h1 {
			font-size: 30px;
		}
		img {
			width: 200px;
		}
		p {
			font-size: 16px;
		}
		.container.first {
			height: 75%;
		}
		.card-video {
			.card-item {
				flex-wrap: wrap;
				height: 150px;
				&:first-child {
					margin-bottom: 1em;
				}
				.img-holder {
					height: 100%;
					width: 40%;
					svg {
						height: 90px;
					}
					img {
						height: 80px;
					}
				}
				.card-intel-holder {
					width: 60%;
					h3 {
						font-size: 20px;
						br {
							display: none;
						}
						span {
							margin-left: 0.25em;
						}
					}
				}
			}
		}
	}
	@media (max-width: ${queryM}) {
		height: auto;
		padding: 3em 0 2em;
		.container.first {
			height: auto;
			margin-bottom: 1em;
		}
		.card-video {
			.card-item {
				border: 1px solid ${blue};
				height: 170px;
			}
		}
	}
	@media (max-width: ${queryS}) {
		.card-video {
			.card-item {
				height: 290px;
				.img-holder {
					img {
						height: 120px;
					}
				}
			}
			&:first-child {
				.card-item {
					height: 220px;
				}
			}
		}
	}
`;

export const SectionNOM = styled.section`
	background: ${vlBlue};
	padding-top: 230px;
	width: 100vw;
	h2 {
		color: ${darkBlue};
		font-size: 35px;
		font-weight: 700;
		margin-bottom: 1.5em;
		text-align: center;
	}
	.floating-text {
		background: ${green};
		color: white;
		font-size: 20px;
		font-weight: 700;
		height: 90px;
		width: 100%;
		.row,
		.container {
			height: 100%;
		}
		p {
			line-height: 1.2;
			margin: 0;
			padding: 0 1.5em;
		}
	}
	.with-background {
		background-attachment: fixed;
		background-color: ${vlBlue};
		background-image: url(${AirplaneBackground});
		background-size: contain;
		border-top: 1px solid ${green};
		padding: 60px 0 40px;
		width: 100%;
		.nom-items {
			align-items: center;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			margin: 0 auto;
			width: 100%;
		}
	}
	@media (max-width: ${query2XL}) {
		padding-top: 200px;
		h2 {
			font-size: 30px;
		}
	}
	@media (max-width: ${queryL}) {
		padding-top: 290px;
		h2 {
			font-size: 25px;
		}
	}
	@media (max-width: ${queryM}) {
		padding-top: 30px;
		h2 {
			font-size: 20px;
		}
		.floating-text {
			p {
				font-size: 16px;
			}
		}
		.with-background {
			background-size: 400%;
		}
	}
	@media (max-width: ${queryS}) {
		.floating-text {
			height: 160px;
		}
	}
`;

export const Nitem = styled.div`
	align-items: flex-start;
	border: 1px solid #07e8cd;
	border-radius: 5px;
	box-shadow: 0 15px 15px ${shadowGreen(0.15)};
	cursor: pointer;
	display: flex;
	height: 75px;
	/* height: 65px; */
	justify-content: flex-start;
	margin: 10px;
	overflow: hidden;
	transform: scale(1);
	transition: all 0.15s ease-in-out;
	width: 250px;
	will-change: transform;
	/* width: 300px; */
	.left-nitem {
		align-items: center;
		background: #07e8cd;
		display: flex;
		height: 100%;
		justify-content: center;
		width: 65px;
		svg {
			fill: white;
			height: 40px;
			width: auto;
		}
	}
	.right-nitem {
		align-items: center;
		background: white;
		display: flex;
		height: 100%;
		justify-content: flex-start;
		padding: 10px 15px;
		position: relative;
		width: calc(100% - 65px);
		p {
			color: #07e8cd;
			font-size: 14px;
			font-weight: 700;
			line-height: 1.2;
			margin: 0;
			position: relative;
			transition: color 0.25s ease-in-out;
			width: 100%;
			will-change: color;
			z-index: 2;
		}
		.background-filter {
			background: #07e8cd;
			border: 0 solid transparent;
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			transition: width 0.15s ease-in-out;
			will-change: width;
			width: 0;
			z-index: 1;
		}
	}
	&:hover {
		transform: scale(1.1);
		.right-nitem {
			p {
				color: white;
			}
			.background-filter {
				width: 100%;
			}
		}
	}
	@media (max-width: ${query2XL}) {
		width: 270px;
		.right-nitem {
			p {
				font-size: 16px;
			}
		}
	}
	@media (max-width: ${queryXL}) {
		width: 290px;
	}
	@media (max-width: ${queryL}) {
		width: 320px;
	}
	@media (max-width: ${queryM}) {
		width: 305px;
	}
`;

export const ColaboradorOne = styled.section`
	background: white;
	padding: 45px 0;
	width: 100vw;
	h2 {
		color: ${darkBlue};
		font-size: 35px;
		font-weight: 700;
		padding: 0 1.5em;
		text-align: center;
	}
	@media (max-width: ${query2XL}) {
		h2 {
			font-size: 30px;
		}
	}
	@media (max-width: ${queryL}) {
		h2 {
			font-size: 25px;
		}
	}
	@media (max-width: ${queryM}) {
		padding: 20px 0;
		h2 {
			font-size: 20px;
		}
	}
`;

export const ColaboradorTwo = styled.section`
	background: ${vlBlue};
	padding: 90px 0 80px;
	.the-gridder {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		column-gap: 20px;
		row-gap: 20px;
	}
	@media (max-width: ${query2XL}) {
	}
	@media (max-width: ${queryL}) {
		.the-gridder {
			grid-template-columns: 1fr 1fr;
			row-gap: 60px;
		}
	}
	@media (max-width: ${queryM}) {
		.the-gridder {
			grid-template-columns: 1fr 1fr;
		}
	}
	@media (max-width: ${queryS}) {
		.the-gridder {
			grid-template-columns: 1fr;
		}
	}
`;

export const ColaborarCard = styled.div`
	align-items: center;
	background: white;
	border: 2px solid ${lightBlue};
	border-radius: 5px;
	box-shadow: 0 10px 20px ${darkRGBA(0.15)};
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 20px 20px 20px;
	position: relative;
	img {
		border-radius: 50%;
		box-shadow: 0 15px 20px ${lbRGBA(0.25)};
		height: 70px;
		width: auto;
		left: 50%;
		position: absolute;
		transform: translate(-50%, -50%);
		top: 0;
	}
	a {
		background: ${lightBlue};
		border-radius: 30px;
		color: white;
		font-weight: 700;
		margin-top: 10px;
		padding: 0.25em 1.25em;
	}
	p {
		color: ${blue};
		font-size: 16px;
		font-weight: 700;
		margin: 0;
		text-align: center;
	}
	&:first-child {
		p {
			margin-top: 20px;
		}
	}
	&:nth-child(2) {
		p {
			margin-top: 20px;
		}
	}
	&:nth-child(3) {
		p {
			margin-top: 20px;
		}
	}
	@media (max-width: ${queryS}) {
		&:nth-child(4) {
			p {
				margin-top: 20px;
			}
		}
	}
`;

export const AyudaStyle = styled.section`
	background: rgb(27, 134, 158);
	height: 90px;
	width: 100%;
	article {
		align-items: center;
		display: flex;
		height: 100%;
		justify-content: center;
		p {
			color: white;
			font-size: 27px;
			font-weight: 700;
			line-height: 1.2;
			margin: 0;
			text-align: left;
		}
	}
	@media (max-width: ${query2XL}) {
		br {
			display: none !important;
		}
		article p {
			font-size: 22px;
		}
	}
	@media (max-width: ${queryL}) {
		height: 110px;
		article p {
			font-size: 20px;
		}
	}
	@media (max-width: ${queryM}) {
		article p {
			font-size: 18px;
		}
	}
`;

export const ContactoStyle = styled.section`
	padding: 50px 0;
	.left,
	.right {
		align-items: flex-start;
		display: flex;
		flex-direction: column;
		justify-content: center;
		a {
			font-size: 25px;
			font-weight: 700;
			margin: 0;
			&.big-email {
				font-size: 20px;
			}
		}
		svg {
			fill: ${blue};
			height: 25px;
			margin: 0;
			width: auto;
		}
		p {
			color: ${blue};
			font-size: 25px;
			font-weight: 700;
			margin: 0 0 0 0.25em;
		}
		.no-margin {
			font-size: 16px;
			margin: 0 0 0.5em 0;
		}
		.single-line {
			div {
				align-items: center;
				display: flex;
				justify-content: flex-start;
				a {
					margin: 0 0 0 0.25em;
				}
			}
		}
	}
	.fake-border {
		background: ${blue};
		height: 60%;
		opacity: 0.5;
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		width: 1px;
	}
	.right {
		padding-left: 2em;
	}
	@media (max-width: ${queryL}) {
		.left .big-email,
		.right .big-email {
			/* font-size: 16px !important; */
			overflow: hidden !important;
			text-overflow: ellipsis !important;
			white-space: nowrap !important;
			width: 100%;
			padding: 0 2em 0 0;
			/* margin-top: -1em; */
		}
	}
	@media (max-width: ${queryM}) {
		.fake-border {
			display: none !important;
		}
		.right {
			.no-margin {
				font-size: 20px;
				margin-top: 1.5em;
				width: 100%;
			}
		}
		.word-breaker-nom {
			display: none;
		}
	}
	@media (max-width: ${queryS}) {
		padding: 20px 0;
		.left .big-email,
		.right .big-email {
			font-size: 16px !important;
			overflow: hidden !important;
			text-overflow: ellipsis !important;
			white-space: nowrap !important;
			width: 100%;
			padding: 0 2em;
			margin-top: -1em;
		}
		.right .big-email {
			margin-top: 0;
		}
		.single-line {
			display: flex;
			flex-direction: column;
			justify-content: center;
			margin-bottom: 0.5em;
			width: 100%;
			a {
				text-align: center;
			}
			div {
				align-items: center;
				justify-content: center !important;
				p {
					font-size: 18px;
				}
				svg {
					height: 18px;
				}
			}
		}
		.right {
			.no-margin {
				font-size: 20px;
				margin-top: 1.5em;
				text-align: center;
				width: 100%;
			}
		}
	}
`;

export const PoliticaStyled = styled.div`
	background: ${darkBlue};
	height: 100px;
	p {
		color: white;
		font-size: 25px;
		font-weight: 700;
		line-height: 1.2;
		margin: 0;
	}
	.container,
	.row {
		height: 100%;
	}
	.right {
		align-items: center;
		display: flex;
		height: 100%;
		justify-content: center;
		.fake-red-button {
			font-weight: 700;
		}
	}
	@media (max-width: ${queryL}) {
		height: 120px;
	}
	@media (max-width: ${queryM}) {
		height: 160px;
		p {
			font-size: 20px;
			text-align: center;
		}
		.right {
			height: auto;
		}
	}
`;
