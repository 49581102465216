import React, { Component } from 'react';
import MenuTop from './MenuTop';
export default class Index extends Component {
	render() {
		// <header style={{ position: 'sticky', top: 0, zIndex: 100 }}>
		return (
			<header>
				<MenuTop />
			</header>
		);
	}
}
