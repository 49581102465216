import React, { Component } from 'react';
import './css/identidad.css';
import pdf from '../../assets/identidad/pdf.svg';
import styled from 'styled-components';
import i18next from 'i18next';
import { ri2020, ri2021, ri2022 } from './jsons/reporteIndustrial';
import { animateScroll as scroll} from "react-scroll";

const ReporteSusten = styled.div`
	.wrapperIdenditad {
		grid-template-columns: repeat(5, 1fr);
	}
`;

export default class SliderSwiper extends Component {

	componentDidMount(){
		scroll.scrollToTop();
	}

	render() {
		return (
			<div style={{ background: '#EDF6FB' }}> 
				<div class="nosotrosWrapper">
					<div class="nosotrosHeader">
						<h4>{i18next.t('Reportes.Boletin.titulo')}</h4>
					</div>
				</div>

				<ReporteSusten>
					<div className="wrapperDownload">
						<div className="wrapperIdenditad">
							<h3 className="fulltitle">2022</h3>
							{ri2022.map((item, index) => {
								if (item.url !== '') {
									return (
										<div
											className="wrapperbuttonBig"
											key={index}
										>
											<div className="wrapperTop">
												<div className="wrapperTopCell">
													<img
														className="noTextImg"
														src={pdf}
														alt="PDF"
													></img>
													<p style={{color:'#035cf7',fontWeight:'bold'}}>
														{i18next.language ===
														'es'
															? item.month.es
															: item.month
																	.en}{' '}
														2022
													</p>
												</div>
											</div>
											<a
												className="wrapperBottom"
												href={item.url}
												target="_blank"
												rel="noopener noreferrer"
											>
												<div className="downloadIdenti">
													<span></span>
													<p style={{color:'#035cf7',fontWeight:'bold'}}>
														{i18next.t(
															'Reportes.Boletin.descargar'
														)}
													</p>
												</div>
											</a>
										</div>
									);
								}
							})}
							<h3 className="fulltitle">2021</h3>
							{ri2021.map((item, index) => {
								if (item.url !== '') {
									return (
										<div
											className="wrapperbuttonBig"
											key={index}
										>
											<div className="wrapperTop">
												<div className="wrapperTopCell">
													<img
														className="noTextImg"
														src={pdf}
														alt="PDF"
													></img>
													<p style={{color:'#035cf7',fontWeight:'bold'}}>
														{i18next.language ===
														'es'
															? item.month.es
															: item.month
																	.en}{' '}
														2021
													</p>
												</div>
											</div>
											<a
												className="wrapperBottom"
												href={item.url}
												target="_blank"
												rel="noopener noreferrer"
											>
												<div className="downloadIdenti">
													<span></span>
													<p style={{color:'#035cf7',fontWeight:'bold'}}>
														{i18next.t(
															'Reportes.Boletin.descargar'
														)}
													</p>
												</div>
											</a>
										</div>
									);
								}
							})}
							<h3 className="fulltitle">2020</h3>
							{ri2020.map((item, index) => (
								<div className="wrapperbuttonBig" key={index}>
									<div className="wrapperTop">
										<div className="wrapperTopCell">
											<img
												className="noTextImg"
												src={pdf}
												alt="PDF"
											></img>
											<p style={{color:'#035cf7',fontWeight:'bold'}}>
												{i18next.language === 'es'
													? item.month.es
													: item.month.en}{' '}
												2020
											</p>
										</div>
									</div>
									<a
										className="wrapperBottom"
										href={item.url}
										target="_blank"
										rel="noopener noreferrer"
									>
										<div className="downloadIdenti">
											<span></span>
											<p style={{color:'#035cf7',fontWeight:'bold'}}>
												{i18next.t(
													'Reportes.Boletin.descargar'
												)}
											</p>
										</div>
									</a>
								</div>
							))}
							<h3 className="fulltitle">2019</h3>
							<div className="wrapperbuttonBig">
								<div className="wrapperTop">
									<div className="wrapperTopCell">
										<img
											className="noTextImg"
											src={pdf}
											alt="PDF"
										></img>
										<p style={{color:'#035cf7',fontWeight:'bold'}}>
											{i18next.language === 'es'
												? 'Diciembre '
												: 'December '}
											2019
										</p>
									</div>
								</div>
								<a
									className="wrapperBottom"
									href="https://grupoaeromexico-my.sharepoint.com/personal/amcomunicacioninterna_aeromexico_com/Documents/Mi%20Aeromexico%20-%20Documentos/2020/BOLETIN%20DE%20INDUSTRIA/12.%20Boleti%CC%81n%20de%20Industria%20Diciembre%202019.pdf?CT=1589469795407&OR=ItemsView"
									target="_blank"
									rel="noopener noreferrer"
								>
									<div className="downloadIdenti">
										<span></span>
										<p style={{color:'#035cf7',fontWeight:'bold'}}>
											{i18next.t(
												'Reportes.Boletin.descargar'
											)}
										</p>
									</div>
								</a>
							</div>
							<div className="wrapperbuttonBig">
								<div className="wrapperTop">
									<div className="wrapperTopCell">
										<img
											className="noTextImg"
											src={pdf}
											alt="PDF"
										></img>
										<p style={{color:'#035cf7',fontWeight:'bold'}}>
											{i18next.language === 'es'
												? 'Noviembre '
												: 'November '}
											2019
										</p>
									</div>
								</div>
								<a
									className="wrapperBottom"
									href="https://grupoaeromexico-my.sharepoint.com/personal/amcomunicacioninterna_aeromexico_com/Documents/Mi%20Aeromexico%20-%20Documentos/2020/BOLETIN%20DE%20INDUSTRIA/11.%20Boleti%CC%81n%20de%20Industria%20Noviembre%202019.pdf?CT=1589469752680&OR=ItemsView"
									target="_blank"
									rel="noopener noreferrer"
								>
									<div className="downloadIdenti">
										<span></span>
										<p style={{color:'#035cf7',fontWeight:'bold'}}>
											{i18next.t(
												'Reportes.Boletin.descargar'
											)}
										</p>
									</div>
								</a>
							</div>
							<div className="wrapperbuttonBig">
								<div className="wrapperTop">
									<div className="wrapperTopCell">
										<img
											className="noTextImg"
											src={pdf}
											alt="PDF"
										></img>
										<p style={{color:'#035cf7',fontWeight:'bold'}}>
											{i18next.language === 'es'
												? 'Octubre '
												: 'October '}
											2019
										</p>
									</div>
								</div>
								<a
									className="wrapperBottom"
									href="https://grupoaeromexico-my.sharepoint.com/personal/amcomunicacioninterna_aeromexico_com/Documents/Mi%20Aeromexico%20-%20Documentos/2020/BOLETIN%20DE%20INDUSTRIA/10.%20Boleti%CC%81n%20de%20Industria%20Octubre%202019.pdf?CT=1589469774590&OR=ItemsView"
									target="_blank"
									rel="noopener noreferrer"
								>
									<div className="downloadIdenti">
										<span></span>
										<p style={{color:'#035cf7',fontWeight:'bold'}}>
											{i18next.t(
												'Reportes.Boletin.descargar'
											)}
										</p>
									</div>
								</a>
							</div>

							<div className="wrapperbuttonBig">
								<div className="wrapperTop">
									<div className="wrapperTopCell">
										<img
											className="noTextImg"
											src={pdf}
										></img>
										<p	 style={{color:'#035cf7',fontWeight:'bold'}}>
											{i18next.t(
												'Reportes.Boletin.sep19'
											)}
										</p>
									</div>
								</div>
								<a
									className="wrapperBottom"
									href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EUxyKBh5_xZOtPj9BaOhC4MBCNMTPcVVIxhkoupNAoqOYQ?e=MlFocC"
									target="_blank"
									rel="noopener noreferrer"
								>
									<div className="downloadIdenti">
										<span></span>
										<p style={{color:'#035cf7',fontWeight:'bold'}}>
											{i18next.t(
												'Reportes.Boletin.descargar'
											)}
										</p>
									</div>
								</a>
							</div>

							<div className="wrapperbuttonBig">
								<div className="wrapperTop">
									<div className="wrapperTopCell">
										<img
											className="noTextImg"
											src={pdf}
										></img>
										<p style={{color:'#035cf7',fontWeight:'bold'}}>
											{i18next.t(
												'Reportes.Boletin.ago19'
											)}
										</p>
									</div>
								</div>
								<a
									className="wrapperBottom"
									href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/ESsHioQt88dJr7UlpkFKOzIBn0uG1i9_rR1ad2dOTroOLQ?e=k2PwY3"
									target="_blank"
									rel="noopener noreferrer"
								>
									<div className="downloadIdenti">
										<span></span>
										<p style={{color:'#035cf7',fontWeight:'bold'}}>
											{i18next.t(
												'Reportes.Boletin.descargar'
											)}
										</p>
									</div>
								</a>
							</div>

							<div className="wrapperbuttonBig">
								<div className="wrapperTop">
									<div className="wrapperTopCell">
										<img
											className="noTextImg"
											src={pdf}
										></img>
										<p style={{color:'#035cf7',fontWeight:'bold'}}>
											{i18next.t(
												'Reportes.Boletin.jul19'
											)}
										</p>
									</div>
								</div>
								<a
									className="wrapperBottom"
									href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/Ebg_xe93OmFLuM6iksGaJgsBo_o_cfdxMkwUZngx4GBrWA?e=tBXSn3"
									target="_blank"
									rel="noopener noreferrer"
								>
									<div className="downloadIdenti">
										<span></span>
										<p style={{color:'#035cf7',fontWeight:'bold'}}>
											{i18next.t(
												'Reportes.Boletin.descargar'
											)}
										</p>
									</div>
								</a>
							</div>

							<div className="wrapperbuttonBig">
								<div className="wrapperTop">
									<div className="wrapperTopCell">
										<img
											className="noTextImg"
											src={pdf}
										></img>
										<p style={{color:'#035cf7',fontWeight:'bold'}}>
											{i18next.t(
												'Reportes.Boletin.jun19'
											)}
										</p>
									</div>
								</div>
								<a
									className="wrapperBottom"
									href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EfvM5DFx2plBkgch1yFatfcBYp41H193v_gh7wIrUza3Sg?e=VTjFOz"
									target="_blank"
									rel="noopener noreferrer"
								>
									<div className="downloadIdenti">
										<span></span>
										<p style={{color:'#035cf7',fontWeight:'bold'}}>
											{i18next.t(
												'Reportes.Boletin.descargar'
											)}
										</p>
									</div>
								</a>
							</div>

							<div className="wrapperbuttonBig">
								<div className="wrapperTop">
									<div className="wrapperTopCell">
										<img
											className="noTextImg"
											src={pdf}
										></img>
										<p style={{color:'#035cf7',fontWeight:'bold'}}>
											{i18next.t(
												'Reportes.Boletin.may19'
											)}
										</p>
									</div>
								</div>
								<a
									className="wrapperBottom"
									href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/eabautista_aeromexico_com/EXHWCyCuTHVKjrViJyhHr2gBb1lG9DaHn_g_A2FSWEYfJQ?e=bUTS2s"
									target="_blank"
									rel="noopener noreferrer"
								>
									<div className="downloadIdenti">
										<span></span>
										<p style={{color:'#035cf7',fontWeight:'bold'}}>
											{i18next.t(
												'Reportes.Boletin.descargar'
											)}
										</p>
									</div>
								</a>
							</div>

							<div className="wrapperbuttonBig">
								<div className="wrapperTop">
									<div className="wrapperTopCell">
										<img
											className="noTextImg"
											src={pdf}
										></img>
										<p style={{color:'#035cf7',fontWeight:'bold'}}>
											{i18next.t(
												'Reportes.Boletin.abr19'
											)}
										</p>
									</div>
								</div>
								<a
									className="wrapperBottom"
									href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/eabautista_aeromexico_com/EaN129umLutIngEDG0bbGrgB1MGa6mTN_SJDDmO70rfSGA?e=f68dj3"
									target="_blank"
									rel="noopener noreferrer"
								>
									<div className="downloadIdenti">
										<span></span>
										<p style={{color:'#035cf7',fontWeight:'bold'}}>
											{i18next.t(
												'Reportes.Boletin.descargar'
											)}
										</p>
									</div>
								</a>
							</div>

							<div className="wrapperbuttonBig">
								<div className="wrapperTop">
									<div className="wrapperTopCell">
										<img
											className="noTextImg"
											src={pdf}
										></img>
										<p style={{color:'#035cf7',fontWeight:'bold'}}>
											{i18next.t(
												'Reportes.Boletin.mar19'
											)}
										</p>
									</div>
								</div>
								<a
									className="wrapperBottom"
									href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/eabautista_aeromexico_com/ESjjRmDb3QJNp8hsPcn6_-MBcHM7LcxSmtqn8KM8KAyWlQ?e=6uIz9X"
									target="_blank"
									rel="noopener noreferrer"
								>
									<div className="downloadIdenti">
										<span></span>
										<p >
											{i18next.t(
												'Reportes.Boletin.descargar'
											)}
										</p>
									</div>
								</a>
							</div>

							<div className="wrapperbuttonBig">
								<div className="wrapperTop">
									<div className="wrapperTopCell">
										<img
											className="noTextImg"
											src={pdf}
										></img>
										<p style={{color:'#035cf7',fontWeight:'bold'}}>
											{i18next.t(
												'Reportes.Boletin.feb19'
											)}
										</p>
									</div>
								</div>
								<a
									className="wrapperBottom"
									href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/eabautista_aeromexico_com/EbO-Mn-mzixMuFU5SIPEsIgBQ-63CltOvTOCjc6PDqoUcQ?e=923Bhp"
									target="_blank"
									rel="noopener noreferrer"
								>
									<div className="downloadIdenti">
										<span></span>
										<p style={{color:'#035cf7',fontWeight:'bold'}}>
											{i18next.t(
												'Reportes.Boletin.descargar'
											)}
										</p>
									</div>
								</a>
							</div>

							<div className="wrapperbuttonBig">
								<div className="wrapperTop">
									<div className="wrapperTopCell">
										<img
											className="noTextImg"
											src={pdf}
										></img>
										<p style={{color:'#035cf7',fontWeight:'bold'}}>
											{i18next.t(
												'Reportes.Boletin.ene19'
											)}
										</p>
									</div>
								</div>
								<a
									className="wrapperBottom"
									href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/eabautista_aeromexico_com/EXuH0HvHcARIpJSTLehh3tUBNo-YAARqUpP5d0qx9eEW4w?e=ZWne0e"
									target="_blank"
									rel="noopener noreferrer"
								>
									<div className="downloadIdenti">
										<span></span>
										<p style={{color:'#035cf7',fontWeight:'bold'}}>
											{i18next.t(
												'Reportes.Boletin.descargar'
											)}
										</p>
									</div>
								</a>
							</div>
						</div>
					</div>
				</ReporteSusten>
				{/* <Index/> */}
			</div>
		);
	}
}
