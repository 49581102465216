import React, { Component } from 'react';
// import { Link } from "react-router-dom";
import i18next from 'i18next';
import { Trans } from 'react-i18next';

import './css/estilos.css';

export default class C06 extends Component {
	render() {
		return (
			<div
				className="seccTh whiteBgColor"
				style={{ background: '#EDF6FB' }}
			>
				<div className="midWrap">
					<div className="c063UP">
						<div className="contWrap01">
							<div className="cirWrap">
								<div className="imgGif" />
							</div>

							<p className="steps">
								{i18next.t('ComiteEjec.lider1.nombre')}
							</p>
							<p className="normal">
								<Trans i18nKey="ComiteEjec.lider1.desc"></Trans>
							</p>
						</div>
						<div className="contWrap01 borders">
							<div className="cirWrap">
								<div className="imgGif" />
							</div>
							<p className="steps">
								{i18next.t('ComiteEjec.lider2.nombre')}
							</p>
							<p className="normal">
								<Trans i18nKey="ComiteEjec.lider2.desc"></Trans>
							</p>
						</div>

						<div className="contWrap01">
							<div className="cirWrap">
								<div className="imgGif" />
							</div>
							<p className="steps">
								{i18next.t('ComiteEjec.lider3.nombre')}
							</p>
							<p className="normal">
								<Trans i18nKey="ComiteEjec.lider3.desc"></Trans>
							</p>
						</div>

						<div className="contWrap01">
							<div className="cirWrap">
								<div className="imgGif" />
							</div>
							<p className="steps">
								{i18next.t('ComiteEjec.lider4.nombre')}
							</p>
							<p className="normal">
								<Trans i18nKey="ComiteEjec.lider4.desc"></Trans>
							</p>
						</div>
						{/* MODIFICAR ESTO */}
						<div className="contWrap01">
							<div className="cirWrap">
								<div className="imgGif" />
							</div>
							<p className="steps">
								{i18next.t('ComiteEjec.lider5.nombre')}
							</p>
							<p className="normal">
								<Trans i18nKey="ComiteEjec.lider5.desc"></Trans>
							</p>
						</div>
						{/* MODIFICAR FINALIZADO */}
						<div className="contWrap01">
							<div className="cirWrap">
								<div className="imgGif" />
							</div>
							<p className="steps">
								{i18next.t('ComiteEjec.lider6.nombre')}
							</p>
							<p className="normal">
								<Trans i18nKey="ComiteEjec.lider6.desc"></Trans>
							</p>
						</div>
						<div className="contWrap01">
							<div className="cirWrap">
								<div className="imgGif" />
							</div>
							<p className="steps">
								{i18next.t('ComiteEjec.lider7.nombre')}
							</p>
							<p className="normal">
								<Trans i18nKey="ComiteEjec.lider7.desc"></Trans>
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
