//Allows you to create instances of a client with access to the Contentful Content Delivery API
import InfoEnvironment from "../utils/info.environment";
export var client = new InfoEnvironment().clientContenful({host:window.location.hostname});

//Allows you to create instances of a client with access to the Contentful Content Delivery API
// import * as contentful from "contentful";
// export var client = contentful.createClient({
//   accessToken:
//     "7f0d618d0d36f9423c92fcf3c8401ab345d94850ddfadbf9cc44c6830aa0bd65",
//   space: "qc4mqnvqn8pc",
//   host: 'preview.contentful.com',
// });