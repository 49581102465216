import React, { Component, Fragment } from 'react';
import '../css/totalrewards.css';
import CurrencyFormat from 'react-currency-format';

export default class GuaranteedCan extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: this.props.privateInfo,
			salarioNum: this.props.privateInfo.salario,
			salarioAnualNum: this.props.privateInfo.salarioAnual
		};
	}

	render() {
		return (
			<div className="container-guaranteed">
				<div className="contTitleComs">
					<h1 className="rewards_title">My Compensation</h1>
				</div>

				<div className="SalarioMoney nomarginSalario">
					<div className="widthMovilSalario">
						<div className="concepSalary">
							<p>Base salary</p>
						</div>

						<div className="MonthlySalary">
							<p className="smallText">Monthly</p>
							<p>
								<CurrencyFormat
									value={
										this.state.salarioNum
									}
									displayType="text"
									thousandSeparator=","
									prefix=""
								/>
							</p>
						</div>

						<div className="MonthlySalary">
							<p className="smallText">Annual</p>

							<p>
								<CurrencyFormat
									value={
										this.state.salarioAnualNum
									}
									displayType="text"
									thousandSeparator=","
									prefix=""
								/>
							</p>
						</div>
					</div>
				</div>
				<p className="contactHRBP">
					For any question or clarifications, contact your HRBP
					{/* Recursos Humanos */}
					{localStorage.getItem('direccion') === 'RECURSOS HUMANOS' &&
					localStorage.getItem('direccionArea') === 'RECURSOS HUMANOS' ? (
						<span>
							<br />
							<span className="name">Martinez Roman Erendira Arizbe</span>{' '}
							<a href="mailto:amartinez@aeromexico.com">
								amartinez@aeromexico.com
							</a>
						</span>
					) : (
						''
					)}
					{localStorage.getItem('direccion') === 'TI' ? (
						<span>
							<br />
							<span className="name">Claudia Valeria Melo Huesca</span>{' '}
							<a href="mailto:cmelo@aeromexico.com">cmelo@aeromexico.com</a>
						</span>
					) : (
						''
					)}
				</p>
			</div>
		);
	}
}
