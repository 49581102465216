import React, { Component, Fragment } from "react";
import arrow from "../../assets/images/arrow-blue.svg";
import "./css/moreTravelBenefits.css";
import { Link } from "react-router-dom";
import Swiper from "react-id-swiper";
import * as contentful from "../../lib/contentful";
import MiniBenefitCard from "./TotalBenefits/MiniBenefitCard";
import BenefitCard from "./TotalBenefits/BenefitCard";
import BenefitManual from "./LovedBenefits/manualBenefits";
import ModalTransportacion from "./Modales/Transportacion";
import getLocale from "../../utils/getLocale";
import i18next from "i18next";
import Arriba from "../Header/Arriba";
import Index from "../../components/Footer/index";

const BenefitCardBG = (props) => (
	<div
		className="benefits-big-card bg-container-card"
		style={{
			backgroundImage: `${props.gradient}, url(${props.image})`,
			backgroundSize: `${props.size}`,
			backgroundPositionY: `${props.positionY}`,
			backgroundPositionX: `${props.positionX}`
		}}>
		<h5 className="moreTravelBenefits__card__title" style={{ color: `${props.color}` }}>
			Estacionamiento
		</h5>
		<p className="moreTravelBenefits__card__text" style={{ color: `${props.color}` }}>
			Cajón de estacionamiento de acuerdo a tu ubicación
		</p>
		<Link className="link-benefitss" to="#">
			<div className="moreTravel">
				<h6 className="moreTravelBenefits__card__more" style={{ color: `${props.color}` }}>
					View more{" "}
				</h6>
				<img className="blue-arrow" src={arrow} alt="blue-arrow" />{" "}
			</div>
		</Link>
	</div>
);
const DownloadManual = () => (
	<div>
		<BenefitManual />
	</div>
);
export default class TotalBenefits extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			error: null,
			entries: null,
			entries2: null,
			nombre: localStorage.getItem("nombre"),
			generalInfo: this.props.generalInfo,
			noEmpleado: localStorage.getItem("noEmpleado"),
			compania: /* localStorage.getItem('compania') */ "hola",
			nivel: localStorage.getItem("nivel"),
			ubicacion: localStorage.getItem("ubicacion"),
			fechaContra: localStorage.getItem("fechaContra"),
			antiguedadEmpleado: localStorage.getItem("antiguedadEmpleado"),
			salario: localStorage.getItem("salario"),
			taxonomia: [{ code: 0, email: "", es: "", en: "" }],
			salarioAnual: localStorage.getItem("salarioAnual"),
			aguinaldo: localStorage.getItem("aguinaldo"),
			primaVacacional: localStorage.getItem("primaVacacional"),
			fondoAhorro: localStorage.getItem("fondoAhorro"),
			compensacion: localStorage.getItem("compensacion"),
			valesDespensa: localStorage.getItem("valesDespensa"),
			bonoUtiles: localStorage.getItem("bonoUtiles"),
			bonoAutomovil: localStorage.getItem("bonoAutomovil"),
			bonoDesempeno: localStorage.getItem("bonoDesempeno"),
			showPdf: true
		};
	}
	componentDidMount() {
		this.fetchEntries();
		this.fetchEntries2();
	}
	fetchEntries = () => {
		contentful.client
			.getEntries({
				content_type: "myBenefits",
				include: 2,
				locale: getLocale()
			})
			.then((response) => response.items)
			.then((data) => {
				this.setState({
					loading: false,
					entries: data
				});
			})
			.catch((error) => {
				this.setState({
					loading: false,
					error: error
				});
			})
			.catch((err) => console.error(err));
	};

	fetchEntries2 = () => {
		contentful.client
			.getEntries({
				content_type: "myBenetifsFeatured",
				include: 2,
				locale: getLocale()
			})
			.then((response) => response.items)
			.then((data) => {
				this.setState({
					loading: false,
					entries2: data
				});
			})
			.catch((error) => {
				this.setState({
					loading: false,
					error: error
				});
			})
			.catch((err) => console.error(err));
	};

	renderList = (entries) => {
		const miniCard = entries.filter((item) => {
			if (item.fields.myBenefitCategoryClass === "enjoyBenefits") {
        console.log(item)
				return item;
			}
		});

		const list = miniCard.map((item) => {
			return <MiniBenefitCard key={item.sys.id} data={item} />;
		});

		return list;
	};

	renderList2 = (entries2) => {
		const benefitCard = entries2.filter((item) => {
			if (item.fields.myBenefitCategoryClass === "enjoyBenefits") {
				return item;
			}
		});

		const list = benefitCard.map((item) => {
			return (
				<div>
					<BenefitCard key={item.sys.id} data={item} />
				</div>
			);
		});

		return list;
	};

	render() {
		const { loading, entries, entries2 } = this.state;
		const params = {
			slidesPerView: 3,
			sliderPerColumn: 1,
			/*
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
        dynamicBullets: true
      }, */
			navigation: {
				nextEl: ".swiper-button-next.swiper-button",
				prevEl: ".swiper-button-prev.swiper-button"
			},
			/* centeredSlides: true, */
			spaceBetween: 30
		};
		return (
      
			<>
				{/* <Arriba /> */}
				<ModalTransportacion />
				<div className="blue-header EnjoyWorkColorr">
					<div className="backContainer">
						<div className="backContainerWrapper">
							<Link to="/recompensa-total/perfil/mis-beneficios" id="backLink">
								<span>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 32 32"
										aria-hidden="true"
										focusable="false"
										class="ay bq ci cb cc cd ce bc ef bj">
										<path d="M23.23 32a1.53 1.53 0 0 0 1.08-2.61L10.93 16 24.32 2.61A1.53 1.53 0 0 0 22.15.45L7.68 14.92a1.53 1.53 0 0 0 0 2.16l14.47 14.47a1.53 1.53 0 0 0 1.08.45z" />
									</svg>
								</span>
								<p>{i18next.t("MiExperAM.benef.categorias.regresar")}</p>
							</Link>
						</div>
					</div>
					<div className="HeaderWrapper">
						<div className="HeaderCell">
							<div className="newsContainer">
								<p>
									<span>{i18next.t("MiExperAM.benef.categorias.trabajo.title")}</span>
								</p>
								<p className="IntroHeader">{i18next.t("MiExperAM.benef.categorias.trabajo.sub")}</p>
							</div>
						</div>
					</div>
				</div>
				{!loading && entries2 && (
					<div className="swiperBenefitWrapper">
						<Swiper {...params}>{!loading && entries2 && this.renderList2(entries2)}</Swiper>
					</div>
				)}
				<div className="moreTravelBenefits__title__container">
					<h1>{i18next.t("MiExperAM.benef.categorias.todos")}</h1>
				</div>
				<div
					className={
						this.state.compania.toString().replace(" ", "") + this.state.nivel + " " + "all-benefits"
					}>
					<div className="container-all-benefits">
						{!loading && entries && this.renderList(entries)}
						 
					</div>
				</div>
				<DownloadManual />
				{/* <Index /> */}
			</>
		);
	}
}
