import React, { Component } from "react";
// import { Link } from "react-router-dom";
import arrow from "../../../assets/images/arrow-blue.svg";
import i18next from "i18next";

export default class TotalBenefits extends Component {
	constructor() {
		super();
		this.state = {
			modalIsShow: false
		};
		this.escFunction = this.escFunction.bind(this);
		this.setWrapperRef = this.setWrapperRef.bind(this);
	}
	componentDidMount() {
		document.addEventListener("keydown", this.escFunction, false);
		document.addEventListener("mousedown", this.handleClick, false);
	}
	UNSAFE_componentWillMount() {
		document.addEventListener("mousedown", this.handleClick, false);
	}
	componentWillUnmount() {
		document.removeEventListener("keydown", this.escFunction, false);
		document.removeEventListener("mousedown", this.handleClick, false);
	}
	escFunction(event) {
		if (event.keyCode === 27) {
			this.setState({
				modalIsShow: false
			});
		}
	}
	handleClick = e => {
		if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
			this.setState({
				modalIsShow: false
			});
		}
	};
	setWrapperRef(node) {
		this.wrapperRef = node;
	}
	showAlertModal = e => {
		e.preventDefault();
		this.setState({
			modalIsShow: true
		});
	};
	render() {
		return (
			<div
				onClick={this.showAlertModal}
				className={
					"benefits-big-card featuredBenefits" +
					" " +
					this.props.data.fields.paymentGroupEmployee +
					" " +
					this.props.data.fields.myBenefitImageType
				}
			>
				<div className="subcontainer-featured-benefits">
					<div
						className={
							"container-content-big-card" +
							" " +
							this.props.data.fields.myBenefitColorText
						}
					>
						<h5 className="moreTravelBenefits__card__title">
							{this.props.data.fields.myBenefitTitle}
						</h5>
						<p className="moreTravelBenefits__card__text">
							{this.props.data.fields.myBenefitIntro}
						</p>

						<a
							href="#modal"
							className="link-benefitss"
							onClick={this.showAlertModal}
						>
							<div className="moreTravel">
								<h6 className="moreTravelBenefits__card__more">
									{i18next.t("MiExperAM.benef.conoce")}{" "}
								</h6>
								<img
									className="blue-arrow"
									src={arrow}
									alt="blue-arrow"
								/>{" "}
							</div>
						</a>
					</div>
					<div className="filterBenefitWhiteText" />
					<div
						className="container-image-big-card"
						style={{
							backgroundImage: `url(${this.props.data.fields.myBenefitSliderImage.fields.file.url})`
						}}
					>
						<img
							src={
								this.props.data.fields.myBenefitSliderImage
									.fields.file.url
							}
							alt="some"
							className="img-big-card"
						/>
					</div>
				</div>
				{this.state.modalIsShow === true ? (
					<div className="container-modal-notification modal-minicard-benefits">
						<div className="modal-notification">
							<div ref={this.setWrapperRef}>
								<div
									className="background-benefits"
									style={{
										backgroundImage: `url(${this.props.data.fields.myBenefitBackground.fields.file.url})`
									}}
								>
									{/* <span>{this.props.data.fields.myBenefitCategoryTitleEs}</span> */}
									<span
										className={
											this.props.data.fields
												.myBenefitCategoryClass +
											" " +
											this.props.data.fields.paymentGroupEmployee
												.toString()
												.replace(",", " ")
										}
									>
										{
											this.props.data.fields
												.myBenefitCategoryTitleEs
										}
									</span>
								</div>
								<div className="content-modal">
									<div className="CloseWrapper">
										<div className="CloseArrow">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 32 32"
												aria-hidden="true"
												focusable="false"
												class="ay bq ci cb cc cd ce bc ef bj"
											>
												<path d="M23.23 32a1.53 1.53 0 0 0 1.08-2.61L10.93 16 24.32 2.61A1.53 1.53 0 0 0 22.15.45L7.68 14.92a1.53 1.53 0 0 0 0 2.16l14.47 14.47a1.53 1.53 0 0 0 1.08.45z" />
											</svg>
										</div>
										<button
											className="close-button"
											onClick={this.showAlertModal}
										>
											{i18next.t(
												"MiExperAM.benef.regresar"
											)}
										</button>
									</div>
									<div className="head-modal">
										<div>
											<img
												className="icon-benefits"
												src={
													this.props.data.fields
														.myBenefitIcon.fields
														.file.url
												}
												alt="some"
											/>
										</div>
										<div>
											<p>
												{
													this.props.data.fields
														.myBenefitTitle
												}
											</p>
											<p>
												CECAM y Hangares Connect y
												Oriente
											</p>
										</div>
									</div>

									<div className="BenefitContent">
										<div
											dangerouslySetInnerHTML={{
												__html: this.props.data.fields
													.myBenefitContentLong
											}}
										/>

										{/* <p>{this.props.data.fields.myBenefitContentLong}</p> */}
										<a
											className={
												this.props.data.fields
													.myBenefitMoreInformationClass
											}
											href={
												this.props.data.fields
													.myBenefitInformation
											}
											target="_blank"
											rel="noopener noreferrer"
										>
											{i18next.t(
												"MiExperAM.benef.conoce"
											)}
										</a>
									</div>

									<div className="termsConditions">
										<p>
											{i18next.t(
												"MiExperAM.benef.terminos"
											)}
										</p>
										<p>
											{
												this.props.data.fields
													.myBenefitTermsLong
											}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				) : (
					""
				)}
			</div>
		);
	}
}
