import { abril2022 } from './ventaEspecialAbril2022';
const lodash = require('lodash');

function testEmails() {
	if (
		localStorage.getItem('email') === 'jtapias@aeromexico.com' ||
		localStorage.getItem('email') === 'jgarciab@aeromexico.com'
		// localStorage.getItem('email') === 'fsoler@aeromexico.com' ||
		// localStorage.getItem("email") === "dcarbajal@aeromexico.com" ||
		// localStorage.getItem('email') === 'irios@aeromexico.com'
	) {
		
		return true;
	} else {
		
		return false;
	}
}

// VENTA ESPECIAL VALIDACION DICIEMBRE 2021
export function abril2022Func() {
	const email = localStorage.getItem('email');
	// febrero2022
	const resultado = lodash.findIndex(abril2022, function (o) {
		return o.email === email;
	});
	return resultado < 0 ? false : true;
}

export default function isVentaEspecialActive() {
	if (testEmails() || abril2022Func()) {
		// if (testEmails()) {
		return true;
	} else {
		return false;
	}
}
