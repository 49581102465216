import React, { Component } from "react";
import "./css/flota.css"
import i18next from "i18next";
import i18n from "../../../i18n";
import Header from './../../../components/Header';
import Index from "../../Footer/index";


export default class alfabetoWrapperPage extends Component {
  render() {
    return (
      <div id="nuestraflota" class="bg-white" >
          {/* <Header/> */}
          
          <div className="wrapperHeaderFlota">
              <div className="midFlota">
                  <div className="textWrapper">
                      <p className="title">{i18next.t('Flota.title')}</p>
                      <p className="subtitle">{i18next.t('Flota.subtitle')}</p>
                  </div>
              </div>
          </div>

        <div class="fleetheaderTitle">
            <p class="titlefleetPlane">{i18next.t('Flota.flotaAero.main')}</p>
            <p className="subtitle">{i18next.t('Flota.flotaAero.sub')}</p>
            <div class="lineTitleFleet"></div>
        </div>

          <div className="loghaulfleet">
                <div className="midFleet">
                    <div className="flettBox">
                        <div className="fleetImage"></div>
                        <p><span>{i18next.t('Flota.flotaAero.flota.n1')}</span> {i18next.t('Flota.flotaAero.flota.t1')}</p>
                    </div>

                    <div className="flettBox">
                        <div className="fleetImage"></div>
                        <p><span>{i18next.t('Flota.flotaAero.flota.n2')}</span> {i18next.t('Flota.flotaAero.flota.t2')}</p>
                    </div>

                    <div className="flettBox">
                        <div className="fleetImage"></div>
                        <p><span>{i18next.t('Flota.flotaAero.flota.n3')}</span> {i18next.t('Flota.flotaAero.flota.t3')}</p>
                    </div>

                    <div className="flettBox">
                        <div className="fleetImage"></div>
                        <p><span>{i18next.t('Flota.flotaAero.flota.n4')}</span> {i18next.t('Flota.flotaAero.flota.t4')}</p>
                    </div>
                </div>
          </div>

          <div className="lineFleetGrid"></div>

        <div class="fleetheaderTitle">
            <p class="titlefleetPlane">{i18next.t('Flota.flotaConnect.main')}</p>
            <p className="subtitle">{i18next.t('Flota.flotaConnect.sub')}</p>
            <div class="lineTitleFleet"></div>
        </div>


          <div className="loghaulfleet embraer">
                <div className="midFleetConnect">
                    <div className="flettBox">
                        <div className="fleetImage"></div>
                        <p><span>{i18next.t('Flota.flotaConnect.flota.n1')}</span> {i18next.t('Flota.flotaConnect.flota.t1')}</p>
                    </div>

                    <div className="flettBox">
                        <div className="fleetImage"></div>
                        <p><span>{i18next.t('Flota.flotaConnect.flota.n2')}</span> {i18next.t('Flota.flotaConnect.flota.t2')}</p>
                    </div>
                </div>
          </div>


          <div className="bannerFleetPlanes">
              <div className="midFleet">
                  <p className="title">{i18next.t('Flota.promedio')}</p>
                  <p className="subtitle">{i18next.t('Flota.promedioSub')}</p>
              </div>
          </div>
          
          {/* <Index/> */}

     </div>

      
    );
  }
}
