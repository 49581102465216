import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const FakeTableRowFC = ({
	anual,
	anualSub = null,
	bottomBorder = false,
	bigBorder = false,
	icon = false,
	id,
	mensual = null,
	title,
	tip
}) => {
	const bottomRegulator = () => {
		if (bottomBorder && bigBorder) return 'border-b-2';
		if (bottomBorder) return 'border-b';
		return;
	};

	return (
		<div
			className={`fake-row-element group relative grid w-full grid-cols-3 gap-0 ${bottomRegulator()}`}
			data-tooltip-id={id}
			data-tooltip-html={tip}
			data-tooltip-variant="dark"
			style={{ borderColor: '#E3E3E3', height: 60 }}
		>
			{!!tip ? (
				<Tooltip
					className="max-w-sm bg-amBlack shadow-xl"
					style={{ opacity: 1 }}
					id={id}
				/>
			) : null}
			<div
				className="justify-left col-span-1 flex items-center border-r px-4"
				style={{ borderColor: '#E3E3E3' }}
			>
				{!!icon ? (
					<img
						src={icon}
						className="mr-2"
						alt={title}
						style={{ height: 30, width: 30 }}
					/>
				) : null}
				<p className="text-sm group-hover:text-amRegularBlue">
					{title}
				</p>
			</div>
			<div
				className="col-span-1 flex flex-col items-start justify-center border-r px-4"
				style={{ borderColor: '#E3E3E3' }}
			>
				{!!mensual ? (
					<>
						{!!mensual.sub ? (
							<>
								<p className="text-xs font-semibold text-amRegularBlue">
									{mensual.sub}
								</p>
							</>
						) : null}
						<CurrencyFormat
							decimalSeparator="."
							decimalScale="2"
							fixedDecimalScale={true}
							value={
								typeof mensual === 'number'
									? mensual
									: mensual.cifra
							}
							displayType={'text'}
							thousandSeparator=","
							prefix="$"
							renderText={(value) => (
								<p className="text-sm font-semibold text-amBlack group-hover:text-amRegularBlue">
									{value}  
								</p>
							)}
						/>
					</>
				) : null}
			</div>
			<div className="col-span-1 flex flex-col items-start justify-center px-4">
				{!!anualSub ? (
					<p className="text-xs font-semibold text-amRegularBlue">
						{anualSub}
					</p>
				) : null}
				{!!anual ? (
					<CurrencyFormat
						decimalSeparator="."
						decimalScale="2"
						fixedDecimalScale={true}
						value={anual}
						displayType={'text'}
						thousandSeparator=","
						prefix="$"
						renderText={(value) => (
							<p className="text-sm font-semibold text-amBlack group-hover:text-amRegularBlue">
								{value}  
							</p>
						)}
					/>
				) : null}
			</div>
		</div>
	);
};

export default FakeTableRowFC;
