import React, { Fragment } from "react";
import { ColaborarCard } from "../styles/Styled";
import Alarm from "../assets/alarm.svg";
import Shield from "../assets/shield.svg";
import Warning from "../assets/warning.svg";
import Mind from "../assets/mind.svg";

function CCard({ icon, text, url, link }) {
	let myIcon;
	const iconSelector = icon => {
		switch (icon) {
			case "Alarm":
				myIcon = Alarm;
				break;
			case "Shield":
				myIcon = Shield;
				break;
			case "Warning":
				myIcon = Warning;
				break;
			default:
				myIcon = Mind;
				break;
		}
	};
	iconSelector(icon);
	return (
		<ColaborarCard>
			<img src={myIcon} alt="ICON" />
			<p>{text}</p>
			{url && (
				<Fragment>
					{url !== "null" ? (
						<a href={url} target="_blank" rel="noopener noreferrer">
							{link}
						</a>
					) : (
						<a
							href={url}
							onClick={e => e.preventDefault()}
							target="_blank"
							rel="noopener noreferrer"
						>
							{link}
						</a>
					)}
				</Fragment>
			)}
		</ColaborarCard>
	);
}

export default CCard;
