import React from 'react';
import ReactDOM from 'react-dom';
import './index.css'; 
import './tailwind.css';
import AppChange from './components/Main/App';
import reportWebVitals from './reportWebVitals';

import i18n from './i18n';
import { ContextProvider } from './globalState/state';
import defaultLang from './utils/defaultLang';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './lib/scrollTop'; 

const root = ReactDOM.createRoot(document.getElementById('root'));

var prom = new Promise(function(resolve, reject) {
	const res = defaultLang();
	i18n.init();
	if (res === true) resolve(); 
	else reject();
});

prom.then(
	i18n.init().then(() =>
		root.render(
			// ReactDOM.render(
			<BrowserRouter>
				<ContextProvider>
					<ScrollToTop>
						<AppChange />
					</ScrollToTop>
				</ContextProvider>
			</BrowserRouter>,
			document.getElementById('root') 
		)
		
	)
); 

reportWebVitals();

/* import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './tailwind.css';
import './index.css';
import App from './components/Main/App';
import { ContextProvider } from './globalState/state';
import i18n from './i18n';

import * as serviceWorker from './serviceWorker';
import ScrollToTop from './lib/scrollTop';
import defaultLang from './utils/defaultLang';

var prom = new Promise(function (resolve, reject) {
	const res = defaultLang();
	i18n.init();
	if (res === true) resolve();
	else reject();
});

prom.then(
	i18n.init().then(() =>
		ReactDOM.render(
			<BrowserRouter>
				<ContextProvider>
					<ScrollToTop>
						<App />
					</ScrollToTop>
				</ContextProvider>
			</BrowserRouter>,
			document.getElementById('root')
		)
	)
);

serviceWorker.unregister();
 */