import React, { Component } from 'react'
import Swiper from 'react-id-swiper'
import * as contentful from '../../lib/contentful'
import '../Slider/css/swiper.css'
import "../Slider/css/style.css"
import i18next from 'i18next'

export default class SliderWork extends Component {
    constructor() {
        super();
        this.state = {
          loading: true,
          error: null,
          entries: null
        };
      }
      componentDidMount() {
        this.fetchEntries();
      }
      
      fetchEntries = () => {
        contentful.client
          .getEntries({
            content_type: "articleSlider",
            include: 2,
            order: '-sys.updatedAt',
          })
          .then(response => response.items)
          .then(data => {
            // console.log(data);
            this.setState({
              loading: false,
              entries: data
            });
          })
          .catch(error => {
            this.setState({
              loading: false,
              error: error
            });
          })
          .catch(err => console.error(err));
      };

      checkVideo = video => {
        if (typeof video === 'undefined') {
          return false
        } else {
          return true
        }
      }

  render () {
    const { loading, entries } = this.state

    const params = {
      slidesPerView: 'auto',
      spaceBetween: 0,
      centeredSlides: true,
      autoplay: {
        delay: 1300,
        disableOnInteraction: true
      },

      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
        dynamicBullets: true
      },
      mousewheel: false,
      freeMode: false,
      
      /*navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },*/
      
    }

    return (
      <React.Fragment>
        {!loading && entries && (
          <Swiper {...params} className="swiper-home-slider">
    
                    <div className="cxQuotes">
                        <p> {i18next.t('CXEvolution.gente.dichos.1')} </p>
                    </div> 

                    <div className="cxQuotes">
                    <p> {i18next.t('CXEvolution.gente.dichos.2')} </p>
                    </div> 

                    <div className="cxQuotes">
                    <p> {i18next.t('CXEvolution.gente.dichos.3')} </p>
                    </div> 

                    <div className="cxQuotes">
                    <p> {i18next.t('CXEvolution.gente.dichos.4')} </p>
                    </div> 

                    <div className="cxQuotes">
                    <p> {i18next.t('CXEvolution.gente.dichos.5')} </p>
                    </div> 

                    <div className="cxQuotes">
                    <p> {i18next.t('CXEvolution.gente.dichos.6')} </p>
                    </div> 
                    
                        

          </Swiper>
        )}
      </React.Fragment>
    )
  }
}
