import React, { Component } from "react";
import * as contentful from "../../../lib/contentful"; 

import ItemCard from "./ItemCard";
import SubMenu from "../SubMenu";
import "./css/articleCards.css";
import "./css/tag.css";
import getLocale from "../../../utils/getLocale";

export default class ArticleCards extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      error: null,
      entries: null
    };
  }
  componentDidMount() {
    this.fetchEntries();
  }
  fetchEntries = () => {
    contentful.client
      .getEntries({
        content_type: "articleClass",
        include: 2,
        // order: '-sys.updatedAt',
        order: '-fields.fechaDeLaPublicacion',
        locale: getLocale(),
      })
      
      .then(response => response.items)
      .then(data => {
        this.setState({
          loading: false,
          entries: data
        });
      })

      // .then((space) => space.getLocale('es-MX'))
      // .then((locale) => console.log(locale))

      .catch(error => {
        this.setState({
          loading: false,
          error: error
        });
      })
      .catch(err => console.error(err));
  };
  renderList = articles => {
    const list = articles.slice(0, 6).map(article => {
      return <ItemCard key={article.sys.id} article={article} />;
    });
    return list;
  };

  render() {
    const { loading, entries, error } = this.state;
    // console.log(entries);
    return (
      <div className="LatestWrap newsWrapperWidth">
        <SubMenu />
        <div className="ArticleCardWrap">
          {!loading && entries && this.renderList(entries)}
        </div>
      </div>
    );
  }
}
