export default function validateSindicate(sindico) {
	switch (sindico) {
		case '99 NSD - NO SINDICALIZADOS':
			return false;
		case '99 NSD - INTERNACIONAL':
			return false;
		case '08 COD - CONFIDENCIAL':
			return false;
		case '08 NSD - NO SINDICALIZADOS':
			return false;
		case '09 NSD - NO SINDICALIZADOS':
			return false;
		case '02 COD - CONFIDENCIAL':
			return false;
		case '02 NSD - NO SINDICALIZADOS':
			return false;
		case '03 NSD - NO SINDICALIZADOS':
			return false;
		case '02 COD - COD':
			return false;
		default:
			return true;
	}
}
