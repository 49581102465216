export default function isMockUserVentaEspecial() {
	var email = localStorage.getItem("email");

	return (
		// email === "jgarciab@aeromexico.com" ||
		// email === 'amaccessguest@aeromexico.com' ||
		localStorage.getItem("email") === "jabadia@aeromexico.com" ||
		localStorage.getItem("email") === "jgarciab@aeromexico.com" ||
		localStorage.getItem("email") === "fsoler@aeromexico.com" ||
		// localStorage.getItem("email") === "dcarbajal@aeromexico.com" ||
		localStorage.getItem("email") === "irios@aeromexico.com"
		// email === 'irios@aeromexico.com' ||
		// email === 'dcarbajal@aeromexico.com' ||
		// email === 'eespindola@aeromexico.com' ||
		// email === 'mxalonso@aeromexico.com'
	);
}
