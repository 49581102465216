export const talentPlanning = {
	title: 'Talent Planning',
	blue_description:
		'Below you will find the list of your direct reports, click on the button to start assessment.',
	description:
		'Talent Planning is a critical Talent Management process to identify potential, build a strong succession plan and cross-functional bench, ensuring leadership continuity in Aeromexico.',
	second_description:
		'The objective of the Talent Assessment is to provide you with a more structure but at the same time more reflective and objective approach to assess your talent, also will help you identify clear development opportunities and actions.',
	answer_restart_one:
		'You can re-start the assessment if you want to modify your initial response. If you continue you won´t be able to change your answers.',
	answer_restart_two: 'Are you sure you want to continue?',
	button_continue: 'Continue',
	button_modify: 'Modify answers',
	table: {
		title: 'Team Members',
		firstColumn: 'Name',
		secondColumn: 'Position',
		thirdColumn: 'Actions',
		button_assessment: 'Start Assessment',
		button_card: 'Complete Assessment',
		button_viewCard: 'View Talent Card',
		button_succession: 'Succession Planning'
	},
	status: {
		notSaved: 'Not Saved',
		saved: 'Saved',
		sent: 'Sent'
	},
	assessment: {
		title: 'Talent Assessment',
		description:
			'Talent Assessment is a tool that will help you managing and developing your team members, understanding the talent who make up your workforce and the required development actions for them to continue growing in Aeromexico.',
		keyPosition: {
			title: 'Key Position',
			description:
				'A Key position or critical role are those positions that carry out the business strategy and have a greater impact across the value chain. Select the option (Yes or No) for Business Impact and Market Availability, to be considered a KEY, role must comply with both options.',
			blueSquare: {
				title: 'IDENTIFY KEY POSITIONS',
				description:
					'To ensure business continuity, you must identify KEY roles based on business impact, market availability and the unique set of competencies and knowledge needed to perform, not only for today and but in the future.'
			},
			answers: {
				result:
					'Based in your selection of Business Impact and Market Availability this role is defined as:',
				key: 'KEY POSITION',
				noKey: "ISN'T A KEY POSITION"
			}
		},
		riskLoss: {
			title: 'Risk of Loss',
			description:
				'Risk of loss section will provide clarity of employee satisfaction and engagement to the company. Select the option (Yes or No) for each question when you finish, you´ll have a LOW, MEDIUM or HIGH risk scoring.',
			blueSquare: {
				title: 'ASSESS RETENTION',
				description:
					'Reflect on the <strong>risk</strong> & impact of losing a team member, in the following section you´ll need to respond several questions, from manager-employee relation, work-life balance satisfaction, job interest alignment, career goals, etc.'
			},
			answers: {
				result: 'Based in your responses the employee Risk of Loss is:',
				low: 'LOW',
				medium: 'MEDIUM',
				high: 'HIGH'
			}
		},
		impactLoss: {
			title: 'Impact of Loss',
			description:
				'Understand the impact that the organization will have if the employee leaves de company. This scoring will help you to be prepared to face talent gaps, whether for employee internal movement and/or exists.',
			blueSquare: {
				title: 'ASSESS RETENTION',
				description:
					'Reflect on the risk & <strong>impact</strong> of losing a team member, in the following section you´ll need to respond several questions, from manager-employee relation, work-life balance satisfaction, job interest alignment, career goals, etc.'
			},
			answers: {
				result:
					'Based in your responses the employee Impact of Loss is:',
				low: 'LOW',
				medium: 'MEDIUM',
				high: 'HIGH'
			}
		},
		talentTier: {
			title: 'Talent Tiers',
			description:
				'Review the above Talent Tiers and select those that fit better with the behaviors shown by the employee.',
			blueSquare: {
				title: 'IDENTIFY POTENTIAL (Talent Tiers)',
				description:
					"Talent tiers helps you to make a prediction of an employee's potential to grow within Aeromexico, reflect which are the behaviors shown by the employee, to identify the right talent tier for him/her."
			},
			answers: {
				result:
					'Based in your responses your employee is defined as a:',
				topPotential: 'Top Potential',
				specializedTalent: 'Specialized Talent',
				coreTalent: 'Core Talent',
				relativeContributor: 'Relative Contributor'
			}
		},
		topPotential: {
			title: 'Top Potential',
			description:
				'Employee consistently demonstrates the 3 elements to be a Top Potential. Does employee have the potential to rise to and succeed in more senior critical positions?',
			blueSquare: {
				title: null,
				description:
					'A top potential in Aeromexico need to demonstrate unique behaviors consistently, to validate if employee is a TOP POTENTIAL verify If they comply with the all defined criteria.'
			},
			answers: {
				result:
					'Based in your responses your employee is defined as a:',
				resultNegative:
					'Based on your responses this role is NOT A TOP POTENTIAL, please select the Talent Tier that best suit with the behaviors shown by the employee.',
				negativeOptions: {
					core: 'CORE TALENT',
					coreRes: 'Core Talent',
					spes: 'SPECIALIZED TALENT',
					spesRes: 'Specialized Talent'
				},
				topPotential: 'Top Potential',
				specializedTalent: 'Specialized Talent'
			}
		},
		performance: {
			title: '- Performance rating:',
			title2: '- Talent Tier:',
			description: '',
			// 'According the 2021 Performance Review and the selected Talent Tier of your employee, we are suggesting the development focus and provide some suggested development actions.',
			subtitle: 'Development Focus Recommendation:',
			talent_button: 'See full Talent Development Grid',
			blueSquare: {
				title: 'REVIEW DEVELOPMENT FOCUS: PERFORMANCE VS POTENTIAL',
				description:
					"Based on 2021 Performance rating and the employee's Talent Tier, we will provide a development recommendation based in Aeromexico Talent Development Grid."
			}
		},
		strenghts: {
			title: 'Strengths & Opportunities',
			description:
				'Select 3 competencies of the drop-down list, choose at least 2 from Aeromexico “Leadership Competencies” and 1 of the drop-down list “Additional Competencies”. Make sure you provide some succinct and clear examples of how employee is showing them is his/her daily work. You can also create one if you don ́t find it in the provided competency lists.',
			blueSquare: {
				title: 'MAP STRENGTHS & OPPORTUNITIES',
				description:
					"A key element to build a meaningful development plan is to identify and justify 3 key employee strengths and opportunities based on AM's Leadership Framework."
			},
			leftColumn: 'Strengths',
			rightColumn: 'Opportunities'
		}
	},
	talentCard: {
		title: 'Talent Card',
		subtitle: 'Complete Talent Card of',
		blueSquare: {
			title: null,
			description:
				'Talent Card summarize all the results from the assessment you have completed. You need to input employee’s picture, mobility, key career question, key development actions and next moves in order to finalized it.'
		}
	},
	successionPlan: {
		title: 'Succession Planning',
		description:
			'Once we already complete the Talent Card. It is necessary to create the succession plan of our key positions.',
		subtitle: 'Complete Succession Plan of',
		instructions:
			'<li>Fill in the following chart with the names of the employees (internal/external) who can occupy the following position.</li><li>Add internal candidates that best fit with this position according to their skills, potential and level of expertise.</li><li>Consider the level of knowledge and skills the position requires.</li>',
		blueSquare: {
			title: null,
			description:
				' Once completed the Talent Card. It is necessary to create the succession plan for Ej. SR VP MARKETING, which has been identified as KEY POSITION.'
		}
	}
};
