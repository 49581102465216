import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './css/talento.css';
import logoMyRH from '../../assets/talento/myrh-logo.png';
import logoMyRHBlue from '../../assets/talento/hr.png';
import competenciesPDF from '../../assets/talento/LeadershipCompetencies.pdf';
import close from '../../assets/logo/close.svg';
import ReactPlayer from 'react-player';
import i18next from 'i18next';
import { Trans } from 'react-i18next';
import initLang from '../../utils/initLang';
import Arriba from '../Header/Arriba';
import Index from '../Footer';

export default class taletoPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modalIsShow: true,
			loading: true,
			lang: ''
		};

		this.escFunction = this.escFunction.bind(this);
		this.setWrapperRef = this.setWrapperRef.bind(this);
	}
	componentDidMount() {
		initLang(this);
		setTimeout(() => this.setState({ loading: false }), 1500);
		document.addEventListener('keydown', this.escFunction, false);
		window.scrollTo(0,0)
	}

	UNSAFE_componentWillMount() {
		document.addEventListener('mousedown', this.handleClick, false);
	}
	escFunction(event) {
		if (event.keyCode === 27) {
			this.setState({
				modalIsShow: false
			});
		}
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.escFunction, false);
		document.removeEventListener('mousedown', this.handleClick, false);
	}
	handleClick = e => {
		if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
			this.setState({
				modalIsShow: false
			});
		}
	};
	setWrapperRef(node) {
		this.wrapperRef = node;
	}

	showAlertModal = () => {
		this.setState({
			modalIsShow: !this.state.modalIsShow
		});
	};

	render() {
		return (
			
			<div>
				{/* <Arriba/> */}
				{this.state.modalIsShow === true ? (
					<div className="flightVideoModal">
				
						<div
							className="videoModalWindow"
							ref={this.setWrapperRef}
						>
							<div
								className="closeModal"
								onClick={this.showAlertModal}
							>
								<img src={close} alt='cerrado'></img>
							</div>
							<ReactPlayer
								// url="https://jmatehuala.wistia.com/medias/add8r78lsd"
								url={`${process.env.REACT_APP_SRC_URL}videos/Video_Evaluacio%CC%81n%20de%20desempen%CC%83o_Subti%CC%81tulos%20EN.mp4`}
								playing="false"
								width="100%"
								height="100%"
								controls="true"
								volume=".5"
							/>
						</div>
					</div>
				) : (
					''
				)}

				<div className="headerDesempeno">
					<div className="midWrapperDesempeno">
						<div className="textDesempeno">
							<p className="smallDese">
								{i18next.t('Desempeño.titulo')}
							</p>
							<p className="bigDese">
								<Trans i18nKey="Desempeño.pregunta"></Trans>
							</p>
							<p className="intro">
								{i18next.t('Desempeño.intro')}
							</p>
							<div
								className={'infinitoImage ' + this.state.lang}
							/>
						</div>
					</div>
				</div>

				<div className="liberarPotencial">
					<div className="midWrapperPotencial">
						<div className="textLiberar">
							<article className="row align-items-center">
								<div className="col-12 col-md-8">
									<p className="titulo">
										<Trans i18nKey="Desempeño.titulo2">
											titulo <span>{i18next.t('1')}</span>{' '}
										</Trans>
									</p>
									<p className="subtitle">
										{i18next.t('Desempeño.subtitulo2')}
									</p>
								</div>

								<a
									className="col-12 col-md-4 ligamasTalentosa"
									// target="_blank"
									// href="https://performancemanager4.successfactors.com/sf/home?bplte_company=aerovasdem&_s.crb=n9TkOaill7n4ciR5gdtpH%2b64oyU%3d#Shell-home"
									href="/recompensa-total/my-hr"
								>
									<a
										// target="_blank"
										href="/recompensa-total/my-hr"
										// href="https://performancemanager4.successfactors.com/sf/home?bplte_company=aerovasdem&_s.crb=n9TkOaill7n4ciR5gdtpH%2b64oyU%3d#Shell-home"
									>
										<img
											src={logoMyRHBlue}
											alt="myRH"
											className="img-fluid"
										/>
										{/* <p className="actualizaAdvice">
											{i18next.t("Desempeño.actualiza")}
										</p> */}
										<p className="l6Advice">
											{i18next.t('Desempeño.niveles')}
										</p>
									</a>
									<a className="visitPageMyHR">
										{i18next.t('Desempeño.ingresar')}
									</a>
								</a>
							</article>
						</div>

						<div className="gridLiberar">
							<div className="textGridLiberar">
								<div className="imageLiberar" />
								<p className="smallito">
									{i18next.t(
										'Desempeño.Objetivos.smalltitle'
									)}
								</p>
								<p className="titulo">
									{i18next.t('Desempeño.Objetivos.title')}
								</p>
								<p className="contendo">
									{i18next.t('Desempeño.Objetivos.desc')}
								</p>
								{/* <a
									// href="#"
									// target="_blank"
									data-toggle="modal"
									data-target="#modalObjetivos"
								>
									{i18next.t('Desempeño.Objetivos.textlink')}
								</a> */}
								{/* https://grupoaeromexico-my.sharepoint.com/:p:/g/personal/eabautista_aeromexico_com/EWlO_oqO9_JNiDV670kOCXMBQh83YwZUZ84U_UN0y81-lw?e=nWwa9E */}
								{/* <a href="https://grupoaeromexico-my.sharepoint.com/:p:/g/personal/eabautista_aeromexico_com/EWlO_oqO9_JNiDV670kOCXMBQh83YwZUZ84U_UN0y81-lw?e=nWwa9E" target="_blank">Conoce cómo definir tus objetivos</a> */}
							</div>

							<div className="textGridLiberar">
								<div className="imageLiberar" />
								<p className="smallito">
									{i18next.t('Desempeño.Talent.smalltitle')}
								</p>
								<p className="titulo">
									{i18next.t('Desempeño.Talent.title')}
								</p>
								<p className="contendo">
									{i18next.t('Desempeño.Talent.desc')}
								</p>
								{/* <a
									// href="#"
									// target="_blank"
									data-toggle="modal"
									data-target="#modalTalent"
								>
									{i18next.t('Desempeño.Talent.textlink')}
								</a> */}
								{/* https://grupoaeromexico-my.sharepoint.com/:p:/g/personal/eabautista_aeromexico_com/Ec0UY6E1lUtMr2vqfzuq0xoBOevIbpnmZXTH8rwxRWoMXw?e=40aGlP */}
							</div>

							<div className="textGridLiberar">
								<div className="imageLiberar" />
								<p className="smallito">
									{i18next.t(
										'Desempeño.Conversacion.smalltitle'
									)}
								</p>
								<p className="titulo">
									{i18next.t('Desempeño.Conversacion.title')}
								</p>
								<p className="contendo">
									{i18next.t('Desempeño.Conversacion.desc')}
								</p>
								{/* <a
									// href="#"
									// target="_blank"
									data-toggle="modal"
									data-target="#modalConversion"
								>
									{i18next.t(
										'Desempeño.Conversacion.textlink'
									)}
								</a> */}
								{/* https://grupoaeromexico-my.sharepoint.com/:p:/g/personal/eabautista_aeromexico_com/EZAxzubP0VROqesu-CHAEVoBv_HvDxH8HnFg3kUg80kk8g?e=xbWLP4 */}
							</div>

							<div className="textGridLiberar">
								<div className="imageLiberar" />
								<p className="smallito">
									{i18next.t(
										'Desempeño.Evaluaciones.smalltitle'
									)}
								</p>
								<p className="titulo">
									{i18next.t('Desempeño.Evaluaciones.title')}
								</p>
								<p className="contendo">
									{i18next.t('Desempeño.Evaluaciones.desc')}
								</p>
								{/* <a
									// href="#"
									// target="_blank"
									data-toggle="modal"
									data-target="#modal360"
								>
									{i18next.t(
										'Desempeño.Evaluaciones.textlink'
									)}
								</a> */}
								{/* https://grupoaeromexico-my.sharepoint.com/:p:/g/personal/eabautista_aeromexico_com/EV9F-UiHs39LrB6pUa0WDRABZAQEg4n1ridkd-t64omZ0w?e=cSSF75 */}
							</div>

							<div
								className="textGridLiberar"
								id="backgroundBlue"
							>
								<div
									className={'imgGridText ' + this.state.lang}
								></div>
								<div className="textGrid">
									{/* <div className="imageLiberar"></div> */}
									{/* <p className="smallito">Planea tu desarrollo</p> */}
									<p className="titulo">
										{i18next.t(
											'Desempeño.Competencias.title'
										)}
									</p>
									<p className="contendo">
										{i18next.t(
											'Desempeño.Competencias.desc'
										)}
									</p>
									{/* <a
										href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/eabautista_aeromexico_com/EU5cLzzbcEFDm8BOmftBOfgBcNzl1AVQ-tySK1TE7u1U3w?e=5BAoaw"
										target="_blank"
										// data-toggle="modal"
										// data-target="#modalCompetencies"
									>
										{i18next.t(
											'Desempeño.Competencias.textlink'
										)}
									</a> */}
									{/* https://grupoaeromexico-my.sharepoint.com/:p:/g/personal/eabautista_aeromexico_com/EV9F-UiHs39LrB6pUa0WDRABZAQEg4n1ridkd-t64omZ0w?e=cSSF75 */}
								</div>
							</div>

							{/* <div className="textGridLiberar">
                          <div className="imageLiberar"></div>
                          <p className="smallito">Planeando tu talento</p>
                          <p className="titulo">Competencias de Liderazgo</p>
                          <p className="contendo">Reflejan las nuevas expectativas para los líderes, fortalecidas por nuestros valores, conductas y el enfoque que le estamos dando al cliente y empleado (CX/EX)</p>
                          <a href="https://grupoaeromexico-my.sharepoint.com/:p:/g/personal/eabautista_aeromexico_com/ETQ263aZZ81LriII10c8q48BieG1-wxdyTwWnUrRq1f2Ow?e=O7ZnCp" target="_blank">Conoce más</a>
                      </div> */}
						</div>
					</div>
				</div>

				{/* <div className="infinito">
              <div className="midWrapInfinito">
                  <div className="textInfinito">
                      <div className="textFloat">
                        <p className="infinitoUno">Nuevas Competencias de Liderazgo</p>
                        <p className="infinitoDos">Las competencias representan una parte crucial en nuestra Transformación Cultural, y nos van ayudar a tomar decisiones informadas sobre atracción, retención, estrategias de sucesión y planificación del desarrollo</p>
                        <p>Se dividen en tres dimensiones:</p>
                        <a href="" target="_blank">Conoce el detalle de las competencias</a>
                      </div>
                  </div>
              </div>
          </div> */}

				{/* Modales */}

				<div
					className="modal fade modal-xl"
					id="modalObjetivos"
					tabindex="-1"
					role="dialog"
					aria-labelledby="modalObjetivosTitle"
					aria-hidden="true"
				>
					<div
						className="modal-dialog modal-dialog-centered modal-xl"
						role="document"
					>
						<div className="modal-content w-100 p-5 modalHRProcesos">
							<div className="imageConversacion objetivosimage">
								<a
									class="closeModal close"
									data-dismiss="modal"
								>
									<img src={close}></img>
								</a>
								<div className="textWrapper">
									<p className="subtitle">
										{i18next.t(
											'Desempeño.ObjModal.smalltitle'
										)}
									</p>
									<p className="title">
										{i18next.t('Desempeño.ObjModal.title')}
									</p>
								</div>
							</div>

							<div className="modal-body text-left">
								<Trans i18nKey="Desempeño.ObjModal.desc"></Trans>

								<div className="objetivosEtapas">
									<div className="objetivosGridEtapas">
										<div className="containerObjetivos">
											<p className="headerObjetivos">
												<Trans i18nKey="Desempeño.ObjModal.titlepart1"></Trans>
											</p>
											<p className="bodyText">
												{i18next.t(
													'Desempeño.ObjModal.descpart1'
												)}
											</p>

											<div className="downloadGuiaGrid">
												<a
													href={
														this.state.lang === 'es'
															? 'https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EZcIMTXdOUlFqM-53SMmHVUBYchRm4fdr6UEaKTKxHQQPw?e=Ffpjdm'
															: 'https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EfLn_GSDoEhFvuPfbPrENVgBpWgDOyCPSHbkYm0a3k5hKw?e=GV0XVW'
													}
													target="_blank"
												>
													{i18next.t(
														'Desempeño.ObjModal.conoceProceso'
													)}
												</a>
												<a
													href={
														this.state.lang === 'es'
															? 'https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EU46g_ZW0ApEgqJEzkMaQI0Bdy7FM9lKL8auflkcbomJRw?e=fHqE3I'
															: 'https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/Ef20YHtVciFFohQliHiNtHcBgLPxqF6f3ZH89XYgYpj6vA?e=kFXc9T'
													}
													target="_blank"
												>
													{i18next.t(
														'Desempeño.ObjModal.textlink'
													)}
												</a>
											</div>
										</div>

										<div className="containerObjetivos">
											<p className="headerObjetivos">
												<Trans i18nKey="Desempeño.ObjModal.titlepart2"></Trans>
											</p>
											<p className="bodyText">
												{i18next.t(
													'Desempeño.ObjModal.descpart2'
												)}
											</p>

											<div className="downloadGuiaGrid">
												<a
													href="https://grupoaeromexico-my.sharepoint.com/:f:/g/personal/amcomunicacioninterna_aeromexico_com/EhTU9LpoiohBnPwowpPb0osBazvc16NWB4BT6MedkI9lkA?e=1YsOT9"
													target="_blank"
												>
													{i18next.t(
														'Desempeño.ObjModal.conoceProceso'
													)}
												</a>
												<a
													href="https://grupoaeromexico-my.sharepoint.com/:f:/g/personal/amcomunicacioninterna_aeromexico_com/EhnDRLRfiKdPvRXtpBSJVuMBDJTm4kIW3qJieL33YPvSjg?e=XW99J4"
													target="_blank"
												>
													{i18next.t(
														'Desempeño.ObjModal.textlink'
													)}
												</a>
											</div>
										</div>

										<div className="containerObjetivos">
											<p className="headerObjetivos">
												<Trans i18nKey="Desempeño.ObjModal.titlepart3"></Trans>
											</p>
											<p className="bodyText">
												<Trans i18nKey="Desempeño.ObjModal.descpart3"></Trans>
											</p>

											<div className="downloadGuiaGrid">
												<a
													href="https://grupoaeromexico-my.sharepoint.com/:f:/g/personal/amcomunicacioninterna_aeromexico_com/EmcFkzgdlllBlZ7Vwk0ogOcBbOCWLtgBXic-Y9ixzQJX-g?e=9Dcbah"
													target="_blank"
												>
													{i18next.t(
														'Desempeño.ObjModal.conoceProceso'
													)}
												</a>
												<a
													href="https://grupoaeromexico-my.sharepoint.com/:f:/g/personal/amcomunicacioninterna_aeromexico_com/EibWlOVPFM5NnIam76L7OxoBxvVFYQHTClH4y5H1T1SIXA?e=xnvNxu"
													target="_blank"
												>
													{i18next.t(
														'Desempeño.ObjModal.textlink'
													)}
												</a>
											</div>
										</div>
									</div>
								</div>

								{/* <p className="h1p">¿Qué es la conversación de carrera y cómo me preparo?</p>    
                <p>Es importante que tengas en cuenta estas acciones y consideraciones para que tu conversación de carrera sea lo más efectiva posible:</p>


                <div className="downloadContainerConversacion">
                  <div className="downloadContainerConversacionCell">
                    <p>Conoce más detalles sobre este proceso</p>
                    <a href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/eabautista_aeromexico_com/Ecz0-nQltQRJudaqZwsdqWsBGsnPDa8u98tERDacPfGHPQ?e=fRtH8O" target="_blank">Ver guía</a>
                  </div>
                </div> */}
							</div>
						</div>
					</div>
				</div>

				<div
					className="modal fade modal-xl"
					id="modalTalent"
					tabindex="-1"
					role="dialog"
					aria-labelledby="modalTalentTitle"
					aria-hidden="true"
				>
					<div
						className="modal-dialog modal-dialog-centered modal-xl"
						role="document"
					>
						<div className="modal-content w-100 p-5 modalHRProcesos">
							<div className="imageConversacion talentoimage">
								<a
									class="closeModal close"
									data-dismiss="modal"
								>
									<img src={close}></img>
								</a>
								<div className="textWrapper">
									<p className="subtitle">
										{i18next.t(
											'Desempeño.TalentModal.smalltitle'
										)}
									</p>
									<p className="title">
										{i18next.t(
											'Desempeño.TalentModal.title'
										)}
									</p>
								</div>
							</div>
							<div className="modal-body text-left">
								<Trans i18nKey="Desempeño.TalentModal.desc"></Trans>
								<p className="h1p">
									{i18next.t('Desempeño.TalentModal.pasos')}
								</p>
								<div className="pasosProcesoTalent">
									<div className="optionGridPasosProceso">
										<div className="topPasosProcesos">
											<p className="titloPasosProcesos">
												{i18next.t(
													'Desempeño.TalentModal.titlepart1'
												)}
											</p>
										</div>
										<div className="bottomPasosProcesos">
											<p className="widthMitad">
												{i18next.t(
													'Desempeño.TalentModal.descpart1'
												)}
											</p>
											<p className="widthMitad">
												{i18next.t(
													'Desempeño.TalentModal.descpart2'
												)}
											</p>
										</div>
									</div>

									<div className="optionGridPasosProceso">
										<div className="topPasosProcesos">
											<p className="titloPasosProcesos">
												{i18next.t(
													'Desempeño.TalentModal.titlepart2'
												)}
											</p>
										</div>
										<div className="bottomPasosProcesos">
											<p>
												{i18next.t(
													'Desempeño.TalentModal.descpart3'
												)}
											</p>
										</div>
									</div>

									<div className="optionGridPasosProceso">
										<div className="topPasosProcesos">
											<p className="titloPasosProcesos">
												{i18next.t(
													'Desempeño.TalentModal.titlepart3'
												)}
											</p>
										</div>
										<div className="bottomPasosProcesos">
											<p className="widthMitad">
												{i18next.t(
													'Desempeño.TalentModal.descpart4'
												)}
											</p>
											<p className="widthMitad">
												{i18next.t(
													'Desempeño.TalentModal.descpart5'
												)}
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div
					className="modal fade modal-xl"
					id="modalConversion"
					tabindex="-1"
					role="dialog"
					aria-labelledby="modalConversionTitle"
					aria-hidden="true"
				>
					<div
						className="modal-dialog modal-dialog-centered modal-xl"
						role="document"
					>
						<div className="modal-content w-100 p-5 modalHRProcesos">
							<div className="imageConversacion">
								<a
									class="closeModal close"
									data-dismiss="modal"
								>
									<img src={close}></img>
								</a>
								<div className="textWrapper">
									<p className="subtitle">
										{i18next.t(
											'Desempeño.ConverModal.smalltitle'
										)}
									</p>
									<p className="title">
										{i18next.t(
											'Desempeño.ConverModal.title'
										)}
									</p>
								</div>
							</div>
							<div className="modal-body text-left">
								<p>{i18next.t('Desempeño.ConverModal.desc')}</p>
								<p className="h1p">
									{i18next.t(
										'Desempeño.ConverModal.pregunta'
									)}
								</p>
								<p>
									{i18next.t(
										'Desempeño.ConverModal.respuesta'
									)}
								</p>

								<div className="gridIconsConsidera">
									<div className="optionConsidera">
										<div className="imgConsidera"></div>
										<p>
											<Trans i18nKey="Desempeño.ConverModal.titlepart1">
												cultura{' '}
												<span>{i18next.t('1')}</span>
											</Trans>
										</p>
									</div>

									<div className="optionConsidera">
										<div className="imgConsidera"></div>
										<p>
											<Trans i18nKey="Desempeño.ConverModal.titlepart2">
												cultura{' '}
												<span>{i18next.t('1')}</span>
											</Trans>
										</p>
									</div>

									<div className="optionConsidera">
										<div className="imgConsidera"></div>
										<p>
											<Trans i18nKey="Desempeño.ConverModal.titlepart3">
												cultura{' '}
												<span>{i18next.t('1')}</span>
											</Trans>
										</p>
									</div>
								</div>

								<div className="downloadContainerConversacion">
									<div className="downloadContainerConversacionCell">
										<p>
											{i18next.t(
												'Desempeño.ConverModal.textlink'
											)}
										</p>
										<a
											href="https://grupoaeromexico-my.sharepoint.com/:p:/g/personal/amcomunicacioninterna_aeromexico_com/EYarHJ2t8nNHpnobVSoUdUIBLJm0hA4usShVMNQKqZcUfg?e=NpYJb7"
											target="_blank"
										>
											{i18next.t(
												'Desempeño.ConverModal.textlinkButton'
											)}
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div
					className="modal fade modal-xl"
					id="modal360"
					tabindex="-1"
					role="dialog"
					aria-labelledby="modal360Title"
					aria-hidden="true"
				>
					<div
						className="modal-dialog modal-dialog-centered modal-xl"
						role="document"
					>
						<div className="modal-content w-100 p-5">
							<div className="modal-body text-left">
								<h3 className="text-blue-am2">
									{i18next.t(
										'Desempeño.EvalModal.smalltitle'
									)}
								</h3>
								<h2 className="h1 text-blue-am1">
									{i18next.t('Desempeño.EvalModal.title')}
								</h2>
								<p className="text-blue-am1">
									{i18next.t('Desempeño.EvalModal.desc')}
								</p>
								<div className="container my-3">
									<article className="row">
										<div className="p-2 col-5 offset-2 bg-blue-am1 text-white text-center">
											<h4>
												{i18next.t(
													'Desempeño.EvalModal.f1c2'
												)}
											</h4>
										</div>
										<div className="p-2 col-5 bg-blue-am2 text-white text-center">
											<h4>
												{i18next.t(
													'Desempeño.EvalModal.f1c3'
												)}
											</h4>
										</div>
									</article>
									<article className="row text-center">
										<div className="col-2 p-2">
											<h6 className="text-blue-am1">
												{i18next.t(
													'Desempeño.EvalModal.f2c1'
												)}
											</h6>
										</div>
										<div className="col-5 p-2 border">
											<h6>
												{i18next.t(
													'Desempeño.EvalModal.f2c2'
												)}
											</h6>
										</div>
										<div className="col-5 p-2 border">
											<h6>
												{i18next.t(
													'Desempeño.EvalModal.f2c3'
												)}
											</h6>
										</div>
									</article>
									<article className="row text-center">
										<div className="col-2 p-2">
											<h6 className="text-blue-am1">
												{i18next.t(
													'Desempeño.EvalModal.f3c1'
												)}
											</h6>
										</div>
										<div className="col-5 p-2 border">
											<h6>
												{i18next.t(
													'Desempeño.EvalModal.f3c2'
												)}
											</h6>
										</div>
										<div className="col-5 p-2 border">
											<h6>
												{i18next.t(
													'Desempeño.EvalModal.f3c3'
												)}
											</h6>
										</div>
									</article>
									<article className="row text-center">
										<div className="col-2 p-2">
											<h6 className="text-blue-am1">
												{i18next.t(
													'Desempeño.EvalModal.f4c1'
												)}
											</h6>
										</div>
										<div className="col-5 p-2 border">
											<h6>
												{i18next.t(
													'Desempeño.EvalModal.f4c2'
												)}
											</h6>
										</div>
										<div className="col-5 p-2 border">
											<h6>
												{i18next.t(
													'Desempeño.EvalModal.f4c3'
												)}
											</h6>
										</div>
									</article>
									<article className="row text-center">
										<div className="col-2 p-2">
											<h6 className="text-blue-am1">
												{i18next.t(
													'Desempeño.EvalModal.f5c1'
												)}
											</h6>
										</div>
										<div className="col-5 p-2 border">
											<h6>
												{i18next.t(
													'Desempeño.EvalModal.f5c2'
												)}
											</h6>
										</div>
										<div className="col-5 p-2 border">
											<h6>
												{i18next.t(
													'Desempeño.EvalModal.f5c3'
												)}
											</h6>
										</div>
									</article>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div
					className="modal fade modal-xl"
					id="modalCompetencies"
					tabindex="-1"
					role="dialog"
					aria-labelledby="modalCompetenciesTitle"
					aria-hidden="true"
				>
					<div
						className="modal-dialog modal-dialog-centered modal-xl"
						role="document"
					>
						<div className="modal-content w-100 p-5">
							<div className="modal-body text-left">
								<div className="embed-responsive embed-responsive-16by9">
									<iframe
										className="embed-responsive-item"
										src={competenciesPDF}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* Modales */}
				{/* <Index/> */}
			</div>
		);
	}
}
