import React from 'react';
import ReactPlayer from 'react-player';
import Generic from '../../assets/737max/737MAX_Aeromexico.jpg';
import './style737.css';

function Max737() {
	return (
		<section
			className="max-737"
			style={{
				background: `url(${Generic}) center`,
				backgroundSize: 'cover',
				width: '100vw'
			}}
		>
			<article className="container">
				<div className="row align-items-center">
					<div
						className="col d-flex flex-column justify-content-center align-items-center"
						style={{
							height: 'auto'
						}}
					>
						<h1
							style={{
								color: 'white',
								fontSize: '35px',
								fontWeight: '700',
								opacity: 1,
								textAlign: 'center',
								margin: '100px 0 30px 0'
							}}
						>
							Boeing 737 Max
						</h1>
						<div
							className="embed-responsive embed-responsive-16by9"
							style={{
								marginBottom: '120px',
								maxWidth: '700px',
								position: 'relative',
								width: '100%'
							}}
						>
							<ReactPlayer
								className="embed-responsive embed-responsive-16by9 react-player"
								style={{
									background: '#0B2342',
									boxShadow:
										'0 25px 50px -12px rgba(15,75,145,0.25)',
									left: '50%',
									top: '50%',
									position: 'absolute',
									transform: 'translate(-50%, -50%)'
								}}
								config={{
									file: {
										attributes: {
											controlsList: 'nodownload',
											disablePictureInPicture: true
										}
									}
								}}
								url={`${process.env.REACT_APP_SRC_URL}videos/1217_MAX_VERSION_GAM_REV9_PANTALLAS.mov`}
								controls={true}
								volume={100}
								playing
								width="100%"
								height="auto"
							/>
						</div>
					</div>
				</div>
			</article>
		</section>
	);
}

export default Max737;
