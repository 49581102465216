import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import i18next from 'i18next';
import SwiperDiscountUSA from './SwiperDiscountUSA';

import profileIcon from '../../../assets/images/profile/icon-mybenefits.svg';
import logobenefit from '../../../assets/images/profile/mybenefitslogo.svg';
import logobenefitEN from '../../../assets/canada/my-benefits.png';
import profileIcon01 from '../../../assets/images/profile/categorias.svg';
import profileIcon02 from '../../../assets/images/profile/icon-mybenefits-02.svg';
import heart from '../../../assets/images/profile/categoria-01.svg';
import Entretenimiento from '../../../assets/images/profile/categoria-02.svg';
import pesas from '../../../assets/images/profile/categoria-03.svg';
import shopping from '../../../assets/images/profile/categoria-04.svg';
import Educacion from '../../../assets/images/profile/categoria-05.svg';
import Hoteles from '../../../assets/images/profile/hoteles.svg';
import restaurante from '../../../assets/images/profile/categoria-07.svg';
import auto from '../../../assets/images/profile/categoria-09.svg';
import Servicios from '../../../assets/images/profile/categoria-10.svg';
import Guarderia from '../../../assets/images/profile/categoria-06.svg';
import BenefitsUSA from './BenefitsUSA';

export default class BDContentUSA extends Component {
	state = {
		language: i18next.language
	};
	render() {
		return (
			<React.Fragment>
				{/* Titulo */}
				<div className="totalBenefits__title__container beneficiosfloatright">
					<div className="iconTitleProfile">
						<img src={profileIcon} alt="Profile Icon" />
					</div>
					<h1>
						{this.state.language === 'es'
							? 'Beneficios Internacionales'
							: 'International Benefits'}
					</h1>

					<div className="logoTabBenefit">
						<img
							src={
								this.state.language === 'es'
									? logobenefit
									: logobenefitEN
							}
							alt="My Benefits"
						/>
					</div>
				</div>
				{/* Destacados */}
				<div className="swiperDiscountsCont">
					<SwiperDiscountUSA />
				</div>
				{/* Categorías para cambiar por componente */}
				<div className="totalBenefits__container">
					<div className="totalBenefits__title__container">
						<div className="iconTitleProfile">
							<img src={profileIcon01} alt="Profile Icon 01" />
						</div>
						<h1>
							{this.state.language === 'es'
								? 'Beneficios EUA'
								: 'Benefits USA'}
						</h1>
					</div>

					<BenefitsUSA />
					<div className="totalBenefits_cards_container_disclaimer">
						<p>*Benefits are subject to change at Aeromexico’s Discretion</p>
					</div>
				</div>
				{/* //componente de categoría */}
				{/* Mis descuentos en mexico */}
				<div
					className="discountPerks__container"
					style={{ borderBottom: '1px solid transparent' }}
				>
					<div className="totalBenefits__title__container">
						<div className="iconTitleProfile">
							<img src={profileIcon02} alt="Profile Icon 02" />
						</div>
						<h1>
							{this.state.language === 'es'
								? 'Descuentos - Aplican sólo en México'
								: 'Discounts - Apply only in Mexico'}
						</h1>
					</div>
					<div className="discountsPerks__list__container">
						<ul>
							<li>
								<Link to="/recompensa-total/mis-beneficios/descuentos/salud-y-belleza">
									{' '}
									<img
										src={heart}
										className="discount_list"
										alt="Salud y belleza"
									/>
									<span className="discount_l">
										{i18next.t(
											'MiExperAM.benef.descuentos.salud'
										)}
									</span>
								</Link>
							</li>
							<li>
								<Link to="/recompensa-total/mis-beneficios/descuentos/entretenimiento">
									{' '}
									<img
										src={Entretenimiento}
										className="discount_list"
										alt="Entretenimiento"
									/>
									<span className="discount_l">
										{i18next.t(
											'MiExperAM.benef.descuentos.entrete'
										)}
									</span>
								</Link>
							</li>
							<li>
								<Link to="/recompensa-total/mis-beneficios/descuentos/gimnasios">
									<img
										src={pesas}
										className="discount_list"
										alt="Gimnasios"
									/>
									<span className="discount_l">
										{i18next.t(
											'MiExperAM.benef.descuentos.gym'
										)}
									</span>
								</Link>
							</li>
						</ul>
						<ul>
							<li>
								<Link to="/recompensa-total/mis-beneficios/descuentos/de-compras">
									{' '}
									<img
										src={shopping}
										className="discount_list"
										alt="Shopping"
									/>
									<span className="discount_l">
										{i18next.t(
											'MiExperAM.benef.descuentos.shopp'
										)}
									</span>
								</Link>
							</li>
							<li>
								<Link to="/recompensa-total/mis-beneficios/descuentos/educacion">
									<img
										src={Educacion}
										className="discount_list"
										alt="Educación"
									/>
									<span className="discount_l">
										{i18next.t(
											'MiExperAM.benef.descuentos.educa'
										)}
									</span>
								</Link>
							</li>

							<li>
								<a
									href="https://www.granplan.com/gam"
									target="_blank"
								>
									<img
										src={Hoteles}
										className="discount_list"
										alt="Educación"
									/>
									<span className="discount_l">
										{i18next.t(
											'MiExperAM.benef.descuentos.hotel'
										)}
									</span>
								</a>
							</li>
						</ul>
						<ul>
							<li>
								<Link to="/recompensa-total/mis-beneficios/descuentos/restaurantes">
									{' '}
									<img
										src={restaurante}
										className="discount_list"
										alt="Restaurantes"
									/>
									<span className="discount_l">
										{i18next.t(
											'MiExperAM.benef.descuentos.resta'
										)}
									</span>
								</Link>
							</li>
							<li>
								<Link to="/recompensa-total/mis-beneficios/descuentos/automoviles">
									{' '}
									<img
										src={auto}
										className="discount_list"
										alt="autos"
									/>
									<span className="discount_l">
										{i18next.t(
											'MiExperAM.benef.descuentos.auto'
										)}
									</span>
								</Link>
							</li>
						</ul>
						<ul>
							<li>
								<Link to="/recompensa-total/mis-beneficios/descuentos/servicios">
									<img
										src={Servicios}
										className="discount_list"
										alt="servicios"
									/>
									<span className="discount_l">
										{i18next.t(
											'MiExperAM.benef.descuentos.servi'
										)}
									</span>
								</Link>
							</li>
							<li>
								<Link to="/recompensa-total/mis-beneficios/descuentos/guarderias">
									<img
										src={Guarderia}
										className="discount_list"
										alt="Guarderias"
									/>
									<span className="discount_l">
										{i18next.t(
											'MiExperAM.benef.descuentos.guarde'
										)}
									</span>
								</Link>
							</li>
						</ul>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
