import { Component } from "react";
import "./css/titleCategory.css";

export default class TitleCategory extends Component {
  headerTitle = links => {
    const recentNews = {
      customer: "All Customer experience news",
      people: "All People experience news",
      amdl: "All Delta experience news",
      comercial: "All Commercial experience news",
      operations: "All Operations experience news",
      skyteam: "All skyteam experience news",
      CEO: "All ceo experience news"
    };
    return recentNews[links];
  };
  render() {
    return (
      <div className="titleCategory">
        <div className="titleCategoryContainer">
          <div className="textTitleCategoryContainer">
            <h4>{this.headerTitle(this.props.data)}</h4>
          </div>
          <div className="filterContainer">
            <h6>Sort by</h6>
            <select>
              <option>Newest</option>
              <option>Others</option>
            </select>
          </div>
        </div>
      </div>
    );
  }
}
