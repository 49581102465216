import React, { useState, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom'; // Importa useHistory
import SignInWidget from './SigInWidget';
import { withAuth } from '@okta/okta-react';


const LoginOktaReact = ({ auth, baseUrl }) => {
  const [authenticated, setAuthenticated] = useState(null);
  const history = useHistory(); // Obtiene la instancia de history
console.log("history", history);

 
  useEffect(() => {
    const checkAuthentication = async () => {
      const isAuthenticated = await auth.isAuthenticated();
      console.log(isAuthenticated);
      setAuthenticated(isAuthenticated);

    };

    checkAuthentication();
  }, [auth]);

    const onSuccess = (res) =>{
    //console.log("RESPUESTA OKTA: ", res);
    if( res != null ){
      localStorage.setItem("oktaToken", res.session.token);
      localStorage.setItem("token", res.user.id);
      localStorage.setItem("okta-user-id", res.user.id);
      localStorage.setItem("nombre", res.user.profile.firstName);
  
      localStorage.setItem("apellidoPaterno", res.user.profile.lastName.split(' ')[0]);
      localStorage.setItem("apellidoMaterno", res.user.profile.lastName.split(' ')[1]);
      localStorage.setItem("email", res.user.profile.login);
      localStorage.setItem("accessDate", `${new Date()}`)
      window.close();  ///Descomentar para ingresar al inicio redireccionado.
    }
  }; 

  const onError = (err) => {
    console.log('error logging in', err);
  };

  if (authenticated === null) return null;

  return authenticated ? (
    <Redirect to={{ pathname: '/inicio' }} />
  ) : (
    <SignInWidget
      baseUrl={baseUrl}
      onSuccess={onSuccess}
      onError={onError}
    />
  );
};

export default withAuth(LoginOktaReact);