import React, { Component, Fragment } from "react";
import InfoEnvironment from "../../utils/info.environment";
import "./css/hidden.css";
import Loader from "../../components/Loader/Loader";
import e from "cors";

const sha1 = require("sha1");

export default class EmailSearch extends Component {
	constructor(props) {
		super(props);
		this.infoEnvironment = new InfoEnvironment();
		// this.onSubmit = this.onSubmit.bind(this);
		this.state = {
			email: localStorage.getItem("email"),
			loading: true,
			loaded: false,
			data: null
		};
		this.callCORE();
	}

	callCORE() {
		// e.preventDefault();
		// if (this.state.email !== localStorage.getItem("email")) {
		// 	alert("¡Listill@ solo puedes buscar tu propio correo!");
		// 	return false;
		// }
		
		const HOST = "NOMBREHOST";
		const token = sha1(
			`${HOST}|${localStorage.getItem("email")}`
		).toUpperCase();
		const email = new Buffer(this.state.email)
			.toString("hex")
			.toUpperCase();
		const URL = this.infoEnvironment.api_url({
			host: window.location.hostname
		});

		let formData = new FormData();
		formData.append("token", token);
		formData.append("email", email);
		formData.append("tipo", "0");
		formData.append("tipo_salida", "JSON");
		fetch(URL, {
			body: formData,
			method: "post"
		})
			.then(response => {
				this.setState({
					...this.state,
					loading: true
				});
				return response.json();
			})
			.then(data => {
				this.setState({
					...this.state,
					data: {
						aguinaldo: this.formatReward(data.aguinaldo),
						bonoAutomovil: this.formatReward(data.bonoAutomovil),
						bonoDesempeno: this.formatReward(data.bonoDesempeno),
						bonoUtiles: this.formatReward(data.bonoUtiles),
						compensacion: this.formatReward(data.compensacion),
						currency_code: this.formatReward(data.currency_code),
						fondoAhorro: this.formatReward(data.fondoAhorro),
						lti: this.formatReward(data.lti),
						primaVacacional: this.formatReward(
							data.primaVacacional
						),
						ratio: data.ratio,
						salario: this.formatReward(data.salario),
						salarioAnual: this.formatReward(data.salarioAnual),
						valesDespensa: this.formatReward(data.valesDespensa)
					},
					loaded: true,
					loading: false
				});
				
			})
			.catch(err => {
				console.error(err);
				this.setState({
					...this.state,
					loading: false,
					loaded: false,
					data: null
				});
			});
	}

	formatReward(reward) {
		
		if (typeof reward === "object") {
			
			return "0";
		} else if (reward === null) {
			return null;
		} else {
			
			return new Buffer(reward, "hex").toString();
		}
	}

	render() {
		return (
			<Fragment>
				{/* <p>{this.state.email}</p> */}
				{/* {this.state.email === "jgarciab@aeromexico.com" ||
				this.state.email === "amaccesoinvitado@aeromexico.com" || "jabadia@aerom" ? (
					<Fragment> */}
				<h1 className="hiddenSearch">Tu información de CORE</h1>
				{/* <form className="hiddenSearch">
					<label className="hiddenSearch" htmlFor="email">
						Correo a buscar
					</label>
					<input
						className="hiddenSearch"
						type="email"
						id="email"
						name="email"
						onChange={e => {
							console.log(e.target.value);
							this.setState({
								...this.state,
								email: e.target.value
							});
						}}
						// ref={c => (this.email = c)}
					/>
					<button className="hiddenSearch" onClick={this.onSubmit}>
						Buscar
					</button>
				</form> */}
				<div className="container">
					<div className="row">
						<div className="col-12 bg-white carta-simple">
							{this.state.loading && <p>Cargando...</p>}
							{this.state.loaded && (
								<Fragment>
									<div className="container">
										<div className="row">
											<div className="col-12 ">
												<p>
													<strong>aguinaldo:</strong>{" "}
													{this.state.data.aguinaldo}
												</p>
												<p>
													<strong>
														bonoAutomovil:
													</strong>{" "}
													{
														this.state.data
															.bonoAutomovil
													}
												</p>
												<p>
													<strong>
														bonoDesempeno:
													</strong>{" "}
													{
														this.state.data
															.bonoDesempeno
													}
												</p>
												<p>
													<strong>bonoUtiles:</strong>{" "}
													{this.state.data.bonoUtiles}
												</p>
												<p>
													<strong>
														compensacion:
													</strong>{" "}
													{
														this.state.data
															.compensacion
													}
												</p>
												<p>
													<strong>
														currency_code:
													</strong>{" "}
													{
														this.state.data
															.currency_code
													}
												</p>
												<p>
													<strong>
														fondoAhorro:
													</strong>{" "}
													{
														this.state.data
															.fondoAhorro
													}
												</p>
												<p>
													<strong>lti:</strong>{" "}
													{this.state.data.lti}
												</p>
												<p>
													<strong>
														primaVacacional:
													</strong>{" "}
													{
														this.state.data
															.primaVacacional
													}
												</p>
												<p>
													<strong>ratio:</strong>{" "}
													{this.state.data.ratio}
												</p>
												<p>
													<strong>salario:</strong>{" "}
													{this.state.data.salario}
												</p>
												<p>
													<strong>
														salarioAnual:
													</strong>{" "}
													{
														this.state.data
															.salarioAnual
													}
												</p>
												<p>
													<strong>
														valesDespensa:
													</strong>{" "}
													{
														this.state.data
															.valesDespensa
													}
												</p>
											</div>
										</div>
									</div>
								</Fragment>
							)}
						</div>
					</div>
				</div>

				{/* </Fragment>
				) : (
					<h1>No tienes permiso para ver esta página</h1>
				)} */}
			</Fragment>
		);
	}
}
