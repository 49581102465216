import { Component } from 'react';
import './css/gamreport.css';
import './css/gamreportinside.css';
import Fade from 'react-reveal/Fade';

import i18next from 'i18next';
import { Trans } from 'react-i18next';
import initLang from '../../../utils/initLang';

export default class GamReportInside extends Component {
	constructor() {
		super();
		this.state = {
			lang: ''
		};
	}

	componentDidMount = () => {
//		initLang(this);
		document.addEventListener("click", initLang(this), true);
	}

	render() {
		return (
			<div className="gamReportInside">
				<Fade big>
					<p className="smallText">
						<Trans i18nKey="Seguridad.reporte"></Trans>
					</p>
				</Fade>

				<Fade top>
					<div className="wantToHearYou">
						<a
							className="hearyouWrapper"
							href="https://gamereportweb.aeromexico.com"
							target="_blank"
							rel="noopener noreferrer"
						>
							<div className="imageWrapper">
								<div className="logo"></div>
								<div className="imageabsolute"></div>
							</div>
							<div className="textWrapper">
								<p className="name">
									{i18next.t('Seguridad.gamTitle')}
								</p>
								<p className="intro">
									{i18next.t('Seguridad.gamReport')}
								</p>
								<p className="callAction">
									{i18next.t('Seguridad.enviarReporte')}
								</p>
							</div>
						</a>

						<a
							// className="hearyouWrapper pointer-events-none relative overflow-hidden shadow-none"
							className="hearyouWrapper"
							href="https://www.resguarda.com/aeromexico"
							// onClick={(e) => e.preventDefault()}
							target="_blank"
							rel="noopener noreferrer"
						>
						 
							<div className={'imageWrapper ' + this.state.lang}>
								<div className="logo"></div>
							</div>
							<div className="textWrapper">
								<p className="name">
									{i18next.t('Seguridad.denunciaTitle')}
								</p>
								<p className="intro">
									{i18next.t('Seguridad.denuncia')}
								</p>
							</div>
						</a>
					</div>
				</Fade>

				<Fade big>
					<p className="accessText">
						<Trans i18nKey="Seguridad.levantar">
							levantar <span>{i18next.t('1')}</span>{' '}
						</Trans>
					</p>
				</Fade>
			</div>
		);
	}
}
