export const r2022 = [
	{
		month: {
			en: 'December',
			es: 'Diciembre'
		},
		urlES: '',
		url: ''
	},
	{
		month: {
			en: 'November',
			es: 'Noviembre'
		},
		urlES: '',
		url: ''
	},
	{
		month: {
			en: 'October',
			es: 'Octubre'
		},
		urlES: '',
		url: ''
	},
	{
		month: {
			en: 'September',
			es: 'Septiembre'
		},
		urlES: '',
		url: ''
	},
	{
		month: {
			en: 'August',
			es: 'Agosto'
		},
		urlES: '',
		url: ''
	},
	{
		month: {
			en: 'July',
			es: 'Julio'
		},
		urlES: '',
		url: ''
	},
	{
		month: {
			en: 'June',
			es: 'Junio'
		},
		urlES: 'https://aeromexico.com/cms/sites/default/files/Junio_2022_Est_Op_R1_VF.pdf',
		url: 'https://aeromexico.com/cms/sites/default/files/Junio_2022_Est_Op_R1_VF.pdf'
	},
	{
		month: {
			en: 'May',
			es: 'Mayo'
		},
		urlES: 'https://aeromexico.com/cms/sites/default/files/Mayo_2022_Est_Op_R1.pdf',
		url: 'https://aeromexico.com/cms/sites/default/files/Mayo_2022_Est_Op_R1.pdf'
	},
	{
		month: {
			en: 'April',
			es: 'Abril'
		},
		urlES: 'https://aeromexico.com/cms/sites/default/files/Abril_2022_Est_OpV3.pdf',
		url: 'https://aeromexico.com/cms/sites/default/files/Abril_2022_Est_OpV3.pdf'
	},
	{
		month: {
			en: 'March',
			es: 'Marzo'
		},
		urlES: 'https://aeromexico.com/cms/sites/default/files/Marzo_2022_Est_OpV3.pdf',
		url: 'https://aeromexico.com/cms/sites/default/files/Marzo_2022_Est_OpV3.pdf'
	},
	{
		month: {
			en: 'February',
			es: 'Febrero'
		},
		urlES: 'https://aeromexico.com/cms/sites/default/files/Febrero_2022_Est_Op3.pdf',
		url: 'https://aeromexico.com/cms/sites/default/files/Febrero_2022_Est_Op3.pdf'
	},
	{
		month: {
			en: 'January',
			es: 'Enero'
		},
		urlES: 'https://aeromexico.com/cms/sites/default/files/Enero_2022_Est_Op_D2.pdf',
		url: 'https://aeromexico.com/cms/sites/default/files/Enero_2022_Est_Op_D2.pdf'
	}
];
export const r2021 = [
	{
		month: {
			en: 'June',
			es: 'Junio'
		},

		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2021/Junio%202021.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2021/Junio%202021.pdf`
		//urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2021/Junio%202021.pdf',
		//url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2021/Junio%202021.pdf'
	},
	{
		month: {
			en: 'May',
			es: 'Mayo'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2021/Mayo%202021.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2021/Mayo%202021.pdf`
		//urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2021/Mayo%202021.pdf',
		//url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2021/Mayo%202021.pdf'
	},
	{
		month: {
			en: 'April',
			es: 'Abril'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2021/Abril%202021.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2021/Abril%202021.pdf`
		//urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2021/Abril%202021.pdf',
		//url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2021/Abril%202021.pdf'
	},
	{
		month: {
			en: 'March',
			es: 'Marzo'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2021/Marzo%202021.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2021/Marzo%202021.pdf`
		//urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2021/Marzo%202021.pdf',
		//url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2021/Marzo%202021.pdf'
	},
	{
		month: {
			en: 'February',
			es: 'Febrero'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2021/Febrero%202021.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2021/Febrero%202021.pdf`
		//urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2021/Febrero%202021.pdf',
		//url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2021/Febrero%202021.pdf'
	},
	{
		month: {
			en: 'January',
			es: 'Enero'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2021/Enero%202021.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2021/Enero%202021.pdf`
		//urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2021/Enero%202021.pdf',
		//url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2021/Enero%202021.pdf'
	}
];

export const r2020 = [
	{
		month: {
			en: 'November',
			es: 'Noviembre'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2020/esp/Noviembre%202020_Est_Op..pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2020/November%202020_Op_Stat..pdf`
		//urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2020/esp/Noviembre%202020_Est_Op..pdf',
		//url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2020/November%202020_Op_Stat..pdf'
	},
	{
		month: {
			en: 'October',
			es: 'Octubre'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2020/esp/Octubre%202020_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2020/October%202020_Op_Stat.pdf`
		//urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2020/esp/Octubre%202020_Est_Op.pdf',
		//url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2020/October%202020_Op_Stat.pdf'
	},
	{
		month: {
			en: 'September',
			es: 'Septiembre'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2020/esp/Septiembre%202020_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2020/September%202020_Op_Stat.pdf`
		//urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2020/esp/Septiembre%202020_Est_Op.pdf',
		//url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2020/September%202020_Op_Stat.pdf'
	},
	{
		month: {
			en: 'August',
			es: 'Agosto'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2020/esp/Agosto%202020_Est_Op%20-%20Standard.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2020/August%202020_Op_Stat%20-%20Standard.pdf`
		//urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2020/esp/Agosto%202020_Est_Op%20-%20Standard.pdf',
		//url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2020/August%202020_Op_Stat%20-%20Standard.pdf'
	},
	{
		month: {
			en: 'July',
			es: 'Julio'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2020/esp/Julio%202020_Est_Op%20-%20Standard.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2020/July%202020_Op_Stat%20-%20Standard.pdf`
		//urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2020/esp/Julio%202020_Est_Op%20-%20Standard.pdf',
		//url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2020/July%202020_Op_Stat%20-%20Standard.pdf'
	},
	{
		month: {
			en: 'June',
			es: 'Junio'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2020/esp/Junio%202020_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2020/June%202020_Op_Stat.pdf`
		//urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2020/esp/Junio%202020_Est_Op.pdf',
		//url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2020/June%202020_Op_Stat.pdf'
	},
	{
		month: {
			en: 'May',
			es: 'Mayo'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2020/esp/Mayo%202020_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2020/May%202020_Op_Stat.pdf`
		//urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2020/esp/Mayo%202020_Est_Op.pdf',
		//url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2020/May%202020_Op_Stat.pdf'
	},
	{
		month: {
			en: 'April',
			es: 'Abril'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2020/esp/Abril_2020_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2020/April%202020_Op_Stat.pdf`
		//urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2020/esp/Abril_2020_Est_Op.pdf',
		//url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2020/April%202020_Op_Stat.pdf'
	},
	{
		month: {
			en: 'March',
			es: 'Marzo'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2020/esp/Marzo_2020_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2020/March_2020_Op_Stat.pdf`
		//urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2020/esp/Marzo_2020_Est_Op.pdf',
		//url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2020/March_2020_Op_Stat.pdf'
	},
	{
		month: {
			en: 'February',
			es: 'Febrero'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2020/esp/Febrero_2020_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2020/February_2020_Op_Stat.pdf`
		//urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2020/esp/Febrero_2020_Est_Op.pdf',
		//url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2020/February_2020_Op_Stat.pdf'
	},
	{
		month: {
			en: 'January',
			es: 'Enero'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2020/esp/Enero_2020_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2020/January_2020_Op_Stat.pdf`
		//urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2020/esp/Enero_2020_Est_Op.pdf',
		//url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2020/January_2020_Op_Stat.pdf'
	}
];
export const r2019 = [
	{
		month: {
			en: 'December',
			es: 'Diciembre'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2019/esp/Diciembre_2019_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2019/December_2019_Op_Stat.pdf`
		//urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2019/esp/Diciembre_2019_Est_Op.pdf',
		//url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2019/December_2019_Op_Stat.pdf'
	},
	{
		month: {
			en: 'November',
			es: 'Noviembre'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2019/esp/Noviembre_2019_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2019/November_2019_Op_Stat.pdf`
		//urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2019/esp/Noviembre_2019_Est_Op.pdf',
		//url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2019/November_2019_Op_Stat.pdf'
	},
	{
		month: {
			en: 'October',
			es: 'Octubre'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2019/esp/Octubre_2019_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2019/October_2019_Op_Stat.pdf`
		//urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2019/esp/Octubre_2019_Est_Op.pdf',
		//url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2019/October_2019_Op_Stat.pdf'
	},
	{
		month: {
			en: 'September',
			es: 'Septiembre'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2019/esp/Septiembre_2019_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2019/September_2019_Op_Stat.pdf`
		//urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2019/esp/Septiembre_2019_Est_Op.pdf',
		//url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2019/September_2019_Op_Stat.pdf'
	},
	{
		month: {
			en: 'August',
			es: 'Agosto'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2019/esp/Agosto_2019_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2019/August_2019_Op_Stat.pdf`
		//urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2019/esp/Agosto_2019_Est_Op.pdf',
		//url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2019/August_2019_Op_Stat.pdf'
	},
	{
		month: {
			en: 'July',
			es: 'Julio'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2019/esp/Julio_2019_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2019/July_2019_Op_Stat.pdf`
		//urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2019/esp/Julio_2019_Est_Op.pdf',
		//url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2019/July_2019_Op_Stat.pdf'
	},
	{
		month: {
			en: 'June',
			es: 'Junio'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2019/esp/Junio_2019_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2019/June_2019_Op_Stat.pdf`
		//urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2019/esp/Junio_2019_Est_Op.pdf',
		//url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2019/June_2019_Op_Stat.pdf'
	},
	{
		month: {
			en: 'May',
			es: 'Mayo'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2019/esp/Mayo_2019_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2019/May_2019_Op_Stat.pdf`
		//urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2019/esp/Mayo_2019_Est_Op.pdf',
		//url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2019/May_2019_Op_Stat.pdf'
	},
	{
		month: {
			en: 'April',
			es: 'Abril'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2019/esp/Abril_2019_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2019/April_2019_Op_Stat.pdf`
		//urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2019/esp/Abril_2019_Est_Op.pdf',
		//url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2019/April_2019_Op_Stat.pdf'
	},
	{
		month: {
			en: 'March',
			es: 'Marzo'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2019/esp/Marzo_2019_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2019/March_2019_Op_Stat.pdf`
		//urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2019/esp/Marzo_2019_Est_Op.pdf',
		//url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2019/March_2019_Op_Stat.pdf'
	},
	{
		month: {
			en: 'February',
			es: 'Febrero'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2019/esp/Febrero_2019_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2019/February_2019_Op_Stat.pdf`
		//urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2019/esp/Febrero_2019_Est_Op.pdf',
		//url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2019/February_2019_Op_Stat.pdf'
	},
	{
		month: {
			en: 'January',
			es: 'Enero'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2019/esp/Enero_2019_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2019/January_2019_Op_Stat.pdf`
		//urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2019/esp/Enero_2019_Est_Op.pdf',
		//url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2019/January_2019_Op_Stat.pdf'
	}
];
export const r2018 = [
	{
		month: {
			en: 'December',
			es: 'Diciembre'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2018/esp/Diciembre_2018_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2018/December_2018_Op_Stat.pdf`
		//urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2018/esp/Diciembre_2018_Est_Op.pdf',
		//url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2018/December_2018_Op_Stat.pdf'
	},
	{
		month: {
			en: 'November',
			es: 'Noviembre'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2018/esp/Noviembre_2018_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2018/November_2018_Op_Stat.pdf`
		//urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2018/esp/Noviembre_2018_Est_Op.pdf',
		//url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2018/November_2018_Op_Stat.pdf'
	},
	{
		month: {
			en: 'October',
			es: 'Octubre'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2018/esp/Octubre_2018_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2018/October_2018_Op_Stat.pdf`
		//urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2018/esp/Octubre_2018_Est_Op.pdf',
		//url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2018/October_2018_Op_Stat.pdf'
	},
	{
		month: {
			en: 'September',
			es: 'Septiembre'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2018/esp/Septiembre_2018_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2018/September_2018_Op_Stat.pdf`
		//urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2018/esp/Septiembre_2018_Est_Op.pdf',
		//url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2018/September_2018_Op_Stat.pdf'
	},
	{
		month: {
			en: 'August',
			es: 'Agosto'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2018/esp/Agosto_2018_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2018/August_2018_Op_Stat.pdf`
		//urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2018/esp/Agosto_2018_Est_Op.pdf',
		//url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2018/August_2018_Op_Stat.pdf'
	},
	{
		month: {
			en: 'July',
			es: 'Julio'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2018/esp/Julio_2018_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2018/July_2018_Op_Stat.pdf`
		//urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2018/esp/Julio_2018_Est_Op.pdf',
		//url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2018/July_2018_Op_Stat.pdf'
	},
	{
		month: {
			en: 'June',
			es: 'Junio'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2018/esp/Junio_2018_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2018/June_2018_Op_Stat.pdf`
		//urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2018/esp/Junio_2018_Est_Op.pdf',
		//url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2018/June_2018_Op_Stat.pdf'
	},
	{
		month: {
			en: 'May',
			es: 'Mayo'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2018/esp/Mayo_2018_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2018/May_2018_Op_Stat.pdf`
		//urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2018/esp/Mayo_2018_Est_Op.pdf',
		//url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2018/May_2018_Op_Stat.pdf'
	},
	{
		month: {
			en: 'April',
			es: 'Abril'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2018/esp/Abril_2018_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2018/April_2018_Op_Stat.pdf`
		//urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2018/esp/Abril_2018_Est_Op.pdf',
		//url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2018/April_2018_Op_Stat.pdf'
	},
	{
		month: {
			en: 'March',
			es: 'Marzo'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2018/esp/Marzo_2018_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2018/March_2018_Op_Stat.pdf`
		//urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2018/esp/Marzo_2018_Est_Op.pdf',
		//url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2018/March_2018_Op_Stat.pdf'
	},
	{
		month: {
			en: 'February',
			es: 'Febrero'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2018/esp/Febrero_2018_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2018/February_2018_Op_Stat.pdf`
		//urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2018/esp/Febrero_2018_Est_Op.pdf',
		//url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2018/February_2018_Op_Stat.pdf'
	},
	{
		month: {
			en: 'January',
			es: 'Enero'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2018/esp/Enero_2018_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2018/January_2018_Op_Stat.pdf`
		//urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2018/esp/Enero_2018_Est_Op.pdf',
		//url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2018/January_2018_Op_Stat.pdf'
	}
];
export const r2017 = [
	{
		month: {
			en: 'December',
			es: 'Diciembre'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2017/esp/Dec_2017_Est_Opv_SPA.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2017/Dec_2017_Op_Statv_ENG.pdf`
		//urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2017/esp/Dec_2017_Est_Opv_SPA.pdf',
		//url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2017/Dec_2017_Op_Statv_ENG.pdf'
	},
	{
		month: {
			en: 'November',
			es: 'Noviembre'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2017/esp/Nov_2017_Est_Opv_SPA.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2017/Nov_2017_Op_Statv_ENG.pdf`
	},
	{
		month: {
			en: 'October',
			es: 'Octubre'
		},
		urlES:`${process.env.REACT_APP_SRC_URL}reportes_operativos/2017/esp/Oct_2017_Est_Opv_SPA.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2017/Oct_2017_Op_Statv_ENG.pdf`
	},
	{
		month: {
			en: 'September',
			es: 'Septiembre'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2017/esp/Septi_2017_Est_Opv.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2017/Sept_2017_Op_Statv.pdf`
	},
	{
		month: {
			en: 'August',
			es: 'Agosto'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2017/esp/Ago_2017_Est_Op-2_0.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2017/Aug_2017_Op_Stat-2_0.pdf`
	},
	{
		month: {
			en: 'July',
			es: 'Julio'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2017/esp/Julio_2017_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2017/Jul_2017_Op_Stat.pdf`
	},
	{
		month: {
			en: 'June',
			es: 'Junio'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2017/esp/Junio_2017_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2017/Jun_2017_Op_Stat.pdf`
	},
	{
		month: {
			en: 'May',
			es: 'Mayo'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2017/esp/Mayo_2017_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2017/May_2017_Op_Stat.pdf`
	},
	{
		month: {
			en: 'April',
			es: 'Abril'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2017/esp/Abril_2017_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2017/April_2017_Op_Stat.pdf`
	},
	{
		month: {
			en: 'March',
			es: 'Marzo'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2017/esp/Marzo_2017_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2017/March_2017_Op_Stat.pdf`
	},
	{
		month: {
			en: 'February',
			es: 'Febrero'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2017/esp/febrero_2017_est_op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2017/february_2017_op_stat.pdf`
	},
	{
		month: {
			en: 'January',
			es: 'Enero'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2017/esp/enero_2017_est_op_1.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2017/january_2017_op_stat.pdf`
	}
];
export const r2016 = [
	{
		month: {
			en: 'December',
			es: 'Diciembre'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2016/esp/diciembre_2016_est_op_0.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2016/december_2016_op_stat_0.pdf`
	},
	{
		month: {
			en: 'November',
			es: 'Noviembre'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2016/esp/Noviembre%202016_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2016/November%202016_Op_Stat.pdf`
	},
	{
		month: {
			en: 'October',
			es: 'Octubre'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2016/esp/Octubre_2016_Est_Op.pdf`,
		url:`${process.env.REACT_APP_SRC_URL}reportes_operativos/2016/October%202016_Op_Stat%20(2).pdf`
	},
	{
		month: {
			en: 'September',
			es: 'Septiembre'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2016/esp/Septiembre_2016_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2016/September%202016_Op_Stat.pdf`
	},
	{
		month: {
			en: 'August',
			es: 'Agosto'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2016/esp/Agosto_2016_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2016/August%202016_Op_Stat.pdf`
	},
	{
		month: {
			en: 'July',
			es: 'Julio'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2016/esp/Julio_2016_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2016/July%202016_Op_Stat.pdf`
	},
	{
		month: {
			en: 'June',
			es: 'Junio'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2016/esp/Junio_2016_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2016/Jun-2016_Op_Stat%20ingles.pdf`
	},
	{
		month: {
			en: 'May',
			es: 'Mayo'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2016/esp/Reporte%20operativo%20Mayo_2016_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2016/May_2016_Op_Stat.%20ENGpdf.pdf`
	},
	{
		month: {
			en: 'April',
			es: 'Abril'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2016/esp/Abril_2016_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2016/April_2016_Op_Stat.pdf`
	},
	{
		month: {
			en: 'March',
			es: 'Marzo'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2016/esp/Marzo_2016_Est_Op_0.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2016/March-2016_Op_Stat.pdf`
	},
	{
		month: {
			en: 'February',
			es: 'Febrero'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2016/esp/Febrero_2016_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2016/February-2016_Op_Stat.pdf`
	},
	{
		month: {
			en: 'January',
			es: 'Enero'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2016/esp/Enero_2016_Est_Op%20(1).pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2016/January-2016_Op_Stat.pdf`
	}
];
export const r2015 = [
	{
		month: {
			en: 'December',
			es: 'Diciembre'
		},

		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2015/esp/Diciembre_2015_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2015/December-2015_Op_Stat.pdf`
	},
	{
		month: {
			en: 'November',
			es: 'Noviembre'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2015/esp/Noviembre_2015_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2015/November2015_Op_Stat.pdf`
	},
	{
		month: {
			en: 'October',
			es: 'Octubre'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2015/esp/Octubre_2015_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2015/October2015_Op_Stat.pdf`
	},
	{
		month: {
			en: 'September',
			es: 'Septiembre'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2015/esp/septiembre_2015_est_op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2015/september_2015_op_stat.pdf`
	},
	{
		month: {
			en: 'August',
			es: 'Agosto'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2015/esp/agosto_2015_est_op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2015/august2015_op_stat.pdf`
	},
	{
		month: {
			en: 'July',
			es: 'Julio'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2015/esp/julio_2015_est_op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2015/july_2015_op_stat.pdf`
	},
	{
		month: {
			en: 'June',
			es: 'Junio'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2015/esp/Junio_2015_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2015/June_2015_Op_Stat.pdf`
	},
	{
		month: {
			en: 'May',
			es: 'Mayo'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2015/esp/ReporteOperativo_Mayo2015.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2015/TrafficRelease_May2015.pdf`
	},
	{
		month: {
			en: 'April',
			es: 'Abril'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2015/esp/abril_2015_est_opv.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2015/april_2015_op_statv.pdf`
	},
	{
		month: {
			en: 'March',
			es: 'Marzo'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2015/esp/Marzo_2015_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2015/March_2015_Op_Stat.pdf`
	},
	{
		month: {
			en: 'February',
			es: 'Febrero'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2015/esp/Febrero_2015_Est_Opv.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2015/February_2015_Op_Stat.pdf`
	},
	{
		month: {
			en: 'January',
			es: 'Enero'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2015/esp/Enero_2016_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2015/January_2015_Op_Stat.pdf`
	}
];
export const r2014 = [
	{
		month: {
			en: 'December',
			es: 'Diciembre'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2014/esp/diciembre_2014_est_opv.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2014/december_2014_op_stat.pdf`
	},
	{
		month: {
			en: 'November',
			es: 'Noviembre'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2014/esp/noviembre_2014_est_op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2014/november_2014_op_stat.pdf`
	},
	{
		month: {
			en: 'October',
			es: 'Octubre'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2014/esp/Octubre_2014_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2014/October_2014_Op_Stat.pdf`
	},
	{
		month: {
			en: 'September',
			es: 'Septiembre'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2014/esp/Septiembre_2014_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2014/September_2014_Op_Stat.pdf`
	},
	{
		month: {
			en: 'August',
			es: 'Agosto'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2014/esp/Ago_2014_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2014/Aug_2014_Op_Stat.pdf`
	},
	{
		month: {
			en: 'July',
			es: 'Julio'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2014/esp/Julio_2014_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2014/Julio_2014_0.pdf`
	},
	{
		month: {
			en: 'June',
			es: 'Junio'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2014/esp/Junio_2014_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2014/June_2014_Op_Stat.pdf`
	},
	{
		month: {
			en: 'May',
			es: 'Mayo'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2014/esp/Mayo_2014_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2014/May_2014_Op_Stat.pdf`
	},
	{
		month: {
			en: 'April',
			es: 'Abril'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2014/esp/Abril_2014_Est_Opv.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2014/April_2014_Op_Stat.pdf`
	},
	{
		month: {
			en: 'March',
			es: 'Marzo'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2014/esp/Marzo_2014_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2014/March_2014_Op_Stat.pdf`
	},
	{
		month: {
			en: 'February',
			es: 'Febrero'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2014/esp/Febrero_2014_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2014/February_2014.pdf`
	},
	{
		month: {
			en: 'January',
			es: 'Enero'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2014/esp/Enero_2014_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2014/January_2014_Op_Stat.pdf`
	}
];
export const r2013 = [
	{
		month: {
			en: 'December',
			es: 'Diciembre'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2013/esp/Diciembre_2013final.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2013/December_2013final.pdf`
	},
	{
		month: {
			en: 'November',
			es: 'Noviembre'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2013/esp/noviembre_2013_pp_stat.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2013/november_2013_op_Stat.pdf`
	},
	{
		month: {
			en: 'October',
			es: 'Octubre'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2013/esp/Octubre_2013_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2013/October_2013_Op_Stat.pdf`
	},
	{
		month: {
			en: 'September',
			es: 'Septiembre'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2013/esp/Septiembre_2013_0.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2013/September_2013.pdf`
	},
	{
		month: {
			en: 'August',
			es: 'Agosto'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2013/esp/Agosto_2013_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2013/August_2013_Op_Stat.pdf`
	},
	{
		month: {
			en: 'July',
			es: 'Julio'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2013/esp/Julio_2013_Est_Op_VF.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2013/July_2013_Op_Stat_VF.pdf`
	},
	{
		month: {
			en: 'June',
			es: 'Junio'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2013/esp/junio_2013_est_op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2013/june_2013_op_stat.pdf`
	},
	{
		month: {
			en: 'May',
			es: 'Mayo'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2013/esp/Mayo_2013_Est_Op__vf.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2013/May_2013_Op_Stat__Vf.pdf`
	},
	{
		month: {
			en: 'April',
			es: 'Abril'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2013/esp/Abril_2013_Est_Op.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2013/April_2013_Op_Stat.pdf`
	},
	{
		month: {
			en: 'March',
			es: 'Marzo'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2013/esp/Marzo_2013_0.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2013/March2013_0.pdf`
	},
	{
		month: {
			en: 'February',
			es: 'Febrero'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2013/esp/febrero_2013_0.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2013/february2013_0.pdf`
	},
	{
		month: {
			en: 'January',
			es: 'Enero'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2013/esp/Enero_2013_0.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2013/January2013_0.pdf`
	}
];
export const r2012 = [
	{
		month: {
			en: 'December',
			es: 'Diciembre'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2012/esp/diciembre_2012.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2012/december2012.pdf`
	},
	{
		month: {
			en: 'November',
			es: 'Noviembre'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2012/esp/noviembre_2012.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2012/november2012.pdf`
	},
	{
		month: {
			en: 'October',
			es: 'Octubre'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2012/esp/octubre_2012.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2012/october2012.pdf`
	},
	{
		month: {
			en: 'September',
			es: 'Septiembre'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2012/esp/septiembre_2012.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2012/september2012.pdf`
	},
	{
		month: {
			en: 'August',
			es: 'Agosto'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2012/esp/agosto_2012.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2012/august2012.pdf`
	},
	{
		month: {
			en: 'July',
			es: 'Julio'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2012/esp/julio_2012.PDF`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2012/july2012.PDF`
	},
	{
		month: {
			en: 'June',
			es: 'Junio'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2012/esp/junio_2012.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2012/june2012.pdf`
	},
	{
		month: {
			en: 'May',
			es: 'Mayo'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2012/esp/Mayo_2012.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2012/May2012_0.pdf`
	},
	{
		month: {
			en: 'April',
			es: 'Abril'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2012/esp/abril_2012.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2012/april2012_0.pdf`
	},
	{
		month: {
			en: 'March',
			es: 'Marzo'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2012/esp/Mar_2012.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2012/March2012_0.pdf`
	},
	{
		month: {
			en: 'February',
			es: 'Febrero'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2012/esp/Feb_2012.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2012/Feb_2012_0.pdf`
	},
	{
		month: {
			en: 'January',
			es: 'Enero'
		},
		urlES: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2012/esp/ene_2012.pdf`,
		url: `${process.env.REACT_APP_SRC_URL}reportes_operativos/2012/Jan2012_0.pdf`
	}
];
