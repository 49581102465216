import React, { useState } from "react";
import { Link } from "react-router-dom";

import "./Futuro.css";
import GrupoAM from "./assets/gpo-am.svg";
import { ReactComponent as Check } from "./assets/circle-check.svg";

const FuturoPage = () => {
	const [isOther, setIsOther] = useState(false);
	const [submited, setSubmited] = useState(false);

	const changeHandler = e => {
		if (e.target.value === "Otros") {
			setIsOther(true);
		}
	};

	const submitHandler = e => {
		e.preventDefault();
		setSubmited(true);
	};

	return (
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-12 col-sm-12 col-lg-8 pt-5 main-container">
					<div className="col-12 d-flex justify-content-center">
						<img
							className="col-6 mx-auto mt-5 mb-2"
							src={GrupoAM}
							alt="Grupo Aeroméxico"
						/>
					</div>
					<h1 className="col-9 mt-4 text-center mx-auto text-muted text-uppercase font-weight-bold">
						Creando el futuro
					</h1>
					<p className="col-9 m-0 mb-4 text-center mx-auto text-muted">
						Con este formulario ayudanos a crear el futuro de
						Aeroméxico con ideas para la nueva realidad
					</p>
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-12 col-md-9 form-holder bg-white mt-2 shadow-lg p-3 mb-5">
								{!submited && (
									<form
										className="p-2"
										onSubmit={submitHandler}
									>
										<div className="form-row">
											<div className="col-12 mb-4 form-group">
												<label
													htmlFor="impacto"
													className="font-weight-bold"
												>
													¿En qué área de Grupo
													Aeroméxico tendría mayor
													impacto la idea?
												</label>
												<select
													className={`custom-select form-control ${
														isOther ? "mb-2" : ""
													}`}
													name="impacto"
													id="impacto"
													// required
													//is-required
													onChange={changeHandler}
												>
													<option selected value="">
														...
													</option>
													<option value="Comercial">
														Comercial
													</option>
													<option value="Marketing">
														Marketing
													</option>
													<option value="Experiencia al cliente">
														Experiencia al cliente
													</option>
													<option value="Operaciones">
														Operaciones
													</option>
													<option value="Seguridad y Salud">
														Seguridad y Salud
													</option>
													<option value="Employee Experience">
														Employee Experience
													</option>
													<option value="Otros">
														Otra área
													</option>
												</select>
												<input
													type="text"
													placeholder="Escribe el área que correspondería a tu propuesta"
													className={`form-control ${
														isOther ? "" : "d-none"
													}`}
												/>
											</div>
											<div className="col-12 mb-4 form-group">
												<label
													htmlFor="mejora"
													className="font-weight-bold"
												>
													¿Qué tipo de mejora quieres
													implementar?
												</label>
												<select
													className="custom-select form-control"
													name="mejora"
													id="mejora"
													// required
													//is-required
												>
													<option selected value="">
														...
													</option>
													<option value="Tecnológica">
														Tecnológica
													</option>
													<option value="Procesos">
														Procesos
													</option>
													<option value="Mejora incremental">
														Mejora incremental
													</option>
													<option value="Radicalmente nueva">
														Radicalmente nueva
													</option>
												</select>
											</div>
											<div className="col-12 mb-4 form-group">
												<label
													htmlFor="estado"
													className="font-weight-bold"
												>
													¿Cuál es el estado de tu
													proyecto?
												</label>
												<select
													className="custom-select form-control"
													name="estado"
													id="estado"
													// required
													//is-required
												>
													<option selected value="">
														...
													</option>
													<option value="Inicial">
														Inicial
													</option>
													<option value="Medio">
														Medio
													</option>
													<option value="Avanzado">
														Avanzado
													</option>
													<option value="Muy avanzado">
														Muy avanzado
													</option>
													<option value="Listo para implementar">
														Listo para implementar
													</option>
												</select>
											</div>
											<div className="col-12 mb-4 form-group">
												<label
													htmlFor="acerca"
													className="font-weight-bold"
												>
													Cuéntanos más sobre tu
													proyecto
												</label>
												<textarea
													className="form-control"
													name="acerca"
													id="acerca"
													rows="10"
													// required
													//is-required
												></textarea>
											</div>
											<div className="col-12 mb-4 form-group">
												<label
													htmlFor="tiempo"
													className="font-weight-bold"
												>
													¿En qué periodo de tiempo se
													buscaría implementar?
												</label>
												<select
													className="custom-select form-control"
													name="estado"
													id="estado"
													// required
													//is-required
												>
													<option selected value="">
														...
													</option>
													<option value="A corto plazo">
														A corto plazo
													</option>
													<option value="Mediano plazo">
														Mediano plazo
													</option>
													<option value="Largo plazo">
														Largo plazo
													</option>
												</select>
											</div>
											<div className="col-12 mb-4 form-group">
												<label
													htmlFor="copy_paste"
													className="font-weight-bold"
												>
													¿Ha visto esta idea operando
													ya en otra empresa o
													competidor directo? ¿Nos
													puedes compartir el caso?
												</label>
												<textarea
													className="form-control"
													name="copy_paste"
													id="copy_paste"
													rows="5"
													// required
													//is-required
												></textarea>
											</div>
											<div className="col-12 mb-4 form-group">
												<label
													htmlFor="old_try"
													className="font-weight-bold"
												>
													¿Sabes si se había intentado
													anteriormente en Grupo
													Aeroméxico?
												</label>
												<textarea
													className="form-control"
													name="old_try"
													id="old_try"
													rows="5"
													// required
													//is-required
												></textarea>
											</div>
											<div className="col-12 mb-4 form-group">
												<label
													htmlFor="measure"
													className="font-weight-bold"
												>
													¿Cómo medirías el impacto de
													la iniciativa en Grupo
													Aeroméxico?
												</label>
												<textarea
													className="form-control"
													name="measure"
													id="measure"
													rows="5"
													// required
													//is-required
												></textarea>
											</div>
											<div className="col-12 mb-4 form-group">
												<label className="font-weight-bold">
													Nos gustaría involucrarte
													para hacer realidad tu idea,
													nos puedes compartir tu
													nombre y cómo te
													contactamos?
												</label>
												<div className="form-row">
													<div className="col">
														<input
															type="text"
															className="form-control mb-1"
															placeholder="Nombre"
															// required
															//is-required
														/>
													</div>
													<div className="col">
														<input
															type="email"
															className="form-control mb-1"
															placeholder="Email"
															// required
															//is-required
														/>
													</div>
												</div>
											</div>
											<div className="col-12 mb-2 d-flex justify-content-center">
												<button className="btn btn-primary btn-block font-weight-bold">
													Enviar
												</button>
											</div>
										</div>
									</form>
								)}
								{submited && (
									<React.Fragment>
										<div className="col-12 mt-2 d-flex justify-content-center">
											<Check className="check-icon" />
										</div>
										<h2 className="col-12 text-center thanks-title">
											¡Gracias!
										</h2>
										<p className="col-10 text-center thanks-text mx-auto">
											Cada formulario será revisado y
											evaluado. Cualquier información o
											avance que tengamos te lo haremos
											llegar.
										</p>
										<div className="col-12 d-flex justify-content-center my-btn">
											<Link
												to="/"
												className="btn font-weight-bold"
											>
												Regresar a Mi Aeroméxico
											</Link>
										</div>
									</React.Fragment>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FuturoPage;
