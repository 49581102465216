import React, { useState, createContext } from "react";

export const PublicContext = createContext();

export function PublicProvider({ children }) {
	const [isTrue, setIsTrue] = useState(false);
	const [data, setData] = useState({
		antiguedadEmpleado: null,
		nombre: null,
		apellidoPaterno: null,
		apellidoMaterno: null,
		posicion: null,
		codigo_empresa: null,
		compania: null,
		country: null,
		direccion: null,
		direccionArea: null,
		fechaContra: null,
		grupo: null,
		nivel: null,
		noEmpleado: null,
		subsidiaria: null,
		territorio_mexicano: null,
		tipo_empleado: null,
		ubicacion: null
	});

	return (
		<PublicContext.Provider value={{ data, setData, isTrue, setIsTrue }}>
			{children}
		</PublicContext.Provider>
	);
}
