import React, { useState } from 'react';
import i18next from 'i18next';
import Modal from 'react-awesome-modal';
import './modal.css';
import CloseIMG from '../../../assets/logo/close.svg';

const noScroll = require('no-scroll');

const BenefitItemMX = ({
	bg,
	benefitIcon,
	classNameData,
	icon,
	intro,
	title,
	titleCategoryES,
	myBenefitContentLong,
	myBenefitMoreInformationClass,
	myBenefitInformation,
	myBenefitTermsLong,
	paymentGroupEmployee
}) => {
	const [show, setShow] = useState(false);

	return (
		<>
			<button
				type="button"
				daya-paymentGroupEmployee={paymentGroupEmployee}
				onClick={() => {
					noScroll.on();
					setShow(true);
				}}
				className="item-holder-benefit outline-none focus:outline-none col-span-1 flex items-center justify-start"
			>
				<img className="mr-3 h-8 w-8" src={icon} alt="Item Icon" />
				<p className="text-left font-medium text-amBlue">{title}</p>
			</button>
			{show ? (
				<Modal
					visible={show}
					width="auto"
					height="auto"
					effect="fadeInDown"
					onClickAway={() => {
						noScroll.off();
						setShow(false);
					}}
					className="container-modal-notification modal-minicard-benefits"
				>
					<div>
						<div className="modal-notification">
							<div
								class="closeModal"
								onClick={() => setShow(false)}
							>
								<img src={CloseIMG} alt="close icon" />
							</div>
							<div
								className="background-benefits"
								style={{
									backgroundImage: `url(${bg})`
								}}
							>
								<span
									className={classNameData + ' px-3 py-1'}
									style={{
										background: 'rgba(0,0,0,0.5)',
										borderRadius: '20px'
									}}
								>
									{titleCategoryES}
								</span>
							</div>
							<div className="content-modal">
								<div className="head-modal">
									<div>
										<img
											className="icon-benefits"
											src={benefitIcon}
											alt="benefit icon"
										/>
									</div>
									<div>
										<p>{title}</p>
										<p>{intro}</p>
									</div>
								</div>

								<div className="BenefitContent">
									<div
										dangerouslySetInnerHTML={{
											__html: myBenefitContentLong
										}}
									/>
									<a
										className={
											myBenefitMoreInformationClass
										}
										href={myBenefitInformation}
										target="_blank"
										rel="noopener noreferrer"
									>
										{i18next.t('MiExperAM.benef.conoce')}
									</a>
								</div>
							</div>
							<div className="termsConditions">
								<p>{i18next.t('MiExperAM.benef.terminos')}</p>
								<p>{myBenefitTermsLong}</p>
							</div>
						</div>
					</div>
				</Modal>
			) : null}
		</>
	);
};

export default BenefitItemMX;
