import React from 'react';
import i18next from 'i18next';
import Arrow from '../../../assets/images/arrow-blue.svg';


const FeaturedSlideInside = ({item})=>{ 
	return <>
<img
								className="absolute left-0 top-0 z-0 h-full w-full object-cover"
								src={
									item.fields.myDiscountsImageCard.fields.file
										.url
								}
								alt="Background feature"
							/>
							<p className="relative z-10 text-xs text-white">
								{i18next.language === 'es'
									? item.fields.myDiscountsCategory
									: item.fields.myDiscountsCategoryEn}
							</p>
							<p className="relative z-10 text-base font-medium">
								{item.fields.myDiscountsTitle}
							</p>
							<p className="relative z-10 mt-2 w-7/12 text-xs text-white">
								{item.fields.myDiscountsIntro}
							</p>
							<p className="absolute left-4 bottom-2 flex items-center justify-start text-sm text-white">
								<span>
									{i18next.t('MiExperAM.benef.conoce')}{' '}
								</span>
								<img
									className="h-8 w-8"
									src={Arrow}
									alt="Conoce más"
								/>
							</p>
							</>
}

export default FeaturedSlideInside;