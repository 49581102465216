import React, { Component } from 'react';
import Iframe from 'react-iframe';
import LoadingScreen from 'react-loading-screen';
import '../css/map.css';
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemPanel,
	AccordionItemButton
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';
import i18next from 'i18next';

export default class Map2 extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true
		};
	}

	componentDidMount() {
		setTimeout(() => this.setState({ loading: false }), 1800);
	}

	hola = code => {
		
	};

	getMapUrl() {
		alert('no debería de mostrar esta info');
		// if (i18next.language === 'en') {
		// 	return 'https://miaeromexico-destinos.herokuapp.com/en';
		// } else {
		// 	return 'https://miaeromexico-destinos.herokuapp.com/';
		// }
	}

	render() {
		const { loading } = this.state;
		var urlMap = this.getMapUrl();

		// var urlMap = "http://localhost:5000/";
		return (
			<LoadingScreen
				loading={loading}
				bgColor="#f5f5f5"
				spinnerColor="#007dc3"
				textColor="#0b2343"
				//logoSrc={avion}
				text={i18next.t('News.cargando')}
			>
				<div className="wrapper-iframe-map">
					<div className="routesContainer">
						<div className="ourRoutes">
							<Accordion
								allowZeroExpanded="true"
								id="ourRoutesAccordion"
							>
								<AccordionItem>
									<AccordionItemHeading>
										<AccordionItemButton>
											<div className="tableBoletosHeader">
												<p className="title">
													{i18next.t(
														'Destinos.title'
													)}
												</p>
												<p className="smallRoutes">
													{i18next.t(
														'Destinos.nacionales'
													)}{' '}
													43 &nbsp; &nbsp; | &nbsp;
													&nbsp;
													{i18next.t(
														'Destinos.interna'
													)}{' '}
													45
												</p>
											</div>
										</AccordionItemButton>
									</AccordionItemHeading>
									<AccordionItemPanel>
										<div className="description">
											<ul>
												<li>
													19 -{' '}
													{i18next.t('Destinos.eua')}
												</li>
												<li>
													8 -{' '}
													{i18next.t(
														'Destinos.centro'
													)}
												</li>
												<li>
													7 -{' '}
													{i18next.t(
														'Destinos.sudam'
													)}
												</li>
												<li>
													5 -{' '}
													{i18next.t(
														'Destinos.europa'
													)}
												</li>
												<li>
													3 -{' '}
													{i18next.t('Destinos.can')}
												</li>
												<li>
													3 -{' '}
													{i18next.t('Destinos.asia')}
												</li>
											</ul>
										</div>
									</AccordionItemPanel>
								</AccordionItem>
							</Accordion>
						</div>
						<div className="routesInfo">
							<div className="routeItem">
								<div className="routeType">
									<div className="routeTypeDashed"></div>
								</div>
								<p>{i18next.t('Destinos.r_temp')}</p>
							</div>
							<div className="routeItem">
								<div className="routeType">
									<div className="routeTypeSolid"></div>
								</div>
								<p>{i18next.t('Destinos.r_fija')}</p>
							</div>
						</div>
					</div>

					<Iframe
						url={urlMap}
						width="100%"
						height="100vh"
						id="mapAeromexico"
						className="iframe-map"
						display="initial"
						position="relative"
						frameBorder="0"
					/>
				</div>
			</LoadingScreen>
		);
	}
}
