import React, { Component } from 'react';


import Map from '../../components/Geolocation/Map'


const mapStyles = {
  width: '100%',
  height: '100%',
};


export  default class   Mapa extends Component {

  constructor() {
    super();
  }

	
  render() {
    return (
      <Map />
    );
  }

}



