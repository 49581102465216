import React, { Component,PureComponent } from "react";
import styled from 'styled-components';
import { Link, DirectLink, Element , Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import i18next from "i18next";

const durationFn = function(deltaTop) {
    return deltaTop;
};

const ContainerMenuScrolled = styled.div`
    .WrapperMenuScrolled{
      opacity: 1;
      background-color: rgba(0,0,0,.8);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
    margin:0;
    top:60px;
    width:100%;
    border-radius:0;
    }
`
const MenuScrollPages = styled.div`
    background-color: rgba(0,0,0,.8);
    position: fixed;
    left: 50%;
    top: 64px;
    transform: translate(-50%,0%);
    width: 1100px;
    height: 60px;
    margin-top: 20px;
    border-radius: 40px;
    overflow: hidden;
    z-index:30;
    backdrop-filter: blur(20px);
    opacity:1;
    transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
    box-shadow:0 20px 40px rgba(0, 0, 0, 0.15);
    opacity:1;
`
const GridMenuElements = styled.ul`
    display: block;
    list-style: none;
    height: calc(100% - 15px);
    width: calc(100% - 15px);
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-gap: 10px;
    margin: 0 auto;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    left: 50%;
    top: 50%;
    position: absolute;
    max-width:1080px;
    a{
        color: white!important;
        border-radius: 30px;
        font-size: 12px;
        text-align: center;
        font-weight: 500;
        line-height: 43px;
        transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
        backdrop-filter: blur(20px);
        cursor:pointer;
        :hover{
            text-decoration: none;
            background: rgba(255,255,255,.2);
        }
    }
`
const ButtonsMenuScroll = styled.a`
    
`
const TextList = styled.li`
    display: inline;
`



export default class SliderSwiper extends Component {

    constructor(props) {
        super(props);
        this.scrollToTop = this.scrollToTop.bind(this);
        this.state = {
          hasScrolled: false,
          error: false
        };
      }

      componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);

        Events.scrollEvent.register('begin', function() {
           
          });
      
          Events.scrollEvent.register('end', function() {
           
      });

      }

      scrollToTop() {
        scroll.scrollToTop();
      }
      scrollTo() {
        scroller.scrollTo('scroll-to-element', {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart'
        })
      }
      scrollToWithContainer() {
    
        let goToContainer = new Promise((resolve, reject) => {
    
          Events.scrollEvent.register('end', () => {
            resolve();
            Events.scrollEvent.remove('end');
          });
    
          scroller.scrollTo('scroll-container', {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
          });
    
        });
    
        goToContainer.then(() =>  
            scroller.scrollTo('scroll-container-second-element', {
                duration: 800,
                delay: 0,
                smooth: 'easeInOutQuart',
                containerId: 'scroll-container'
            }));
      }
      componentWillUnmount() {
        Events.scrollEvent.remove('begin');
        Events.scrollEvent.remove('end');
    }

      handleScroll = event => {
        const scrollTop = window.pageYOffset;
        if (scrollTop > 35) {
          this.setState({ hasScrolled: true });
        } else {
          this.setState({ hasScrolled: false });
        }
      };

  render() {
    return (

    <ContainerMenuScrolled>

      <MenuScrollPages className={
        this.state.hasScrolled
          ? "WrapperMenu WrapperMenuScrolled"
          : "WrapperMenu"
      }>
          <GridMenuElements>


            <Link activeClass="activeSeguridad" className="test1" to="test1" spy={true} smooth={true} duration={500} offset={-250}>
                <TextList>{i18next.t('ClienteEx.topmenu.inicio')}</TextList>
            </Link>

            <Link activeClass="activeSeguridad" className="test2" to="test2" spy={true} smooth={true} duration={500} offset={-250}>
              <TextList>{i18next.t('ClienteEx.topmenu.net')}</TextList>
            </Link>

            <Link activeClass="activeSeguridad" className="test3" to="test3" spy={true} smooth={true} duration={500} offset={-250}>
              <TextList>{i18next.t('ClienteEx.topmenu.resultado')}</TextList>
            </Link>

            <Link activeClass="activeSeguridad" className="test4" to="test4" spy={true} smooth={true} duration={500} offset={-250}>
              <TextList>{i18next.t('ClienteEx.topmenu.pilares')}</TextList>
            </Link>

            <Link activeClass="activeSeguridad" className="test5" to="test5" spy={true} smooth={true} duration={500} offset={-250}>
              <TextList>{i18next.t('ClienteEx.topmenu.logros')}</TextList>
            </Link>

            <Link activeClass="activeSeguridad" className="test6" to="test6" spy={true} smooth={true} duration={500} offset={-250}>
              <TextList>{i18next.t('ClienteEx.topmenu.quejas')}</TextList>
            </Link>

          </GridMenuElements>
      </MenuScrollPages>
      </ContainerMenuScrolled>
    );
  }
}
