import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./css/newsCategoryHeader.css";
import i18next from "i18next";
export default class NewsCategoryHeader extends Component {
	getEntryTag(data) {
		if (i18next.language === "es") {
			return data.fields.articleTagEs;
		} else {
			return data.fields.articleTagEn;
		}
	}
	render() {
		const { data } = this.props;
		return (
			<div className="newsCategoryHeaderContainer">
				<div className="backgroundContainer">
					<div className="newsCategoryMenuContainer">
						<div className="miniContainer">
							<div className="backLinkContainer1">
								<Link to="/inicio" id="backLink1">
									<i class="fa fa-long-arrow-left" aria-hidden="true" id="backIcon1" />
									<span>{i18next.t("News.regresarInicio")}</span>
								</Link>
							</div>
							<div className="newsCategoryMenu">
								<ul>
									<Link
										activeClassName="buttonactiveNew"
										to="/noticias/ultimas-noticias"
										className="buttons">
										<li className="buttonText">{i18next.t("News.topMenu.ultimas")}</li>
									</Link>

									<Link
										activeClassName="buttonactiveNew"
										to="/noticias/seguridad"
										className="buttons">
										<li className="buttonText">{i18next.t("News.topMenu.seguridad")}</li>
									</Link>
									<Link
										activeClassName="buttonactiveNew"
										to="/noticias/nuestragente"
										className="buttons">
										<li className="buttonText">{i18next.t("News.topMenu.gente")}</li>
									</Link>
									<Link
										activeClassName="buttonactiveNew"
										to="/noticias/experienciacliente"
										className="buttons">
										<li className="buttonText">{i18next.t("News.topMenu.expCliente")}</li>
									</Link>
									<Link activeClassName="buttonactiveNew" to="/noticias/delta" className="buttons">
										<li className="buttonText">{i18next.t("News.topMenu.jca")}</li>
									</Link>
									<Link
										activeClassName="buttonactiveNew"
										to="/noticias/comercial"
										className="buttons">
										<li className="buttonText">{i18next.t("News.topMenu.comercial")}</li>
									</Link>
									<Link
										activeClassName="buttonactiveNew"
										to="/noticias/operaciones"
										className="buttons">
										<li className="buttonText">{i18next.t("News.topMenu.opera")}</li>
									</Link>

									<Link activeClassName="buttonactiveNew" to="/noticias/skyteam" className="buttons">
										<li className="buttonText">{i18next.t("News.topMenu.sky")}</li>
									</Link>

									<Link
										activeClassName="buttonactiveNew"
										to="/noticias/sostenibilidad"
										className="buttons">
										<li className="buttonText">{i18next.t("News.topMenu.sosten")}</li>
									</Link>
								</ul>
							</div>
						</div>
					</div>

					<div className="newsCategoryTextContainer">
						<div className="newsCategoryText">
							<div className="miniContainerText">
								{/*<div className="h6Container">
                  <h6>{data.fields.articleTagEs}</h6>
                </div>*/}
								{/*<h1>{data.fields.firstArticleTitle}</h1>*/}
								<h1>{this.getEntryTag(data)}</h1>
								{/*<div className="pContainer">
                  <p>{data.fields.firstArticleIntro}</p>
              </div>*/}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
