import React from 'react';
import FakeTableRowFC from './FakeTableRowFC';

const FakeTableFC = ({ data, even = false }) => {
	return (
		<>
			{!!data.titulo ? (
				<div className="contTitleComs">
					<h1 className="rewards_title" style={{ marginTop: '1px' }}>
						{data.titulo}
					</h1>
				</div>
			) : null}

			<div
				className={`fake-table-element ${
					even ? 'even-table' : 'odd-table'
				} m-0 grid h-auto w-full grid-cols-1 gap-0 rounded-md bg-white shadow-md`}
				style={{
					color: '#999999'
				}}
			>
				{data.elementos.map((el, index) => el.anual.cifra > 0 || el.anual > 0 ? (
					<FakeTableRowFC
						key={el.id}
						anual={el.anual.cifra ? el.anual.cifra : el.anual}
						anualSub={el.anual.sub ? el.anual.sub : null}
						bigBorder={el.bigBottom}
						bottomBorder={el.bottom}
						icon={el.icon}
						id={el.id}
						mensual={el.mensual}
						title={el.titulo}
						tip={el.tooltip}
					/>
				) : null)}
			</div>
		</>
	);
};

export default FakeTableFC;
