import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import Banner from './assets/mi-linea-atencion.png';
import Compu from './assets/compu.svg';
import Boletos from './assets/boletos.svg';
import Avion from './assets/avion.svg';
import ID from './assets/id.svg';
import Doctor from './assets/doctor.svg';
/* import Header from '../../components/Header'; */
import Index from '../../components/Footer';
import Arriba from '../../components/Header/Arriba';
import i18next from 'i18next';

const MiLineaAtencion = () => {
	const isSpanish = i18next.language === 'es';
	useEffect(() => {
		window.scrollTo(0,0)
	})
	return (
		<motion.div
			key="mi-linea-atencion-page"
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			className="full-container">

			{/* <Arriba/> */}
			<br/><br/>
			<div className="content-holder-talent">
				<header className="talent-header relative h-60">
					<div className="absolute left-1/2 top-1/2 z-10 flex w-full -translate-x-1/2 -translate-y-1/2 transform flex-col items-center">
						<h1 className="mb-3 w-full text-center text-5xl font-bold text-white opacity-100">
						{isSpanish?"Mi Línea de Atención":"My hotline "}
							<br />
							(ext. 4488)
						</h1>
						<p className="font-regular w-full px-10 text-center text-white  lg:px-24">
							<strong>
							{isSpanish?"Integramos en una línea única de contacto el proceso de atención telefónica para empleados":"We integrate into a single line of contact with the telephone service process for employees"}
							</strong>
							{ isSpanish?". Esta línea estará disponible para ti en todo momento y con un seguimiento puntual para solucionar cualquier duda o soporte que requieras durante tu estancia en Aeroméxico.":". This line will be available to you at all moment and with timely follow-up to solve any questions or support you require during your Stay at Aeroméxico."}
						</p>
					</div>
					<img
						src={Banner}
						alt="Talent Planning"
						className="absolute left-0 top-0 z-0 h-full w-full object-cover"
					/>
				</header>
				<div className="top-navigation z-50 flex w-full items-center justify-center border-b border-gray-200 bg-amRegularBlue py-3">
					<p className="text-mg mx-4 text-center font-bold text-white">
					{ isSpanish?"Ahora marcando a la ext. 4488 encontrarás un menú de acuerdo al tipo de servicio que requieras ya sea para atención directa de nuestros especialistas, orientación para la búsqueda de información o incluso levantar un ticket que será canalizado a un experto.":"Now dialing the ext. 4488 you will find a menu of according to the type of service you require, whether for direct attention from our specialists, guidance to search for information or even raise a ticket that will be channeled to an expert."}
					</p>
				</div>
				<div className='container'>
				<section className=" relative mx-auto px-10 lg:px-20">
					<article className="container relative left-0 top-0 z-50 hidden w-full bg-white py-4 md:sticky md:top-14 md:flex">
						<h2 className="w-5/12 text-center text-xl font-bold text-amRegularBlue">{isSpanish?"Opción del menú":"Option menu"}</h2>
						<div className="ml-1 hidden border-l border-amWashedGreen md:block"></div>
						<h2 className="w-7/12 text-center text-xl font-bold text-amRegularBlue">{isSpanish?"Descripción":"Description"}</h2>
					</article>
					<article className="options-atention-line mb-14 mt-10 flex flex-wrap rounded-md border-b border-l border-r border-t border-amWashedGreen shadow-lg md:mt-0">
						{/* OPTION 1 */}
						<div className="option-section flex w-full flex-col items-stretch justify-center bg-amRegularBlue bg-opacity-10 p-4 md:flex-row">
							<div className="grid w-full grid-cols-4 pr-12 md:w-5/12">
								<div className="col-span-2 flex items-center justify-center">
									<div className="flex h-20 w-20 items-center justify-center rounded-full bg-amBlue">
										<img className="h-20 w-20" src={Boletos} alt="Soporte TI" />
									</div>
								</div>
								<div className="option-intel col-span-2 flex flex-col items-center justify-center">
									<p className="w-full text-center text-2xl font-bold text-amBlue">#1</p>
									<p className="w-full text-center text-xl font-bold text-amBlack">{isSpanish?"Línea LINC":"LINC line"}</p>
									<p className="w-full text-center text-xs text-amBlack">
										{isSpanish?"Lunes a viernes - 9:00 a 18:00 h.":"Monday to Friday - 9:00 a.m. to 6:00 p.m."}
										<br />
										<br />
										{isSpanish?"Lunes a domingo - 8:00 a 00:00 h.":"Monday to Sunday - 8:00 a.m. to 12:00 a.m."}
										<br />
										{isSpanish?"(Solo emergencias)":"(Only emergencies)"}
									</p>
								</div>
							</div>
							<div className="hidden border-l border-amWashedGreen md:block"></div>
							<div className="flex w-full flex-col items-center justify-center pl-12 pt-4 md:w-7/12 md:pt-0">
								<p>
									{isSpanish?"Nuestros agentes de LINC te brindarán la asistencia necesaria para solicitudes de Travel Benefits que no puedas realizar por CORE como consultas, soporte, trámites, cambios, etc.":"Our LINC agents will provide you with the necessary assistance for requests for Travel Benefits that you cannot carry out through CORE such as consultations, support, procedures, changes, etc."}
								</p>
								<p className="mt-4">
									{isSpanish?"Además, se brinda asistencia fuera del horario de atención para emergencias con	vuelos con salida dentro de las próximas 48 h o últimos días de trámite.":"Additionally, after-hours assistance is provided for emergencies with flights departing within the next 48 hours or last days of processing."}
								</p>
							</div>
						</div>
						{/* OPTION 1 END */}
						{/* OPTION 2 */}
						<div className="option-section flex w-full flex-col items-stretch justify-center bg-amBlue bg-opacity-5 p-4 md:flex-row">
							<div className="grid w-full grid-cols-4 pr-12 md:w-5/12">
								<div className="col-span-2 flex items-center justify-center">
									<div className="flex h-20 w-20 items-center justify-center rounded-full bg-amRegularBlue">
										<img className="h-20 w-20" src={Avion} alt="Soporte TI" />
									</div>
								</div>
								<div className="option-intel col-span-2 flex flex-col items-center justify-center">
									<p className="w-full text-center text-2xl font-bold text-amRegularBlue">#2</p>
									<p className="w-full text-center text-xl font-bold text-amBlack">{isSpanish?"COBUS":"COBUS"}</p>
									<p className="w-full text-center text-xs text-amBlack">
									{isSpanish?"Lunes a viernes":"Monday to Friday"}
										 <br />
										{isSpanish?"9:00 a 19:00 h.":"9:00 a 19:00 h."}
									</p>
								</div>
							</div>
							<div className="hidden border-l border-amWashedGreen md:block"></div>
							<div className="flex w-full flex-col items-center justify-center py-3 pl-12 md:w-7/12">
								<p>
								{isSpanish?"En COBUS podrás solicitar tu reservación de	avión para pases en comisión de servicio y posteriormente realizar tu trámite por CORE." : "In COBUS you can request your plane reservation for duty passes and later complete your procedure through CORE."}
								</p>
							</div>
						</div>
						{/* OPTION 2 END */}
						{/* OPTION 3 */}
						<div className="option-section flex w-full flex-col items-stretch justify-center bg-amRegularBlue bg-opacity-10 p-4 md:flex-row">
							<div className="grid w-full grid-cols-4 pr-12 md:w-5/12">
								<div className="col-span-2 flex items-center justify-center">
									<div className="flex h-20 w-20 items-center justify-center rounded-full bg-amWashedGreen">
										<img className="h-20 w-20" src={ID} alt="Soporte TI" />
									</div>
								</div>
								<div className="option-intel col-span-2 flex flex-col items-center justify-center">
									<p className="w-full text-center text-2xl font-bold text-amWashedGreen">#3</p>
									<p className="w-full text-center text-xl font-bold text-amBlack">
									{isSpanish? "Servicios al Empleado": "Employee Services"}
									</p>
									<p className="w-full text-center text-xs text-amBlack">
									{isSpanish?"(Servicio las 24 h del día)": "Service 24 h all day"}
									</p>
								</div>
							</div>
							<div className="hidden border-l border-amWashedGreen md:block"></div>
							<div className="flex w-full flex-col items-center justify-center pl-12 pt-4 md:w-7/12 md:pt-0">
								<p>
								{isSpanish?"El equipo de Help Desk te orientará para resolver tus dudas o incidentes referentes a prestaciones, beneficios, percepciones, deducciones y en caso necesario, asignará un ticket al especialista responsable de dar seguimiento a tu caso quien te contactará de lunes a viernes en horarios laborales para brindarte la solución correspondiente.":"The Help Desk team will guide you to resolve your doubts or incidents regarding benefits, benefits, perceptions, deductions and, if necessary, will assign a ticket to the specialist responsible for following up on your case who will contact you from Monday to Friday during business hours to provide you with the corresponding solution."}
								</p>
							</div>
						</div>
						{/* OPTION 3 END */}
						{/* OPTION 3 EXTENSION */}
						<div className="option-section flex w-full flex-col flex-wrap items-stretch justify-center bg-amWashedGreen p-4 md:flex-row">
							<h3 className="w-full text-center text-xl font-bold text-white">
							{isSpanish?"Servicios que puedes encontrar":"Services you can find"}
							</h3>
							<div className="mx-auto mt-4 grid w-full grid-cols-1 gap-0 text-white md:w-10/12 md:grid-cols-2 md:gap-x-4">
								<div className="col-span-1 flex items-start justify-center px-10">
									<ul className="w-full list-disc">
										<li>{isSpanish?"Permutas para personal operativo":"Exchanges for operational personnel"}</li>
										<li>{isSpanish?"Corrección de Datos personales en CORE":"Correction of personal data in CORE"}</li>
										<li>{isSpanish?	"Registro de Beneficiarios en CORE":"Registration of Beneficiaries in CORE"}</li>
										<li>{isSpanish?"Solicitudes de Fondo de Ahorro":"Savings Fund Applications"}</li>
										<li>{isSpanish?"Reposición de Credencial": "Credential replacement"}</li>
										<li>{isSpanish?"Tarjeta de Vales de Despensa":"Food Voucher Card"}</li>
										<li>{isSpanish?"Solicitud de Vacaciones":"Vacation request"}</li>
										<li>{isSpanish?"Días Festivos":"Holidays"}</li>
										<li>{isSpanish?"Política de permisos especiales":"Special permissions policy"}</li>
										<li>{isSpanish?"Proceso para registro de Incapacidades":"Process for registration of Disabilities"}</li>
									</ul>
								</div>
								<div className="col-span-1 flex items-start justify-center px-10">
									<ul className="w-full list-disc">
										<li>{isSpanish?"Incio de descuento de nómina de crédito Infonavit":"Beginning of payroll deduction Infonavit credit"}</li>
										<li>{isSpanish?"Tarjeta de gasolina":"Gasoline card"}</li>
										<li>{isSpanish?"Servicios de Caja de Ahorro":"Savings Bank Services"}</li>
										<li>{isSpanish?"Plan de retiro No Sindicalizados":"Non-Union Retirement Plan"}</li>
										<li>{isSpanish?"Cambio de Tarjeta de Nómina":"Payroll Card Change"}</li>
										<li>{isSpanish?"Contactos de atención para Seguro de Gastos Médicos Mayores, Póliza de exceso y Seguro de Vida":"Care contacts for Major Medical Expense Insurance, Excess Policy and Life Insurance"}
										</li>
										<li>{isSpanish?"Adquisición de Seguros Voluntarios (Dental, Autos, Vivienda, Mascota)":"Acquisition of Voluntary Insurance (Dental, Car, Housing, Pet)"}</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="option-section flex w-full flex-col flex-wrap items-stretch justify-center bg-amDarkBlue p-4 md:flex-row">
							<h3 className="w-full text-center text-xl font-bold text-white">
							{isSpanish?"¿Requires otro tipo de servicio de Recursos	Humanos?":"¿Do you require another type of Human Resources service?"}
							</h3>
							<p className="w-full text-center text-lg text-white">
							{isSpanish?"Uno de nuestros agentes tomará tu requerimiento	para canalizarlo a un especialista que te contactará para dar solución.":"One of our agents will take your request to refer you to a specialist who will will contact you to provide a solution."}
							</p>
						</div>
						{/* OPTION 3 EXTENSION END */}
						{/* OPTION 4 */}
						<div className="option-section flex w-full flex-col items-stretch justify-center bg-amBlue bg-opacity-5 p-4 md:flex-row">
							<div className="grid w-full grid-cols-4 pr-12 md:w-5/12">
								<div className="col-span-2 flex items-center justify-center">
									<div className="flex h-20 w-20 items-center justify-center rounded-full bg-amGreen">
										<img className="h-20 w-20" src={Doctor} alt="Soporte TI" />
									</div>
								</div>
								<div className="option-intel col-span-2 flex flex-col items-center justify-center">
									<p className="w-full text-center text-2xl font-bold text-amGreen">#4</p>
									<p className="w-full text-center text-xl font-bold text-amBlack">{isSpanish?"Servicios Médico":"Medical Services"}</p>
									<p className="w-full text-center text-xs text-amBlack">
									{isSpanish?"(Servicio las 24 h del día)":"(Service 24 hours a day)"}
									</p>
								</div>
							</div>
							<div className="hidden border-l border-amWashedGreen md:block"></div>
							<div className="flex w-full flex-col items-center justify-center py-3 pl-12 md:w-7/12">
								<p>
								{isSpanish?"Nuestro equipo de médicos y psicólogos te atenderán directamente en un horario 24/7	para cualquier situación relacionada con tu	salud.":"Our team of doctors and psychologists will They will assist you directly 24/7 for any situation related to your health."}
								</p>
							</div>
						</div>
						{/* OPTION 4 END */}
						{/* OPTION 4 EXTENSION */}
						<div className="option-section flex w-full  flex-wrap items-stretch justify-center bg-amBlue bg-opacity-5 p-4">
							<div className="mx-auto mb-2 mt-2 grid w-full grid-cols-1 gap-x-4 md:mb-0 md:w-10/12 md:grid-cols-2">
								<div className="col-span-1 flex items-start justify-center md:col-span-2">
									<ul className="flex w-full list-disc flex-col items-center justify-center px-20">
										<li className="flex items-center justify-start">
											<span className="mr-2 text-2xl font-bold text-amGreen">1</span>
											<span>{isSpanish?"Consultorio médico Hangar Oriente":"Hangar Oriente medical office"}</span>
										</li>
										<li className="flex items-center justify-start">
											<span className="mr-2 text-2xl font-bold text-amGreen">2</span>
											<span>{isSpanish?"Consultorio médico Hangar Connect":"Hangar medical office Connect"}</span>
										</li>
										<li className="flex items-center justify-start">
											<span className="mr-2 text-2xl font-bold text-amGreen">3</span>
											<span>{isSpanish?"Atención emocional":"Emotional attention"}</span>
										</li>
									</ul>
								</div>
								{/* <div className="col-span-1 flex items-start justify-center">
									<ul className="w-full list-disc px-20">
										<li className="flex items-center justify-start">
											<span className="mr-2 text-2xl font-bold text-amGreen">4</span>
											<span>Línea dos de atención emocional</span>
										</li>
										<li className="flex items-center justify-start">
											<span className="mr-2 text-2xl font-bold text-amGreen">5</span>
											<span>Línea uno en confianza para tripulantes</span>
										</li>
										<li className="flex items-center justify-start">
											<span className="mr-2 text-2xl font-bold text-amGreen">6</span>
											<span>Línea dos en confianza para tripulantes</span>
										</li>
									</ul>
								</div> */}
							</div>
						</div>
						{/* OPTION 4 EXTENSION END */}
						{/* OPTION 5 */}
						<div className="option-section flex w-full flex-col items-stretch justify-center bg-amRegularBlue bg-opacity-10 p-4 md:flex-row">
							<div className="grid w-full grid-cols-4 pr-12 md:w-5/12">
								<div className="col-span-2 flex items-center justify-center">
									<div className="flex h-20 w-20 items-center justify-center rounded-full bg-amOrange">
										<img className="h-20 w-20" src={ID} alt="Soporte TI" />
									</div>
								</div>
								<div className="option-intel col-span-2 flex flex-col items-center justify-center">
									<p className="w-full text-center text-2xl font-bold text-amOrange">#5</p>
									<p className="w-full text-center text-xl font-bold text-amBlack">
									{isSpanish?"Atención a excolaboradores":"Attention to former collaborators"}
									</p>
								</div>
							</div>
							<div className="hidden border-l border-amWashedGreen md:block"></div>
							<div className="flex w-full flex-col items-center justify-center py-3 pl-12 md:w-7/12">
								<p>
									{isSpanish?"Uno de nuestros agentes tomará tu requerimiento junto con tus datos para canalizarlo a un especialista que te contactará para dar respuesta a tu caso.":"One of our agents will take your request along with your data to channel it to a specialist who will contact you to respond to your case."}
								</p>
							</div>
						</div>
						{/* OPTION 5 END */}
						{/* OPTION 6 */}
						<div className="option-section flex w-full flex-col items-stretch justify-center bg-amBlue bg-opacity-5 p-4 md:flex-row">
							<div className="grid w-full grid-cols-4 pr-12 md:w-5/12">
								<div className="col-span-2 flex items-center justify-center">
									<div className="flex h-20 w-20 items-center justify-center rounded-full bg-amBlack">
										<img className="h-20 w-20" src={Compu} alt="Soporte TI" />
									</div>
								</div>
								<div className="option-intel col-span-2 flex flex-col items-center justify-center">
									<p className="w-full text-center text-2xl font-bold text-amBlack">#6</p>
									<p className="w-full text-center text-xl font-bold text-amBlack">{isSpanish?"Service Desk":"Service Desk"}</p>
									<p className="w-full text-center text-xs text-amBlack">
										{isSpanish?"(Servicio las 24 h del día)":"(Service 24 hours a day)"}
									</p>
								</div>
							</div>
							<div className="hidden border-l border-amWashedGreen md:block"></div>
							<div className="flex w-full items-center justify-center py-3 pl-12 md:w-7/12">
								<p>
									{isSpanish?"Un agente de Help Desk te atenderá para requerimientos y soporte técnico relacionado con sistemas y aplicaciones.":"A Help Desk agent will assist you with requests and related technical support with systems and applications."}
								</p>
							</div>
						</div>
						{/* OPTION 6 END */}
					</article>
				</section>
				</div>
			</div>
			{/* <Index/> */}
		</motion.div>
	);
};

export default MiLineaAtencion;
