import React, { Component } from "react";
import { Link } from "react-router-dom";
import Modal from "react-awesome-modal";
import "./css/triques.css";
import close from "../../assets/logo/close.svg";
import CurrencyFormat from "react-currency-format";
import ReactMarkdown from "react-markdown";

var noScroll = require("no-scroll");

export default class NewsCard extends Component {
	constructor() {
		super();
		this.state = {
			show: false,
			visible: false,
			loading: true,
			error: null,
			entries: null
		};
		this.escFunction = this.escFunction.bind(this);
		this.setWrapperRef = this.setWrapperRef.bind(this);
	}

	componentDidMount() {
		document.addEventListener("keydown", this.escFunction, false);
		document.addEventListener("mousedown", this.handleClicktest, false);
	}

	UNSAFE_componentWillMount() {
		document.addEventListener("mousedown", this.handleClicktest, false);
	}
	componentWillUnmount() {
		document.removeEventListener("keydown", this.escFunction, false);
		document.removeEventListener("mousedown", this.handleClicktest, false);
	}
	escFunction(event) {
		if (event.keyCode === 27) {
			this.setState({
				visible: false
			});
			noScroll.off();
		}
	}
	handleClicktest = e => {
		if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
			this.setState({
				show: false
			});
			noScroll.off();
		}
	};

	openModal() {
		this.setState({
			visible: true
		});
		noScroll.on();
	}

	closeModal() {
		this.setState({
			visible: false
		});
		noScroll.off();
	}
	setWrapperRef(node) {
		this.wrapperRef = node;
	}

	show = () => {
		this.setState({ show: true });
	};

	render() {
		const { loading, entries } = this.state;
		return (
			<div
				className={
					"firstChild" + " " + this.props.data.fields.categoriaTrique
				}
			>
				<div
					id={this.props.data.sys.id}
					className="ventaOptions"
					onClick={() => this.openModal()}
				>
					<div className="imageWrap">
						<img
							src={
								this.props.data.fields.imageTriques.fields.file
									.url
							}
						></img>
					</div>
					<div className="padding">
						<p className="newProduct">Reciente</p>
						<div className="textWrap">
							<p className="title">
								{this.props.data.fields.titleTrique}
							</p>
							<CurrencyFormat
								value={this.props.data.fields.priceTriques}
								displayType={"text"}
								thousandSeparator={true}
								decimalScale={"2"}
								prefix={""}
								renderText={value => (
									<p className="price">{value} </p>
								)}
							/>
							<a className="viewDetails">Ver detalles</a>

							{/* <div className="buyButton">
                        <CurrencyFormat value={this.props.data.fields.priceTriques} displayType={'text'} thousandSeparator={true} decimalScale={"2"} prefix={''} renderText={value => <p className="price">{value} </p>} />
                        <a href={'mailto:' + this.props.data.fields.emailContacto + '?subject=Triques y Trueques - ' + this.props.data.fields.titleTrique + '&body=Escribe tu mensaje para completar la compra con ' + this.props.data.fields.nombreContacto}><p>Contactar</p> <span></span></a>
                    </div> */}
						</div>
					</div>
				</div>

				<Modal
					visible={this.state.visible}
					width="auto"
					height="auto"
					effect="fadeInDown"
					onClickAway={() => this.closeModal()}
				>
					<div className="windowWhiteTrique">
						<div
							class="closeModal"
							onClick={() => this.closeModal()}
						>
							<img src={close} />
						</div>
						<div className="leftWrap">
							<img
								src={
									this.props.data.fields.imageTriques.fields
										.file.url
								}
							/>
						</div>
						<div className="rightWrap defaultOption">
							<div className="scroll">
								<p className="modaltitle">
									{this.props.data.fields.titleTrique}
								</p>
								<p className="bestprice">Mejor precio</p>
								<CurrencyFormat
									value={this.props.data.fields.priceTriques}
									displayType={"text"}
									thousandSeparator={true}
									decimalScale={"2"}
									prefix={""}
									renderText={value => (
										<p className="modalPrice">{value} </p>
									)}
								/>
								<a
									className="buynow"
									href={
										"mailto:" +
										this.props.data.fields.emailContacto +
										"?subject=Triques y Trueques - " +
										this.props.data.fields.titleTrique +
										"&body=Escribe tu mensaje para completar la compra con " +
										this.props.data.fields.nombreContacto
									}
								>
									<p>Contactar comprador</p> <span></span>
								</a>
								<p className="descriptionTitle">Detalles</p>
								<ReactMarkdown className="detailsWrap">
									{this.props.data.fields.descripcionTriques}
								</ReactMarkdown>
								{/* <div
                          className="detailsWrap"
                          dangerouslySetInnerHTML={{
                            __html: this.props.data.fields
                              .descripcionTriques
                          }}
                        /> */}
							</div>
						</div>
						<div className="rightWrap smallOption">
							<p className="modaltitle">
								{this.props.data.fields.titleTrique}
							</p>
							<p className="bestprice">Mejor precio</p>
							<CurrencyFormat
								value={this.props.data.fields.priceTriques}
								displayType={"text"}
								thousandSeparator={true}
								decimalScale={"2"}
								prefix={""}
								renderText={value => (
									<p className="modalPrice">{value} </p>
								)}
							/>
							<a
								className="buynow"
								href={
									"mailto:" +
									this.props.data.fields.emailContacto +
									"?subject=Triques y Trueques - " +
									this.props.data.fields.titleTrique +
									"&body=Escribe tu mensaje para completar la compra con " +
									this.props.data.fields.nombreContacto
								}
							>
								<p>Contactar comprador</p> <span></span>
							</a>
							<p className="descriptionTitle">Detalles</p>
							<ReactMarkdown className="detailsWrap">
								{this.props.data.fields.descripcionTriques}
							</ReactMarkdown>
						</div>
					</div>
				</Modal>
			</div>
		);
	}
}
