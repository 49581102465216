import React, { Component } from 'react';
import Buttons from './Buttons';
import './css/article.css';
import * as contentful from '../../lib/contentful';
import ArticleCards from '../../components/Home/Cards/articleNewsMore';
import { Parallax, Background } from 'react-parallax';
import getLocale from '../../utils/getLocale';

export default class SingleNewsSlider extends Component {
	constructor() {
		super();
		this.state = {
			loading: false,
			error: null,
			entries: null
		};
	}
	componentDidMount= () =>{
		this.fetchEntries();
	}
	fetchEntries = () => {
		contentful.client
			.getEntries({
				content_type: 'articleSlider',
				include: 2,
				locale: getLocale()
			})
			.then(response => response.items)
			.then(data => {
				
				this.setState({
					loading: false,
					entries: data
				});
			})
			.catch(error => {
				this.setState({
					loading: false,
					error: error
				});
			})
			.catch(err => console.error(err));
	};

	getEntryTag(item) {
		if (this.state.lang === 'es') {
			return item.fields.articleTagES;
		} else {
			return item.fields.articleTagEN;
		}
	}

	renderList = entries => {
		const miniCard = entries.filter(item => {
			if (this.props.match.params.articleSliderId === item.sys.id) {
				return item;
			}
		});

		const list = miniCard.map(item => {
			return (
				<div>
					<div className="article-container">
						<div className="barNavButtons">
							<Buttons />
						</div>

						{/* HEADER IMAGE */}
						<div className="imageHeaderContainer">
							<Parallax
								bgImage={item.fields.articleCover.fields.file.url}
								bgImageAlt="vuela"
								strength={-350}
								bgImageStyle={{ height: '820px' }}
							>
								<div className="imgDivArticle"></div>
							</Parallax>
						</div>
						{/* END HEADER IMAGE */}

						<div className="left-container">
							<div className="paddingarticle">
								<div className="container-scroll-article">
									<div className="paddingTextArticle">
										{/* ARTICLE TYPE */}
										<div className="subtitle-articles">
											<h1 className="Subtitle-art">{this.getEntryTag(item)}</h1>
										</div>
										{/* END ARTICLE TYPE */}

										{/* ARTICLE TITLE */}
										<h2 className="desktop-title">
											{item.fields.articleTittle}{' '}
										</h2>
										{/* END ARTICLE TITLE */}

										{/* DATE */}
										<div className="Container_date">
											<h1 className="Date_articles">
												Publicado el {item.sys.updatedAt}
											</h1>
										</div>
										{/* END DATE */}
										{/* Intro for articles */}
										<p className="introTextArticle">
											{item.fields.articleIntro}
										</p>
										{/* END Intro for articles */}

										<div className="container-text-article">
											<div className="mobile-buttons">
												<button className="small-font">A</button>
												<button className="big-font">A</button>
											</div>

											<div className="text-article">
												<div
													dangerouslySetInnerHTML={{
														__html: item.fields.articleContent
													}}
												/>
											</div>
										</div>
									</div>
								</div>
								<ArticleCards />
							</div>
						</div>
					</div>
				</div>
			);
		});

		return list;
	};

	render() {
		const { loading, entries } = this.state;

		return (
			<React.Fragment>
				{!loading && entries && this.renderList(entries)}
			</React.Fragment>
		);
	}
}
