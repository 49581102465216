import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { withAccess } from '../../utils/validateTeletrabajo';
import MiamLogo from '../../assets/images/logomiam.svg';
import { isLocal, isFromDev, isFromQA } from './../../core/Ambientes';
	// # Variables que switchean las ligas que se consumen segun sea elcaso
	// const isLocal = process.env.REACT_APP_ISLOCAL; // Tomara entorno local
	// const isFromDev = process.env.REACT_APP_ISFROMDEV; //Se pondra en true para ambiente DEV
	// const isFromQA = process.env.REACT_APP_ISFROMQA; //Se pondra en true para ambiente QA
	/* Para productivo se ponen todas en falso */
	
	// #URl de ambiente

	
	// #URl de ambiente
const urlLocal = `${process.env.REACT_APP_LOCAL}`;
const urlDev = `${process.env.REACT_APP_CADMIN_DEV}`;
const urlQA = `${process.env.REACT_APP_CADMIN_QA}`
const urlProd = `${process.env.REACT_APP_CADMIN_PROD}`

const tokenLocal = `${process.env.REACT_APP_TOKEN_LOCAL}`;
const tokenDev   = `${process.env.REACT_APP_CADMIN_DEV_TOKEN}`;
const tokenQA 	 = `${process.env.REACT_APP_CADMIN_QA_TOKEN}`;
const tokenProd  = `${process.env.REACT_APP_API_TOKEN}`

// # Liga validada
const urlValid = isLocal === true ? urlLocal 
	: isFromDev === true ? urlDev
	: isFromQA  === true? urlQA
	: urlProd;
	
// Se genera el token segun el ambiente
const tokenValid = isLocal === true ? tokenLocal
	: isFromDev === true ? tokenDev
	: isFromQA === true ? tokenQA
	: tokenProd;

		const cabecera = new Headers({
			"Content-type": "application/json",
			Authorization: "Bearer " + tokenValid
		});

const serverURL = `${urlValid}`;
// const serverURL = 'http://localhost:1337';

function Teletrabajo() {
	const [data, setData] = useState({});
	const [accepted, setAccepted] = useState(false);
	const [firstLoad, setFirstLoad] = useState(true);
	const [loading, setLoading] = useState(true);
	const [hasAccess, setHasAccess] = useState(false);
	const [canAccept, setCanAccept] = useState(true);

	useEffect(() => {
		const employee = localStorage.getItem('noEmpleado');
		// alert(localStorage.getItem('noEmpleado'));
		if (withAccess(employee)) {
			// alert('ENTRA VALIDO EL ACCESO');
			setHasAccess(true);
		}
		setFirstLoad(false);
	}, []);

	useEffect(() => {
		fetch(
			encodeURI(`${serverURL}teletrabajos?email=${localStorage.getItem('email')}`), {headers:cabecera}
		)
			.then(res => res.json())
			.then(res => {
				if (!!res && res.length > 0) {
					setAccepted(true);
					setLoading(false);
				} else {
					setLoading(false);
				
					setData({
						name: `${localStorage.getItem(
							'nombre'
						)} ${localStorage.getItem(
							'apellidoPaterno'
						)} ${localStorage.getItem('apellidoMaterno')}`,
						email: localStorage.getItem('email'),
						employee: parseInt(localStorage.getItem('noEmpleado'))
					});
				}
			})
			.catch(err => console.log(err));
	}, []);

	const handleAccept = () => {
		fetch(`${serverURL}teletrabajos`, {
			method: 'POST',
			mode: 'cors',
			headers: cabecera,
			body: JSON.stringify(data)
		})
			.then(res => {
				if (!!res && res.status === 200) {
					window.location.reload();
				}
			})
			.catch(err => console.log(err));
	};
	if (hasAccess && !firstLoad) {
		return (
			<div className="container mx-auto flex items-start justify-center">
				<div className="w-full md:w-10/12 lg:w-8/12 bg-white py-5 rounded-sm shadow-2xl mt-10 mb-12">
					<div className="title max-w-prose mx-auto text-lg w-full">
						<span className="block text-base text-center font-semibold tracking-wide uppercase leading-tight text-gray-400 m-0">
							Convenio
						</span>
						<span className="mt-2 block text-3xl text-center leading-tight font-bold tracking-tight text-gray-900 sm:text-4xl text-amBlue m-0">
							Ayuda Teletrabajo
						</span>
					</div>
					<div className="mt-6 prose prose-lg text-gray-500 mx-auto px-8 xl:px-0 text-gray-600 text-base">
						<p>
							Esta ayuda mensual se otorgará a los colaboradores
							de Grupo Aeroméxico que, debido a la actual
							contingencia sanitaria, han sido designados
							temporalmente para realizar sus funciones bajo el
							esquema de teletrabajo (home office).
						</p>
						<p>
							Por lo anterior, recibirás una ayuda mensual de $185
							pesos mexicanos* destinada a cubrir la parte
							proporcional de los gastos de electricidad e
							internet relacionados con tu actividad laboral. Esta
							ayuda es determinada con base en los costos promedio
							de la República Mexicana, de acuerdo a las
							características técnicas mínimas necesarias para el
							desempeño de tus funciones.
						</p>
						<p>
							Esta ayuda te será proporcionada solamente durante
							el tiempo en el que realices tus funciones laborales
							bajo el esquema de teletrabajo.
						</p>
						<p>
							Las condiciones de este convenio podrán ser
							modificadas o revisadas de manera mensual, semestral
							o anual, con la finalidad de informar la suspensión
							o continuidad del esquema de teletrabajo así como la
							ayuda otorgada.
						</p>
						<p className="text-sm">
							* La ayuda anteriormente descrita está sujeta al
							pago del ISPT / ISR correspondiente.
						</p>
						<div className="w-full flex items-center justify-center mt-8">
							{!loading ? (
								<>
									{!accepted ? (
										<button
											// disabled={!canAccept}
											onClick={() => {
												if (canAccept) {
													setCanAccept(false);
													handleAccept();
												}
											}}
											className="bg-amRed rounded-full text-white px-6 py-2 font-bold uppercase text-base outline-none focus:outline-none"
										>
											Acepto
										</button>
									) : (
										<div className="max-w-sm px-4 py-1 border border-gray-200 text-sm bg-gray-100 text-amDarkBlue font-bold">
											<p>Ya aceptaste el convenio</p>
										</div>
									)}
								</>
							) : (
								<div>cargando... </div >
							)}
						</div>
					</div>
				</div>
			</div>
		);
	} else {
		return (
			<div className="container mx-auto flex flex-col items-center justify-center min-h-screen py-12">
				<div className="w-full flex items-center justify-center -mt-32">
					<img
						src={MiamLogo}
						alt="Mi Aeroméxico"
						style={{
							maxWidth: 296
						}}
					/>
				</div>
				<h2
					className="w-full font-bold text-center my-2"
					style={{ fontSize: '45px' }}
				>
					403
				</h2>
				<h1 className="w-full md:w-8/12 lg:w-8/12 text-center text-xl text-black flex flex-col items-center justify-center">
					<span className="font-bold text-2xl mb-1">
						No cumples con los requisitos para acceder a esta
						página.
					</span>
					Si tienes alguna duda habla con tu HRBP o envía un correo a{' '}
					<a
						href="mailto:amcomunicacioninterna@aeromexico.com"
						className="underline text-amRed hover:text-amBlue hover:underline"
					>
						amcomunicacioninterna@aeromexico.com
					</a>
					<Link
						to="/inicio"
						className="bg-amBlack hover:bg-amBlue px-4 py-2 text-white border border-2 rounded-full text-base mt-10"
					>
						Regresar a inicio
					</Link>
				</h1>
			</div>
		);
	}
}

export default Teletrabajo;
