import React from 'react';
import { motion } from 'framer-motion';
import ReactMarkdown from 'react-markdown';
import i18next from 'i18next';

function Modal({ handleClose, intel }) {
	
	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			// className="modal-container fixed left-0 top-0 flex h-screen w-screen items-center justify-center"
			className="fixed flex h-screen w-screen items-center justify-center"
			style={{ zIndex: 999, willChange: 'transform, opacity, top' }}
		>
			<div
				onClick={handleClose}
				className="modal-bg absolute left-0 top-0 h-full w-full bg-black opacity-75"
			></div>
			<motion.div
				initial={{ opacity: 0, y: 50 }}
				animate={{ opacity: 1, y: 0 }}
				exit={{ opacity: 0, y: 50 }}
				transition={{ delay: 0.25 }}
				style={{
					willChange: 'transform, opacity, top',
					maxHeight: '90vh'
				}}
				className="relative inline-block max-w-sm overflow-hidden overflow-y-scroll rounded-lg bg-white pb-4 text-left align-bottom shadow-2xl sm:my-8 sm:w-full sm:align-middle md:max-w-md"
			>
				<button
					onClick={handleClose}
					type="button"
					className="absolute rounded-full bg-white opacity-50 outline-none"
					style={{ top: '10px', right: '10px' }}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 50 50"
						className="h-10 w-10 rounded-full fill-current text-black"
						style={{ transform: 'scale(1.47)' }}
					>
						<path d="M25 42c-9.4 0-17-7.6-17-17S15.6 8 25 8s17 7.6 17 17-7.6 17-17 17zm0-32c-8.3 0-15 6.7-15 15s6.7 15 15 15 15-6.7 15-15-6.7-15-15-15z" />
						<path d="M32.283 16.302l1.414 1.415-15.98 15.98-1.414-1.414z" />
						<path d="M17.717 16.302l15.98 15.98-1.414 1.415-15.98-15.98z" />
					</svg>
				</button>
				<header
					className="w-full overflow-hidden border-b border-black"
					style={{ height: '150px' }}
				>
					<img
						className="h-full w-full object-cover"
						src={intel.data.attributes.Background[0].url}
						alt={intel.data.attributes.Title}
					/>
				</header>
				<section className="px-4 pt-5 sm:p-6">
					<p className="text-2xl font-bold text-black">
						{intel.data.attributes.Title}
					</p>
					<p className="text-sm font-bold text-amRed">
						{intel.data.attributes.description}
					</p>
					<div className="the-modal-content prose prose-indigo mx-auto mt-2 text-base leading-tight text-black">
						<ReactMarkdown>{intel.data.attributes.Discount_type}</ReactMarkdown>
					</div>
					{intel.has_intel ? (
						<div className="the-modal-content prose prose-indigo my-3 mx-auto leading-tight">
							<ReactMarkdown>{intel.data.attributes.Intel}</ReactMarkdown>
						</div>
					) : (
						<a
							href={intel.data.attributes.url}
							target="_blank"
							rel="noreferrer"
							className="my-3 text-xs font-bold text-amRed"
						>
							{intel.data.attributes.url}
						</a>
					)}
					<div className="the-modal-content prose prose-indigo mx-auto mt-2 text-sm leading-tight">
						<span className="font-bold">{i18next.language === 'es' ? 'Envíos': 'Sends'}</span>
						<br />
						<ReactMarkdown>{intel.data.attributes.Sends}</ReactMarkdown>
					</div>
				</section>
			</motion.div>
		</motion.div>
	);
}

export default Modal;
