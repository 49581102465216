export const taxonomiaDef = [
	{ code: 0, es: '', en: '' },
	{ code: 1, es: 'Analista Junior', en: 'Junior Analyst' },
	{ code: 2, es: 'Analista', en: 'Analyst' },
	{ code: 3, es: 'Analista Senior o Supervisor Junior', en: 'Senior Analyst or Junior Supervisor'},
	{ code: 4, es: 'Especialista Junior o Supervisor', en: 'Junior Specialist or Supervisor' },
	{ code: 5, es: 'Especialista o Supervisor Senior', en: 'Specialist or Senior Supervisor' },
	// { code: 5, es: 'Asistente Administrativa', en: 'Administrative Assistant' },
	{ code: 6, es: 'Especialista Senior o Líder', en: 'Senior Specialist or Leader' },
	{ code: 7, es: 'Gerente Junior', en: 'Junior Manager' },
	{ code: 8, es: 'Gerente', en: 'Manager' },
	{ code: 9, es: 'Subdirector o Gerente Senior', en: 'Senior Manager' },
	{ code: 10, es: 'Director', en: 'Director' },
	{ code: 11, es: 'Vicepresidente', en: 'Vice President' },
	{ code: 12, es: 'Vicepresidente Senior', en: 'Senior Vice President' }
];

// {code:10, es:'Director Cargo', en:'Director Cargo', email:'cherrerac@aeromexico.com'},
// {code:10, es:'Director Mantenimiento Aeroméxico Connect', en:'Director Aeromexico Connect Maintenance', email:'cgarcia@aeromexico.com'},
// {code:10, es:'Director Mantenimiento Aeroméxico', en:'Director Aeromexico Maintenance', email:'acaballero@aeromexico.com'},
// {code:10, es:'Director Ingeniería Planeacion y Servicios Técnicos', en:'Director Engineering Planning & Technical Services', email:'dnakamura@aeromexico.com'},
// {code:10, es:'Director Aeroméxico Servicios', en:'Director Aeromexico Services', email:'gfajardo@aeromexico.com'},
// {code:10, es:'Director Abastecimientos', en:'Director Procurement', email:'mpina@aeromexico.com'},
// {code:10, es:'Director Fiscal', en:'Director Taxes', email:'spina@aeromexico.com'},
// {code:10, es:'Director Administración de Riesgos', en:'Director Risk Management', email:'llgomez@aeromexico.com'},
// {code:10, es:'Director Centro de Servicios Compartidos', en:'Director Shared Services Center', email:'jhernandez@aeromexico.com'},
// {code:10, es:'Director Relaciones Laborales', en:'Director Labor Relations', email:'ccervantes@aeromexico.com'},
// {code:10, es:'Director Legal', en:'Director Legal', email:'chassey@aeromexico.com'},
// {code:10, es:'Director Aeroméxico Formación', en:'Director Technical Training', email:'ergarcia@aeromexico.com'},
// {code:10, es:'Director Comunicación Corporativa y Asuntos Públicos', en:'Director Corporate Communications & Public Affairs', email:'ctorres@aeromexico.com'},
// {code:10, es:'Director Ventas Directas y Otros Ingresos', en:'Director Direct Sales & Ancillaries', email:'echacon@aeromexico.com'},
// {code:10, es:'Director de Distribución, Sistemas y Analíticos de Ingresos', en:'Director Revenue Management Distribution, Systems & Analytics', email:'egomes@aeromexico.com'},
// {code:10, es:'Director Recursos Humanos Operaciones', en:'Director Operations Human Resources', email:'cmenchaca@aeromexico.com'},
// {code:10, es:'Director Compensaciones y Beneficios', en:'Director Compensations & Benefits', email:'rigutierrez@aeromexico.com'},
// {code:10, es:'Director de Producto y Servicios a Bordo', en:'Director Onboard Product & Services', email:'afernandezo@aeromexico.com'},
// {code:11, es:'Vicepresidente Servicios a Bordo', en:'Vice President In Flight Services', email:'darconada@aeromexico.com'},
// {code:11, es:'Vicepresidente Aeropuerto Ciudad de México', en:'Vice President Mexico City Airport', email:'acontreras@aeromexico.com'},
// {code:11, es:'Vicepresidente Operaciones de Vuelo', en:'Vice President Flight Operations', email:'rsuarezc@aeromexico.com'},
// {code:11, es:'Vicepresidente Planeación Tripulaciones', en:'Vice President Crew Planning', email:'nrhoads@aeromexico.com'},
// {code:11, es:'Vicepresidente Ventas Norteamérica', en:'Vice President North America Sales', email:'grobles@aeromexico.com'},
// {code:11, es:'Vicepresidente Ventas Internacional', en:'Vice President International Sales', email:'pverhagen@aeromexico.com'},
// {code:11, es:'Vicepresidente Estrategia de Ingresos', en:'Vice President Pricing & Revenue Management', email:'ade@aeromexico.com'},
// {code:11, es:'Vicepresidente Ventas México', en:'Vice President Mexico Sales', email:'gmulinelli@aeromexico.com'},
// {code:11, es:'Vicepresidente Experiencia al Cliente', en:'Vice President Customer Experience', email:'epiquet@aeromexico.com'},
// {code:12, es:'Vicepresidente Senior Mantenimiento e Ingeniería', en:'Senior Vice President Maintenance & Engineering', email:'jjacome@aeromexico.com'},
// {code:12, es:'Vicepresidente Senior Aeropuertos Global', en:'Senior Vice President Global Airports', email:'aperezz@aeromexico.com'},
// {code: 12, es:'Vicepresidente Senior Abastecimientos', en:'Senior Vice President Procurement', email:'amendez@aeromexico.com'},
// {code:12, es:'Vicepresidente Senior Contraloría', en:'Senior Vice President Controller', email:'cclaguna@aeromexico.com'},
// {code:12, es:'Vicepresidente Senior Tesorería', en:'Senior Vice President Treasury', email:'acnunez@aeromexico.com'},
// {code:12, es:'Vicepresidente Senior Flota y Nuevos Negocios', en:'Senior Vice President Fleet & New Businesses', email:'malvarez@aeromexico.com'},
// {code:12, es:'Vicepresidente Senior Planeación Financiera', en:'Senior Vice President Financial Planning', email:'jwallden@aeromexico.com'},
// {code:12, es:'Vicepresidente Senior Legal y Cumplimiento', en:'Senior Vice President Legal & Compliance', email:'mcamposm@aeromexico.com'},
// {code:12, es:'Vicepresidente Senior Seguridad Corporativa y Aérea', en:'Senior Vice President Safety & Security', email:'aduhart@aeromexico.com'},
// {code:12, es:'Vicepresidente Senior Planeación de Red y Estrategia', en:'Senior Vice President Network Planning & Strategy', email:'laandahl@aeromexico.com'},
// {code:12, es:'Vicepresidente Senior Auditoría', en:'Senior Vice President Audit', email:'mtorresra@aeromexico.com'}
