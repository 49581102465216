export default function isUSABlockedUser(email) {
	// return null;
	return (
		email === 'adelrazo@aeromexico.com' ||
		// email === 'blcampos@aeromexico.com' ||
		// email === 'elopezdearkos@aeromexico.com' ||
		// email === 'fvelasco@aeromexico.com' ||
		// email === "ggomezs@aeromexico.com" ||
		// email === 'irjimenez@aeromexico.com' ||
		// email === 'isalim@aeromexico.com' ||
		// email === 'jmarino@aeromexico.com' ||
		email === 'kchon@aeromexico.com' ||
		email === 'lcalvet@aeromexico.com' ||
		email === 'mruizp@aeromexico.com' ||
		// email === 'myepez@aeromexico.com' ||
		// email === "nafernandez@aeromexico.com" ||
		email === 'pssalazar@aeromexico.com' ||
		email === 'rfigueroa@aeromexico.com'
	);
}
