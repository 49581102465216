import React, { Component } from "react";
import "../flightplan/css/flightplan.css";
import LoadingScreen from "react-loading-screen";
import avion from "../../assets/images/Spinner/avion.gif";
import close from "../../assets/logo/close.svg";
import { Link } from "react-router-dom";
import Modal from "react-awesome-modal";

var noScroll = require("no-scroll");

export default class vision extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modalIsShow: false,
			modalIsShowIdiomas: false,
			loading: false,
			error: null,
			entries: null,
			visible: false
		};

		this.escFunction = this.escFunction.bind(this);
		this.setWrapperRef = this.setWrapperRef.bind(this);
	}

	componentDidMount() {
		setTimeout(() => this.setState({ loading: false }), 1500);
		document.addEventListener("keydown", this.escFunction, false);
	}

	UNSAFE_componentWillMount() {
		document.addEventListener("mousedown", this.handleClick, false);
	}
	escFunction(event) {
		if (event.keyCode === 27) {
			this.setState({
				modalIsShow: false,
				modalIsShowIdiomas: false,
				visible: false
			});
		}
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.escFunction, false);
		document.removeEventListener("mousedown", this.handleClick, false);
	}
	handleClick = e => {
		if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
			this.setState({
				modalIsShow: false,
				modalIsShowIdiomas: false
			});
		}
	};
	setWrapperRef(node) {
		this.wrapperRef = node;
	}

	showAlertModal = () => {
		this.setState({
			modalIsShow: !this.state.modalIsShow
		});
	};

	showAlertModal01 = () => {
		this.setState({
			modalIsShowIdiomas: !this.state.modalIsShowIdiomas
		});
	};

	openModal() {
		this.setState({
			visible: true
		});
		noScroll.on();
	}

	closeModal() {
		this.setState({
			visible: false
		});
		noScroll.off();
	}

	render() {
		const { loading } = this.state;
		return (
			<LoadingScreen
				loading={loading}
				bgColor="#f5f5f5"
				spinnerColor="#007dc3"
				textColor="#0b2343"
				//logoSrc={avion}
				text="경험치 불러 오기"
			>
				<div>
					{this.state.modalIsShow === true ? (
						<div className="flightVideoModal">
							<div
								className="videoModalWindow"
								ref={this.setWrapperRef}
							>
								<div
									className="closeModal"
									onClick={this.showAlertModal}
								>
									<img src={close} alt="cerrar"></img>
								</div>
								<iframe
									width="100%"
									height="100%"
									src="https://www.youtube.com/embed/5mt3FdPdUT0"
									frameborder="0"
									allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
									allowfullscreen
								></iframe>
							</div>
						</div>
					) : (
						""
					)}

					<Modal
						visible={this.state.visible}
						width="auto"
						height="auto"
						effect="fadeInDown"
						onClickAway={() => this.closeModal()}
						className="container-modal-notification"
					>
						<div className="selectLangugeModal">
							<div className="whiteModal">
								<div
									className="closeModal"
									onClick={() => this.closeModal()}
								>
									<img src={close}></img>
								</div>

								<div className="padding">
									<h4>Selecciona un idioma</h4>
									<div className="gridFlags">
										<Link
											className="contFlag"
											to="/nosotros/plan-de-vuelo"
										>
											<div className="flags spanish"></div>
											<p>Español</p>
										</Link>

										<Link
											className="contFlag"
											to="/nosotros/plan-de-vuelo/ingles"
										>
											<div className="flags english"></div>
											<p>Inglés</p>
										</Link>

										<Link
											className="contFlag"
											to="/nosotros/plan-de-vuelo/frances"
										>
											<div className="flags french"></div>
											<p>Francés</p>
										</Link>

										<Link
											className="contFlag"
											to="/nosotros/plan-de-vuelo/holandes"
										>
											<div className="flags dutch"></div>
											<p>Holandés</p>
										</Link>

										<Link
											className="contFlag"
											to="/nosotros/plan-de-vuelo/portugues"
										>
											<div className="flags portuguese"></div>
											<p>Portugués</p>
										</Link>

										<Link
											className="contFlag"
											to="/nosotros/plan-de-vuelo/chino"
										>
											<div className="flags mandarin"></div>
											<p>Mandarín</p>
										</Link>

										<Link
											className="contFlag"
											to="/nosotros/plan-de-vuelo/coreano"
										>
											<div className="flags korean"></div>
											<p>Coreano</p>
										</Link>

										<Link
											className="contFlag"
											to="/nosotros/plan-de-vuelo/japones"
										>
											<div className="flags japan"></div>
											<p>Japonés</p>
										</Link>
									</div>
								</div>
							</div>
						</div>
					</Modal>

					<div className="flightplanWrapper">
						<div className="midflightplan">
							<h1>
								2019 <br></br> 년 운항계획
							</h1>

							<a
								className="download"
								href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EdBg9i_By-FKqjS9y8IFs-ABd5gBhfCHyevBQ90C6LMjKw?e=3FlUmL"
								target="_blank"
								rel="noopener noreferrer"
							>
								<p>다운로드</p>
							</a>

							<div className="linenamebottom"></div>

							{/*<div className="downloadFlightPlan">
                <div className="downloadIcon"></div>
                <div className="textdownload">
                  <h6>Plan de Vuelo 2019</h6>
                  <p>Descarga el Plan de Vuelo completo </p>
                </div>
          </div>*/}

							<div
								className="languageFlightPlan"
								onClick={() => this.openModal()}
							>
								<p>한국어</p>
								<div className="languageFlag korea"></div>
							</div>

							<div className="circlePlaneFlight">
								<h4 className="title color01">안전 및 보안</h4>
								<p className="securityFirst">
									<span></span>항상 안전을 첫번째로 한다
								</p>

								<h4 className="title color02">직원의 경험</h4>
								<p className="employeeExperience">
									<span></span>우리의 가치와 도덕성에 바탕을
									둔 행동으로 살아간다
								</p>

								<h4 className="title color03">소비자의 경험</h4>
								<p className="cutomerExperience">
									<span></span>최상의 서비스를 제공하고 고객의
									관점에서 프로그램을 적용한다
								</p>

								<h4 className="title color04">효율성 창출</h4>
								<p className="crearEficiencia">
									<span></span>지속적으로 영업결과를
									제출하며생산성을 강화한다
								</p>

								<h4 className="title color05">완벽한 실행</h4>
								<p className="ejecucionImpecable">
									<span></span>지속적으로 개선하는 정신으로
									항공분야에서 최상의 운항을 한다
								</p>

								<div className="planeitem"></div>
								<div className="planebg planebgKorea"></div>
							</div>
						</div>
					</div>
				</div>
			</LoadingScreen>
		);
	}
}
