import React, { Component } from "react";
import "./css/gamtalks.css";
import close from "../../assets/logo/close.svg";
import ReactPlayer from "react-player";

export default class GamTalksPage extends Component {
	constructor() {
		super();
		this.state = {
			visible: false,
			modalIsShow: false
		};
		this.escFunction = this.escFunction.bind(this);
		this.setWrapperRef = this.setWrapperRef.bind(this);
	}

	componentDidMount() {
		document.addEventListener("keydown", this.escFunction, false);
	}
	UNSAFE_componentWillMount() {
		document.addEventListener("mousedown", this.handleClick, false);
	}
	escFunction(event) {
		if (event.keyCode === 27) {
			this.setState({
				modalIsShow: false
			});
		}
	}
	componentWillUnmount() {
		document.removeEventListener("keydown", this.escFunction, false);
		document.removeEventListener("mousedown", this.handleClick, false);
	}
	handleClick = e => {
		if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
			this.setState({
				visible: false
			});
		}
	};

	setWrapperRef(node) {
		this.wrapperRef = node;
	}

	showAlertModal = () => {
		this.setState({
			modalIsShow: !this.state.modalIsShow
		});
	};

	openModal() {
		this.setState({
			visible: true
		});
	}

	closeModal() {
		this.setState({
			visible: false
		});
	}

	render() {
		return (
			<React.Fragment>
				<div key={this.props.data.sys.id}>
					{this.state.modalIsShow === true ? (
						<div className="flightVideoModal">
							<div
								className="videoModalWindow"
								ref={this.setWrapperRef}
							>
								<div
									className="closeModal"
									onClick={this.showAlertModal}
								>
									<img src={close} alt="cerrar"></img>
								</div>

								<ReactPlayer
									url={this.props.data.fields.videoLink}
									playing="false"
									width="100%"
									height="100%"
									controls="true"
									volume=".1"
								/>
							</div>
						</div>
					) : (
						""
					)}

					<div
						className="squareGamTakls"
						onClick={this.showAlertModal}
					>
						<div className="containerGamTalks">
							<div className="padding">
								<p className="area">
									<span>
										{this.props.data.fields.areaGam}
									</span>
								</p>
								<div className="bottom">
									<div className="floatText">
										<p className="title">
											{
												this.props.data.fields
													.tituloGamTalks
											}
										</p>
										<p className="name">
											{
												this.props.data.fields
													.introductionText
											}
										</p>
									</div>
									<div className="play"></div>
								</div>
							</div>
						</div>
						<img
							className="imgBackground"
							src={
								this.props.data.fields.previewVideoImagen.fields
									.file.url
							}
						></img>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
