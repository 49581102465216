 const fakeStockER = [
    {
      empleado: '301723',
      email: 'jalvaradoj@aeromexico.com',
      nombre: 'Jose Luis Alvarado Jimenez',
      rango: 'CAP',
      equipo: '787',
      contrato: 'A',
      passConstra : "Jakemate.12"
    },
  ];

  export default fakeStockER;