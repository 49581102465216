import React from 'react';
import { motion } from 'framer-motion';
import BenefitItemMX from './BenefitItemMX';
import { UTILS_COMPANIES_GLOSARY } from '../../../utils/glosarioCompanias.js';
import './NewBenefitsMX.css';
import { before } from 'lodash';

const BenefitCategoryMX = ({
	benefits,
	description,
	id,
	image,
	loading,
	title,
	level,
	companyCode,
	company
}) => {

	const isValidUser = (paymentGroupEmployee, title, category) => {
		const parsedLevel = parseInt(localStorage.getItem("nivel"));
		const userLevel = parsedLevel < 10 ? `0${parsedLevel}` : localStorage.getItem("nivel");
		const userCompanyCode = localStorage.getItem("codigo_empresa_AM");
		const beforeLevel = UTILS_COMPANIES_GLOSARY.find(item => item.code === userCompanyCode);
		const compoundValidator = beforeLevel.title === "AFI" ? "AMFormacion" : beforeLevel.title + userLevel;
		
		const found = paymentGroupEmployee.find(item => item === "grupoAMTodos" || item === compoundValidator);
		
		return !!found;
	};

	// const credentialsValidator = (data, children) => {
	// 	return true;
	// 	const switchValidator = data.paymentGroupEmployee[0];
	// 	if (switchValidator === 'grupoAMTodos') return children;
	// 	if (company === undefined || companyCode === undefined) return null;
	// 	// return children;
	// 	const glosarySelected = UTILS_COMPANIES_GLOSARY.find(
	// 		(item) => item.code === companyCode
	// 	);

	// 	const finder = glosarySelected.title + level;
	// 	const exists = data.paymentGroupEmployee.find(
	// 		(item) => item === finder
	// 	);
	// 	return exists !== undefined ? children : null;
	// };

	return loading ? (
		<motion.div
			key={`skeleton-loader-${id}`}
			className="benefits-skeleton-container my-5 flex items-start justify-center"
			initial={{ opacity: 0, y: 100 }}
			animate={{ opacity: 1, y: 0 }}
			exit={{ opacity: 0, y: 100 }}
		>
			<div className="mr-4 w-4/12">
				
				<div className="mt-3" />
				
			</div>
			<div className="w-8/12">
				
			</div>
		</motion.div>
	) : (
		<motion.div
			key={`benefit-content-${id}`}
			id={id}
			initial={{ opacity: 0, y: 100 }}
			animate={{ opacity: 1, y: 0 }}
			exit={{ opacity: 0, y: 100 }}
			className="flex w-full items-start justify-start"
			style={{ margin: '50px 0' }}
		>
			<div className="left-side-card mr-5 flex w-4/12 flex-col">
				<img
					className="benefit-category-item-img rounded-lg object-cover"
					src={image}
					alt={title}
				/>
				<h2 className="my-4 text-3xl font-medium text-amBlue">
					{title}
				</h2>
				<p className="text-sm leading-tight text-amBlue">
					{description}
				</p>
			</div>
			<div className="right-side-card flex w-8/12 flex-col items-end justify-start">
				<div className="right-side-card grid w-full grid-cols-2 gap-4 rounded-lg bg-white p-4 shadow-lg">
					{benefits
						.sort((a, b) => {
							const da = new Date(a.sys.updatedAt);
							const db = new Date(b.sys.updatedAt);

							if (da < db) return 1;
							else return -1;
						})
						.map((item) => isValidUser(item.fields.paymentGroupEmployee, item.fields.myBenefitTitle, item.fields.myBenefitCategoryTitleEs) ?
							(
								<BenefitItemMX
									key={item.fields.myBenefitTitle}

									bg={
										item.fields.myBenefitBackground.fields
											.file.url
									}
									paymentGroupEmployee={item.fields.paymentGroupEmployee}
									benefitIcon={
										item.fields.myBenefitIcon.fields.file
											.url
									}
									classNameData={
										item.fields.myBenefitCategoryClass +
										' ' +
										item.fields.paymentGroupEmployee
											.toString()
											.replace(',', ' ')
									}
									intro={item.fields.myBenefitIntro}
									titleCategoryES={
										item.fields.myBenefitCategoryTitleEs
									}
									icon={
										item.fields.myBenefitIcon.fields.file
											.url
									}
									title={item.fields.myBenefitTitle}
									myBenefitContentLong={
										item.fields.myBenefitContentLong
									}
									myBenefitMoreInformationClass={
										item.fields
											.myBenefitMoreInformationClass
									}
									myBenefitInformation={
										item.fields.myBenefitInformation
									}
									myBenefitTermsLong={
										item.fields.myBenefitTermsLong
									}
								/>
							) : null
						)}
				</div>
				<p className="mt-4 w-full text-right text-sm font-medium text-amBlue">
					*Planes de seguros contributorios
				</p>
			</div>
		</motion.div>
	);
};

export default BenefitCategoryMX;
