import React, { Component } from 'react'
import './css/recentCards.css'
import { Link } from 'react-router-dom'
// import * as contentful from "../../../lib/contentful";

export default class RecentCards extends Component {

  checkVideo = video => {
    if (typeof video === 'undefined') {
      return false
    } else {
      return true
    }
  }

  render () {
    const { data } = this.props; 
    
    return (
      <div className='recentCard'>
        <Link to={'/articles/' + data.sys.id}>
        {this.checkVideo(data.fields.articlePicture) ? (
          <div
            className='recentCard__image'
            style={{
              backgroundImage: `url(
              ${data?.fields?.articlePicture?.fields?.file?.url}
            )`
            }}
          />
          ) : (
            ''
          )}
          <h5 className='recentCard__title'>{data.fields.articleTittle}</h5>
          <p className='recentCard__text'>{data.fields.articleIntro}</p>
          <h6 className='recentCard__time'>5 minutes</h6>
        </Link>
      </div>
    )
  }
}
