import React, { Component } from 'react';
import i18next from 'i18next';
import * as contentful from '../../../lib/contentful';
import getLocale from '../../../utils/getLocale';
import Loader from '../../Loader/Loader';
import BCcard from './BCcard';

import './BenefitsCanada.css';

export default class BenefitsCanada extends Component {
	state = {
		country: this.props.country,
		loading: true,
		error: null,
		entries: null
	};

	componentDidMount() { 
		this.fetchEntries();
	}

	fetchEntries() {
		contentful.client
			.getEntries({
				content_type: 'benefitsCanada',
				include: 4,
				order: '-sys.updatedAt',
				locale: getLocale()
			})
			.then(response => response.items)
			.then(data => { 
				this.setState({
					...this.state,
					loading: false,
					entries: data
				});
			})
			.catch(error => {
				this.setState({
					...this.state,
					loading: false,
					error: error
				});
				console.error(error);
			});
	}

	render() {
		return (
			<React.Fragment>
				{this.state.loading && <Loader />}
				{!this.state.loading && (
					<div className="totalBenefits__cards__container benefitsCanada">
						{this.state.entries &&
							this.state.entries.map((benefits, index) => {
								return <BCcard key={index} data={benefits} />;
							})}
					</div>
				)}
			</React.Fragment>
		);
	}
}
