import React, { Component } from 'react';
import * as contentful from '../../../lib/contentful';
import ItemCard from './ItemCard';
import './css/articleCards.css';
import './css/tag.css';
import getLocale from '../../../utils/getLocale';

export default class ArticleCardsLanding extends Component {
	
	constructor() {
		super();
		this.state = {
			loading: true,
			error: null,
			entries: null
		};
	}

	componentDidMount = ()=> {
		this.fetchEntries();
	}

	fetchEntries = () => {
		contentful.client
			.getEntries({
				content_type: 'articleClass',
				include: 2,  
				order: '-fields.fechaDeLaPublicacion',
				locale: getLocale()
			})

			.then(response => response.items)
			.then(data => {
				this.setState({
					loading: false,
					entries: data
				});
			})

			.catch(error => {
				this.setState({
					loading: false,
					error: error
				});
			})
			.catch(err => console.error(err));
	};
	
	renderList = articles => {
		const list = articles.slice(0, 8).map(article => {
			return <ItemCard key={article.sys.id} article={article} />;
		});
		return list;
	};

	render() {
		const { loading, entries } = this.state;
		return (
			<div className="LatestWrap newsWrapperWidth">
				<div className="ArticleCardWrap">
					{!loading && entries && this.renderList(entries)}
				</div>
			</div>
		);
	}
}
