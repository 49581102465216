import { combineReducers } from 'redux';
import publicUser from './reducers/reducer-public';
import privateUser from './reducers/reducer-private';

const rootReducer = combineReducers({
	publicUser,
	privateUser
});

export default rootReducer;
