import React, { Component } from 'react';
/* import { useHistory } from 'react-router-dom'; */
import i18next from 'i18next';

export default function Buttons() {
	/* let history = useHistory(); */

	return (
		<div className="buttons-container">
			<div>
				<button
					type="button"
					onClick={() => window.history.back()}
					className="border-link"
				>
					<span>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 32 32"
							aria-hidden="true"
							focusable="false"
							class="ay bq ci cb cc cd ce bc ef bj"
						>
							<path d="M23.23 32a1.53 1.53 0 0 0 1.08-2.61L10.93 16 24.32 2.61A1.53 1.53 0 0 0 22.15.45L7.68 14.92a1.53 1.53 0 0 0 0 2.16l14.47 14.47a1.53 1.53 0 0 0 1.08.45z"></path>
						</svg>
					</span>
					<p> {i18next.t('News.regresar')} </p>
				</button>
			</div>
		</div>
	);
}

// export default class Buttons extends Component {
// 	componentDidMount() {
// 		console.log('NEWS HISTORY PROPS', Router);
// 	}

// 	static contextTypes = {
// 		router: () => true // replace with PropTypes.object if you use them
// 	};

// 	render() {
// 		return (
// 			<div className="buttons-container">
// 				{/* <button
//         className="button icon-left"
//         onClick={this.context.router.history.goBack}>
//           Back
//       </button> */}

// 				<div className="">
// 					<a
// 						className="border-link"
// 						// onClick={this.context.router.history.goBack}
// 						// onClick={Router.}
// 					>
// 						<span>
// 							<svg
// 								xmlns="http://www.w3.org/2000/svg"
// 								viewBox="0 0 32 32"
// 								aria-hidden="true"
// 								focusable="false"
// 								class="ay bq ci cb cc cd ce bc ef bj"
// 							>
// 								<path d="M23.23 32a1.53 1.53 0 0 0 1.08-2.61L10.93 16 24.32 2.61A1.53 1.53 0 0 0 22.15.45L7.68 14.92a1.53 1.53 0 0 0 0 2.16l14.47 14.47a1.53 1.53 0 0 0 1.08.45z"></path>
// 							</svg>
// 						</span>
// 						<p> {i18next.t('News.regresar')} </p>
// 					</a>
// 				</div>
// 			</div>
// 		);
// 	}
// }
