import React, { Component } from 'react';

import '../Search/css/style.css';
import * as contentful from '../../lib/contentful';

import ItemSearch from './Items/ItemSearch';
import i18next from 'i18next';
import getLocale from '../../utils/getLocale';

export default class Index extends Component {
	constructor() {
		super();
		this.state = {
			loading: true,
			error: null,
			entries: null,
			notFound: false,
			notWrite: false,
			results: 0
		};
	}
	searchInput = React.createRef();
	fetchEntries = e => {
		e.preventDefault();

		if (this.searchInput.current.value !== '') {
			contentful.client
				.getEntries({
					query: this.searchInput.current.value,
					order: 'sys.contentType.sys.id,sys.createdAt',
					locale: getLocale()
				})
				.then(response => response.items)
				.then(data => {
					
					if (data.length !== 0) {
						this.setState({
							loading: false,
							entries: data,
							notFound: false,
							notWrite: false,
							results: this.filteredItems(data).length
						});
					} else {
						this.setState({
							loading: false,
							notFound: true,
							notWrite: false,
							results: 0,
							entries: null
						});
					}
				})
				.catch(error => {
					this.setState({
						loading: false,
						error: error,
						notFound: false,
						notWrite: false,
						results: 0,
						entries: false
					});
				})
				.catch(err => console.error(err));
		} else {
			this.setState({
				notWrite: true,
				results: 0,
				entries: false
			});
		}
	};

	renderList = entries => {
		const searchResult = this.filteredItems(entries);
		const list = searchResult.map(item => {
			return <ItemSearch key={item.sys.id} data={item} />;
		});
		return list;
	};

	filteredItems(data) {
		const filteredItems = data.filter(item => {
			if (
				item.sys.contentType.sys.id !== 'headerArticlePage' &&
				item.sys.contentType.sys.id !== 'homeNotification' &&
				item.sys.contentType.sys.id !== 'myBenetifsFeatured' &&
				item.sys.contentType.sys.id !== 'seguridad' &&
				item.sys.contentType.sys.id !== 'oportunidadesDeCarreraVisibilidad' &&
				item.sys.contentType.sys.id !== 'paginasVacantes' &&
				item.sys.contentType.sys.id !== 'myTotalRewards' &&
				item.sys.contentType.sys.id !== 'paginasNosotros' &&
				item.sys.contentType.sys.id !== 'paginasLogoIdentidad' &&
				item.sys.contentType.sys.id !== 'aerolineasdelGolfo' &&
				item.sys.contentType.sys.id !==
					'aerolineasDelGolfoPreguntasYRespesutas' &&
				item.sys.contentType.sys.id !== 'ventaEspecialDestinos' &&
				item.sys.contentType.sys.id !== 'ventaEspecialLegales' &&
				item.sys.contentType.sys.id !== 'benefitsUSA' &&
				item.sys.contentType.sys.id !== 'benefitsCanada' &&
				item.sys.contentType.sys.id !== 'workTools' &&
				item.sys.contentType.sys.id !== 'gamTalks' &&
				item.sys.contentType.sys.id !== 'responsabilidadReportes' &&
				item.sys.contentType.sys.id !== 'defendamosNuestroCieloNoticias'
			) {
				return item;
			}
		});
		return filteredItems;
	}

	render() {
		const { loading, error, entries, notFound, notWrite, results } = this.state;
		return (
			<div className="page search">
				<div className="search-container">
					<div className="box-container-search">
						<p className="title-search">
							{i18next.t('MainMenu.barraBuscar.busqueda')}
						</p>
						<form className="form-search" onSubmit={this.fetchEntries}>
							<div className="search-sub-container">
								<input
									className="input-search"
									name="search"
									placeholder=""
									ref={this.searchInput}
									/*onChange={this.fetchEntries}*/
								/>
								<button className="button-search">
									<i
										className="fa fa-search button-search-icon"
										aria-hidden="true"
									/>
								</button>
							</div>
						</form>
					</div>
				</div>
				<div className="Info-search-result">
					<div className="info-search">
						<h2 className="qty-results">
							{i18next.t('MainMenu.barraBuscar.cercaDe')} ({results}){' '}
							{i18next.t('MainMenu.barraBuscar.resultados')}
						</h2>
						{notFound && (
							<span>{i18next.t('MainMenu.barraBuscar.noResultados')}</span>
						)}
						{notWrite && (
							<span>{i18next.t('MainMenu.barraBuscar.noDato')}</span>
						)}
						{error && <span>{error}</span>}
					</div>
					{!loading && entries && (
						<ul className="item-list-search">{this.renderList(entries)}</ul>
					)}
				</div>
			</div>
		);
	}
}
