import React, { Component } from 'react';
import dot from '../images/cityDot.png';
import deafult from '../../../assets/map/default.png';
export default class Marker extends Component {
	
	render() {
		return (
			<div className="marker-point">
				<img className="dot-image" src={dot} title={this.props.data.partida} />
				<div className="tooltip-map-wrapper">
					<div className="tooltip-title">
						<span className="tooltip-map-name">
							{'(' + this.props.data.code + ') ' + this.props.data.partida}
						</span>
					</div>
					<div className="tooltip-content">
						<div className="wrapper-image-tooltip">
							<img className="image-destination" src={deafult} />
						</div>
						<span className="tooltip-map-description">{this.props.data.description}</span>
						<button onClick={this.hola(this.props.data.code)}>onClick </button>
					</div>
				</div>
			</div>
		);
	}
}
