import React, { Component } from "react";
import Aside from "../Profile/AsideProfile";
import "./css/triques.css";
import TriquesAutos from "./triques-autos";
import coche from "../../assets/triques/carro.png";
import imac from "../../assets/triques/imac.png";
import estufa from "../../assets/triques/estufa.png";
import playera from "../../assets/triques/playera.png";
import box from "../../assets/triques/box.png";
import triqueslogo from "../../assets/triques/triques.svg";
import CookieConsent from "react-cookie-consent";
import i18next from "i18next";

import { Trans } from "react-i18next";
import Index from "../Footer/index";
import { isLocal, isFromDev, isFromQA } from "./../../core/Ambientes";

export default class MyHR extends Component {
	constructor() {
		super();
		this.state = {
			loading: true,
			tabActive: "todos"
		};
	}

	componentDidMount() {
		setTimeout(() => this.setState({ loading: false }), 1500);
	}

	tabSelector = (evt) => {
		this.setState({
			tabActive: evt.currentTarget.attributes.name.textContent
		});
	};

	render() {
		// const { loading } = this.state;
		return (
			<div className="full-container flex " style={{marginTop:'-2%'}}>
				<CookieConsent
					location="none"
					containerClasses="containerCoookie"
					buttonText={i18next.t("Trueques.comenzar")}
					cookieName="modalCookieTriques"
					buttonClasses="buttonAcceptCookieTriques"
					disableButtonStyles="true"
					style={{
						background: "rgba(0,0,0,.8)",
						position: "fixed",
						top: "0%",
						width: "100%",
						height: "100vh",
						left: "0"
					}}
					expires={365}>
					<div className="modalWhiteWelcome modalTextTriques">
						<p className="title">
							<Trans i18nKey="Trueques.title"></Trans>
						</p>
						<p className="subtitle">{i18next.t("Trueques.desc")}</p>
					</div>
				</CookieConsent>

				<div className="w-1/8 p-4">
					<div className="contProfileMenu" style={{overflowY:'auto'}}>
						<div class="profileContainers" id="profileMenu">
							<Aside />
						</div>
					</div>
				</div>

				<div className="w-100">
					<div className="myHR__container" style={{ backgroundColor: "white", marginBottom: "80px", overflowY:'auto' , height:'auto'}}>
						<div>
							<div className="titleTriques">
								<p>Triques y Trueques</p>
							</div>
							<div className="menuSelectionTriques">
								<div className="wrapSelection">
									<div
										className={
											this.state.tabActive === "todos"
												? "contOptionSelection active"
												: "contOptionSelection"
										}
										onClick={this.tabSelector}
										name="todos">
										<img src={box} alt="Todos"></img>
										<p>{i18next.t("Trueques.titlecatego4")}</p>
										<div className="activeBar"></div>
									</div>
									<div
										className={
											this.state.tabActive === "autos"
												? "contOptionSelection active"
												: "contOptionSelection"
										}
										onClick={this.tabSelector}
										name="autos">
										<img src={coche} alt="Coche"></img>
										<p>{i18next.t("Trueques.titlecatego1")}</p>
										<div className="activeBar"></div>
									</div>

									<div
										className={
											this.state.tabActive === "computo"
												? "contOptionSelection active"
												: "contOptionSelection"
										}
										onClick={this.tabSelector}
										name="computo">
										<img src={imac} alt="iMac"></img>
										<p>{i18next.t("Trueques.titlecatego2")}</p>
										<div className="activeBar"></div>
									</div>

									<div
										className={
											this.state.tabActive === "electronics"
												? "contOptionSelection active"
												: "contOptionSelection"
										}
										onClick={this.tabSelector}
										name="electronics">
										<img src={estufa} alt="Estufa"></img>
										<p>{i18next.t("Trueques.titlecatego3")}</p>
										<div className="activeBar"></div>
									</div>

									{/* <div
									className={
										this.state.tabActive === 'phones'
											? 'contOptionSelection active'
											: 'contOptionSelection'
									}
									onClick={this.tabSelector}
									name="phones"
								>
									<img src={phone} alt="Teléfono"></img>
									<p>{i18next.t('Trueques.titlecatego4')}</p>
									<div className="activeBar"></div>
								</div> */}

									<div
										className={
											this.state.tabActive === "ropa"
												? "contOptionSelection active"
												: "contOptionSelection"
										}
										onClick={this.tabSelector}
										name="ropa">
										<img src={playera} alt="Playera"></img>
										<p>{i18next.t("Trueques.titlecatego5")}</p>
										<div className="activeBar"></div>
									</div>

									<div
										className={
											this.state.tabActive === "otros"
												? "contOptionSelection active"
												: "contOptionSelection"
										}
										onClick={this.tabSelector}
										name="otros">
										<img src={box} alt="Caja"></img>
										<p>{i18next.t("Trueques.titlecatego6")}</p>
										<div className="activeBar"></div>
									</div>
								</div>
							</div>

							{/* INIT ANUNCIO DE FORMULARIO DE TRIQUES Y TRUEQUES */}

							<div className="bluemidWrap">
								<div class="totalBenefits__title__container beneficiosfloatright">
									<img class="iconTitleProfile" src={triqueslogo} alt="Triques y Trueques Logo" />
									<h1>
										<Trans i18nKey="Trueques.footertitle"></Trans>
									</h1>
								</div>
								<p className="des">
									Para poder publicar tu producto en venta/renta, por favor da click en el botón y{" "}
									<strong>llena el formulario con la información necesaria</strong>.
								</p>

								<a
									// href="https://capi.miaeromexico.com/triques-trueques"
									href="https://capi.ngrok.pro/triques-trueques"
									className="buynow"
									target="_blank"
									rel="noopener noreferrer">
									Enviar Información
								</a>
							</div>

							{/* END ANUNCIO DE FORMULARIO DE TRIQUES Y TRUEQUES */}

							<div className="profile-tabs-content midWrapBlueTriques">
								{this.state.tabActive === "autos" ? (
									<div id="autosBoxTriques" className="wrapperBoxesTriques">
										<h2>{i18next.t("Trueques.desccatego1")}</h2>
										<TriquesAutos category="vehiculos" />
									</div>
								) : "" || this.state.tabActive === "computo" ? (
									<div id="computoBoxTriques" className="wrapperBoxesTriques">
										<h2>{i18next.t("Trueques.desccatego2")}</h2>
										<TriquesAutos category="electronica" />
									</div>
								) : "" || this.state.tabActive === "electronics" ? (
									<div id="electroBoxTriques" className="wrapperBoxesTriques">
										<h2>{i18next.t("Trueques.desccatego3")}</h2>
										<TriquesAutos category="bienesRaices" />
									</div>
								) : "" || this.state.tabActive === "todos" ? (
									<div id="phonesBoxTriques" className="wrapperBoxesTriques">
										<h2>{i18next.t("Trueques.desccatego4")}</h2>
										<TriquesAutos category="todos" />
									</div>
								) : "" || this.state.tabActive === "ropa" ? (
									<div id="ropaBoxTriques" className="wrapperBoxesTriques">
										<h2>{i18next.t("Trueques.desccatego5")}</h2>
										<TriquesAutos category="ropaAccesorios" />
									</div>
								) : "" || this.state.tabActive === "otros" ? (
									<div id="otrosBoxTriques" className="wrapperBoxesTriques">
										<h2>{i18next.t("Trueques.desccatego6")}</h2>
										<TriquesAutos category="servicios" />
									</div>
								) : (
									""
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
