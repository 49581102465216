import React, { Component } from "react";
import "./css/responsabilidadGrid.css";
import ReportesGrid from "./reportesGrid";
// import "./css/responsabilidad.css";
import wave from "../../assets/wave.svg";
import { Parallax, Background } from "react-parallax";
import styled from "styled-components";
import pdf from "../../assets/identidad/pdf.svg";
// import SwiperSocial from "./swipersocial";
import {
	Link,
	DirectLink,
	Element,
	Events,
	animateScroll as scroll,
	scrollSpy,
	scroller
} from "react-scroll";
import Fade from "react-reveal/Fade"; 
import ReactPlayer from "react-player";
import close from "../../assets/logo/close.svg";
import i18next from "i18next";
import { Trans } from "react-i18next"; 

const ReporteSusten = styled.div`
	.wrapperIdenditad {
		grid-template-columns: repeat(5, 1fr);
		padding: 20px 0 0 !important;
		width: 100% !important;
	}
`;

export default class vision extends Component {
	constructor() {
		super();
		this.state = {
			tabActive: "reportes",
			modalIsShow: false,
			loading: true,
			error: null
		};
		// this.scrollRef = React.createRef();

		this.escFunction = this.escFunction.bind(this);
		// this.setWrapperRef = this.setWrapperRef.bind(this);
	}

	tabSelector = evt => {
		this.setState({
			tabActive: evt.currentTarget.attributes.name.textContent
		});
	};

	componentDidMount() {
		// document.addEventListener("keydown", this.escFunction, false);
		// window.addEventListener("scroll", this.handleScroll);
		setTimeout(() => this.setState({ loading: false }), 1500);
		scroll.scrollToTop();
	}

	UNSAFE_componentWillMount() {
		document.addEventListener("mousedown", this.handleClick, false);
	}

	escFunction(event) {
		if (event.keyCode === 27) {
			this.setState({
				modalIsShow: false,
				modalIsShowGallery: false
			});
		}
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.escFunction, false);
		document.removeEventListener("mousedown", this.handleClick, false);
	}
	handleClick = e => {
		if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
			this.setState({
				modalIsShow: false,
				modalIsShowGallery: false
			});
		}
	};


	setWrapperRef(node) {
		this.wrapperRef = node;
	}

	showAlertModal = () => {
		this.setState({
			modalIsShow: !this.state.modalIsShow
		});
	};

	render() {
		const { loading } = this.state;
		return (
			<div className="reponsabilidadPage">
				{/* <Parallax
                bgImage={require('../')}
                bgImageAlt="the cat"
                strength={-350}
                bgImageStyle={{height: '900px'}}
            > */}

				{/* MODAL VIDEO */}
				{this.state.modalIsShow === true ? (
					<div className="flightVideoModal">
						<div
							className="videoModalWindow"
							ref={this.setWrapperRef}
						>
							<div
								className="closeModal"
								onClick={this.showAlertModal}
							>
								<img src={close}></img>
							</div>

							<ReactPlayer
								url="https://jabadia.wistia.com/medias/pzhm4ot8iv"
								playing="true"
								width="100%"
								height="100%"
								controls="true"
								volume=".1"
							/>
						</div>
					</div>
				) : (
					""
				)}

				<Parallax
					bgImage={require("../../assets/vuela-verde/wall-header1.jpg")}
					bgImageAlt="vuela"
					strength={-350}
					bgImageStyle={{ height: "1100px" }}
				>
					<div className="headerResponsabilidad">
						<Fade big cascade>
							<div className="midWrapper">
								{/* <div className="logoAlas"></div> */}
								{/* <p className="title">Sostenibilidad</p> */}
								<p className="subtitle subBig">
									{i18next.t("Sostenib.main")}
								</p>
								<p className="subtitle">
									{i18next.t("Sostenib.sub")}
								</p>
								{/* ../../assets/vuela-verde/vuela-verde.jpg */}
							</div>
						</Fade>

						{/* <div className="videoCXEvolution">
                  <video className='video' autoPlay muted loop playsInline >
                    <source src={videoCX}/>
                  </video>
              </div> */}
					</div>
				</Parallax>

				<div className="pilaresResponsabilidad">
					<div className="midPilares">
						<Fade top cascade>
							<div>
								<p className="pilaresTitle">
									{i18next.t("Sostenib.pilares.title")}
								</p>
								<p className="pilaresIntro">
									{i18next.t("Sostenib.pilares.sub")}
								</p>
							</div>
						</Fade>

						<div className="wrapperPilaresGrid">
							<Fade bottom>
								<div className="opcPilarBox">
									<div className="imagePilar" />
									<div className="textPilar">
										<p>
											{i18next.t(
												"Sostenib.pilares.respon.title"
											)}
										</p>
										<p>
											{i18next.t(
												"Sostenib.pilares.respon.sub"
											)}
										</p>
									</div>
								</div>

								<div className="opcPilarBox">
									<div className="imagePilar" />
									<div className="textPilar">
										<p>
											{i18next.t(
												"Sostenib.pilares.practicas.title"
											)}
										</p>
										<p>
											{i18next.t(
												"Sostenib.pilares.practicas.sub"
											)}
										</p>
									</div>
								</div>

								<div className="opcPilarBox">
									<div className="imagePilar" />
									<div className="textPilar">
										<p>
											{i18next.t(
												"Sostenib.pilares.sustenta.title"
											)}
										</p>
										<p>
											{i18next.t(
												"Sostenib.pilares.sustenta.sub"
											)}
										</p>
									</div>
								</div>

								<div className="opcPilarBox">
									<div className="imagePilar" />
									<div className="textPilar">
										<p>
											{i18next.t(
												"Sostenib.pilares.vincula.title"
											)}
										</p>
										<p>
											{i18next.t(
												"Sostenib.pilares.vincula.sub"
											)}
										</p>
									</div>
								</div>
							</Fade>
						</div>
					</div>
				</div>

				<Parallax
					bgImage={require("../../assets/vuela-verde/greenbuildings.jpg")}
					bgImageAlt="vuela"
					strength={-350}
					bgImageStyle={{ height: "880px" }}
				>
					<div className="vuelaverdeAlas" />
				</Parallax>

				<div className="AlasMundoWrapper">
					<div className="midCenterAlas">
						<div className="whiteAlas">
							<div className="paddingAlas">
								<Fade top cascade>
									<div>
										<p className="px-5 px-lg-0 title">
											<Trans i18nKey="Sostenib.soste.title"></Trans>
										</p>
										<p className="p-5  px-lg-0 subtitle">
											{i18next.t("Sostenib.soste.sub")}
										</p>
									</div>
								</Fade>

								<div className="alasBoxGrid">
									<Fade bottom>
										<a
											className="BoxContWrapper"
											href="https://www.aeromexico.com/es-mx/acerca-de-aeromexico/empresa-verde/vuela-verde"
											target="_blank"
											rel="noopener noreferrer"
										>
											<Fade big cascade>
												<div className="bottomText">
													<p>
														{i18next.t(
															"Sostenib.soste.compensa.title"
														)}
													</p>
													<p>
														{i18next.t(
															"Sostenib.soste.compensa.sub"
														)}{" "}
														<span>
															{i18next.t(
																"Sostenib.soste.compensa.conoce"
															)}
														</span>
													</p>
												</div>
											</Fade>
										</a>

										<a className="BoxContWrapper">
											<Fade big cascade>
												<div className="bottomText">
													<p>
														{i18next.t(
															"Sostenib.soste.alas.title"
														)}
													</p>
													<p>
														{i18next.t(
															"Sostenib.soste.alas.sub"
														)}
													</p>
												</div>
											</Fade>
										</a>
									</Fade>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div
					className="pilaresResponsabilidad reportes-sustentabilidad"
					id="reportes-sostenibilidad"
				>
					<Fade left cascade>
						<div className="midPilares">
							<p className="pilaresTitle">
								<span />
								<Trans i18nKey="Sostenib.informes.title"></Trans>
							</p>
							<p className="pilaresIntro">
								{i18next.t("Sostenib.informes.sub")}
							</p>
						</div>
					</Fade>
					
					<br className="d-block" />
					<br className="d-block" />
					<div className="tabsReporteWrapper">
						<div className="midTabs">
							<div className="tabResponsive">
								<div
									className={
										this.state.tabActive === "reportes"
											? "tabBox tabBoxActive"
											: "tabBox"
									}
									onClick={this.tabSelector}
									name="reportes"
								>
									<p style={{fontWeight:'bold'}}>
										{i18next.t(
											"Sostenib.informes.informes"
										)}
									</p>
								</div>

								<div
									className={
										this.state.tabActive === "politicas"
											? "tabBox tabBoxActive"
											: "tabBox"
									}
									onClick={this.tabSelector}
									name="politicas"
								>
									<p style={{fontWeight:'bold'}} >
										{i18next.t(
											"Sostenib.informes.politicas"
										)}
									</p>
								</div>

								<div
									className={
										this.state.tabActive === "declaraciones"
											? "tabBox tabBoxActive"
											: "tabBox"
									}
									onClick={this.tabSelector}
									name="declaraciones"
								>
									<p>
										<p style={{fontWeight:'bold'}}>
											{i18next.t(
												"Sostenib.informes.declara"
											)}
										</p>
									</p>
								</div>
							</div>
						</div>
					</div>

					{this.state.tabActive === "reportes" ? (
						
							<div
								className="wrapperDownload"
								id="midWrapperPdfSocial"
							>
								<div
									className="wrapperIdenditad"
									id="reportesResponsabilidad"
								>
									<ReportesGrid />
								</div>
							</div>
						
					) : "" || this.state.tabActive === "politicas" ? (
						
							<div
								className="wrapperDownload"
								id="midWrapperPdfSocial"
							>
								<div
									className="wrapperIdenditad"
									id="reportesPoliticas"
								>
									<ReportesGrid />
								</div>
							</div>
						
					) : "" || this.state.tabActive === "declaraciones" ? (
						
							<div
								className="wrapperDownload"
								id="midWrapperPdfSocial"
							>
								<div
									className="wrapperIdenditad"
									id="reportesDeclaracion"
								>
									<ReportesGrid />
								</div>
							</div>
						
					) : (
						""
					)}
				</div>

				{/* </Parallax> */}

				{/* <div className="wave" aria-hidden="true">
          <img className="svgWave" src={wave}></img>
        </div> */}
			</div>
		);
	}
}
