import React from "react";
import { withRouter } from "react-router-dom";

import "./style.css";
import { ReactComponent as Ambot } from "../../assets/ambot.svg";

const AmbotComponent = ({ location, match, history }) => {
	// let location = useLocation();
	return null;
	// if (location.pathname !== '/inicio') {
	// 	return (
	// 		<a
	// 			className="bubble-holder z-999"
	// 			href="https://teams.microsoft.com/l/chat/0/0?users=28:74712901-6ea0-4ade-8af2-e4b76800631f"
	// 			target="_blank"
	// 			rel="noopener noreferrer"
	// 		>
	// 			<Ambot />
	// 		</a>
	// 	);
	// } else {
	// 	return null;
	// }
};

export default withRouter(AmbotComponent);
