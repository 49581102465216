import React, { Component } from 'react';
import ReactDOM from 'react-dom'; 
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import '@okta/okta-signin-widget/dist/css/okta-theme.css';
import OktaSignIn from '@okta/okta-signin-widget/dist/js/okta-sign-in.min.js';
// import OktaSignIn from '@okta/okta-signin-widget';
import logoImage from './logo-miam.png';

export default class SignInWidget extends Component {
  constructor(props) {
    super(props);
    this.widgetRef = React.createRef();
  }

  componentDidMount() {
    const el = this.widgetRef.current;

    this.widget = new OktaSignIn({
      baseUrl: this.props.baseUrl,
      logo: logoImage,
      logoText: 'Logo Mi Aeromexico',
      // redirectUri: 'http://localhost:4032',
      // authParams: {issuer:'https://gateone.amx-digital.com/oauth2/default'},
      // clientId: '0oa2l5lu2ywEjXJpi4x7'
    }); 
    this.widget.renderEl({ el }, this.props.onSuccess, this.props.onError);
  }

  
  componentWillUnmount() {
    this.widget.remove();
  }

  render() {
    return <div ref={this.widgetRef}></div>;
  }
}
